import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/station_dividend",
  name: "station_dividend",
  component: function component() {
    return import("../../../views/extension/service_station_dividend/index.vue");
  },
  meta: {
    foot: true
  }
}];