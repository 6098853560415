import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//区域代理开发
{
  path: "/outArea/areaList",
  component: function component() {
    return import("../../../views/outArea/outArea.vue");
  },
  name: "outAreaList",
  meta: {
    title: "区域外服务佣金",
    foot: true
  }
}, {
  path: "/outArea/areaSelect",
  component: function component() {
    return import("../../../views/outArea/areaSelect/areaSelect.vue");
  },
  name: "outAreaSelect",
  meta: {
    title: "区域选择",
    foot: true
  }
},
//爱心值二开
{
  path: "/love/loveValue",
  component: function component() {
    return import("../../../views/outArea/loveValue/loveValue.vue");
  },
  name: "loveValue",
  meta: {
    title: "爱心值释放",
    foot: true
  }
},
//余额手续费奖励开发
{
  path: "/balanceAward",
  component: function component() {
    return import("../../../views/outArea/balanceAward/balanceAward.vue");
  },
  name: "balanceAward",
  meta: {
    title: "余额手续费奖励",
    foot: true
  }
},
//单单有喜
{
  path: "/onlyHappy",
  component: function component() {
    return import("../../../views/outArea/onlyHappy/onlyHappy.vue");
  },
  name: "onlyHappy",
  meta: {
    title: "单单有喜",
    foot: true
  }
}];