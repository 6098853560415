import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/periodPay/list",
  name: "periodPay",
  component: function component() {
    return import("../../../views/period_pay/period_pay.vue");
  },
  meta: {
    title: "支付宝周期扣款",
    foot: false
  }
}];