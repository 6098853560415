import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//distribution-coupon 配销券
{
  path: "/distributionCoupon",
  component: function component() {
    return import("../../../views/others/distributionCoupon.vue");
  },
  name: "distributionCoupon",
  meta: {
    title: "配销券",
    foot: true
  }
}];