import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//咖啡机对接
{
  path: "/coffee_machine",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine.vue");
  },
  name: "coffee_machine",
  meta: {
    title: "设备管理",
    foot: true
  }
}, {
  path: "/coffee_machineIndex/:machine_id",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machineIndex.vue");
  },
  name: "coffee_machineIndex",
  meta: {
    title: "门店中心",
    foot: true
  }
}, {
  path: "/coffee_machine_nearby",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine_nearby.vue");
  },
  name: "coffee_machine_nearby",
  meta: {
    title: "附近设备",
    foot: true
  }
}, {
  path: "/coffee_machine_record/:machine_id",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine_record.vue");
  },
  name: "coffee_machine_record",
  meta: {
    title: "收入明细",
    foot: true
  }
}, {
  path: "/coffee_machine_verification/:order_id",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine_verification.vue");
  },
  name: "coffee_machine_verification",
  meta: {
    title: "核销码列表",
    foot: true
  }
},
// 咖啡机商品管理
{
  path: "/coffee_machine_good/:machine_id",
  component: function component() {
    return import("../../../views/member/supplier/commodity_management/management.vue");
  },
  name: "coffee_machine_good",
  meta: {
    title: "商品管理",
    foot: true
  }
}, {
  path: "/coffee_machine_cate/:machine_id/:category?",
  component: function component() {
    return import("../../../views/category/category");
  },
  name: "coffee_machine_cate",
  meta: {
    title: "商品列表",
    foot: true
  }
}, {
  path: "/coffee_machine_search/:machine_id",
  component: function component() {
    return import("../../../views/search.vue");
  },
  name: "coffee_machine_search",
  meta: {
    title: "商品列表",
    foot: true
  }
}, {
  path: "/coffee_machine_location",
  component: function component() {
    return import("../../../views/o2o/location/home.vue");
  },
  name: "coffee_machine_location",
  meta: {
    title: "",
    foot: true
  }
}];