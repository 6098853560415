import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var StatePointsDividend = function StatePointsDividend(r) {
  return require(["../../../views/sino_skill/static_points_dividend/index.vue"], r);
};
export default [{
  path: "/static_points_dividend",
  name: "static_points_dividend",
  component: StatePointsDividend,
  meta: {
    foot: true
  }
}, {
  path: "/weekly_reward",
  name: "weekly_reward",
  component: function component() {
    return import("../../../views/sino_skill/weekly_reward/index.vue");
  },
  meta: {
    title: "周度奖励",
    foot: true
  }
}, {
  path: "/weekly_reward_order/:num?",
  name: "weekly_reward_order",
  component: function component() {
    return import("../../../views/sino_skill/weekly_reward/order.vue");
  },
  meta: {
    foot: true
  }
}];