import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//海报中心
{
  path: "/newPosterIndex",
  component: function component() {
    return import("../../../views/newPoster/newPosterIndex.vue");
  },
  name: "newPosterIndex",
  meta: {
    title: "海报中心",
    foot: true
  }
}];