import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/txTeamPerformance/apply",
  component: function component() {
    return import("../../../views/dev-plug/tx-team-performance/apply.vue");
  },
  name: "txTeamPerformanceApply",
  meta: {
    title: "",
    foot: true,
    notKeepAlive: true
  }
}];