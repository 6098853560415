import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 消费券插件 路由表
export default [
//消费券插件页面
{
  path: "/merchantsIn",
  component: function component() {
    return import("../../../views/consumption/merchantsIn/merchantsIn.vue");
  },
  name: "merchantsIn",
  meta: {
    title: "商户入驻",
    foot: true
  }
}, {
  path: "/business",
  component: function component() {
    return import("../../../views/consumption/business/business.vue");
  },
  name: "business",
  meta: {
    title: "商家助手",
    foot: true
  }
}, {
  path: "/commodity",
  component: function component() {
    return import("../../../views/consumption/commodity/commodity.vue");
  },
  name: "commodity",
  meta: {
    title: "商家管理",
    foot: true
  }
}, {
  path: "/goodsDetails",
  component: function component() {
    return import("../../../views/consumption/goodsDetails/goodsDetails.vue");
  },
  name: "goodsDetails",
  meta: {
    title: "商品详情",
    foot: true
  }
}, {
  path: "/shopList",
  component: function component() {
    return import("../../../views/consumption/shopList/shopList.vue");
  },
  name: "shopList",
  meta: {
    title: "适用门店",
    foot: true
  }
}, {
  path: "/orderAffirm",
  component: function component() {
    return import("../../../views/consumption/orderAffirm/orderAffirm.vue");
  },
  name: "orderAffirm",
  meta: {
    title: "订单确认",
    foot: true
  }
}, {
  path: "/ticketOrderList",
  component: function component() {
    return import("../../../views/consumption/ticketOrderList/ticketOrderList.vue");
  },
  name: "ticketOrderList",
  meta: {
    title: "订单列表",
    foot: true
  }
}, {
  path: "/orderDetails",
  component: function component() {
    return import("../../../views/consumption/orderDetails/orderDetails.vue");
  },
  name: "orderDetails",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/sellDetail",
  component: function component() {
    return import("../../../views/consumption/sellDetail/sellDetail.vue");
  },
  name: "sellDetail",
  meta: {
    title: "售卖明细",
    foot: true
  }
}, {
  path: "/incomeDetail",
  component: function component() {
    return import("../../../views/consumption/incomeDetail/incomeDetail.vue");
  },
  name: "incomeDetail",
  meta: {
    title: "收益明细",
    foot: true
  }
}, {
  path: "/verifierList",
  component: function component() {
    return import("../../../views/consumption/verifierList/verifierList.vue");
  },
  name: "verifierList",
  meta: {
    title: "核销员管理",
    foot: true
  }
}, {
  path: "/verifier",
  component: function component() {
    return import("../../../views/consumption/verifier/verifier.vue");
  },
  name: "verifier",
  meta: {
    title: "添加核销员",
    foot: true
  }
}, {
  path: "/verificationDetails",
  component: function component() {
    return import("../../../views/consumption/verificationDetails/verificationDetails.vue");
  },
  name: "verificationDetails",
  meta: {
    title: "核销明细",
    foot: true
  }
}, {
  path: "/consumption/search",
  component: function component() {
    return import("../../../views/consumption/search/search.vue");
  },
  name: "consumptionSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/verification",
  component: function component() {
    return import("../../../views/consumption/verification/verification.vue");
  },
  name: "verification",
  meta: {
    title: "核销",
    foot: true
  }
}, {
  path: "/consumption/evaluate",
  component: function component() {
    return import("../../../views/consumption/evaluate/evaluate.vue");
  },
  name: "conevaluate",
  meta: {
    title: "评价",
    foot: true
  }
}, {
  path: "/clientEvaluation",
  component: function component() {
    return import("../../../views/consumption/clientEvaluation/clientEvaluation.vue");
  },
  name: "clientEvaluation",
  meta: {
    title: "客户评价",
    foot: true
  }
}, {
  path: "/refundOrder",
  component: function component() {
    return import("../../../views/consumption/refundOrder/refundOrder.vue");
  },
  name: "refundOrder",
  meta: {
    title: "申请退款",
    foot: true
  }
}, {
  path: "/commercial",
  component: function component() {
    return import("../../../views/consumption/commercial/commercial.vue");
  },
  name: "commercial",
  meta: {
    title: "商户信息",
    foot: true
  }
}, {
  path: "/consumption/searchPage",
  component: function component() {
    return import("../../../views/consumption/searchPage/searchPage.vue");
  },
  name: "consumeSearchPage",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/consumption/refundProcess",
  component: function component() {
    return import("../../../views/consumption/refundProcess/refundProcess.vue");
  },
  name: "refundProcess",
  meta: {
    title: "退款详情",
    foot: true
  }
}, {
  path: "/consumption/copyWriting",
  component: function component() {
    return import("../../../views/consumption/copyWriting/copyWriting.vue");
  },
  name: "copyWriting",
  meta: {
    title: "文案分享",
    foot: true
  }
}, {
  path: "/consumption/project",
  component: function component() {
    return import("../../../views/consumption/project/project.vue");
  },
  name: "projectManage",
  meta: {
    title: "项目组管理",
    foot: true
  }
}, {
  path: "/consumption/directly",
  component: function component() {
    return import("../../../views/consumption/directly/directly.vue");
  },
  name: "projectDirectly",
  meta: {
    title: "直属小组",
    foot: true
  }
}];