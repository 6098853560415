import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 能量值
export default [{
  path: "/energy/energy",
  name: "energyValue",
  component: function component() {
    return import("../../../views/energy/energy.vue");
  },
  meta: {
    title: "能量值",
    foot: true
  }
}, {
  path: "/energy_contribution",
  name: "contribution",
  component: function component() {
    return import("../../../views/energy/contribution.vue");
  },
  meat: {
    foot: true
  }
}];