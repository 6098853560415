import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/videoCourse/member",
  name: "courseMember",
  component: function component() {
    return import("../../../views/videoCourse/courseMember/member");
  },
  meta: {
    title: "企业微信课程视频",
    foot: true
  }
}, {
  path: "/videoCourse/search",
  name: "courseSearch",
  component: function component() {
    return import("../../../views/videoCourse/courseSearch/search");
  },
  meta: {
    title: "搜索页",
    foot: true
  }
}, {
  path: "/videoCourse/groupData/:id",
  name: "courseGroupData",
  component: function component() {
    return import("../../../views/videoCourse/groupData/groupData");
  },
  meta: {
    title: "群数据",
    foot: true
  }
}, {
  path: "/videoCourse/groupMember",
  name: "groupMember",
  component: function component() {
    return import("../../../views/videoCourse/groupMember/groupMember");
  },
  meta: {
    title: "群会员",
    foot: true
  }
}, {
  path: "/videoCourse/datum/:id",
  name: "courseDatum",
  component: function component() {
    return import("../../../views/videoCourse/courseDatum/datum");
  },
  meta: {
    title: "课程视频数据",
    foot: true
  }
}, {
  path: "/videoCourse/memberDetails/",
  name: "memberDetails",
  component: function component() {
    return import("../../../views/videoCourse/memberDetails/memberDetails");
  },
  meta: {
    title: "课程视频详情",
    foot: true
  }
}, {
  path: "/videoCourse/videoDetails/:id",
  name: "videoDetails",
  component: function component() {
    return import("../../../views/videoCourse/videoDetails/videoDetails");
  },
  meta: {
    title: "课程视频详情",
    foot: true
  }
}, {
  path: "/videoCourse/classifyCourse",
  name: "classifyCourse",
  component: function component() {
    return import("../../../views/videoCourse/classifyCourse/classifyCourse");
  },
  meta: {
    title: "企业微信课程视频",
    foot: true
  }
}, {
  path: "/videoCourse/answerData/:id",
  name: "answerData",
  component: function component() {
    return import("../../../views/videoCourse/answerData/answerData");
  },
  meta: {
    title: "答题数据",
    foot: true
  }
}, {
  path: "/videoCourse/answerLive/:id",
  name: "answerLive",
  component: function component() {
    return import("../../../views/videoCourse/answerLive/answerLive");
  },
  meta: {
    title: "在线观看用户",
    foot: true
  }
}];