import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// #24886
export default [{
  path: "/expressSignature",
  component: function component() {
    return import("../../../views/extension/express_signature/index");
  },
  name: "expressSignature",
  meta: {
    title: "快递签收",
    foot: false
  }
}, {
  path: "/receiptNumber/:express_sn?",
  component: function component() {
    return import("../../../views/extension/express_signature/receipt_number.vue");
  },
  name: "receiptNumber",
  meta: {
    title: "快递签收",
    foot: true
  }
}, {
  path: "/scanRecord",
  component: function component() {
    return import("../../../views/extension/express_signature/scan_record.vue");
  },
  name: "scanRecord",
  meta: {
    title: "扫描记录",
    foot: true
  }
}, {
  path: "/signHome",
  component: function component() {
    return import("../../../views/extension/express_signature/sign_home.vue");
  },
  name: "signHome",
  meta: {
    title: "商城名称",
    foot: false
  }
}, {
  path: "/expressSearch",
  component: function component() {
    return import("../../../views/extension/express_signature/search.vue");
  },
  name: "expressSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}];