var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "crmBind" } },
    [
      _c(
        "van-dialog",
        {
          attrs: {
            "show-cancel-button": "",
            "cancel-button-text": "联系客服",
            "confirm-button-text": "关闭"
          },
          on: { cancel: _vm.linkService },
          model: {
            value: _vm.showSuccess,
            callback: function($$v) {
              _vm.showSuccess = $$v
            },
            expression: "showSuccess"
          }
        },
        [
          _c("div", { staticClass: "crm-binb-success" }, [
            _c("div", { staticClass: "title" }, [_vm._v("恭喜您注册成功！")]),
            _c(
              "div",
              { staticStyle: { "line-height": "24px" } },
              [
                _vm._v(" 系统检测到您是益生康健会员已为您自动绑定，"),
                _vm.selectMember && _vm.selectMember.Address != ""
                  ? [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("绑定账户中的收件地址为")) +
                          _vm._s(
                            _vm._f("numHidden")(_vm.selectMember.Address)
                          ) +
                          "，"
                      )
                    ]
                  : _vm._e(),
                _vm._v("如有疑问可联系在线客服 "),
                _vm.mobile
                  ? [_vm._v("，或致电" + _vm._s(_vm.mobile))]
                  : _vm._e()
              ],
              2
            )
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false },
          model: {
            value: _vm.showSelectBind,
            callback: function($$v) {
              _vm.showSelectBind = $$v
            },
            expression: "showSelectBind"
          }
        },
        [
          _c(
            "div",
            { staticClass: "crm-binb-select" },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "系统检测到您存在多个益生康健线下账户，请在以下收件信息中，选择一个账户进行绑定："
                )
              ]),
              _c(
                "div",
                { staticClass: "bind-list-main" },
                [
                  _c(
                    "van-radio-group",
                    {
                      attrs: { "checked-color": "#f15353" },
                      model: {
                        value: _vm.selectRadio,
                        callback: function($$v) {
                          _vm.selectRadio = $$v
                        },
                        expression: "selectRadio"
                      }
                    },
                    _vm._l(_vm.crm_member_list, function(item, index) {
                      return _c(
                        "van-radio",
                        {
                          key: item.CrmID,
                          attrs: { shape: "square", name: index }
                        },
                        [_vm._v(_vm._s(_vm._f("numHidden")(item.Address)))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "van-button",
                {
                  staticStyle: { margin: "24px auto 0", "font-size": "15px" },
                  attrs: { type: "primary", block: "", color: "#f15353" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.bindCrm.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("确认绑定")))]
              ),
              _vm.cservice
                ? _c(
                    "div",
                    {
                      staticClass: "size-text",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.linkService.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("都不是我, 联系客服")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }