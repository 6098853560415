//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  model: {
    prop: "showWechatshar",
    event: "onEmit"
  },
  props: {
    //提示文字
    text: {
      type: String,
      default: $i18n.t("立即分享给好友吧") + "br" + $i18n.t("点击屏幕右上角将页面分享给好友")
    },
    //按钮是否显示，默认不显示
    showWechatshar: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      textArr: []
    };
  },
  components: {},
  computed: {},
  mounted: function mounted() {
    if (this.text.indexOf("br") > -1) {
      this.textArr = this.text.split("br");
    }
  },
  methods: {
    onClick: function onClick() {
      this.$store.commit("openWallerBind", {
        value: false,
        text: ''
      });
      this.$emit("onEmit", false);
    }
  }
};