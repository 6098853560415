import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//腾信互联-话费对接
{
  path: "/phoneBillRecharge/:rid?",
  component: function component() {
    return import("../../../views/phoneBillRecharge/index.vue");
  },
  name: "phoneBillRecharge",
  meta: {
    title: "话费充值开卡",
    foot: true
  }
}, {
  path: "/phoneBillRechargeHome",
  component: function component() {
    return import("../../../views/phoneBillRecharge/home.vue");
  },
  name: "phoneBillRechargeHome",
  meta: {
    title: "话费充值",
    foot: true
  }
}, {
  path: "/phoneBillRechargeRecord/:id/:ind",
  component: function component() {
    return import("../../../views/phoneBillRecharge/record.vue");
  },
  name: "phoneBillRechargeRecord",
  meta: {
    title: "",
    foot: true
  }
},
// 经销商话费分红
{
  path: "/phone_bill_recharge_dealer",
  component: function component() {
    return import("../../../views/outArea/outArea.vue");
  },
  name: "phone_bill_recharge_dealer",
  meta: {
    title: "经销商话费分红",
    foot: true
  }
}];