import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//ecologicalPoint
{
  path: "/differenceReward/index",
  component: function component() {
    return import("../../../views/dev-plug/differenceReward/index.vue");
  },
  name: "differenceRewardIndex",
  meta: {
    title: "",
    foot: true
  }
}];