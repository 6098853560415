var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hide
        ? _c(
            "van-nav-bar",
            {
              class: [this.fun.getPhoneEnv() == 3 ? "pcStyle" : ""],
              attrs: {
                fixed: "",
                title: _vm.text ? _vm.text : _vm.$t("ke-hu"),
                "z-index": "99",
                border: false
              }
            },
            [
              !_vm.goback
                ? _c("van-button", {
                    attrs: {
                      slot: "left",
                      type: "default",
                      icon: "arrow-left"
                    },
                    on: { click: _vm.goto },
                    slot: "left"
                  })
                : _vm._e(),
              _vm._t("left", null, { slot: "left" }),
              _vm.tolink
                ? [
                    !_vm.showToast
                      ? _c(
                          "router-link",
                          {
                            staticStyle: { "font-size": "14px" },
                            attrs: {
                              slot: "right",
                              to: _vm.fun.getUrl(
                                _vm.tolink,
                                _vm.setParams,
                                _vm.setQuery
                              )
                            },
                            slot: "right"
                          },
                          [_vm._v(_vm._s(_vm.totext))]
                        )
                      : _vm._e(),
                    _vm.showToast
                      ? _c(
                          "div",
                          {
                            attrs: { slot: "right" },
                            on: { click: _vm.tapShow },
                            slot: "right"
                          },
                          [_vm._v(_vm._s(_vm.totext))]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._t("edit", null, { slot: "right" })
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticStyle: { height: "40px" } }),
      _c("transition", { attrs: { name: "plus-icon" } }, [
        _vm.show
          ? _c("div", { staticClass: "posbox" }, [
              _c(
                "div",
                { staticClass: "toastBox" },
                _vm._l(_vm.toastTxt, function(item, ind) {
                  return _c(
                    "span",
                    {
                      key: ind,
                      staticClass: "txt",
                      on: {
                        click: function($event) {
                          return _vm.gourl(item.url)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }