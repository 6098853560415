import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var Balance_recharge = function Balance_recharge(r) {
  return require(["../../../views/member/balance/member_balance_recharge"], r);
};
var Balance_withdrawals_v2 = function Balance_withdrawals_v2(r) {
  return require(["../../../views/member/balance/member_balance_withdrawals_v2"], r);
};
var Love_trading_sell = function Love_trading_sell(r) {
  return require(["../../../views/love/love_trading_sell"], r);
};
export default [{
  path: "/yccMoneyIndex",
  name: "yccMoneyIndex",
  component: function component() {
    return import("../../../views/yccMoney/yccMoneyIndex.vue");
  },
  meta: {
    title: "YCC资产",
    foot: false
  }
}, {
  path: "/YccRecharge",
  component: Balance_recharge,
  name: "YccRecharge",
  meta: {
    title: "充值",
    foot: true
  }
}, {
  path: "/member/balance_withdrawals/YCC",
  component: Balance_withdrawals_v2,
  name: "Ycc_withdrawals",
  meta: {
    title: "提现",
    foot: true
  }
}, {
  path: "/YccformFrozenLove",
  component: Love_trading_sell,
  name: "YccformFrozenLove",
  meta: {
    title: "转换",
    foot: true
  }
}];