import { evals, minus, KeyWord, calculate, href_filters, mobile_dispose, normalTime, pwd_filter, trainMinPirce, trainRunTime, escapeTitle, timestampToTime, numberToWan } from "./evals";
var filters = {
  normalTime: normalTime,
  evals: evals,
  calculate: calculate,
  minus: minus,
  mobile_dispose: mobile_dispose,
  pwd_filter: pwd_filter,
  KeyWord: KeyWord,
  trainRunTime: trainRunTime,
  trainMinPirce: trainMinPirce,
  href_filters: href_filters,
  escapeTitle: escapeTitle,
  timestampToTime: timestampToTime,
  numberToWan: numberToWan
};
export default filters;