import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//新客下单
{
  path: "/levelCompel",
  component: function component() {
    return import("../../../views/others/levelCompel/levelCompel.vue");
  },
  name: "levelCompel",
  meta: {
    title: "新客下单",
    foot: true
  }
}];