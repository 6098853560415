import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 消费券插件 路由表
export default [
//消费券插件页面
{
  path: "/sachertorte/sachertorteIndex",
  component: function component() {
    return import("../../../views/sachertorte/sachertorteIndex/sachertorteIndex.vue");
  },
  name: "sachertorteIndex",
  meta: {
    title: "首页",
    foot: false
  }
}, {
  path: "/sachertorte/classify",
  component: function component() {
    return import("../../../views/sachertorte/classify/classify.vue");
  },
  name: "sachertorteClassify",
  meta: {
    title: "分类",
    foot: true
  }
}, {
  path: "/sachertorte/sachertorteSearch",
  component: function component() {
    return import("../../../views/sachertorte/sachertorteSearch/sachertorteSearch.vue");
  },
  name: "sachertorteSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/sachertorte/sachertorteGoods",
  component: function component() {
    return import("../../../views/sachertorte/sachertorteGoods/sachertorteGoods.vue");
  },
  name: "sachertorteGoods",
  meta: {
    title: "商品详情",
    foot: true
  }
}, {
  path: "/sachertorte/purchase",
  component: function component() {
    return import("../../../views/sachertorte/purchase/purchase.vue");
  },
  name: "sachertortePurchase",
  meta: {
    title: "限时抢购",
    foot: true
  }
}, {
  path: "/sachertorte/deliveryArea",
  component: function component() {
    return import("../../../views/sachertorte/deliveryArea/deliveryArea.vue");
  },
  name: "deliveryArea",
  meta: {
    title: "配送范围",
    foot: true
  }
}, {
  path: "/sachertorteBand",
  component: function component() {
    return import("../../../views/sachertorte/sachertorteBand.vue");
  },
  name: "sachertorteBand",
  meta: {
    title: "品牌馆",
    foot: true
  }
}, {
  path: "/sachertorteBandShop/:brand_id",
  component: function component() {
    return import("../../../views/sachertorte/sachertorteBandShop.vue");
  },
  name: "sachertorteBandShop",
  meta: {
    title: "品牌馆",
    foot: true
  }
}];