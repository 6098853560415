import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 链动 #10027
export default [{
  path: "/lecturer",
  component: function component() {
    return import("../../../views/course/lecturer/lecturer.vue");
  },
  name: "lecturer",
  meta: {
    title: "讲师主页",
    foot: false
  }
}, {
  path: "/course/courseList",
  component: function component() {
    return import("../../../views/course/courseAll/courseList.vue");
  },
  name: "courseList",
  meta: {
    title: "全部课程",
    foot: false
  }
}, {
  path: "/course/chapterList/:chapterId/:courseId?/:sectionId?/:videoId?",
  component: function component() {
    return import("../../../views/course/chapterList/chapterList.vue");
  },
  name: "chapterList",
  meta: {
    title: "课程目录",
    foot: true
  }
}];