var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-sticky", [
        _c("div", {
          staticClass: "mHead-h",
          style: { height: _vm.statusBarHeight + "px" }
        }),
        _c("div", {
          staticClass: "mHead",
          style: { paddingTop: _vm.statusBarHeight + "px" }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }