var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "qrCode" } }, [
    _c("div", { staticClass: "topBox" }, [
      _c("img", {
        attrs: {
          src: _vm.$store.state.temp.mailInfo
            ? _vm.$store.state.temp.mailInfo.logo
            : "",
          alt: ""
        }
      }),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.$store.state.temp.mailInfo
              ? _vm.$store.state.temp.mailInfo.name
              : ""
          )
        )
      ])
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ewmBox" }, [
      _c("p", [_vm._v("手机微信“扫一扫”")]),
      _c("canvas", { attrs: { id: "qrccode-canvas" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }