import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//商家让利
{
  path: "/shopsProfits/profits",
  //转让
  component: function component() {
    return import("../../../views/shopsProfits/profits/profits.vue");
  },
  name: "shopsProfits",
  meta: {
    title: "商家让利",
    foot: true
  }
}, {
  path: "/shopsProfits/profitsPay",
  //转让
  component: function component() {
    return import("../../../views/shopsProfits/profitsPay/profitsPay.vue");
  },
  name: "profitsPay",
  meta: {
    title: "商家让利",
    foot: true
  }
}, {
  path: "/shopsProfits/shopsLove",
  //转让
  component: function component() {
    return import("../../../views/shopsProfits/shopsLove/shopsLove.vue");
  },
  name: "shopsLove",
  meta: {
    title: "爱心值帐目明细",
    foot: true
  }
}];