var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "yz_wechatShareImg" } }, [
    _vm.showWechatshar
      ? _c(
          "div",
          { staticClass: "yz_wechatShareImg-main", on: { click: _vm.onClick } },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/images/wn_zhishi@2x.png"),
                alt: ""
              }
            }),
            _vm.textArr && _vm.textArr.length > 0
              ? _c(
                  "p",
                  _vm._l(_vm.textArr, function(text, index) {
                    return _c("span", { key: index, class: "font-" + index }, [
                      _vm._v(_vm._s(text)),
                      _c("br")
                    ])
                  }),
                  0
                )
              : _c("p", [_vm._v(_vm._s(_vm.text))])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }