var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value || _vm.show
    ? _c("div", { attrs: { id: "loadding" } }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "horizontal" }, [
        _c("div", { staticClass: "circlesup" }, [
          _c("div", { staticClass: "circle" }),
          _c("div", { staticClass: "circle" }),
          _c("div", { staticClass: "circle" }),
          _c("div", { staticClass: "circle" }),
          _c("div", { staticClass: "circle" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }