//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapState, mapMutations } from "vuex";

export default {
  props: ["show"],
  data: function data() {
    return {};
  },
  computed: {
    value: {
      get: function get() {
        //getter读取数据
        if (this.$store.state.isloadding) {
          // this.isshow = true;  不要在computed中改变值
          return true;
        } else {
          // this.isshow = false;
          return false;
        }
      },
      set: function set(val) {
        console.log(val, "触发");
      }
    }
  },
  activated: function activated() {},
  mounted: function mounted() {},
  methods: {}
};