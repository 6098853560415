import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/syncMainIndex",
  component: function component() {
    return import("../../../views/syncMain/syncMainIndex");
  },
  name: "syncMainIndex",
  meta: {
    title: "划转",
    foot: true
  }
}, {
  path: "/syncMainPlugin/:plugin",
  component: function component() {
    return import("../../../views/syncMain/syncMainPlugin");
  },
  name: "syncMainPlugin",
  meta: {
    title: "积分划转",
    foot: true
  }
}];