import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 商城路由表三，如二开插件的路由，请到上一级文件夹mirco中，新建路由文件夹，路由名称为插件英文名
export default [{
  path: "/walletSiteBind",
  component: function component() {
    return import("../views/dev-plug/walletSite/walletSiteBind.vue");
  },
  name: "walletSiteBind",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/inviterAndPhone",
  component: function component() {
    return import("../views/member/visit/inviterAndPhone.vue");
  },
  name: "inviterAndPhone",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/goodLimit/index",
  component: function component() {
    return import("../views/dev-plug/goods-limit/index.vue");
  },
  name: "goodLimitIndex",
  meta: {
    title: "",
    foot: false
  }
}];