import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/yzSupplyLease",
  name: "yzSupplyLease",
  component: function component() {
    return import("../../../views/yzSupplyLease/index.vue");
  },
  meta: {
    title: "租赁",
    foot: false
  }
}, {
  path: "/yzSupplyLeaseArea",
  name: "yzSupplyLeaseArea",
  component: function component() {
    return import("../../../views/yzSupplyLease/yzSupplyLeaseArea.vue");
  },
  meta: {
    title: "选择区域",
    foot: false
  }
}];