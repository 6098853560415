import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
// Ktv柜机对接
{
  path: "/gradevin_machine",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine.vue");
  },
  name: "gradevin_machine",
  meta: {
    title: "设备管理",
    foot: true
  }
}, {
  path: "/gradevin_machineIndex/:machine_id",
  component: function component() {
    return import("../../../views/gradevin/gradevin_machineIndex.vue");
  },
  name: "gradevin_machineIndex",
  meta: {
    title: "设备详情",
    foot: true
  }
}, {
  path: "/gradevin_machine_presentationRecord/:gradevin?",
  component: function component() {
    return import("../../../views/member/income/member_income_presentationRecord.vue");
  },
  name: "gradevin_machine_presentationRecord",
  meta: {
    title: "我的提成",
    foot: true
  }
}, {
  path: "/cargoWayManage/:device_id?",
  component: function component() {
    return import("../../../views/gradevin/cargoWayManage.vue");
  },
  name: "cargoWayManage",
  meta: {
    title: "货道管理",
    foot: true
  }
}, {
  path: "/deviceClass/:device_id",
  component: function component() {
    return import("../../../views/gradevin/deviceClass.vue");
  },
  name: "deviceClass",
  meta: {
    title: "商品列表",
    foot: true
  }
}, {
  path: "/myWarehouse",
  component: function component() {
    return import("../../../views/gradevin/myWarehouse.vue");
  },
  name: "myWarehouse",
  meta: {
    title: "我的仓库",
    foot: true
  }
}, {
  path: "/purchasingCenter",
  component: function component() {
    return import("../../../views/gradevin/purchasingCenter.vue");
  },
  name: "purchasingCenter",
  meta: {
    title: "进货中心",
    foot: true
  }
}, {
  path: "/inventoryCenter",
  component: function component() {
    return import("../../../views/gradevin/inventoryCenter.vue");
  },
  name: "inventoryCenter",
  meta: {
    title: "库存中心",
    foot: true
  }
}, {
  path: "/inventoryDetail/:id",
  component: function component() {
    return import("../../../views/gradevin/inventoryDetail.vue");
  },
  name: "inventoryDetail",
  meta: {
    title: "库存明细",
    foot: true
  }
}, {
  path: "/purchasingCenterGood/:id",
  component: function component() {
    return import("../../../views/goods/goods_v2.vue");
  },
  name: "purchasingCenterGood",
  meta: {
    title: "商品详情",
    foot: true
  }
}];