import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//多平台订单转换
{
  path: "/platform_order_import_index",
  component: function component() {
    return import("../../../views/platform_order_import/platform_order_import_index.vue");
  },
  name: "platform_order_import_index",
  meta: {
    title: "多平台订单转换",
    foot: false
  }
}, {
  path: "/platform_order_import_recode",
  component: function component() {
    return import("../../../views/platform_order_import/platform_order_import_recode.vue");
  },
  name: "platform_order_import_recode",
  meta: {
    title: "转换记录",
    foot: false
  }
}];