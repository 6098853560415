import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var Balance_recharge = function Balance_recharge(r) {
  return require(["../../../views/member/balance/member_balance_recharge"], r);
};
export default [
//充值标签
{
  path: "/tagBalance/tagBalanceIndex",
  component: function component() {
    return import("../../../views/tagBalance/tagBalanceIndex.vue");
  },
  name: "tagBalanceIndex",
  meta: {
    title: "充值标签",
    foot: true
  }
}, {
  path: "/tagBalance/detailed",
  component: function component() {
    return import("../../../views/tagBalance/tagBalanceDetailed.vue");
  },
  name: "tagBalanceDetailed",
  meta: {
    title: "充值明细",
    foot: true
  }
}, {
  path: "/tagBalance/record",
  component: function component() {
    return import("../../../views/tagBalance/tagBalanceRecord.vue");
  },
  name: "tagBalanceRecord",
  meta: {
    title: "过期标签明细",
    foot: true
  }
}, {
  path: "/tagBalance/tag",
  component: Balance_recharge,
  name: "balance_rechargeTag",
  meta: {
    title: "充值",
    foot: true
  }
}];