import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 冻结券 #21099
export default [{
  path: "/frozeCoupon",
  component: function component() {
    return import("../../../views/extension/freeze_coupon/index.vue");
  },
  name: "frozeCoupon",
  meta: {
    title: "我的积分消费券",
    foot: true
  }
}];