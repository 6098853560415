import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/idShop_apply",
  name: "idShop_apply",
  component: function component() {
    return import("../../../views/idShop/idShop_apply.vue");
  },
  meta: {
    title: "我的钱包",
    foot: true
  }
}, {
  path: "/idShop_apply_enterprise",
  name: "idShop_apply_enterprise",
  component: function component() {
    return import("../../../views/idShop/idShop_apply_enterprise.vue");
  },
  meta: {
    title: "企业认证",
    foot: true
  }
}, {
  path: "/idShop_apply_enterprise_record",
  name: "idShop_apply_enterprise_record",
  component: function component() {
    return import("../../../views/idShop/idShop_apply_enterprise_record.vue");
  },
  meta: {
    title: "企业认证记录",
    foot: true
  }
}, {
  path: "/myWallet",
  name: "myWallet",
  component: function component() {
    return import("../../../views/idShop/myWallet.vue");
  },
  meta: {
    title: "我的钱包",
    foot: true
  }
}, {
  path: "/staffManagement/idShop",
  name: "staffManagement_idShop",
  component: function component() {
    return import("../../../views/idShop/staffManagement.vue");
  },
  meta: {
    title: "员工管理",
    foot: true
  }
}, {
  path: "/Sub_account",
  name: "Sub_account",
  component: function component() {
    return import("../../../views/idShop/Sub_account.vue");
  },
  meta: {
    title: "子id号",
    foot: true
  }
}, {
  path: "/Sub_account_buy",
  name: "Sub_account_buy",
  component: function component() {
    return import("../../../views/idShop/Sub_account_buy.vue");
  },
  meta: {
    title: "子id号",
    foot: true
  }
}, {
  path: "/idShopApplyPay/:tag/:price",
  name: "idShopApplyPay",
  component: function component() {
    return import("../../../views/member/order/order_payment.vue");
  },
  meta: {
    title: "订单支付",
    foot: true
  }
}];