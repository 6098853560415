import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
import "vant/es/datetime-picker/style";
import _DatetimePicker from "vant/es/datetime-picker";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/slider/style";
import _Slider from "vant/es/slider";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/rate/style";
import _Rate from "vant/es/rate";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/step/style";
import _Step from "vant/es/step";
import "vant/es/steps/style";
import _Steps from "vant/es/steps";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import "vant/es/row/style";
import _Row from "vant/es/row";
import "vant/es/col/style";
import _Col from "vant/es/col";
import "vant/es/dropdown-menu/style";
import _DropdownMenu from "vant/es/dropdown-menu";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/dropdown-item/style";
import _DropdownItem from "vant/es/dropdown-item";
import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/stepper/style";
import _Stepper from "vant/es/stepper";
import "vant/es/progress/style";
import _Progress from "vant/es/progress";
import "vant/es/grid/style";
import _Grid from "vant/es/grid";
import "vant/es/grid-item/style";
import _GridItem from "vant/es/grid-item";
import "vant/es/number-keyboard/style";
import _NumberKeyboard from "vant/es/number-keyboard";
import "vant/es/password-input/style";
import _PasswordInput from "vant/es/password-input";
import "vant/es/swipe-cell/style";
import _SwipeCell from "vant/es/swipe-cell";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/sticky/style";
import _Sticky from "vant/es/sticky";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/circle/style";
import _Circle from "vant/es/circle";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/submit-bar/style";
import _SubmitBar from "vant/es/submit-bar";
import "vant/es/share-sheet/style";
import _ShareSheet from "vant/es/share-sheet";
import "vant/es/sku/style";
import _Sku from "vant/es/sku";
import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/calendar/style";
import _Calendar from "vant/es/calendar";
import "vant/es/goods-action/style";
import _GoodsAction from "vant/es/goods-action";
import "vant/es/goods-action-button/style";
import _GoodsActionButton from "vant/es/goods-action-button";
import "vant/es/goods-action-icon/style";
import _GoodsActionIcon from "vant/es/goods-action-icon";
import "vant/es/sidebar/style";
import _Sidebar from "vant/es/sidebar";
import "vant/es/sidebar-item/style";
import _SidebarItem from "vant/es/sidebar-item";
import "vant/es/divider/style";
import _Divider from "vant/es/divider";
import "vant/es/index-bar/style";
import _IndexBar from "vant/es/index-bar";
import "vant/es/index-anchor/style";
import _IndexAnchor from "vant/es/index-anchor";
import "vant/es/form/style";
import _Form3 from "vant/es/form";
import "vant/es/skeleton/style";
import _Skeleton from "vant/es/skeleton";
import "vant/es/popover/style";
import _Popover3 from "vant/es/popover";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _OptionGroup2 from "element-ui/lib/theme-chalk/option-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _OptionGroup from "element-ui/lib/option-group";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _Upload2 from "element-ui/lib/theme-chalk/upload.css";
import "element-ui/lib/theme-chalk/base.css";
import _Upload from "element-ui/lib/upload";
import _Carousel2 from "element-ui/lib/theme-chalk/carousel.css";
import "element-ui/lib/theme-chalk/base.css";
import _Carousel from "element-ui/lib/carousel";
import _CarouselItem2 from "element-ui/lib/theme-chalk/carousel-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CarouselItem from "element-ui/lib/carousel-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _Popover2 from "element-ui/lib/theme-chalk/popover.css";
import "element-ui/lib/theme-chalk/base.css";
import _Popover from "element-ui/lib/popover";
import _Tree2 from "element-ui/lib/theme-chalk/tree.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tree from "element-ui/lib/tree";
import "/Users/blue/Desktop/dddd/front/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/blue/Desktop/dddd/front/node_modules/core-js/modules/es.promise.js";
import "/Users/blue/Desktop/dddd/front/node_modules/core-js/modules/es.object.assign.js";
import "/Users/blue/Desktop/dddd/front/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
// import Vue from "vue";
// import Vuex from 'vuex'

// load vuex i18n module
// import vuexI18n from 'vuex-i18n'
// import VueI18n from 'vue-i18n'
import "@vant/touch-emulator";
import "babel-polyfill";
import promise from "es6-promise";
import VueClipboard from "vue-clipboard2";
import VueRouter from "vue-router";
import i18n from "../public/static/app/locales";
import App from "./app";
import filters from "./filters";
import Fun from "./fun";
import Http from "./fun/http";
import routes from "./router";
import storeOption from "./store";
promise.polyfill();
// import debug from "./fun/debug";

import cTitle from "components/title";
import MoneySwitch from "components/MoneySwitch";
import MoneyShow from "components/MoneyShow";
Vue.component("c-title", cTitle); //全局自定义组件
Vue.component("money-switch", MoneySwitch); //全局自定义组件
Vue.component("money-show", MoneyShow); //全局自定义组件

Vue.config.devtools = true;
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import microApp from "@micro-zoe/micro-app";
microApp.start();
// import VideoPlayer from 'vue-video-player';
// require('video.js/dist/video-js.css');
// require('vue-video-player/src/custom-theme.css');
// import 'videojs-flash';
// import 'videojs-contrib-hls';
// import 'videojs-contrib-hls/dist/videojs-contrib-hls';
// Vue.use(VueVideoPlayer);

// import vuefinger from 'vue-finger'
// import Vant from 'vant'
// import 'vant/lib/index.css'
// import url from './utils/url';//获取url参数

// import ydui from 'vue-ydui'
// import Lrz from "lrz"
// import 'vue-ydui/dist/ydui.px.css'
// 导入ydui base.css
// import 'vue-ydui/dist/ydui.base.css'

// const R = require("ramda");

// 引入mockjs
// require("../mock/mock.js");

// PC端按需引入element

Vue.use(_Select).use(_Option).use(_OptionGroup).use(_Pagination).use(_Tooltip).use(_Upload).use(_Carousel).use(_CarouselItem).use(_Form).use(_FormItem).use(_Input).use(_Popover).use(_Tree);

// ydui的弹窗组件 $yddialog
// import { Confirm, Alert } from "vue-ydui/dist/lib.rem/dialog";
//
// Vue.prototype.$yddialog = {
//   confirm: Confirm,
//   alert: Alert,
//   // loading: Loading
// };

import { CitySelect } from "vue-ydui/dist/lib.px/cityselect";
Vue.component(CitySelect.name, CitySelect);
// import 'vue-ydui/dist/ydui.base.css';  换成链接引入

// vant的弹窗组件 $dialog
// 按需引入vant组件

// 使用vant组件
Vue.use(_DatetimePicker).use(_Cell).use(_Slider).use(_CellGroup).use(_Popup).use(_Icon).use(_Button).use(_Field).use(_Rate).use(_Overlay).use(_Swipe).use(_SwipeItem).use(_Uploader).use(_NavBar).use(_Step).use(_Steps).use(_Tab).use(_Tabs).use(_Picker).use(_Checkbox).use(_CheckboxGroup).use(_RadioGroup).use(_Radio).use(_Dialog).use(_Notify).use(_Collapse).use(_CollapseItem).use(_Row).use(_Col).use(_DropdownMenu).use(_Toast).use(_Loading).use(_DropdownItem).use(_ImagePreview).use(_NoticeBar).use(_Search).use(_Stepper).use(_Progress).use(_Grid).use(_GridItem).use(_NumberKeyboard).use(_PasswordInput).use(_SwipeCell).use(_Switch).use(_PullRefresh).use(_List).use(_Empty).use(_Sticky).use(_CountDown).use(_ActionSheet).use(_Circle).use(_Card).use(_SubmitBar).use(_ShareSheet).use(_Sku).use(_Area).use(_Tag).use(_Calendar).use(_GoodsAction).use(_GoodsActionButton).use(_GoodsActionIcon).use(_Sidebar).use(_SidebarItem).use(_Divider).use(_IndexBar).use(_IndexAnchor).use(_Form3).use(_Skeleton).use(_Popover3);
// Vue.use(MINT);
// Vue.use(Vuex)
Vue.use(VueRouter);
// Vue.use(ydui);
// Vue.use(vant);
Vue.use(window.VueAwesomeSwiper);
Vue.config.productionTip = false;
if (Fun.isIE()) {
  //ie浏览器不兼容懒加载组件处理
  Vue.directive("lazy", function (el, binding) {
    el.src = binding.value;
  });
} else {
  Vue.use(_Lazyload, {
    lazyComponent: true,
    preLoad: 1.1,
    error: "/static/app/images/img_default.png",
    loading: "/static/app/images/video-loading.gif",
    attempt: 1,
    filter: {
      progressive: function progressive(listener, options) {
        var path = JSON.parse(window.localStorage.getItem("yz_basic_info"));
        if (path.thumb_parameter) {
          listener.src = "".concat(listener.src).concat(path.thumb_parameter);
        }
      }
    }
  });
}

// 忽略打开微信小程序的组件
Vue.config.ignoredElements = ["wx-open-launch-weapp"];
Vue.config.ignoredElements = ["wx-open-subscribe"];

// import base64_encode from "./utils/base64_encode";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.min.css";
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);
// import VConsole from "vconsole";
// new VConsole();

// import jquery  from 'jquery';
// global.jquery = global.jQuery  = global.$ = jquery;
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: false,
    title: false,
    toolbar: false,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: false,
    keyboard: false,
    url: "src"
  }
});
Vue.use(Viewer);

// 使用剪切板
Vue.use(VueClipboard);
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});

// Vue.use(debug, { entryName: "yunzshop" });

// 创建一个 store 对象用于管理应用状态
var store = new Vuex.Store(storeOption);

// 注册全局方法
// Vue.prototype.$url=url

var scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  // return 期望滚动到哪个的位置
  if (savedPosition) {
    return savedPosition;
  } else {
    if (from.meta.keepAlive) {
      from.meta.savedPosition = document.body.scrollTop;
    }
    return {
      x: 0,
      y: to.meta.savedPosition || 0
    };
  }
};

// Vue.use(vuefinger)
// 创建一个路由对象用于管理页面的路由
var router = new VueRouter({
  hashbang: true,
  // mode: 'history',//去除url上的#号模式
  // mode:'abstract',
  history: true,
  hashPrefix: "!",
  linkActiveClass: "active",
  routes: routes,
  base: "/",
  scrollBehavior: scrollBehavior
});

//以下是不需要登录的页面 转到http.js
// const passRouter = ["login", "findpwd", "register", "category", "DiyForm"];

// function isPassRoute(to) {
//   for (let i = 0; i < passRouter.length; i++) {
//     // 编程式
//     if (to.name === passRouter[i]) {
//       return true;
//     }
//   }
//   return false;
// }

// 可否访问前过滤方法
router.beforeEach(function (to, from, next) {
  if (!window.initUrl) {
    window.initUrl = window.location.href;
  }
  if (to.name === "microCommentDetails" || to.name === "microRelease" || to.name === "microEdit") {
    //名片该功能已去除 to.name === "BusinessCard" || to.name === "EditCard"
    // 录音 转化amr
    if (window.business_card !== 1) {
      window.business_card = 1;
      var script = document.createElement("script");
      script.src = "/addons/yun_shop/static/app/beta-amr.js";
      document.body.appendChild(script);
    }
  }
  // window.localStorage.i = to.query.i;
  if (window.yz_bd) {
    if (!window._hmt) {
      /*百度统计*/
      var _hmt = _hmt || [];
      window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?" + window.yz_bd;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
        _hmt.push(["_trackPageview", "/addons/yun_shop/?menu" + to.fullPath]);
      })();
      /*百度统计end*/
    } else {
      /*百度统计*/
      if (_hmt) {
        if (to.path) {
          _hmt.push(["_trackPageview", "/addons/yun_shop/?menu" + to.fullPath]);
        }
      }
      /*百度统计end*/
    }
  }
  from.query.detailid = "name";
  window.localStorage.mid = to.query.mid;
  // console.log("登录状态：：", localStorage.getItem("isWxLogin"))
  // if (localStorage.getItem("isWxLogin") != -1 && to.name === "login") {
  //   console.log("已登录过，不需要跳转登录页");
  //   window.location.href = Fun.getSiteRoot() + "/addons/yun_shop/" + "?menu#" + "/home?i=" + to.query.i + "&type=" + Fun.getTyep() + "&mid=" + to.query.mid;
  // }
  if (to.name === "alipay") {
    next();
    return;
  }
  // let json = { url: document.location.href };

  // Http.get("home-page.get-params", json, " ")
  //   .then(response => {
  //     if (response.result === 1) {
  //       localStorage.setItem("mailLanguage", JSON.stringify(response.data.lang));
  //       localStorage.setItem("balanceLang", JSON.stringify(response.data.balance));
  //       tempInfo(response.data.home);
  //       if (response.data.home.memberinfo) {
  //         window.localStorage.uid = response.data.home.memberinfo.uid;
  //       }
  //       localStorage.setItem(
  //         "globalParameter",
  //         JSON.stringify(response.data.globalParameter)
  //       );
  //
  //       if (response.basic_info) {
  //         window.popularizeYZ = response.basic_info.popularize_page;
  //         getInvite(response.basic_info.validate_page);
  //       }

  if (window.popularizeYZ) {
    if (Fun.getTyep() == "5" || Fun.getTyep() == "17" || Fun.getTyep() == "18" || Fun.getTyep() == "20") {
      if (window.popularizeYZ.wap.vue_route.indexOf(to.name) >= 0) {
        if (Fun.isTextEmpty(window.popularizeYZ.wap.url)) {
          next("/404?i=".concat(Fun.getKeyByI()));
        } else {
          window.location.href = window.popularizeYZ.wap.url;
        }
      } else {
        next();
      }
    } else if (Fun.getTyep() == "1") {
      if (window.popularizeYZ.wechat.vue_route.indexOf(to.name) >= 0) {
        if (Fun.isTextEmpty(window.popularizeYZ.wechat.url)) {
          next("/404?i=".concat(Fun.getKeyByI()));
        } else {
          window.location.href = window.popularizeYZ.wechat.url;
        }
      } else {
        next();
      }
    } else if (Fun.getTyep() == "2") {
      if (window.popularizeYZ.mini.vue_route.indexOf(to.name) >= 0) {
        if (Fun.isTextEmpty(window.popularizeYZ.mini.url)) {
          //window.location.href=$popularize.mini.url;
          next("/404?i=".concat(Fun.getKeyByI()));
        } else {
          wx.miniProgram.navigateTo({
            url: window.popularizeYZ.mini.url
          });
        }
      } else {
        next();
      }
    } else if (Fun.getTyep() == "7") {
      if (window.popularizeYZ.app.vue_route.indexOf(to.name) >= 0) {
        if (Fun.isTextEmpty(window.popularizeYZ.app.url)) {
          next("/404?i=".concat(Fun.getKeyByI()));
        } else {
          window.location.href = window.popularizeYZ.app.url;
        }
      } else {
        next();
      }
    } else if (Fun.getTyep() == "8") {
      if (window.popularizeYZ.alipay.vue_route.indexOf(to.name) >= 0) {
        if (Fun.isTextEmpty(window.popularizeYZ.alipay.url)) {
          next("/404?i=".concat(Fun.getKeyByI()));
        } else {
          window.location.href = window.popularizeYZ.alipay.url;
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }

  // }
  // });
});
//跳转后赋值
router.afterEach(function (to, from) {
  // 设置noLogPath将不记录到share_path
  if (to && to.path && to.path != "/" && to.path != "/share" && to.path != "/login" && !to.meta.noLogPath) {
    window.localStorage.setItem("share_path", to.path);
  }
});
var originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
window.scrollArr = [];
window.localStorage.isWeiXin = Fun.isWeiXin();
window.localStorage.isAlipay = Fun.isAlipay();
var type = "";
window.localStorage.isWeiXin == "true" ? type = 1 : type = 5;
if (window.localStorage.isAlipay == "true") {
  type = 8;
}
window.localStorage.type = type;
Vue.prototype.fun = Fun;
window.$http = Http;

// window.R = R;
window.__lendApp__ = new Vue({
  el: "#app",
  i18n: i18n,
  router: router,
  store: store,
  components: {
    App: App
  },
  render: function render(h) {
    return h(App);
  }
});
Vue.prototype.$vueIsTextEmpty = function (str1) {
  if (this.fun.isTextEmpty(str1)) {
    return true;
  }
  return false;
};
Array.prototype.removeByValue = function (val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) {
      this.splice(i, 1);
      break;
    }
  }
};
Date.prototype.Format = function (fmt) {
  // author: meizz
  var o = {
    "M+": this.getMonth() + 1,
    // 月份
    "d+": this.getDate(),
    // 日
    "h+": this.getHours(),
    // 小时
    "m+": this.getMinutes(),
    // 分
    "s+": this.getSeconds(),
    // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3),
    // 季度
    S: this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return fmt;
};
window.isMiniprogram = false;
function ready() {
  console.log("window.__wxjs_environment", window.__wxjs_environment === "miniprogram"); // true
  if (window.__wxjs_environment === "miniprogram") {
    window.isMiniprogram = true;
  }
}
if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
  document.addEventListener("WeixinJSBridgeReady", ready, false);
} else {
  ready();
}

// Vue长按事件（vue指令封装）
// const requireContext = require.context("./directives", false, /\.js$/);

// export default (Vue) => {
//   requireContext.keys().forEach(path => {
//     if (path.indexOf("index") === -1) {
//       const requireItem = requireContext(path).default;
//       Vue.directive(requireItem.name, requireItem.instance);
//     }
//   });
// };
import _longpress from "./directives/longpress.js";
Vue.directive(_longpress.name, _longpress.instance);
// Vue长按事件（vue指令封装） end