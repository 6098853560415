import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//ecologicalPoint
{
  path: "/ecologicalPointAward/:status",
  component: function component() {
    return import("../../../views/others/ecologicalPoint/ecologicalPointAward.vue");
  },
  name: "ecologicalPointAward",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/ecologicalPointOther/:status",
  component: function component() {
    return import("../../../views/others/ecologicalPoint/ecologicalPointOther.vue");
  },
  name: "ecologicalPointOther",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/ecologicalPointDetailed",
  component: function component() {
    return import("../../../views/others/ecologicalPoint/ecologicalPointDetailed.vue");
  },
  name: "ecologicalPointDetailed",
  meta: {
    title: "",
    foot: true
  }
}];