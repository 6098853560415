import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//聚推联盟(聚推联盟)
{
  path: "/unionCps/transfer",
  //中转页
  component: function component() {
    return import("../../../views/dev-plug/union-cps/transfer.vue");
  },
  name: "unionCpsTransfer",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/unionCps/order/:status",
  //订单列表
  component: function component() {
    return import("../../../views/dev-plug/union-cps/order.vue");
  },
  name: "unionCpsOrder",
  meta: {
    title: "",
    foot: true
  }
}];