import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//drink-machine
{
  path: "/drinkMachind/bindCode/:dev?",
  component: function component() {
    return import("../../../views/drinkMachind/bindCode.vue");
  },
  name: "bindCode",
  meta: {
    title: "绑定编码",
    foot: true
  }
}, {
  path: "/drinkMachind/bindpj/:pj?",
  component: function component() {
    return import("../../../views/drinkMachind/bindCode.vue");
  },
  name: "bindPj",
  meta: {
    title: "绑定配件  ",
    foot: true
  }
}, {
  path: "/drinkMachind/deviceManage/:id",
  component: function component() {
    return import("../../../views/drinkMachind/deviceManage.vue");
  },
  name: "deviceManage",
  meta: {
    title: "设备管理",
    foot: true
  }
}, {
  path: "/drinkMachind/cloudTiming/:machine_id",
  component: function component() {
    return import("../../../views/drinkMachind/cloudTiming.vue");
  },
  name: "cloudTiming",
  meta: {
    title: "云定时",
    foot: true
  }
}, {
  path: "/drinkMachindIndex",
  component: function component() {
    return import("../../../views/drinkMachind/drinkMachindIndex.vue");
  },
  name: "drinkMachindIndex",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/drinkMachindRecord/:id",
  component: function component() {
    return import("../../../views/drinkMachind/drinkMachindRecord.vue");
  },
  name: "drinkMachindRecord",
  meta: {
    title: "历史用水情况",
    foot: true
  }
}];