import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      showSuccess: false,
      showSelectBind: false,
      crm_member_list: [],
      selectMember: {},
      selectRadio: null,
      cservice: null,
      //在线客服
      mobile: null //客服电话
    };
  },
  created: function created() {
    this.getBindData();
  },
  components: {},
  computed: {},
  mounted: function mounted() {
    this.cservice = this.$store.state.temp.mailInfo.cservice;
    this.mobile = this.$store.state.temp.mailInfo.service_mobile;
  },
  methods: {
    getBindData: function getBindData() {
      var _this = this;
      $http.get("plugin.ys-system.frontend.member-info.search-user", {}, ".").then(function (response) {
        if (response.result === 1) {
          _this.crm_member_list = response.data.list || [];
          if (!response.data.list || response.data.list.length == 0) {
            return;
          }
          if (response.data.is_binding == 0) {
            // 需要选择
            _this.showSelectBind = true;
          } else {
            // 无需选择，自动绑定,list不为空时才显示
            _this.showSuccess = true;
            _this.selectMember = response.data.list[0];
          }
        } else {
          _this.$toast(response.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    bindCrm: function bindCrm() {
      var _this2 = this;
      // 绑定crm账号
      if (this.selectRadio == null) {
        this.$toast("请选择其中一个账号");
        return;
      }
      this.selectMember = this.crm_member_list[this.selectRadio];
      $http.get("plugin.ys-system.frontend.member-info.appoint-bind-user", {
        ys_user_id: this.crm_member_list[this.selectRadio].CrmID
      }, ".").then(function (response) {
        if (response.result === 1) {
          _this2.showSelectBind = false;
          _this2.showSuccess = true;
        } else {
          _this2.$toast(response.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    linkService: function linkService() {
      var _this3 = this;
      if (!this.cservice) {
        this.$toast("暂无客服联系方式！");
        return;
      }
      this.showSuccess = false;
      this.showSelectBind = false;
      this.$nextTick(function () {
        window.location.href = _this3.cservice;
      });
    }
  },
  filters: {
    numHidden: function numHidden(value) {
      if (!value) return "";
      value = value.toString();
      return value.replace(/[0-9]/gi, "*");
    }
  }
};