import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      show: false,
      currentCurrency: "USD",
      actions: [{
        name: '🇺🇸  USD',
        className: 'USD'
      }, {
        name: '🇪🇺  EUR',
        className: 'EUR'
      }, {
        name: '🇲🇽  MXN',
        className: 'MXN'
      }, {
        name: '🇵🇪  PEN',
        className: 'PEN'
      }, {
        name: '🇨🇱  CLP',
        className: 'CLP'
      }, {
        name: '🇨🇴  COP',
        className: 'COP'
      }]
    };
  },
  mounted: function mounted() {
    var currentCurrency = window.localStorage.getItem("currentCurrency");
    if (currentCurrency) {
      //   const parse = JSON.parse(currentCurrency);
      //   if(parse){
      //     if(parse?.result?.to){
      this.currentCurrency = currentCurrency.toUpperCase();
      // }
      //   }
      //   https://api.susumia.com/addons/yun_shop/api.php?i=&uuid=0&type=5&version=v1.1.146&basic_info=1&validate_page=1&route=home-page.exchange_rate&amount=1&from=USD&to=USD
    }
    this.updateDisabled(this.currentCurrency);
  },
  methods: {
    updateDisabled: function updateDisabled(currentCurrency) {
      this.actions.forEach(function (item) {
        if (item.className === currentCurrency) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
      });
    },
    onSelect: function onSelect(name) {
      this.currentCurrency = name.className;
      this.updateDisabled(this.currentCurrency);
      window.localStorage.setItem("currentCurrency", this.currentCurrency);
      window.location.reload();
    }
  }
};