import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//manual-order 手动下单
{
  path: "/manualOrder",
  component: function component() {
    return import("../../../views/manualOrder/manualOrder.vue");
  },
  name: "manualOrder",
  meta: {
    title: "手动下单",
    foot: true
  }
}, {
  path: "/manualOrderDetail/:manual_order_id",
  component: function component() {
    return import("../../../views/manualOrder/manualOrderDetail.vue");
  },
  name: "manualOrderDetail",
  meta: {
    title: "查看详情",
    foot: true
  }
}];