import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// #32645 优惠券扫码开发
export default [{
  path: "/coupon_scan_share_index",
  component: function component() {
    return import("../../../views/coupon_scan_share/coupon_scan_share_index.vue");
  },
  name: "coupon_scan_share_index",
  meta: {
    title: "我的优惠券码分享",
    foot: true
  }
}, {
  path: "/coupon_scan_share_Get",
  component: function component() {
    return import("../../../views/coupon_scan_share/coupon_scan_share_Get.vue");
  },
  name: "coupon_scan_share_Get",
  meta: {
    title: "优惠券领取",
    foot: true
  }
}];