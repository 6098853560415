//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QRCode from 'qrcode';
var canvas = '';
export default {
  data: function data() {
    return {
      info: {}
    };
  },
  mounted: function mounted() {
    // this.init()
    this.$nextTick(function () {
      // DOM操作
      canvas = document.getElementById('qrccode-canvas');
      //   this.createQrc();
    });
  },
  methods: {
    init: function init() {
      this.info = this.$store.state.temp.mailInfo;
    },
    createQrc: function createQrc() {
      var _url = document.location.href;
      QRCode.toCanvas(canvas, _url, function (error) {
        if (error) {
          console.log(error);
        }
      });
    }
  },
  watch: {
    $route: function $route(to, from) {
      this.$nextTick(function () {
        this.createQrc();
      });
    }
  }
};