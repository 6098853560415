import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/community-marketing/index",
  name: "communityMarketIndex",
  component: function component() {
    return import("../../../views/dev-plug/community-marketing/index.vue");
  },
  meta: {
    foot: true,
    title: "入群新人礼"
  }
}, {
  path: "/community-marketing/search",
  name: "communityMarketSearch",
  component: function component() {
    return import("../../../views/dev-plug/community-marketing/search.vue");
  },
  meta: {
    foot: true,
    title: "选择门店社群"
  }
}];