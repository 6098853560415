import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mHead",
  data: function data() {
    return {
      statusBarHeight: '25'
    };
  },
  created: function created() {
    var _this = this;
    window.WindVane.call('SBase', 'getDeviceInfo', {}, function (res) {
      console.log('getDeviceInfo ===============', res);
      var data = res.data; //包含数据的对象
      _this.statusBarHeight = (data.statusHeight / window.devicePixelRatio).toFixed(2);
    }, function (e) {
      console.log('getDeviceInfo 失败===============');
    });
  },
  activated: function activated() {
    console.log('getDeviceInfo activated===============');
  }
};