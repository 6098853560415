import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
/**
 * Created by zhong on 16/10/15.
 */
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import category from "./category";
import memberstate from "./member_model";
import knowledge from "./knowledge";
export default {
  modules: {
    category: category,
    memberstate: memberstate,
    knowledge: knowledge
  },
  state: {
    cart_num: 0,
    //购物车数量（包括失效）
    shopLanguage: {},
    pop: {},
    // true
    integral: "",
    // 会员装修数据
    member_temp: {},
    // 装修数据
    temp: {
      item: {
        janst: ""
      }
    },
    foot: false,
    message: "提示",
    cou: 50,
    view: true,
    name: "",
    level: "",
    icon: "",
    sex: 0,
    vip: false,
    islogin: false,
    // 订单列表点击mt-tab-item
    selected_status: false,
    // 订单列表订单状态
    status: 0,
    // 当前发货地址
    currentAddress: {
      dispatch_type_id: 1,
      address: "",
      mobile: "",
      realname: ""
    },
    // 详情页面用到的订单
    detail_order: "",
    // 从服务器请求的地址字符串
    address_info: "",
    // 账户余额
    balance: 0,
    isShow: 0,
    // 服务器请求的全国省份，市，辖区数据
    city: [],
    district: [],
    province: [],
    mailInfo: {},
    // 商城信息
    cservice: "",
    // 客服
    // 插件列表
    plugins: [],
    loginStatus: 0,
    trainCity: [],
    // 火车对应的城市
    airCity: [],
    // 飞机对应城市

    shop_id: "",
    mailLanguage: {},
    // 自定义语言
    location: {},
    referer: "",
    callback_url: "",
    // 区域id
    zoneId: "",
    o2oLocation: {},
    // zone开关
    zoneShow: 0,
    blockchainLang: {},
    is_Inviter: 0,
    isloadding: false,
    isSwitchCity: false,
    selfCarryApply: [],
    applyModel: {},
    //门店/酒店申请
    passwordValidation: 0,
    adApply: false,
    adDevice: false,
    liveRoomID: null,
    //直播间进入商品详情页购买下单使用
    share_room_id: null,
    //共享直播插件，直播间进入商品详情页购买下单使用
    _isShowReturnLiveBtn: false,
    //是否显示返回直播按钮
    isWatchDiypage: 1,
    //会员等级浏览装修页权限
    caseLibrary: null,
    //案例库

    newDiyData: {},
    isWXlogined: 1,
    isShomegGoodComment: false,
    _isShowCrmBind: false,
    //是否显示益生crm绑定会员
    _isShowWalletBind: false,
    showWechatshar: {
      value: false,
      text: ''
    },
    //是否显示全屏提示，场景：提示在浏览器打开
    orderErrReturnUrl: null,
    //下单页接口返回失败时，提示框确认返回链接

    time_appointment: 0 //分时预约技师id
  },
  mutations: {
    setShowComment: function setShowComment(state, status) {
      state.isShomegGoodComment = status;
    },
    // 存储自定义语言包
    setCartNum: function setCartNum(state, cart_num) {
      state.cart_num = cart_num;
    },
    // 存储自定义语言包
    setShopLanguage: function setShopLanguage(state, lang) {
      state.shopLanguage = lang;
    },
    // 存储自定义语言包
    setBlockchainLang: function setBlockchainLang(state, lang) {
      state.blockchainLang = lang;
    },
    setLocation: function setLocation(state, o2oLocation) {
      // console.info('=====setLocation======', o2oLocation)

      state.o2oLocation = o2oLocation;
    },
    setWatchDiypage: function setWatchDiypage(state, data) {
      console.info("=====setWatchDiypage======", data);
      state.isWatchDiypage = data != undefined ? data : 1;
    },
    setSwitchCity: function setSwitchCity(state, show) {
      state.isSwitchCity = show;
    },
    // 存储shop_id
    setShopId: function setShopId(state, shop_id) {
      state.shop_id = shop_id;
    },
    setLoginStatus: function setLoginStatus(state, info) {
      state.loginStatus = info;
    },
    tempIndex: function tempIndex(state, info) {
      state.temp = info;
    },
    tempItemIndex: function tempItemIndex(state, info) {
      state.temp.item = info;
    },
    // 修改商品列表模板id
    puteListCode: function puteListCode(state, info) {
      state.temp.item.ViewSet.goodsList.code = "goodsList".concat(info);
    },
    membertempIndex: function membertempIndex(state, info) {
      state.member_temp = info;
    },
    setNewDiyData: function setNewDiyData(state, info) {
      state.newDiyData = info;
    },
    setCservice: function setCservice(state, n) {
      state.cservice = n;
    },
    toIsShow: function toIsShow(state, n) {
      state.isShow = n;
    },
    alerts: function alerts(state, msg) {
      _Dialog.alert({
        message: msg
      });
    },
    views: function views(state) {
      state.view = !state.view;
    },
    viewsFalse: function viewsFalse(state) {
      // 中台租赁不随装修显示横排商品
      state.view = true;
    },
    MESSAGE: function MESSAGE(state, value) {
      state.message = value || state.message;
    },
    member: function member(state, obj) {
      state.name = obj.name;
      state.level = obj.level;
      state.icon = obj.icon;
    },
    login: function login(state, log) {
      state.islogin = log.login;
    },
    updateBanlace: function updateBanlace(state, balance) {
      // console.log('账户余额')
      // console.log(balance.balance)
      state.balance = balance.balance;
    },
    updateLocation: function updateLocation(state, location) {
      // console.log('账户余额')
      // console.info('=====updateLocation======', location)
      state.location = location;
    },
    setReferer: function setReferer(state, referer) {
      state.referer = referer;
    },
    setCallback: function setCallback(state, callback_url) {
      state.callback_url = callback_url;
    },
    setStatus: function setStatus(state, value) {
      state.status = value.status;
    },
    setCurrentAddress: function setCurrentAddress(state, address) {
      state.currentAddress.dispatch_type_id = address.dispatch_type_id;
      state.currentAddress.address = address.address;
      state.currentAddress.mobile = address.mobile;
      state.currentAddress.realname = address.realname;
    },
    savePop: function savePop(state, pop) {
      state.pop = pop;
    },
    saveAddresssInfo: function saveAddresssInfo(state, address) {
      // console.log(address)
      state.address_info = address.value;
      state.city = address.city;
      state.district = address.district;
      state.province = address.province;
    },
    setMailInfo: function setMailInfo(state, mailInfo) {
      // console.log("setMailInfo=", mailInfo)
      state.mailInfo = mailInfo;
    },
    // 设置插件列表
    setPlugins: function setPlugins(state, plugins) {
      state.plugins = plugins;
    },
    // 存储火车票对应城市
    setTrainCity: function setTrainCity(state, trainCity) {
      state.trainCity = trainCity;
    },
    // 存储飞机票对应城市
    setAirCity: function setAirCity(state, airCity) {
      state.airCity = airCity;
    },
    // 存储自定义语言包
    setMailLanguage: function setMailLanguage(state, mailLanguage) {
      state.mailLanguage = mailLanguage;
    },
    // 存储区域id
    setZoneId: function setZoneId(state, zoneId) {
      state.zoneId = zoneId;
    },
    // 存储区域开关
    setZoneShow: function setZoneShow(state, zoneShow) {
      state.zoneShow = zoneShow;
    },
    setDefaultMoreData: function setDefaultMoreData(state, data) {
      state.temp.default.goods.data = state.temp.default.goods.data.concat(data);
    },
    // 旧装修
    setMoreData: function setMoreData(state, data) {
      if (state.temp.item.data[data.index].temp == "showgoods" || state.temp.item.data[data.index].temp == "showflashsale") {
        state.temp.item.data[data.index].data = state.temp.item.data[data.index].data.concat(data.data);
      }
    },
    setLoadMore: function setLoadMore(state, data) {
      state.temp.item.data[data.index].isLoadMore = data.isLoadMore;
    },
    // 附近商品
    setNearData: function setNearData(state, data) {
      state.temp.item.data[data.index].get_info = data.data;
    },
    setNearMoreData: function setNearMoreData(state, data) {
      state.temp.item.data[data.index].get_info.data = state.temp.item.data[data.index].get_info.data.concat(data.data);
    },
    setNearLoadMore: function setNearLoadMore(state, data) {
      state.temp.item.data[data.index].isLoadMore = data.isLoadMore;
    },
    setmemberNearMoreData: function setmemberNearMoreData(state, data) {
      state.member_temp.data[data.index].get_info.data = state.member_temp.data[data.index].get_info.data.concat(data.data);
    },
    setmemberNearLoadMore: function setmemberNearLoadMore(state, data) {
      state.member_temp.data[data.index].isLoadMore = data.isLoadMore;
    },
    setNearMemberData: function setNearMemberData(state, data) {
      state.member_temp.data[data.index].get_info = data.data;
    },
    // 设置是否第一次进入邀请页面
    setInviter: function setInviter(state, is_Inviter) {
      state.is_Inviter = is_Inviter;
    },
    setSelfCarryApply: function setSelfCarryApply(state, data) {
      state.selfCarryApply = data;
    },
    setApplyModel: function setApplyModel(state, data) {
      state.applyModel = data;
    },
    setPasswordValidation: function setPasswordValidation(state, data) {
      state.passwordValidation = data;
    },
    setAdApply: function setAdApply(state, data) {
      state.adApply = data;
    },
    setLiveRoomID: function setLiveRoomID(state, data) {
      state.liveRoomID = data;
    },
    setShareLiveRoomID: function setShareLiveRoomID(state, data) {
      state.share_room_id = data;
    },
    setReturnLiveBtn: function setReturnLiveBtn(state, data) {
      state._isShowReturnLiveBtn = data;
    },
    setAdDevice: function setAdDevice(state, data) {
      state.adDevice = data;
    },
    // 案例库
    setCaseLibrary: function setCaseLibrary(state, datas) {
      state.caseLibrary = datas;
    },
    setCrmBind: function setCrmBind(state, data) {
      state._isShowCrmBind = data;
    },
    setWallerBind: function setWallerBind(state, data) {
      state._isShowWalletBind = data;
    },
    openWallerBind: function openWallerBind(state, data) {
      state.showWechatshar = data;
    },
    setOrderErrReturnUrl: function setOrderErrReturnUrl(state, data) {
      state.orderErrReturnUrl = data;
    },
    setWXlogined: function setWXlogined(state, data) {
      state.isWXlogined = data; //是否已经微信登录过
    },
    setAppointment: function setAppointment(state, data) {
      state.time_appointment = data;
    }
  }
};