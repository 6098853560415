import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 门店自助买单
export default [{
  path: "/storePriceTagIndex/:store_id?",
  component: function component() {
    return import("../../../views/o2o/storePriceTag/storePriceTagIndex.vue");
  },
  name: "storePriceTagIndex",
  meta: {
    title: "自助买单",
    foot: true
  }
}];