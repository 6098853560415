import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 天天用水
export default [{
  path: "/water_machine_consumer",
  name: "water_machine_consumer",
  component: function component() {
    return import("../../../views/water_machine/index.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_tie_card",
  name: "water_machine_tie_card",
  component: function component() {
    return import("../../../views/water_machine/tie_card.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_no_card/:device_no?",
  name: "water_machine_no_card",
  component: function component() {
    return import("../../../views/water_machine/no_card_water.vue");
  },
  meta: {
    title: "无卡打水",
    foot: true
  }
}, {
  path: "/water_machine_history",
  name: "water_machine_history",
  component: function component() {
    return import("../../../views/water_machine/history.vue");
  },
  meta: {
    title: "历史记录",
    foot: true
  }
}, {
  path: "/water_machine_card",
  name: "water_machine_card",
  component: function component() {
    return import("../../../views/water_machine/card.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_recharge",
  name: "water_machine_recharge",
  component: function component() {
    return import("../../../views/water_machine/recharge.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_fetch",
  name: "water_machine_fetch",
  component: function component() {
    return import("../../../views/water_machine/fetch.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_business",
  name: "water_machine_business",
  component: function component() {
    return import("../../../views/water_machine/subscriber.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_equipment",
  name: "water_machine_equipment",
  component: function component() {
    return import("../../../views/water_machine/equipment.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/water_machine_apply_for/:apply_flag",
  name: "water_machine_apply_for",
  component: function component() {
    return import("../../../views/water_machine/apply_for.vue");
  },
  meta: {
    foot: true
  }
}];