import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/new_distribution",
  name: "new_distribution",
  component: function component() {
    return import("../../../views/member/new_distribution/index");
  },
  meta: {
    title: "新分销",
    foot: true
  }
}, {
  path: "/distribution_funds",
  name: "distribution_funds",
  component: function component() {
    return import("../../../views/member/new_distribution/distribution_funds.vue");
  },
  meta: {
    title: "慈善基金",
    foot: true
  }
}, {
  path: "/new_distribution/red_packet",
  name: "new_distribution_red_packet",
  component: function component() {
    return import("../../../views/member/new_distribution/red_packet.vue");
  },
  meta: {
    title: "领取红包",
    foot: true
  }
}];