var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.fun.isWindVane() ? _c("m-head") : _vm._e(),
      _c(
        "div",
        {
          class: [
            _vm.fun.getPhoneEnv() == 3 &&
            !_vm.fun.isApp() &&
            (!_vm.$route.meta.isPC ||
              (_vm.$store.state.caseLibrary != null &&
                _vm.$store.state.caseLibrary.set.pc_style == 0))
              ? "pcStyle"
              : ""
          ],
          style: {
            width:
              _vm.fun.getPhoneEnv() == 3 &&
              !_vm.fun.isApp() &&
              (!_vm.$route.meta.isPC ||
                (_vm.$store.state.caseLibrary != null &&
                  _vm.$store.state.caseLibrary.set.pc_style == 0))
                ? "375px"
                : "",
            margin:
              _vm.fun.getPhoneEnv() == 3 &&
              !_vm.fun.isApp() &&
              (!_vm.$route.meta.isPC ||
                (_vm.$store.state.caseLibrary != null &&
                  _vm.$store.state.caseLibrary.set.pc_style == 0))
                ? "0 auto"
                : ""
          },
          attrs: { id: "appMain" }
        },
        [
          _c(
            "keep-alive",
            [
              !_vm.$route.meta.notKeepAlive &&
              _vm.isRouterAlive &&
              !_vm.$route.meta.notKeepAliveKey
                ? _c("router-view", {
                    key: _vm.$route.fullPath,
                    on: { changeFoot: _vm.initFoot }
                  })
                : _vm._e(),
              !_vm.$route.meta.notKeepAlive &&
              _vm.isRouterAlive &&
              _vm.$route.meta.notKeepAliveKey
                ? _c("router-view", { on: { changeFoot: _vm.initFoot } })
                : _vm._e()
            ],
            1
          ),
          _vm.$route.meta.notKeepAlive && _vm.isRouterAlive
            ? _c("router-view", { key: _vm.$route.fullPath })
            : _vm._e(),
          _c("loading")
        ],
        1
      ),
      _vm.isShowReturnLiveBtn
        ? _c(
            "div",
            {
              attrs: { id: "returnLiveBtn" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.gotoLiveRoom.apply(null, arguments)
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-angle-left" }),
              _c("span", { staticClass: "rlbtext" }, [
                _vm._v(_vm._s(_vm.$i18n.t("返回直播"))),
                _c("i", {
                  staticClass:
                    "iconfont icon-adsystem_icon_cancle clone_btn_style",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.clearLiveBtn.apply(null, arguments)
                    }
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      !_vm.foot &&
      (_vm.$store.state.temp.item.is_decorate === 1 ||
        _vm.$store.state.temp.item.menus === "" ||
        (_vm.$store.state.temp.item.menus &&
          _vm.$store.state.temp.item.menus.page_info))
        ? _c("U_foot", { attrs: { id: _vm.id + "1" } })
        : _vm._e(),
      _vm.isShowCrmBind ? _c("crmBind") : _vm._e(),
      _vm.isShowWalletBind && this.fun.isWalletApp() && !_vm.isCloneWalletBind
        ? _c("walletBind")
        : _vm._e(),
      _c("yz-wechat-share-img", {
        attrs: { text: _vm.shareText },
        model: {
          value: _vm.showWechatshar,
          callback: function($$v) {
            _vm.showWechatshar = $$v
          },
          expression: "showWechatshar"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }