import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 链动 #10027
export default [{
  path: "/chaining",
  component: function component() {
    return import("../../../views/extension/chaining/index.vue");
  },
  name: "chaining",
  meta: {
    title: "新店商",
    foot: true
  }
}];