import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//数字藏品开发
{
  path: "/collection/user",
  component: function component() {
    return import("../../../views/numberCollection/collectionUser/collectionUser.vue");
  },
  name: "collectionUser",
  meta: {
    title: "藏品管理",
    foot: true
  }
}, {
  path: "/collection/list",
  component: function component() {
    return import("../../../views/numberCollection/collectionList/collectionList.vue");
  },
  name: "collectionList",
  meta: {
    title: "我的藏品",
    foot: true
  }
}, {
  path: "/collection/present",
  component: function component() {
    return import("../../../views/numberCollection/presentList/presentList.vue");
  },
  name: "presentList",
  meta: {
    title: "转赠明细",
    foot: true
  }
}, {
  path: "/collection/orderList",
  component: function component() {
    return import("../../../views/numberCollection/orderList/orderList.vue");
  },
  name: "collectionOrder",
  meta: {
    title: "已兑换藏品",
    foot: true
  }
}, {
  path: "/collection/classify",
  component: function component() {
    return import("../../../views/numberCollection/classify/classify.vue");
  },
  name: "collectionClassify",
  meta: {
    title: "藏品中心",
    foot: true
  }
}];