import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 自动售货机
export default [{
  path: "/vending_machine",
  name: "vending_machine",
  component: function component() {
    return import("../../../views/vending_machine");
  },
  meta: {
    foot: true
  }
}];