import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//资产详情

//评估资产详情
var Assessment = function Assessment(r) {
  return require(["../views/blockchain/asset_details/assessment"], r);
};
//分红
// const AssetProfit = r => require(['../views/blockchain/asset_details/asset_profit'], r);
//全部类型
// const Categories = r => require(['../views/blockchain/asset_details/categories'], r);
//报告详情
var AssetNotices = function AssetNotices(r) {
  return require(["../views/blockchain/asset_details/asset_notices"], r);
};
//数字资产
// const DigitalAsset = r => require(['../views/blockchain/asset_details/digital_asset'], r);
//财务
// const Finance = r => require(['../views/blockchain/asset_details/finance'], r);
//简况
var AssetIntroduction = function AssetIntroduction(r) {
  return require(["../views/blockchain/asset_details/introduction"], r);
};
//报告和公告详情
var DetailReport = function DetailReport(r) {
  return require(["../views/blockchain/asset_details/report"], r);
};
//我要销售
var AssetSale = function AssetSale(r) {
  return require(["../views/blockchain/asset_details/sale"], r);
};

//我的资产

//认购记录、激活、奖励、买卖记录、转让记录、分红记录
var BuyingRecord = function BuyingRecord(r) {
  return require(["../views/blockchain/my_asset/buying_record"], r);
};
//查看资产
var CheckAsset = function CheckAsset(r) {
  return require(["../views/blockchain/my_asset/check_asset"], r);
};
//进出记录
var IncomeRecord = function IncomeRecord(r) {
  return require(["../views/blockchain/my_asset/income_record"], r);
};
//我的资产
var MyAsset = function MyAsset(r) {
  return require(["../views/blockchain/my_asset/my_asset"], r);
};
//我的秘钥
var SecretKey = function SecretKey(r) {
  return require(["../views/blockchain/my_asset/secret_key"], r);
};
//交易密码
var SetPassword = function SetPassword(r) {
  return require(["../views/blockchain/my_asset/set_password"], r);
};
//转让
var AssetTrans = function AssetTrans(r) {
  return require(["../views/blockchain/my_asset/transfer"], r);
};
//交易详情（买入详情..）
var TransactionDetails = function TransactionDetails(r) {
  return require(["../views/blockchain/my_asset/transaction_details"], r);
};

//交易大厅主页

//区块链-资产数字化
var TransHome = function TransHome(r) {
  return require(["../views/blockchain/transaction/home"], r);
};
//资产分类
var AssetClassify = function AssetClassify(r) {
  return require(["../views/blockchain/transaction/asset_classify"], r);
};
//认购买入
var Buying = function Buying(r) {
  return require(["../views/blockchain/transaction/buying"], r);
};
//咨询公告
var TransNotice = function TransNotice(r) {
  return require(["../views/blockchain/transaction/notice"], r);
};
//分类搜索列表
var ClassifiedSearch = function ClassifiedSearch(r) {
  return require(["../views/blockchain/transaction/classified_search"], r);
};
//交易大厅
// const TradingHall = r => require(["../views/blockchain/transaction/trading_hall"], r);

//名片

//名片中心
var CardCenter = function CardCenter(r) {
  return require(["../views/business_card/card_center"], r);
};
//名片角色
var BusinessCard = function BusinessCard(r) {
  return require(["../views/business_card/business_card"], r);
};
//名片动作
var Card_Action = function Card_Action(r) {
  return require(["../views/business_card/card_action"], r);
};
//名片码
var CardCode = function CardCode(r) {
  return require(["../views/business_card/card_code"], r);
};
//名片收藏
var CardCollect = function CardCollect(r) {
  return require(["../views/business_card/card_collect"], r);
};
//名片足迹
var CardFootprint = function CardFootprint(r) {
  return require(["../views/business_card/card_footprint"], r);
};
//名片选择商品
var CardGoods = function CardGoods(r) {
  return require(["../views/business_card/card_goods"], r);
};
//名片排行
var CardRanking = function CardRanking(r) {
  return require(["../views/business_card/card_ranking"], r);
};
//名片图片
var CardVisit = function CardVisit(r) {
  return require(["../views/business_card/card_visit"], r);
};
//编辑名片
var EditCard = function EditCard(r) {
  return require(["../views/business_card/edit_card"], r);
};

// 渠道商
var distributorIndex = function distributorIndex(r) {
  return require(["../views/distributor/index"], r);
};
// 累计收入
var accumulatedIncome = function accumulatedIncome(r) {
  return require(["../views/distributor/accumulated_income"], r);
};
// 推荐升级
var recommendUp = function recommendUp(r) {
  return require(["../views/distributor/recommendUp"], r);
};
// 提交推荐升级
var submitRecommend = function submitRecommend(r) {
  return require(["../views/distributor/submitRecommend"], r);
};
// 我的库存
var myRepertory = function myRepertory(r) {
  return require(["../views/distributor/my_repertory"], r);
};
// 订货
var orderGoods = function orderGoods(r) {
  return require(["../views/distributor/order_goods"], r);
};
// 发货
var deliverGoods = function deliverGoods(r) {
  return require(["../views/distributor/deliver_goods"], r);
};
// 发货下一步
var deliverGoodsNext = function deliverGoodsNext(r) {
  return require(["../views/distributor/deliver_goods_next"], r);
};
// 下单
var placeOrder = function placeOrder(r) {
  return require(["../views/distributor/place_order"], r);
};
// 换货
var exchangeGoods = function exchangeGoods(r) {
  return require(["../views/distributor/exchange_goods"], r);
};
// 换货详情
var exchangeDetail = function exchangeDetail(r) {
  return require(["../views/distributor/exchange_detail"], r);
};
// 兑换券
var exchangeTicket = function exchangeTicket(r) {
  return require(["../views/distributor/exchange_ticket"], r);
};
// 我的下线客户
var myReferrals = function myReferrals(r) {
  return require(["../views/distributor/my_referrals"], r);
};
// 我的换货
var myExchange = function myExchange(r) {
  return require(["../views/distributor/my_exchange"], r);
};
// 客户订单 我的发货  我的补货
var clientOrder = function clientOrder(r) {
  return require(["../views/distributor/client_order"], r);
};
// 订单详情
var DisOrderDetail = function DisOrderDetail(r) {
  return require(["../views/distributor/order_detail"], r);
};
// 订货换货
var bookingChange = function bookingChange(r) {
  return require(["../views/distributor/booking_change"], r);
};
//库存明细
var distributor_stock = function distributor_stock(r) {
  return require(["../views/distributor/distributor_stock"], r);
};
//客户订货详情
// const dis_order_datail = r => require(['../views/distributor/dis_order_datail'], r);
//购物车
var exchangeCart = function exchangeCart(r) {
  return require(["../views/distributor/cart"], r);
};
var distributorRank = function distributorRank(r) {
  return require(["../views/distributor/distributor_rank"], r);
};
var estimatedIncome = function estimatedIncome(r) {
  return require(["../views/distributor/estimated_income"], r);
};
var estimatedIncomeDetail = function estimatedIncomeDetail(r) {
  return require(["../views/distributor/estimated_income_detail"], r);
};

//直播Live
//直播助手
var helperLiveList = function helperLiveList(r) {
  return require(["../views/live/helper_liveList"], r);
};
//直播助手直播间
var helperLiveRoom = function helperLiveRoom(r) {
  return require(["../views/live/helper_liveRoom"], r);
};
//主播申请
var anchorApply = function anchorApply(r) {
  return require(["../views/live/anchorApply"], r);
};
//主播个人中心
var anchorDetail = function anchorDetail(r) {
  return require(["../views/live/anchorDetail"], r);
};
//直播列表
var liveList = function liveList(r) {
  return require(["../views/live/liveList"], r);
};
//直播页
var livePage = function livePage(r) {
  return require(["../views/live/livePage"], r);
};
//直播预告
var foreshow = function foreshow(r) {
  return require(["../views/live/foreshow"], r);
};
//直播主播分红
var liveDividend = function liveDividend(r) {
  return require(["../views/live/liveDividend"], r);
};
//粉丝列表
var liveFanList = function liveFanList(r) {
  return require(["../views/live/liveFanList"], r);
};

//精英奖
var eliteAwardHome = function eliteAwardHome(r) {
  return require(["../views/member/eliteAward/eliteAward_home"], r);
};
var eliteAwardRecord = function eliteAwardRecord(r) {
  return require(["../views/member/eliteAward/eliteAward_record"], r);
};
var eliteAwardRank = function eliteAwardRank(r) {
  return require(["../views/member/eliteAward/eliteAward_rank"], r);
};

//安装服务（绿象）
var workerApply = function workerApply(r) {
  return require(["../views/member/install_service/worker_apply"], r);
};
var workerOrderlist = function workerOrderlist(r) {
  return require(["../views/member/install_service/worker_orderlist"], r);
};
var workerOrderDetail = function workerOrderDetail(r) {
  return require(["../views/member/install_service/worker_orderDetail"], r);
};
var userOrderlist = function userOrderlist(r) {
  return require(["../views/member/install_service/user_orderlist"], r);
};
var userOrderDetail = function userOrderDetail(r) {
  return require(["../views/member/install_service/user_orderDetail"], r);
};
var installIncome = function installIncome(r) {
  return require(["../views/member/install_service/install_income"], r);
};

//抢团
var grab_group_home = function grab_group_home(r) {
  return require(["../views/goods/grab_group/grab_group_home"], r);
};
var grab_group_my = function grab_group_my(r) {
  return require(["../views/goods/grab_group/grab_group_my"], r);
};
var grab_group_my_record = function grab_group_my_record(r) {
  return require(["../views/goods/grab_group/grab_group_my_record"], r);
};
var grab_group_reward = function grab_group_reward(r) {
  return require(["../views/goods/grab_group/grab_group_reward"], r);
};
// const grab_group = r => require(["../views/goods/grab_group/grab_group"], r);
var grabGroup_detail = function grabGroup_detail(r) {
  return require(["../views/goods/grab_group/grab_group_detail"], r);
};

//推荐官
var referral_officer = function referral_officer(r) {
  return require(["../views/extension/other/referral_officer"], r);
};
var referral_officerDetail = function referral_officerDetail(r) {
  return require(["../views/extension/other/referral_officerDetail"], r);
};

// 安装服务
var installationSeverApply = function installationSeverApply(r) {
  return require(["../views/extension/other/installationSever/worker_apply"], r);
};
var installationSeverExtension = function installationSeverExtension(r) {
  return require(["../views/extension/other/installationSeverExtension"], r);
};
var installationSeverDividends = function installationSeverDividends(r) {
  return require(["../views/extension/other/installationSever/installationSeverDividends"], r);
};
var installationSeverHome = function installationSeverHome(r) {
  return require(["../views/extension/other/installationSever/home"], r);
};
// 师傅端详情
var installationSeverDetail = function installationSeverDetail(r) {
  return require(["../views/extension/other/installationSever/detail"], r);
};
// 会员端详情
var installationSeverRefund = function installationSeverRefund(r) {
  return require(["../views/extension/other/installationSever/refund"], r);
};
// 售后原因
var installationSeverAftersale = function installationSeverAftersale(r) {
  return require(["../views/extension/other/installationSever/afterSaleList"], r);
};
// 售后申请
var installationSeverAfterApply = function installationSeverAfterApply(r) {
  return require(["../views/extension/other/installationSever/afterApply"], r);
};
// 商家核销
var installationSeverWrite = function installationSeverWrite(r) {
  return require(["../views/extension/other/installationSever/installationSeverWrite"], r);
};
//群活码
var groupCodeIndex = function groupCodeIndex(r) {
  return require(["../views/member/group_code/group_code_index"], r);
};
var groupCodeCreate = function groupCodeCreate(r) {
  return require(["../views/member/group_code/group_code_create"], r);
};
var groupCodeData = function groupCodeData(r) {
  return require(["../views/member/group_code/group_code_data"], r);
};
var groupCodeEdit = function groupCodeEdit(r) {
  return require(["../views/member/group_code/group_code_edit"], r);
};
var groupCodeRanking = function groupCodeRanking(r) {
  return require(["../views/member/group_code/group_code_ranking"], r);
};
var groupCodeRecord = function groupCodeRecord(r) {
  return require(["../views/member/group_code/group_code_record"], r);
};
var groupCodeShow = function groupCodeShow(r) {
  return require(["../views/member/group_code/group_code_show"], r);
};
var groupCodeLabel = function groupCodeLabel(r) {
  return require(["../views/member/group_code/group_code_label"], r);
};
var groupCodeSearch = function groupCodeSearch(r) {
  return require(["../views/member/group_code/group_code_search"], r);
};
var groupCodeGoodsList = function groupCodeGoodsList(r) {
  return require(["../views/member/group_code/group_code_goodsList"], r);
};

// 测肤
var skinHome = function skinHome(r) {
  return require(["../views/others/skin/home"], r);
};
var skinResult = function skinResult(r) {
  return require(["../views/others/skin/result"], r);
};
var skinShare = function skinShare(r) {
  return require(["../views/others/skin/share"], r);
};
var skinLog = function skinLog(r) {
  return require(["../views/others/skin/log"], r);
};
var skinRank = function skinRank(r) {
  return require(["../views/others/skin/rank"], r);
};
var AllResult = function AllResult(r) {
  return require(["../views/others/skin/all_result"], r);
};

//社区团购B
var communityBuyIndex = function communityBuyIndex(r) {
  return require(["../views/community_buying/index"], r);
};
var communityBuyEdit_info = function communityBuyEdit_info(r) {
  return require(["../views/community_buying/edit_info"], r);
};
var communityBuyGroup = function communityBuyGroup(r) {
  return require(["../views/community_buying/group_buying"], r);
};
var communityBuyDetails = function communityBuyDetails(r) {
  return require(["../views/community_buying/buying_details"], r);
};
var communityBuyData = function communityBuyData(r) {
  return require(["../views/community_buying/buying_data"], r);
};
var communityBuyOrder = function communityBuyOrder(r) {
  return require(["../views/community_buying/buying_order"], r);
};
var communityBuyPersonal = function communityBuyPersonal(r) {
  return require(["../views/community_buying/buying_personal"], r);
};
var mygroup = function mygroup(r) {
  return require(["../views/community_buying/mygroup"], r);
};

//站内消息通知
var stationNoticeIndex = function stationNoticeIndex(r) {
  return require(["../views/member/station_notice/station_notice_index"], r);
};
var stationNoticeSetting = function stationNoticeSetting(r) {
  return require(["../views/member/station_notice/station_notice_setting"], r);
};
var stationNoticeExtract = function stationNoticeExtract(r) {
  return require(["../views/member/station_notice/station_notice_extract"], r);
};
var stationNoticeExamine = function stationNoticeExamine(r) {
  return require(["../views/member/station_notice/station_notice_examine"], r);
};
var stationNoticeLogistics = function stationNoticeLogistics(r) {
  return require(["../views/member/station_notice/station_notice_logistics"], r);
};
var stationNoticekefu = function stationNoticekefu(r) {
  return require(["../views/member/station_notice/stationNoticekefu"], r);
};
var stationNoticeStore = function stationNoticeStore(r) {
  return require(["../views/member/station_notice/stationNoticeStore"], r);
};
var stationNoticeAnchor = function stationNoticeAnchor(r) {
  return require(["../views/member/station_notice/station_notice_anchor"], r);
};
var stationNoticeCustomer = function stationNoticeCustomer(r) {
  return require(["../views/member/station_notice/station_notice_customer"], r);
};
var stationNoticeProperty = function stationNoticeProperty(r) {
  return require(["../views/member/station_notice/station_notice_property"], r);
};
var stationNoticeOrder = function stationNoticeOrder(r) {
  return require(["../views/member/station_notice/station_notice_order"], r);
};
var stationNoticeActivity = function stationNoticeActivity(r) {
  return require(["../views/member/station_notice/station_notice_activity"], r);
};
var stationNoticeActivityDetail = function stationNoticeActivityDetail(r) {
  return require(["../views/member/station_notice/station_notice_activity_detail"], r);
};
//爱心值排行榜
var loveRanking = function loveRanking(r) {
  return require(["../views/member/love_ranking/love_ranking"], r);
};

// //拼单
// const collageIndex = r => require(["../views/member/collage/collage_index"], r); //首页
// const collageReportform = r => require(["../views/member/collage/collage_reportform"], r); //报表
// const collageRegionBonus = r => require(["../views/member/collage/collage_region_bonus"], r); // 拼单分红

// /***************非会员******************/

// const tacitApply = r => require(["../views/member/collage/tacit/tacit_apply"], r); //会员申请
// const tacitAddproject = r => require(["../views/member/collage/tacit/tacit_addproject"], r); //添加项目
// const tacitDetproject = r => require(["../views/member/collage/tacit/tacit_detproject"], r); //项目详情
// const tacitEditproject = r => require(["../views/member/collage/tacit/tacit_editproject"], r); //修改项目

// /***************等级创始人******************/
// const founderCollage = r => require(["../views/member/collage/founder/founder_collage"], r); //拼单
// const founderEchievement = r => require(["../views/member/collage/founder/founder_echievement"], r); //业绩榜单
// const founderTeam = r => require(["../views/member/collage/founder/founder_team"], r); //我的团队
// const founderSinglepool = r => require(["../views/member/collage/founder/founder_singlepool"], r); //拼单池
// const founderBicycle = r => require(["../views/member/collage/founder/founder_bicycle"], r); //拼单车

//区域代理
var regionalAgent = function regionalAgent(r) {
  return require(["../views/member/regional_agent/regional_agent_index"], r);
};
var regionalAgentResult = function regionalAgentResult(r) {
  return require(["../views/member/regional_agent/regional_agent_result"], r);
};
var regionalAgentSearch = function regionalAgentSearch(r) {
  return require(["../views/member/regional_agent/regional_agent_search"], r);
};

//看视频答题
var videoAnswer = function videoAnswer(r) {
  return require(["../views/video_goods/little_video/video_answer"], r);
};

//共享合伙人
var sharedPartner = function sharedPartner(r) {
  return require(["../views/member/shared_partner/shared_partner"], r);
};
var sharedPartner_bonus = function sharedPartner_bonus(r) {
  return require(["../views/member/shared_partner/sharedPartner_bonus"], r);
};
//推广自定义页面
var diyIncomePage = function diyIncomePage(r) {
  return require(["../views/diy_page/diy_income_page"], r);
};
//社群接龙
var community_solitaire = function community_solitaire(r) {
  return require(["../views/others/community_solitaire/index"], r);
};
//新抽奖
var newDrawIndex = function newDrawIndex(r) {
  return require(["../views/others/new_draw/index"], r);
};
var lottery_complaint = function lottery_complaint(r) {
  return require(["../views/others/new_draw/lottery_complaint"], r);
};
var winners_list = function winners_list(r) {
  return require(["../views/others/new_draw/winners_list"], r);
};
var mydraw = function mydraw(r) {
  return require(["../views/others/new_draw/mydraw"], r);
};
var lucky_draw = function lucky_draw(r) {
  return require(["../views/others/new_draw/lucky_draw"], r);
};
var newDrawMyPrize = function newDrawMyPrize(r) {
  return require(["../views/others/new_draw/my_prize"], r);
};
var newDrawRecommentReward = function newDrawRecommentReward(r) {
  return require(["../views/others/new_draw/recomment_reward"], r);
};
var newDrawPayment = function newDrawPayment(r) {
  return require(["../views/others/new_draw/payment"], r);
};

//业绩区域奖励
var regionalAwards = function regionalAwards(r) {
  return require(["../views/member/regional_awards/regional_awards_index"], r);
};
var regionalAwardsRecord = function regionalAwardsRecord(r) {
  return require(["../views/member/regional_awards/regional_awards_record"], r);
};

//新团队分红
var newTeamBonus = function newTeamBonus(r) {
  return require(["../views/member/income/teamAgentCenter/new_team_bonus"], r);
};
//蓝积分
var integralBlueCattle = function integralBlueCattle(r) {
  return require(["../views/member/integral_v2/member_integral_blueCattle"], r);
};
//蓝积分登陆
var lanLogin = function lanLogin(r) {
  return require(["../views/member/integral_v2/lan_login"], r);
};

//我的市场
var myMarket = function myMarket(r) {
  return require(["../views/member/tool/my_market"], r);
};

// 商品分红
var commodityDividends = function commodityDividends(r) {
  return require(["../views/extension/commodityDividends"], r);
};

//盲盒
var blindBoxIndex = function blindBoxIndex(r) {
  return require(["../views/member/blindBox/blindBoxIndex"], r);
};
var blindBoxDetail = function blindBoxDetail(r) {
  return require(["../views/member/blindBox/blindBoxDetail"], r);
};
var blindBoxHelping = function blindBoxHelping(r) {
  return require(["../views/member/blindBox/blindBoxHelping"], r);
};

// 社区门店
var communityStore = function communityStore(r) {
  return require(["../views/o2o/community_stores/community_stores"], r);
};
var communityStoreDetails = function communityStoreDetails(r) {
  return require(["../views/o2o/community_stores/community_stores_details"], r);
};

//新零售
var newRetailIndex = function newRetailIndex(r) {
  return require(["../views/member/newRetail/index"], r);
};
var newRetailSearch = function newRetailSearch(r) {
  return require(["../views/member/newRetail/search"], r);
};
var newRetail_agencyPackage = function newRetail_agencyPackage(r) {
  return require(["../views/member/newRetail/agency_package"], r);
};
var newRetailInventory = function newRetailInventory(r) {
  return require(["../views/member/newRetail/inventory"], r);
};
var newRetailRetailLink = function newRetailRetailLink(r) {
  return require(["../views/member/newRetail/retailLink"], r);
};
var newRetailSalesRecord = function newRetailSalesRecord(r) {
  return require(["../views/member/newRetail/salesRecord"], r);
};
var newRetailSalesRecordDetail = function newRetailSalesRecordDetail(r) {
  return require(["../views/member/newRetail/salesRecord_detail"], r);
};
var newRetailSalesExtension = function newRetailSalesExtension(r) {
  return require(["../views/member/newRetail/extension"], r);
};
var newRetailInventoryDetails = function newRetailInventoryDetails(r) {
  return require(["../views/member/newRetail/Inventory_details"], r);
};
// const newRetailSalesGood = r => require(["../views/member/newRetail/good"], r);

var Test = function Test(r) {
  return require(["../views/business_card/test"], r);
};

//广告投放
var advertisingApply = function advertisingApply(r) {
  return require(["../views/member/Advertising/apply"], r);
};
var advertisingAdvertisers = function advertisingAdvertisers(r) {
  return require(["../views/member/Advertising/advertisers"], r);
};
var advertisingAdDetails = function advertisingAdDetails(r) {
  return require(["../views/member/Advertising/advertisersDetail"], r);
};
var advertisingRelease = function advertisingRelease(r) {
  return require(["../views/member/Advertising/releaseAD"], r);
};
var advertisingList = function advertisingList(r) {
  return require(["../views/member/Advertising/adList"], r);
};
var advertisingPutAD = function advertisingPutAD(r) {
  return require(["../views/member/Advertising/putAD"], r);
};
var advertisingPlayPage = function advertisingPlayPage(r) {
  return require(["../views/member/Advertising/adPlayPage"], r);
};

//评估资产详情
var version = function version(r) {
  return require(["../views/version"], r);
};

//消费积分
var consumerRewardIndex = function consumerRewardIndex(r) {
  return require(["../views/extension/consumer_reward/consumer_reward_index"], r);
};
var consumerRewardApply = function consumerRewardApply(r) {
  return require(["../views/extension/consumer_reward/consumer_reward_apply"], r);
};
var consumerRewardRecord = function consumerRewardRecord(r) {
  return require(["../views/extension/consumer_reward/consumer_reward_record"], r);
};
var consumerRewardPaySuccess = function consumerRewardPaySuccess(r) {
  return require(["../views/extension/consumer_reward/pay_success"], r);
};

//上级代付
var superiorPaymentIndex = function superiorPaymentIndex(r) {
  return require(["../views/superior_payment/superior_payment_index"], r);
};
var superiorPaymentPay = function superiorPaymentPay(r) {
  return require(["../views/superior_payment/superior_payment_pay"], r);
};
var superiorPaymentExtension = function superiorPaymentExtension(r) {
  return require(["../views/superior_payment/superior_payment_extension"], r);
};
var superiorPaymentDetails = function superiorPaymentDetails(r) {
  return require(["../views/superior_payment/superior_payment_details"], r);
};
//群拓客
var gduIndex = function gduIndex(r) {
  return require(["../views/member/group_develop_user/gdu_index"], r);
};
var gduEnterpriseDetails = function gduEnterpriseDetails(r) {
  return require(["../views/member/group_develop_user/gdu_enterprise_details"], r);
};
var gduEnterprisePoster = function gduEnterprisePoster(r) {
  return require(["../views/member/group_develop_user/join_group_poster"], r);
};
//订单导入
var orderImportIndex = function orderImportIndex(r) {
  return require(["../views/member/orderImport/order_import_index"], r);
};
var orderImportApply = function orderImportApply(r) {
  return require(["../views/member/orderImport/order_import_apply"], r);
};

// 门店独立余额
var independenceBalance = function independenceBalance(r) {
  return require(["../views/o2o/independenceBalance"], r);
};
var storeBalanceDetail = function storeBalanceDetail(r) {
  return require(["../views/o2o/storeBalanceDetail"], r);
};
// cps
var cpsPage = function cpsPage(r) {
  return require(["../views/others/cps/cpsPage"], r);
};
var cpsSearchPage = function cpsSearchPage(r) {
  return require(["../views/others/cps/cpsSearchPage"], r);
};
var cpsGoodDetail = function cpsGoodDetail(r) {
  return require(["../views/others/cps/cpsDetail"], r);
};
var cpsTransfer = function cpsTransfer(r) {
  return require(["../views/others/cps/cpsTransfer"], r);
};
// 个人红包
var personRed = function personRed(r) {
  return require(["../views/others/daily_red/personRed/personRed"], r);
};
var personRedList = function personRedList(r) {
  return require(["../views/others/daily_red/personRed/personRedList"], r);
};
var personRedListDetail = function personRedListDetail(r) {
  return require(["../views/others/daily_red/personRed/personRedListDetail"], r);
};
var personRedPay = function personRedPay(r) {
  return require(["../views/others/daily_red/personRed/personRedPay"], r);
};
var personRedissue = function personRedissue(r) {
  return require(["../views/others/daily_red/personRed/personRedissue"], r);
};
var personRedrecord = function personRedrecord(r) {
  return require(["../views/others/daily_red/personRed/personRedrecord"], r);
};
// 奖金池
var prizePool = function prizePool(r) {
  return require(["../views/others/prizePool/prizePool"], r);
};

// 商品详情新优化评价页面
var goodsComment = function goodsComment(r) {
  return require(["../views/goods/GoodsComment"], r);
};

//捐赠项目
var donationProjectIndex = function donationProjectIndex(r) {
  return require(["../views/member/donation_project/donation_project_index"], r);
};
var donationProjectList = function donationProjectList(r) {
  return require(["../views/member/donation_project/donation_project_list"], r);
};
var donationProjectSearch = function donationProjectSearch(r) {
  return require(["../views/member/donation_project/donation_project_search"], r);
};
var donationProjectPicture = function donationProjectPicture(r) {
  return require(["../views/member/donation_project/donation_project_picture"], r);
};
var donationProjectVideo = function donationProjectVideo(r) {
  return require(["../views/member/donation_project/donation_project_video"], r);
};
var donationProjectDetails = function donationProjectDetails(r) {
  return require(["../views/member/donation_project/donation_project_details"], r);
};
var donationProjectInfo = function donationProjectInfo(r) {
  return require(["../views/member/donation_project/donation_project_info"], r);
};
// 企业微信侧边栏
var workWechatSidebar = function workWechatSidebar(r) {
  return require(["../views/enterprise_wechat/wechat_sidebar"], r);
};
var sidebarIndex = function sidebarIndex(r) {
  return require(["../views/enterprise_wechat/index"], r);
};
var sidebarOrderDetail = function sidebarOrderDetail(r) {
  return require(["../views/enterprise_wechat/orderDetail"], r);
};
var customerManagement = function customerManagement(r) {
  return require(["../views/customer/customerManagement/customer"], r);
};
//小学霸-区域分红
var gratitudeRewardIndex = function gratitudeRewardIndex(r) {
  return require(["../views/extension/gratitude_reward/gratitude_reward_index"], r);
};
var DonateIndex = function DonateIndex(r) {
  return require(["../views/love/donate/DonateIndex"], r);
};
// 供应商配送
var supplierDriver = function supplierDriver(r) {
  return require(["../views/member/supplier/supplierDist/supplierDriver"], r);
};
// 配送入口
var distributionEntrance = function distributionEntrance(r) {
  return require(["../views/member/supplier/supplierDist/distributionEntrance"], r);
};

// 店铺助手
var storeManagementIndex = function storeManagementIndex(r) {
  return require(["../views/store_management/index"], r);
};
var storeGoodsManagement = function storeGoodsManagement(r) {
  return require(["../views/store_management/goods_management"], r);
};
var releaseStoreGoods = function releaseStoreGoods(r) {
  return require(["../views/store_management/release_goods"], r);
};
var storeGoodsCatgory = function storeGoodsCatgory(r) {
  return require(["../views/store_management/goods_category"], r);
};
var editGoodsCatgory = function editGoodsCatgory(r) {
  return require(["../views/store_management/edit_category"], r);
};
var storeManageDiyform = function storeManageDiyform(r) {
  return require(["../views/store_management/storeManageDiyform"], r);
};
var storeManageFormDetail = function storeManageFormDetail(r) {
  return require(["../views/store_management/storeManageFormDetail"], r);
};
var formDetail = function formDetail(r) {
  return require(["../views/store_management/formDetail"], r);
};
// 店铺助手--会员管理
var memberManagement = function memberManagement(r) {
  return require(["../views/store_management/member_management"], r);
};
var storeMemberInfo = function storeMemberInfo(r) {
  return require(["../views/store_management/member_info"], r);
};
var storeIntegralList = function storeIntegralList(r) {
  return require(["../views/store_management/integral_detail"], r);
};
var storeBalanceList = function storeBalanceList(r) {
  return require(["../views/store_management/balance_detail"], r);
};
var storeCouponList = function storeCouponList(r) {
  return require(["../views/store_management/coupon_list"], r);
};
// 店铺助手--财务管理
var financeManagement = function financeManagement(r) {
  return require(["../views/store_management/finance_management"], r);
};
// 店铺助手--提现记录
var withdrawalList = function withdrawalList(r) {
  return require(["../views/store_management/withdrawal_list"], r);
};
var withdrawalDetail = function withdrawalDetail(r) {
  return require(["../views/store_management/withdrawal_detail"], r);
};

//任务奖励开发
var dealerTaskRewardIndex = function dealerTaskRewardIndex(r) {
  return require(["../views/extension/dealer_task_reward/index"], r);
};
var dealerTaskRewardRecord = function dealerTaskRewardRecord(r) {
  return require(["../views/extension/dealer_task_reward/record"], r);
};

// 企业微信好友裂变
var workWxFriendExtend = function workWxFriendExtend(r) {
  return require(["../views/others/wx_friend_extend/index"], r);
};
var friendRanking = function friendRanking(r) {
  return require(["../views/others/wx_friend_extend/rankingList"], r);
};
var invitationRecord = function invitationRecord(r) {
  return require(["../views/others/wx_friend_extend/invitationRecord"], r);
};
var friendRewardRecord = function friendRewardRecord(r) {
  return require(["../views/others/wx_friend_extend/rewardRecord"], r);
};

// 企业微信 sop任务
var sopPushTask = function sopPushTask(r) {
  return require(["../views/workWx/sop_task/pushTaskList.vue"], r);
};
var sopTaskDetail = function sopTaskDetail(r) {
  return require(["../views/workWx/sop_task/taskDetail"], r);
};
var sopTaskList = function sopTaskList(r) {
  return require(["../views/workWx/sop_task/taskList"], r);
};

//品牌资源
var brandResourcesIndex = function brandResourcesIndex(r) {
  return require(["../views/brand_resources/brand_resources_index"], r);
};
var cardVoucherInfo = function cardVoucherInfo(r) {
  return require(["../views/brand_resources/card_voucher_info"], r);
};
// 代理商奖励
var allowanceIndex = function allowanceIndex(r) {
  return require(["../views/extension/allowanceIndex/allowanceIndex"], r);
};
var allowanceReward = function allowanceReward(r) {
  return require(["../views/extension/allowanceIndex/allowanceReward"], r);
};
var allowanceAchievement = function allowanceAchievement(r) {
  return require(["../views/extension/allowanceIndex/allowanceAchievement"], r);
};
var allowanceTeam = function allowanceTeam(r) {
  return require(["../views/extension/allowanceIndex/allowanceTeam"], r);
};
// 宙子云开发单独页面
var cloudPage = function cloudPage(r) {
  return require(["../views/others/cloudPage/cloudPage"], r);
};
var payBack = function payBack(r) {
  return require(["../views/member/order/pay_back/pay_back"], r);
};
// 分时预约
var timeAppointment_index = function timeAppointment_index(r) {
  return require(["../views/timeAppointment/timeAppointment_index"], r);
};
var timeAppointment_search = function timeAppointment_search(r) {
  return require(["../views/timeAppointment/timeAppointment_search"], r);
};
var timeAppointmentMy = function timeAppointmentMy(r) {
  return require(["../views/timeAppointment/timeAppointmentMy"], r);
};
var timeAppointmentMylist = function timeAppointmentMylist(r) {
  return require(["../views/timeAppointment/timeAppointmentMylist"], r);
};
var AppointmentUserDe = function AppointmentUserDe(r) {
  return require(["../views/timeAppointment/AppointmentUserDe"], r);
};
// 小猪欢欢项目 奖励池
var bonusPoolsIndex = function bonusPoolsIndex(r) {
  return require(["../views/extension/bonusPoolsIndex/bonusPoolsIndex"], r);
};
var bonusPoolsTeam = function bonusPoolsTeam(r) {
  return require(["../views/extension/bonusPoolsIndex/bonusPoolsTeam"], r);
};

// 话术库
var SpeechLibrary = function SpeechLibrary(r) {
  return require(["../views/workWx/speech-library/index.vue"], r);
};

// 见点奖励
var seePoint = function seePoint(r) {
  return require(["../views/extension/seePoint"], r);
};
// 置换亿栈
var debtapply = function debtapply(r) {
  return require(["../views/debt/debtapply"], r);
};
var debtindex = function debtindex(r) {
  return require(["../views/debt/debtindex"], r);
};
// 债权人
var creditorindex = function creditorindex(r) {
  return require(["../views/debt/creditor/creditorindex"], r);
};
// 债务人
var debtorindex = function debtorindex(r) {
  return require(["../views/debt/debtor/debtorindex"], r);
};
// 债务人店铺
var debtorShop = function debtorShop(r) {
  return require(["../views/debt/debtor/debtorShop"], r);
};
var userManagement = function userManagement(r) {
  return require(["../views/debt/userManagement"], r);
}; // 会员管理
var purchaseDetails = function purchaseDetails(r) {
  return require(["../views/debt/purchaseDetails"], r);
}; //采购款明细
var debtorAudit = function debtorAudit(r) {
  return require(["../views/debt/debtorAudit"], r);
}; //售后审核
var vipAdd = function vipAdd(r) {
  return require(["../views/debt/vipAdd"], r);
}; //搜索添加vip
var vipAddindex = function vipAddindex(r) {
  return require(["../views/debt/vipAddindex"], r);
}; //添加vip
var vipIndex = function vipIndex(r) {
  return require(["../views/debt/vipIndex"], r);
}; //vip首页
var vipBindRecord = function vipBindRecord(r) {
  return require(["../views/debt/vipBindRecord"], r);
}; //vip绑定记录
// 置换亿栈搜索
var debtSearch = function debtSearch(r) {
  return require(["../views/debt/search"], r);
};
// 置换亿栈店铺二维码
var debtShopCode = function debtShopCode(r) {
  return require(["../views/debt/debtShopCode"], r);
};

// 拓客雷达
var customerStatistic = function customerStatistic(r) {
  return require(["../views/workWx/customer-radar/statistic"], r);
};
var memberRecordDetail = function memberRecordDetail(r) {
  return require(["../views/workWx/customer-radar/record_detail"], r);
};
var wxWorkChatTransfer = function wxWorkChatTransfer(r) {
  return require(["../views/workWx/customer-radar/chatTransfer"], r);
};

// 门店签到
var storeSign = function storeSign(r) {
  return require(["../views/o2o/storeSign/storeSign.vue"], r);
};
// 门店签到明细
var storeSignDetail = function storeSignDetail(r) {
  return require(["../views/o2o/storeSign/storeSignDetail.vue"], r);
};
// 爱心公益
var publicWelfare = function publicWelfare(r) {
  return require(["../views/extension/public_welfare_fund/index"], r);
};
var welfareDonations = function welfareDonations(r) {
  return require(["../views/extension/public_welfare_fund/donations"], r);
};
var welfareRecord = function welfareRecord(r) {
  return require(["../views/extension/public_welfare_fund/record"], r);
};

// 关联收益
var newDistributor = function newDistributor(r) {
  return require(["../views/extension/new_distributor/index"], r);
};

// 银联支付
var unionPay = function unionPay(r) {
  return require(["../views/others/unionPay"], r);
};
// 见点奖励
var pointAward = function pointAward(r) {
  return require(["../views/others/pointAward"], r);
};
// 个人额度
var personalCredit = function personalCredit(r) {
  return require(["../views/others/personalCredit"], r);
};
var wxh5 = function wxh5(r) {
  return require(["../views/member/pay/wxh5_detail"], r);
};
export default [
// 代理商奖励
{
  path: "/allowanceIndex",
  component: allowanceIndex,
  name: "allowanceIndex",
  meta: {
    title: "代理商奖励",
    foot: true
  }
}, {
  path: "/allowanceReward/:ind",
  component: allowanceReward,
  name: "allowanceReward",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/allowanceAchievement",
  component: allowanceAchievement,
  name: "allowanceAchievement",
  meta: {
    title: "业绩统计",
    foot: true
  }
}, {
  path: "/allowanceTeam",
  component: allowanceTeam,
  name: "allowanceTeam",
  meta: {
    title: "团队人员",
    foot: true
  }
},
//资产详情
{
  path: "/assessment/:asset_id",
  component: Assessment,
  name: "Assessment",
  meta: {
    title: "资产详情",
    foot: false
  }
},
// {path: '/asset_profit', component: AssetProfit, name: 'AssetProfit', meta: {title: '分红', foot: true}},
// {path: '/categories', component: Categories, name: 'Categories', meta: {title: '全部类型', foot: true}},
{
  path: "/asset_notices/:notice_id",
  component: AssetNotices,
  name: "AssetNotices",
  meta: {
    title: "财务报告",
    foot: true
  }
},
// {path: '/digital_asset', component: DigitalAsset, name: 'DigitalAsset', meta: {title: '数字资产', foot: true}},
// {path: '/finance', component: Finance, name: 'Finance', meta: {title: '财务', foot: true}},
{
  path: "/asset_introduction/:asset_id",
  component: AssetIntroduction,
  name: "AssetIntroduction",
  meta: {
    title: "简况",
    foot: true
  }
}, {
  path: "/detail_report/:finance_id",
  component: DetailReport,
  name: "DetailReport",
  meta: {
    title: "财务报告",
    foot: true
  }
}, {
  path: "/asset_sale/:asset_id",
  component: AssetSale,
  name: "AssetSale",
  meta: {
    title: "我要销售",
    foot: true
  }
},
//我的资产
{
  path: "/buying_record/:asset_id",
  component: BuyingRecord,
  name: "BuyingRecord",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/check_asset/:asset_id",
  component: CheckAsset,
  name: "CheckAsset",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/income_record/:asset_id",
  component: IncomeRecord,
  name: "IncomeRecord",
  meta: {
    title: "进出记录",
    foot: true
  }
}, {
  path: "/my_asset",
  component: MyAsset,
  name: "MyAsset",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/secret_key/:name",
  component: SecretKey,
  name: "SecretKey",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/set_password",
  component: SetPassword,
  name: "SetPassword",
  meta: {
    title: "交易密码",
    foot: true
  }
}, {
  path: "/transfer/:asset_id",
  component: AssetTrans,
  name: "AssetTrans",
  meta: {
    title: "转让",
    foot: true
  }
}, {
  path: "/transaction_details/:id",
  component: TransactionDetails,
  name: "TransactionDetails",
  meta: {
    title: "详情",
    foot: true
  }
},
//交易大厅主页
{
  path: "/trans_home",
  component: TransHome,
  name: "TransHome",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/asset_classify",
  component: AssetClassify,
  name: "AssetClassify",
  meta: {
    title: "交易大厅",
    foot: true
  }
}, {
  path: "/buying/:asset_id",
  component: Buying,
  name: "Buying",
  meta: {
    title: "认购",
    foot: true
  }
}, {
  path: "/trans_notice",
  component: TransNotice,
  name: "TransNotice",
  meta: {
    title: "公告",
    foot: true
  }
}, {
  path: "/classified_search",
  component: ClassifiedSearch,
  name: "ClassifiedSearch",
  meta: {
    title: "分类搜索列表",
    foot: true
  }
},
// { path: "/trading_hall", component: TradingHall, name: "TradingHall", meta: { title: "交易大厅", foot: true } },

//名片
{
  path: "/business_card/card_center",
  component: CardCenter,
  name: "CardCenter",
  meta: {
    title: "名片中心",
    foot: true
  }
}, {
  path: "/business_card/business_card",
  component: BusinessCard,
  name: "BusinessCard",
  meta: {
    title: "名片",
    foot: true
  }
}, {
  path: "/business_card/card_action/:card_id",
  component: Card_Action,
  name: "Card_Action",
  meta: {
    title: "数据统计",
    foot: true
  }
}, {
  path: "/business_card/card_code/:card_id",
  component: CardCode,
  name: "CardCode",
  meta: {
    title: "名片码",
    foot: true
  }
}, {
  path: "/business_card/card_collect/:card_id",
  component: CardCollect,
  name: "CardCollect",
  meta: {
    title: "名片收藏",
    foot: true
  }
}, {
  path: "/business_card/card_footprint/:card_id",
  component: CardFootprint,
  name: "CardFootprint",
  meta: {
    title: "名片足迹",
    foot: true
  }
}, {
  path: "/business_card/card_goods/:id",
  component: CardGoods,
  name: "CardGoods",
  meta: {
    title: "名片选择商品",
    foot: true
  }
}, {
  path: "/business_card/card_ranking",
  component: CardRanking,
  name: "CardRanking",
  meta: {
    title: "名片排行",
    foot: true
  }
}, {
  path: "/business_card/card_visit/:card_id",
  component: CardVisit,
  name: "CardVisit",
  meta: {
    title: "名片图片",
    foot: true
  }
}, {
  path: "/business_card/edit_card/:card_id",
  component: EditCard,
  name: "EditCard",
  meta: {
    title: "编辑名片",
    foot: true
  }
},
// 渠道商
{
  path: "/distributor_index",
  component: distributorIndex,
  name: "distributorIndex",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/accumulated_income",
  component: accumulatedIncome,
  name: "accumulatedIncome",
  meta: {
    title: "累计收入",
    foot: true
  }
}, {
  path: "/recommendUp",
  component: recommendUp,
  name: "recommendUp",
  meta: {
    title: "推荐升级",
    foot: true
  }
}, {
  path: "/submitRecommend",
  component: submitRecommend,
  name: "submitRecommend",
  meta: {
    title: "提交推荐",
    foot: true
  }
}, {
  path: "/my_repertory",
  component: myRepertory,
  name: "myRepertory",
  meta: {
    title: "我的库存",
    foot: true
  }
}, {
  path: "/order_goods",
  component: orderGoods,
  name: "orderGoods",
  meta: {
    title: "订货",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/deliver_goods",
  component: deliverGoods,
  name: "deliverGoods",
  meta: {
    title: "发货",
    foot: true
  }
}, {
  path: "/deliverGoodsNext",
  component: deliverGoodsNext,
  name: "deliverGoodsNext",
  meta: {
    title: "发货",
    foot: true
  }
}, {
  path: "/place_order",
  component: placeOrder,
  name: "placeOrder",
  meta: {
    title: "下单",
    foot: true
  }
}, {
  path: "/exchange_goods",
  component: exchangeGoods,
  name: "exchangeGoods",
  meta: {
    title: "换货",
    foot: true
  }
}, {
  path: "/exchange_detail/:id",
  component: exchangeDetail,
  name: "exchangeDetail",
  meta: {
    title: "换货",
    foot: true
  }
}, {
  path: "/exchange_ticket",
  component: exchangeTicket,
  name: "exchangeTicket",
  meta: {
    title: "兑换券",
    foot: true
  }
}, {
  path: "/my_referrals",
  component: myReferrals,
  name: "myReferrals",
  meta: {
    title: "我的下线客户",
    foot: true
  }
}, {
  path: "/my_exchange",
  component: myExchange,
  name: "myExchange",
  meta: {
    title: "我的换货",
    foot: true
  }
}, {
  path: "/client_order/:status",
  component: clientOrder,
  name: "clientOrder",
  meta: {
    title: "客户订单",
    foot: true
  }
}, {
  path: "/client_order/:status/:orderType",
  component: clientOrder,
  name: "myDeliver",
  meta: {
    title: "我的发货",
    foot: true
  }
}, {
  path: "/client_order/:status/:orderType",
  component: clientOrder,
  name: "dismyReplenishment",
  meta: {
    title: "我的补货",
    foot: true
  }
}, {
  path: "/dis_order_detail/:order_id/:orderType",
  component: DisOrderDetail,
  name: "DisOrderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/booking_change/:order_id",
  component: bookingChange,
  name: "bookingChange",
  meta: {
    title: "补货",
    foot: true
  }
}, {
  path: "/exchangeCart/:type/:order_id?",
  component: exchangeCart,
  name: "exchangeCart",
  meta: {
    title: "购物车",
    foot: true
  }
}, {
  path: "/distributor_stock/:goods_id/:option_id",
  component: distributor_stock,
  name: "distributor_stock",
  meta: {
    title: "库存明细",
    foot: true
  }
}, {
  path: "/distributor/rank",
  component: distributorRank,
  name: "distributorRank",
  meta: {
    title: "排行榜",
    foot: true
  }
}, {
  path: "/distributor/estimatedIncome",
  component: estimatedIncome,
  name: "estimatedIncome",
  meta: {
    title: "预估收益",
    foot: true
  }
}, {
  path: "/distributor/estimatedIncomeDetail/:id",
  component: estimatedIncomeDetail,
  name: "estimatedIncomeDetail",
  meta: {
    title: "预估收益详情",
    foot: true
  }
},
// {path: '/dis_order_datail', component: dis_order_datail, name: 'dis_order_datail', meta: {title: '客户订货详情', foot: true}},
//直播
{
  path: "/helperLiveList",
  component: helperLiveList,
  name: "helperLiveList",
  meta: {
    title: "主播助手",
    foot: true
  }
}, {
  path: "/helperLiveRoom/:id",
  component: helperLiveRoom,
  name: "helperLiveRoom",
  meta: {
    title: "直播间",
    foot: true
  }
}, {
  path: "/anchorApply",
  component: anchorApply,
  name: "anchorApply",
  meta: {
    title: "主播申请",
    foot: true
  }
}, {
  path: "/anchorDetail/:id",
  component: anchorDetail,
  name: "anchorDetail",
  meta: {
    title: "主播页面",
    foot: true
  }
}, {
  path: "/liveList",
  component: liveList,
  name: "liveList",
  meta: {
    title: "直播列表",
    foot: true
  }
}, {
  path: "/liveCategory/:cate_id",
  component: liveList,
  name: "liveCategory",
  meta: {
    title: "分类列表",
    foot: true
  }
}, {
  path: "/livePage",
  component: livePage,
  name: "livePage",
  meta: {
    title: "直播间",
    foot: true,
    is_single_share: true
  }
}, {
  path: "/foreshow/:id",
  component: foreshow,
  name: "foreshow",
  meta: {
    title: "直播预告",
    foot: true
  }
}, {
  path: "/liveDividend",
  component: liveDividend,
  name: "liveDividend",
  meta: {
    title: "主播分红",
    foot: true
  }
}, {
  path: "/liveFanList",
  component: liveFanList,
  name: "liveFanList",
  meta: {
    title: "粉丝列表",
    foot: true
  }
},
//精英奖
{
  path: "/eliteAwardHome",
  component: eliteAwardHome,
  name: "eliteAwardHome",
  meta: {
    title: "精英奖",
    foot: true
  }
}, {
  path: "/eliteAwardRecord",
  component: eliteAwardRecord,
  name: "eliteAwardRecord",
  meta: {
    title: "精英奖记录",
    foot: true
  }
}, {
  path: "/eliteAwardRank",
  component: eliteAwardRank,
  name: "eliteAwardRank",
  meta: {
    title: "排行榜记录",
    foot: true
  }
}, {
  path: "/business_card/Test",
  component: Test,
  name: "Test",
  meta: {
    title: "Test",
    foot: true
  }
}, {
  path: "/version",
  component: version,
  name: "version",
  meta: {
    title: "version",
    foot: true
  }
},
//群活码
{
  path: "/member/groupCode",
  component: groupCodeIndex,
  name: "groupCode",
  meta: {
    title: "群活码",
    foot: false
  }
}, {
  path: "/member/groupCodeCreate",
  component: groupCodeCreate,
  name: "groupCodeCreate",
  meta: {
    title: "活码创建",
    foot: true
  }
}, {
  path: "/member/groupCodeData",
  component: groupCodeData,
  name: "groupCodeData",
  meta: {
    title: "群活码数据",
    foot: false
  }
}, {
  path: "/member/groupCodeEdit",
  component: groupCodeEdit,
  name: "groupCodeEdit",
  meta: {
    title: "群活码编辑",
    foot: true
  }
}, {
  path: "/member/groupCodeRanking",
  component: groupCodeRanking,
  name: "groupCodeRanking",
  meta: {
    title: "群活码排行榜",
    foot: false
  }
}, {
  path: "/member/groupCodeRecord",
  component: groupCodeRecord,
  name: "groupCodeRecord",
  meta: {
    title: "群活码记录",
    foot: false
  }
}, {
  path: "/member/groupCodeShow",
  component: groupCodeShow,
  name: "groupCodeShow",
  meta: {
    title: "群活码展示",
    foot: false
  }
}, {
  path: "/member/groupCodeLabel",
  component: groupCodeLabel,
  name: "groupCodeLabel",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/member/groupCodeSearch",
  component: groupCodeSearch,
  name: "groupCodeSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/member/groupCodeGoodsList/:id",
  component: groupCodeGoodsList,
  name: "groupCodeGoodsList",
  meta: {
    title: "群活码商品列表",
    foot: true
  }
},
//测肤
{
  path: "/skin/Home",
  component: skinHome,
  name: "skinHome",
  meta: {
    title: "测肤",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/skin/Result/:id",
  component: skinResult,
  name: "skinResult",
  meta: {
    title: "测肤结果",
    foot: false
  }
}, {
  path: "/skin/Share/:id",
  component: skinShare,
  name: "skinShare",
  meta: {
    title: "测肤结果",
    foot: false
  }
}, {
  path: "/skin/Log",
  component: skinLog,
  name: "skinLog",
  meta: {
    title: "测肤记录",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/skin/Rank",
  component: skinRank,
  name: "skinRank",
  meta: {
    title: "测肤结果",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/skin/AllResult/:id",
  component: AllResult,
  name: "AllResult",
  meta: {
    title: "测肤结果",
    foot: false
  }
},
//社区团购
{
  path: "/community_buying/index",
  component: communityBuyIndex,
  name: "communityBuyIndex",
  meta: {
    title: $i18n.t("自提点"),
    foot: true
  }
}, {
  path: "/community_buying/edit_info",
  component: communityBuyEdit_info,
  name: "communityBuyEdit_info",
  meta: {
    title: $i18n.t("自提点"),
    foot: true
  }
}, {
  path: "/community_buying/group_buying",
  component: communityBuyGroup,
  name: "communityBuyGroup",
  meta: {
    title: $i18n.t("社区团购"),
    foot: true
  }
}, {
  path: "/community_buying/mygroup",
  component: mygroup,
  name: "mygroup",
  meta: {
    title: $i18n.t("我的团"),
    foot: true
  }
}, {
  path: "/community_buying/buying_details/:id",
  component: communityBuyDetails,
  name: "communityBuyDetails",
  meta: {
    title: "团购商品",
    foot: true
  }
}, {
  path: "/community_buying/buying_data/:id",
  component: communityBuyData,
  name: "communityBuyData",
  meta: {
    title: "团购商品",
    foot: true
  }
}, {
  path: "/community_buying/buying_order",
  component: communityBuyOrder,
  name: "communityBuyOrder",
  meta: {
    title: $i18n.t("社区团购"),
    foot: true
  }
}, {
  path: "/community_buying/buying_personal/:id/:deliver_id",
  component: communityBuyPersonal,
  name: "communityBuyPersonal",
  meta: {
    title: $i18n.t("社区团购"),
    foot: true
  }
},
//站内通知
{
  path: "/member/stationNotice",
  component: stationNoticeIndex,
  name: "stationNotice",
  meta: {
    title: "信息",
    foot: false
  }
}, {
  path: "/member/stationNoticeSetting",
  component: stationNoticeSetting,
  name: "stationNoticeSetting",
  meta: {
    title: "信息",
    foot: false
  }
}, {
  path: "/member/stationNoticeExtract",
  component: stationNoticeExtract,
  name: "stationNoticeExtract",
  meta: {
    title: "提现",
    foot: false
  }
}, {
  path: "/member/stationNoticeExamine",
  component: stationNoticeExamine,
  name: "stationNoticeExamine",
  meta: {
    title: "申请",
    foot: false
  }
}, {
  path: "/member/stationNoticeLogistics",
  component: stationNoticeLogistics,
  name: "stationNoticeLogistics",
  meta: {
    title: "交易物流",
    foot: false
  }
}, {
  path: "/member/stationNoticekefu",
  component: stationNoticekefu,
  name: "stationNoticekefu",
  meta: {
    title: "客服通知",
    foot: false
  }
}, {
  path: "/member/stationNoticeStore",
  component: stationNoticeStore,
  name: "stationNoticeStore",
  meta: {
    title: "审核通知",
    foot: false
  }
}, {
  path: "/member/stationNoticeAnchor",
  component: stationNoticeAnchor,
  name: "stationNoticeAnchor",
  meta: {
    title: "服务通知",
    foot: false
  }
}, {
  path: "/member/stationNoticeCustomer",
  component: stationNoticeCustomer,
  name: "stationNoticeCustomer",
  meta: {
    title: "客户通知",
    foot: false
  }
}, {
  path: "/member/stationNoticeProperty",
  component: stationNoticeProperty,
  name: "stationNoticeProperty",
  meta: {
    title: "资产通知",
    foot: false
  }
}, {
  path: "/member/stationNoticeOrder",
  component: stationNoticeOrder,
  name: "stationNoticeOrder",
  meta: {
    title: "订单提醒",
    foot: false
  }
}, {
  path: "/member/stationNoticeActivity",
  component: stationNoticeActivity,
  name: "stationNoticeActivity",
  meta: {
    title: "活动优惠",
    foot: false
  }
}, {
  path: "/member/stationNoticeActivityDetail",
  component: stationNoticeActivityDetail,
  name: "stationNoticeActivityDetail",
  meta: {
    title: "活动优惠",
    foot: false
  }
},
//安装服务
{
  path: "/member/workerApply",
  component: workerApply,
  name: "workerApply",
  meta: {
    title: "申请",
    foot: true
  }
}, {
  path: "/member/workerOrderlist",
  component: workerOrderlist,
  name: "workerOrderlist",
  meta: {
    title: "安装服务",
    foot: true
  }
}, {
  path: "/member/workerOrderDetail/:id",
  component: workerOrderDetail,
  name: "workerOrderDetail",
  meta: {
    title: "安装服务",
    foot: true
  }
}, {
  path: "/member/userOrderlist",
  component: userOrderlist,
  name: "userOrderlist",
  meta: {
    title: "安装服务",
    foot: true
  }
}, {
  path: "/member/userOrderDetail/:id",
  component: userOrderDetail,
  name: "userOrderDetail",
  meta: {
    title: "安装服务",
    foot: true
  }
}, {
  path: "/member/installIncome",
  component: installIncome,
  name: "installIncome",
  meta: {
    title: "安装服务",
    foot: true
  }
},
//推荐人
{
  path: "/extension/referral_officer",
  component: referral_officer,
  name: "referral_officer",
  meta: {
    title: "推荐官",
    foot: true
  }
}, {
  path: "/extension/referral_officerDetail/:id",
  component: referral_officerDetail,
  name: "referral_officerDetail",
  meta: {
    title: "查看详情",
    foot: true
  }
},
//抢团
{
  path: "/grabGroupHome",
  component: grab_group_home,
  name: "grab_group_home",
  meta: {
    title: "抢团",
    foot: false
  }
}, {
  path: "/grabGroupMy",
  component: grab_group_my,
  name: "grab_group_my",
  meta: {
    title: "我的抢团",
    foot: true
  }
}, {
  path: "/grabGroup_myRecord",
  component: grab_group_my_record,
  name: "grab_group_my_record",
  meta: {
    title: "我的抢团",
    foot: true
  }
}, {
  path: "/grabGroupReward",
  component: grab_group_reward,
  name: "grab_group_reward",
  meta: {
    title: "我的抢团",
    foot: true
  }
},
// /grabGroup_detail/:id (id为团长id)
{
  path: "/grabGroup_detail/:id",
  component: grabGroup_detail,
  name: "grabGroup_detail",
  meta: {
    title: "抢团详情",
    foot: true
  }
},
//爱心值排行榜
{
  path: "/member/loveRanking",
  component: loveRanking,
  name: "loveRanking",
  meta: {
    title: "爱心值排行榜",
    foot: true
  }
},
//拼单
// {
//   path: "/member/collage/collageReportform",
//   component: collageReportform,
//   name: "collageReportform",
//   meta: { title: "收入报表", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/collageIndex",
//   component: collageIndex,
//   name: "collageIndex",
//   meta: { title: "拼单", foot: true }
// },
// {
//   path: "/member/collage/collageRegionBonus",
//   component: collageRegionBonus,
//   name: "collageRegionBonus",
//   meta: { title: "拼单", foot: true }
// },

// //拼单——非会员
// {
//   path: "/member/collage/tacitApply",
//   component: tacitApply,
//   name: "tacitApply",
//   meta: { title: "会员申请", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/tacitAddproject",
//   component: tacitAddproject,
//   name: "tacitAddproject",
//   meta: { title: "添加项目", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/tacitDetproject",
//   component: tacitDetproject,
//   name: "tacitDetproject",
//   meta: { title: "项目详情", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/tacitEditproject",
//   component: tacitEditproject,
//   name: "tacitEditproject",
//   meta: { title: "修改项目", foot: true, notKeepAlive: true }
// },

// //拼单——等级创始人
// {
//   path: "/member/collage/founderCollage",
//   component: founderCollage,
//   name: "founderCollage",
//   meta: { title: "拼单", foot: true }
// },
// {
//   path: "/member/collage/founderEchievement",
//   component: founderEchievement,
//   name: "founderEchievement",
//   meta: { title: "业绩榜单", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/founderTeam",
//   component: founderTeam,
//   name: "founderTeam",
//   meta: { title: "我的团队", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/founderSinglepool",
//   component: founderSinglepool,
//   name: "founderSinglepool",
//   meta: { title: "拼单池", foot: true, notKeepAlive: true }
// },
// {
//   path: "/member/collage/founderBicycle",
//   component: founderBicycle,
//   name: "founderBicycle",
//   meta: { title: "拼单车", foot: true, notKeepAlive: true }
// },

//区域代理
{
  path: "/member/regionalAgent",
  component: regionalAgent,
  name: "regionalAgent",
  meta: {
    title: "区域代理",
    foot: true
  }
}, {
  path: "/member/regionalAgentResult",
  component: regionalAgentResult,
  name: "regionalAgentResult",
  meta: {
    title: "查询结果",
    foot: true
  }
}, {
  path: "/member/regionalAgentSearch",
  component: regionalAgentSearch,
  name: "regionalAgentSearch",
  meta: {
    title: "搜索区域",
    foot: true
  }
}, {
  path: "/community_solitaire/:id",
  component: community_solitaire,
  name: "community_solitaire",
  meta: {
    title: "社群接龙",
    foot: true
  }
}, {
  path: "/newDrawIndex/:id",
  component: newDrawIndex,
  name: "newDrawIndex",
  meta: {
    title: "抽奖活动",
    foot: true
  }
}, {
  path: "/lottery_complaint/:id",
  component: lottery_complaint,
  name: "lotteryComplaint",
  meta: {
    title: "抽奖投诉",
    foot: true
  }
}, {
  path: "/winners_list/:id/:num",
  component: winners_list,
  name: "winnersList",
  meta: {
    title: "中奖名单",
    foot: true
  }
}, {
  path: "/mydraw",
  component: mydraw,
  name: "mydraw",
  meta: {
    title: "我的抽奖",
    foot: false
  }
}, {
  path: "/lucky_draw/:tag?/:index?",
  component: lucky_draw,
  name: "luckyDraw",
  meta: {
    title: "抽奖活动",
    foot: false
  }
}, {
  path: "/newDrawMyPrize",
  component: newDrawMyPrize,
  name: "newDrawMyPrize",
  meta: {
    title: "我的奖品",
    foot: true
  }
}, {
  path: "/newDrawRecommentReward",
  component: newDrawRecommentReward,
  name: "newDrawRecommentReward",
  meta: {
    title: "中奖推荐奖励",
    foot: true
  }
}, {
  path: "/newDrawPayment",
  component: newDrawPayment,
  name: "newDrawPayment",
  meta: {
    title: "支付",
    foot: true
  }
},
//业绩区域奖励
{
  path: "/member/regionalAwards",
  component: regionalAwards,
  name: "regionalAwards",
  meta: {
    title: "业绩区域奖励",
    foot: true
  }
}, {
  path: "/member/regionalAwardsRecord",
  component: regionalAwardsRecord,
  name: "regionalAwardsRecord",
  meta: {
    title: "奖励记录",
    foot: true
  }
},
//新团队分红
{
  path: "/newTeamBonus",
  component: newTeamBonus,
  name: "newTeamBonus",
  meta: {
    title: "新团队分红",
    foot: true
  }
},
//看视频答题
{
  path: "/videoAnswer",
  component: videoAnswer,
  name: "videoAnswer",
  meta: {
    title: "答题",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/sharedPartner",
  component: sharedPartner,
  name: "sharedPartner",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/sharedPartner_bonus",
  component: sharedPartner_bonus,
  name: "sharedPartner_bonus",
  meta: {
    title: "分红份数明细",
    foot: true
  }
},
//自定义页面
{
  path: "/diyIncomePage/:url",
  component: diyIncomePage,
  name: "diyIncomePage",
  meta: {
    title: "",
    foot: false
  }
},
//蓝积分
{
  path: "/member/integralBlueCattle",
  component: integralBlueCattle,
  name: "integralBlueCattle",
  meta: {
    title: "蓝积分",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/lanLogin",
  component: lanLogin,
  name: "lanLogin",
  meta: {
    title: "跳转中",
    foot: true,
    notKeepAlive: true
  }
},
//我的市场
{
  path: "/member/myMarket",
  component: myMarket,
  name: "myMarket",
  meta: {
    title: "我的市场",
    foot: true,
    notKeepAlive: true
  }
},
//盲盒
{
  path: "/member/blindBoxIndex",
  component: blindBoxIndex,
  name: "blindBoxIndex",
  meta: {
    title: "首页",
    foot: true
  }
}, {
  path: "/member/blindBoxDetail",
  component: blindBoxDetail,
  name: "blindBoxDetail",
  meta: {
    title: "抽奖",
    notKeepAlive: true
  }
}, {
  path: "/member/blindBoxHelping",
  component: blindBoxHelping,
  name: "blindBoxHelping",
  meta: {
    title: "助力",
    foot: true
  }
},
//商品分红
{
  path: "/commodityDividends",
  component: commodityDividends,
  name: "commodityDividends",
  meta: {
    title: "商品分红",
    foot: true,
    notKeepAlive: true
  }
},
//安装服务 插件名：live-install
{
  path: "/installationSeverApply",
  component: installationSeverApply,
  name: "installationSeverApply",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverExtension",
  component: installationSeverExtension,
  name: "installationSeverExtension",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverDividends/:active",
  component: installationSeverDividends,
  name: "installationSeverDividends",
  meta: {
    title: "分红记录",
    foot: true
  }
}, {
  path: "/installationSeverHome",
  component: installationSeverHome,
  name: "installationSeverHome",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverMemberHome",
  component: installationSeverHome,
  name: "installationSeverMemberHome",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverDetail/:id",
  component: installationSeverDetail,
  name: "installationSeverDetail",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverRefund/:id",
  component: installationSeverRefund,
  name: "installationSeverRefund",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverAftersale/:oid",
  component: installationSeverAftersale,
  name: "installationSeverAftersale",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/installationSeverAfterApply/:id",
  component: installationSeverAfterApply,
  name: "installationSeverAfterApply",
  meta: {
    title: "售后申请",
    foot: true
  }
}, {
  path: "/installationSeverWrite/:take_sn",
  component: installationSeverWrite,
  name: "installationSeverWrite",
  meta: {
    title: "商家核销",
    foot: true
  }
}, {
  path: "/newRetail/index",
  component: newRetailIndex,
  name: "newRetailIndex",
  meta: {
    title: "新零售",
    foot: true
  }
}, {
  path: "/newRetail/Search/:type",
  component: newRetailSearch,
  name: "newRetailSearch",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/newRetail/agencyPackage/:id",
  component: newRetail_agencyPackage,
  name: "newRetail_agencyPackage",
  meta: {
    title: "代理套餐",
    foot: true
  }
}, {
  path: "/newRetail/inventory",
  component: newRetailInventory,
  name: "newRetailInventory",
  meta: {
    title: "我的库存",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/newRetail/retailLink/:id",
  component: newRetailRetailLink,
  name: "newRetailRetailLink",
  meta: {
    title: "我的库存",
    foot: true
  }
}, {
  path: "/newRetail/salesRecord",
  component: newRetailSalesRecord,
  name: "newRetailSalesRecord",
  meta: {
    title: "销售记录",
    foot: true
  }
}, {
  path: "/newRetail/salesRecord/datail/:id/:mode",
  component: newRetailSalesRecordDetail,
  name: "newRetailSalesRecordDetail",
  meta: {
    title: "销售记录",
    foot: true
  }
}, {
  path: "/newRetail/extension",
  component: newRetailSalesExtension,
  name: "newRetailSalesExtension",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/newRetail/Inventory/Details/:gid/:optionId",
  component: newRetailInventoryDetails,
  name: "newRetailInventoryDetails",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/stockDividend/extension",
  component: newRetailSalesExtension,
  name: "stockDividendExtension",
  meta: {
    title: "",
    foot: true
  }
},
// 社区门店
{
  path: "/communityStore",
  component: communityStore,
  name: "communityStore",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/communityStoreDetails",
  component: communityStoreDetails,
  name: "communityStoreDetails",
  meta: {
    title: "",
    foot: false
  }
},
//消费奖励
{
  path: "/consumerRewardIndex",
  component: consumerRewardIndex,
  name: "consumerRewardIndex",
  meta: {
    title: "",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/consumerRewardApply",
  component: consumerRewardApply,
  name: "consumerRewardApply",
  meta: {
    title: "",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/consumerRewardRecord",
  component: consumerRewardRecord,
  name: "consumerRewardRecord",
  meta: {
    title: "领取记录",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/consumerRewardPaySuccess",
  component: consumerRewardPaySuccess,
  name: "consumerRewardPaySuccess",
  meta: {
    title: "支付成功",
    foot: false,
    notKeepAlive: true
  }
},
//上级代付
{
  path: "/superiorPaymentIndex/:order_id",
  component: superiorPaymentIndex,
  name: "superiorPaymentIndex",
  meta: {
    title: "上级代付",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/superiorPaymentPay",
  component: superiorPaymentPay,
  name: "superiorPaymentPay",
  meta: {
    title: "上级代付",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/superiorPaymentExtension",
  component: superiorPaymentExtension,
  name: "superiorPaymentExtension",
  meta: {
    title: "上级代付",
    foot: false
  }
}, {
  path: "/superiorPaymentDetails",
  component: superiorPaymentDetails,
  name: "superiorPaymentDetails",
  meta: {
    title: "上级代付",
    foot: false,
    notKeepAlive: true
  }
},
//广告投放
{
  path: "/advertising/Apply",
  component: advertisingApply,
  name: "advertisingApply",
  meta: {
    title: "广告主申请",
    foot: true
  }
}, {
  path: "/advertising/Advertisers",
  component: advertisingAdvertisers,
  name: "advertisingAdvertisers",
  meta: {
    title: "广告主",
    foot: true
  }
}, {
  path: "/advertising/AdDetails/:id",
  component: advertisingAdDetails,
  name: "advertisingAdDetails",
  meta: {
    title: "广告主",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/advertising/release",
  component: advertisingRelease,
  name: "advertisingRelease",
  meta: {
    title: "发布广告",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/advertising/list/:tag/:id?",
  component: advertisingList,
  name: "advertisingList",
  meta: {
    title: "广告列表",
    foot: true
  }
}, {
  path: "/advertising/put/:tag/:id",
  component: advertisingPutAD,
  name: "advertisingPutAD",
  meta: {
    title: "投放广告",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/advertising/play/:tag/:id/:aid/:page",
  component: advertisingPlayPage,
  name: "advertisingPlayPage",
  meta: {
    title: "广告播放",
    foot: true,
    notKeepAlive: true
  }
},
//群拓客
{
  path: "/member/gduIndex",
  component: gduIndex,
  name: "gduIndex",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/member/gduEnterpriseDetails",
  component: gduEnterpriseDetails,
  name: "gduEnterpriseDetails",
  meta: {
    title: "",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/member/gduEnterprisePoster/:id",
  component: gduEnterprisePoster,
  name: "gduEnterprisePoster",
  meta: {
    title: "",
    foot: true,
    notKeepAlive: true
  }
},
//订单导入
{
  path: "/member/orderImportIndex",
  component: orderImportIndex,
  name: "orderImportIndex",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/member/orderImportApply",
  component: orderImportApply,
  name: "orderImportApply",
  meta: {
    title: "",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/cpsPage",
  component: cpsPage,
  name: "cpsPage",
  meta: {
    title: "聚合页",
    foot: true
  }
}, {
  path: "/cpsSearchPage",
  component: cpsSearchPage,
  name: "cpsSearchPage",
  meta: {
    title: "商品列表",
    foot: true
  }
}, {
  path: "/cpsGoodDetail",
  component: cpsGoodDetail,
  name: "cpsGoodDetail",
  meta: {
    title: "商品详情",
    foot: true
  }
},
// 聚合cps中转页
{
  path: "/cpsTransfer",
  component: cpsTransfer,
  name: "cpsTransfer",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/personRed",
  component: personRed,
  name: "personRed",
  meta: {
    title: "红包雨",
    foot: true
  }
}, {
  path: "/independenceBalance/:store_id",
  component: independenceBalance,
  name: "independenceBalance",
  meta: {
    title: "余额",
    foot: false
  }
}, {
  path: "/storeBalanceDetail/:store_id",
  component: storeBalanceDetail,
  name: "storeBalanceDetail",
  meta: {
    title: "余额明细",
    foot: false
  }
}, {
  path: "/personRedList",
  component: personRedList,
  name: "personRedList",
  meta: {
    title: "个人记录",
    foot: true
  }
}, {
  path: "/personRedListDetail/:id",
  component: personRedListDetail,
  name: "personRedListDetail",
  meta: {
    title: "发放详情",
    foot: true
  }
}, {
  path: "/personRedPay",
  component: personRedPay,
  name: "personRedPay",
  meta: {
    title: "支付",
    foot: true
  }
}, {
  path: "/personRedissue",
  component: personRedissue,
  name: "personRedissue",
  meta: {
    title: "发红包",
    foot: true
  }
}, {
  path: "/personRedrecord/:id",
  component: personRedrecord,
  name: "personRedrecord",
  meta: {
    title: "领取记录",
    foot: true
  }
}, {
  path: "/commissionExtra/index",
  component: function component(r) {
    return require(["../views/extension/commission-extra/index"], r);
  },
  name: "commissionExtraIndex",
  meta: {
    title: "",
    foot: true,
    notKeepAlive: true
  }
},
//商品详情新优化
{
  path: "/goodsComment/:gid/:istype?/:comment_name?",
  component: goodsComment,
  name: "goodsComment",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/groupWork/index/:id?",
  component: function component(r) {
    return require(["../views/goods/group-work/index"], r);
  },
  name: "groupWorkIndex",
  meta: {
    title: "拼团活动",
    foot: true
  }
}, {
  path: "/groupWork/my",
  component: function component(r) {
    return require(["../views/goods/group-work/my_group-work"], r);
  },
  name: "groupWorkMy",
  meta: {
    title: "我的拼团",
    foot: true
  }
}, {
  path: "/groupWork/detail/:aid/:id",
  component: function component(r) {
    return require(["../views/goods/group-work/detail"], r);
  },
  name: "groupWorkDetail",
  meta: {
    title: $i18n.t("拼团详情"),
    foot: true
  }
}, {
  path: "/workWechatSidebar",
  component: workWechatSidebar,
  name: "workWechatSidebar",
  meta: {
    title: "会员详情",
    foot: false
  }
}, {
  path: "/workWechatSidebar/index",
  component: sidebarIndex,
  name: "sidebarIndex",
  meta: {
    title: "商城",
    foot: false
  }
}, {
  path: "/workWechatSidebar/sideOrderdetail/:order_id/:orderType?/:customer?/:memberId?",
  component: sidebarOrderDetail,
  name: "sideOrderdetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/offlineInvestment/index/:id",
  component: function component(r) {
    return require(["../views/others/offline_investment/index"], r);
  },
  name: "offlineInvestmentIndex",
  meta: {
    title: "线下招商会",
    foot: true
  }
}, {
  path: "/offlineInvestment/extension",
  component: function component(r) {
    return require(["../views/others/offline_investment/extension"], r);
  },
  name: "offlineInvestmentExtension",
  meta: {
    title: "线下招商会",
    foot: true
  }
}, {
  path: "/prizePool",
  component: prizePool,
  name: "prizePool",
  meta: {
    title: "奖金池",
    foot: true
  }
},
//捐赠项目
{
  path: "/donationProjectIndex",
  component: donationProjectIndex,
  name: "donationProjectIndex",
  meta: {
    title: "捐赠查询",
    foot: false
  }
}, {
  path: "/donationProjectList",
  component: donationProjectList,
  name: "donationProjectList",
  meta: {
    title: "公示页捐赠项目详情",
    foot: false
  }
}, {
  path: "/donationProjectSearch",
  component: donationProjectSearch,
  name: "donationProjectSearch",
  meta: {
    title: "捐赠信息查询",
    foot: true
  }
}, {
  path: "/donationProjectPicture",
  component: donationProjectPicture,
  name: "donationProjectPicture",
  meta: {
    title: "捐赠项目图片详情",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/donationProjectVideo",
  component: donationProjectVideo,
  name: "donationProjectVideo",
  meta: {
    title: "捐赠项目视频详情",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/donationProjectDetails",
  component: donationProjectDetails,
  name: "donationProjectDetails",
  meta: {
    title: "个人捐赠详情",
    foot: false
  }
}, {
  path: "/donationProjectInfo",
  component: donationProjectInfo,
  name: "donationProjectInfo",
  meta: {
    title: "个人捐赠详情",
    foot: false,
    notKeepAlive: true
  }
}, {
  path: "/teamOdd/dividend",
  component: function component(r) {
    return require(["../views/extension/other/team_odd_dividend"], r);
  },
  name: "teamOddDividend",
  meta: {
    title: "团队单数分红",
    foot: true
  }
}, {
  path: "/selfPickupPoint/select/:tag/:deliverId?",
  component: function component(r) {
    return require(["../views/member/self_carry/select_selfPickupPoint"], r);
  },
  name: "selectSelfPickupPoint",
  meta: {
    title: "选择自提点",
    foot: true
  }
}, {
  path: "/gratitudeRewardIndex",
  component: gratitudeRewardIndex,
  name: "gratitudeRewardIndex",
  meta: {
    title: "感恩奖",
    foot: true
  }
}, {
  path: "/storeManagement/Index",
  component: storeManagementIndex,
  name: "storeManagementIndex",
  meta: {
    title: "店铺助手",
    foot: true
  }
}, {
  path: "/storeManagement/storeGoodsManagement",
  component: storeGoodsManagement,
  name: "storeGoodsManagement",
  meta: {
    title: "商品管理",
    foot: true
  }
}, {
  path: "/storeManagement/releaseStoreGoods/:id?",
  component: releaseStoreGoods,
  name: "releaseStoreGoods",
  meta: {
    title: "商品发布",
    foot: true
  }
}, {
  path: "/storeManagement/storeGoodsCatgory",
  component: storeGoodsCatgory,
  name: "storeGoodsCatgory",
  meta: {
    title: "商品分类",
    foot: true
  }
}, {
  path: "/storeManagement/editGoodsCatgory/:id?",
  component: editGoodsCatgory,
  name: "editGoodsCatgory",
  meta: {
    title: "编辑分类",
    foot: true
  }
}, {
  path: "/storeManagement/addGoodsCatgory/:parentId?",
  component: editGoodsCatgory,
  name: "addGoodsCatgory",
  meta: {
    title: "添加分类",
    foot: true
  }
}, {
  path: "/storeManagement/memberManagement",
  component: memberManagement,
  name: "memberManagement",
  meta: {
    title: "会员管理",
    foot: true
  }
}, {
  path: "/storeManagement/storeMemberInfo/:id",
  component: storeMemberInfo,
  name: "storeMemberInfo",
  meta: {
    title: "会员详情",
    foot: true
  }
}, {
  path: "/storeManagement/storeIntegralList/:id?",
  component: storeIntegralList,
  name: "storeIntegralList",
  meta: {
    title: "积分明细",
    foot: true
  }
}, {
  path: "/storeManagement/storeBalanceList/:id?",
  component: storeBalanceList,
  name: "storeBalanceList",
  meta: {
    title: "余额明细",
    foot: true
  }
}, {
  path: "/storeManagement/storeCouponList/:id?",
  component: storeCouponList,
  name: "storeCouponList",
  meta: {
    title: "优惠券",
    foot: true
  }
}, {
  path: "/storeManagement/financeManagement",
  component: financeManagement,
  name: "financeManagement",
  meta: {
    title: "财务管理",
    foot: true
  }
}, {
  path: "/storeManagement/withdrawalList",
  component: withdrawalList,
  name: "withdrawalList",
  meta: {
    title: "提现记录",
    foot: true
  }
}, {
  path: "/storeManagement/withdrawalDetail/:id",
  component: withdrawalDetail,
  name: "withdrawalDetail",
  meta: {
    title: "提现详情",
    foot: true
  }
}, {
  path: "/order/fullPackage",
  component: function component(r) {
    return require(["../views/goods/full_package"], r);
  },
  name: "orderullPackage",
  meta: {
    title: "满额包邮",
    foot: true
  }
}, {
  path: "/gdt/reward",
  component: function component(r) {
    return require(["../views/extension/other/gdt_reward"], r);
  },
  name: "gdtReward",
  meta: {
    title: "广告返利",
    foot: true
  }
}, {
  path: "/DonateIndex",
  component: DonateIndex,
  name: "DonateIndex",
  meta: {
    title: "债权人绑定债务人",
    foot: true
  }
}, {
  path: "/warehouseFarm/index",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/index"], r);
  },
  name: "warehouseFarmIndex",
  meta: {
    title: "圈仓",
    foot: true
  }
}, {
  path: "/warehouseFarm/giftList/:id?",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/buy-gift"], r);
  },
  name: "warehouseFarmBuyGift",
  meta: {
    title: "买礼盒",
    foot: true
  }
}, {
  path: "/warehouseFarm/good/:id",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/goods"], r);
  },
  name: "warehouseFarmGood",
  meta: {
    title: "买礼盒",
    foot: true
  }
}, {
  path: "/warehouseFarm/gift",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/gift"], r);
  },
  name: "warehouseFarmGift",
  meta: {
    title: "送礼物",
    foot: true
  }
}, {
  path: "/warehouseFarm/create/:id",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/createGift"], r);
  },
  name: "warehouseFarmCreate",
  meta: {
    title: "送礼物",
    foot: true
  }
}, {
  path: "/warehouseFarm/giftLog",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/giftLog"], r);
  },
  name: "warehouseFarmGiftLog",
  meta: {
    title: "送礼物",
    foot: true
  }
}, {
  path: "/warehouseFarm/giftDetail/:mode?",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/giftDetail"], r);
  },
  name: "warehouseFarmGiftDetail",
  meta: {
    title: "礼物详情",
    foot: true
  }
}, {
  path: "/warehouseFarm/extract",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/extract-gift"], r);
  },
  name: "warehouseFarmExtract",
  meta: {
    title: "提礼物",
    foot: true
  }
}, {
  path: "/warehouseFarm/Inventory",
  component: function component(r) {
    return require(["../views/dev-plug/warehouse-farm/Inventory"], r);
  },
  name: "warehouseFarmInventory",
  meta: {
    title: "圈仓",
    foot: true
  }
}, {
  path: "/handwrittenEsign/esign/:id",
  component: function component(r) {
    return require(["../views/dev-plug/handwrittenEsign/esign"], r);
  },
  name: "handwrittenEsignIndex",
  meta: {
    title: "协议详情",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/handwrittenEsignCheck",
  component: function component(r) {
    return require(["../views/dev-plug/handwrittenEsign/check"], r);
  },
  name: "handwrittenEsignCheck",
  meta: {
    title: "手签协议",
    foot: true,
    notKeepAlive: false
  }
}, {
  path: "/handwrittenEsign/log",
  component: function component(r) {
    return require(["../views/dev-plug/handwrittenEsign/log"], r);
  },
  name: "handwrittenEsignLog",
  meta: {
    title: "手签协议",
    foot: true
  }
}, {
  path: "/diyRormProve/index",
  component: function component(r) {
    return require(["../views/dev-plug/diy-form-prove/index"], r);
  },
  name: "diyRormProveIndex",
  meta: {
    title: "表单核销",
    foot: true
  }
}, {
  path: "/diyRormProve/:mode/:id",
  component: function component(r) {
    return require(["../views/dev-plug/diy-form-prove/submit"], r);
  },
  name: "diyRormProveSubmit",
  meta: {
    title: "自定义表单",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/diyRormProve/log",
  component: function component(r) {
    return require(["../views/dev-plug/diy-form-prove/log"], r);
  },
  name: "diyRormProveLog",
  meta: {
    title: "核销记录",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/diyRormProve/detail/:id",
  component: function component(r) {
    return require(["../views/dev-plug/diy-form-prove/detail"], r);
  },
  name: "diyRormProveDetail",
  meta: {
    title: "表单信息",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/dealerTaskRewardIndex",
  component: dealerTaskRewardIndex,
  name: "dealerTaskRewardIndex",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/dealerTaskRewardRecord",
  component: dealerTaskRewardRecord,
  name: "dealerTaskRewardRecord",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/supplierDriver/:id/:code?",
  component: supplierDriver,
  name: "supplierDriver",
  meta: {
    title: "配送入口",
    foot: true
  }
}, {
  path: "/distributionEntrance",
  component: distributionEntrance,
  name: "distributionEntrance",
  meta: {
    title: "配送入口",
    foot: true
  }
}, {
  path: "/workWxFriendExtend",
  component: workWxFriendExtend,
  name: "workWxFriendExtend",
  meta: {
    title: "好友裂变",
    foot: true
  }
}, {
  path: "/workWxFriendExtend/friendRanking",
  component: friendRanking,
  name: "friendRanking",
  meta: {
    title: "好友裂变排行榜",
    foot: true
  }
}, {
  path: "/workWxFriendExtend/invitationRecord",
  component: invitationRecord,
  name: "invitationRecord",
  meta: {
    title: "邀请记录",
    foot: true
  }
}, {
  path: "/workWxFriendExtend/rewardRecord",
  component: friendRewardRecord,
  name: "friendRewardRecord",
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  path: "/workWx/sopPushTask",
  component: sopPushTask,
  name: "sopPushTask",
  meta: {
    title: "推送详情",
    foot: true
  }
}, {
  path: "/workWx/sopTaskList",
  component: sopTaskList,
  name: "sopTaskList",
  meta: {
    title: "任务列表",
    foot: true
  }
}, {
  path: "/workWx/sopTaskDetail/:taskLog/:taskId?",
  component: sopTaskDetail,
  name: "sopTaskDetail",
  meta: {
    title: "任务详情",
    foot: true
  }
}, {
  path: "/brandResourcesIndex",
  component: brandResourcesIndex,
  name: "brandResourcesIndex",
  meta: {
    title: "品牌资源",
    foot: true
  }
}, {
  path: "/cardVoucherInfo/:order_id",
  component: cardVoucherInfo,
  name: "cardVoucherInfo",
  meta: {
    title: "卡密",
    foot: true
  }
}, {
  path: "/cloudPage",
  component: cloudPage,
  name: "cloudPage",
  meta: {
    title: "跳转中",
    foot: true
  }
}, {
  path: "/order/pay_back/:ids/:sn?/:is_cps?/:pageUrl?",
  component: payBack,
  name: "payBack",
  meta: {
    title: "支付成功",
    foot: true
  }
}, {
  path: "/workWx/speechLibrary",
  component: SpeechLibrary,
  name: "speechLibrary",
  meta: {
    title: "话术库",
    foot: true
  }
}, {
  path: "/timeAppointment_index",
  component: timeAppointment_index,
  name: "timeAppointment_index",
  meta: {
    title: "分时预约",
    foot: false
  }
}, {
  path: "/timeAppointment_search",
  component: timeAppointment_search,
  name: "timeAppointment_search",
  meta: {
    title: "分时预约",
    foot: true
  }
}, {
  path: "/timeAppointmentMy",
  component: timeAppointmentMy,
  name: "timeAppointmentMy",
  meta: {
    title: "分时预约",
    foot: true
  }
}, {
  path: "/timeAppointmentMylist/:status",
  component: timeAppointmentMylist,
  name: "timeAppointmentMylist",
  meta: {
    title: "分时预约",
    foot: true
  }
}, {
  path: "/AppointmentUserDe/:id",
  component: AppointmentUserDe,
  name: "AppointmentUserDe",
  meta: {
    title: "预约人员详情",
    foot: true
  }
}, {
  path: "/seePoint",
  component: seePoint,
  name: "seePoint",
  meta: {
    title: "见点奖励",
    foot: true
  }
}, {
  path: "/workWx/customerStatistic",
  component: customerStatistic,
  name: "customerStatistic",
  meta: {
    title: "数据统计",
    foot: true
  }
}, {
  path: "/workWx/memberRecordDetail/:id",
  component: memberRecordDetail,
  name: "memberRecordDetail",
  meta: {
    title: "访问记录",
    foot: true
  }
}, {
  path: "/workWx/wxWorkChatTransfer",
  component: wxWorkChatTransfer,
  name: "wxWorkChatTransfer",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/bonusPoolsIndex",
  component: bonusPoolsIndex,
  name: "bonusPoolsIndex",
  meta: {
    title: "奖金池奖励",
    foot: true
  }
}, {
  path: "/bonusPoolsTeam",
  component: bonusPoolsTeam,
  name: "bonusPoolsTeam",
  meta: {
    title: "团队业绩值",
    foot: true
  }
}, {
  path: "/debtapply/:type",
  component: debtapply,
  name: "debtapply",
  meta: {
    title: "债务人申请",
    foot: true
  }
}, {
  path: "/debtindex",
  component: debtindex,
  name: "debtindex",
  meta: {
    title: "置换亿栈",
    foot: true
  }
}, {
  path: "/debtorindex",
  component: debtorindex,
  name: "debtorindex",
  meta: {
    title: "债务人",
    foot: true
  }
}, {
  path: "/debtorShop/:kid/:debtUid?",
  component: debtorShop,
  name: "debtorShop",
  meta: {
    title: "债务人店铺",
    foot: true
  }
}, {
  path: "/userManagement",
  component: userManagement,
  name: "userManagement",
  meta: {
    title: "会员管理",
    foot: true
  }
}, {
  path: "/purchaseDetails/:coupon?/:debtId?",
  component: purchaseDetails,
  name: "purchaseDetails",
  meta: {
    title: "采购款明细",
    foot: true
  }
}, {
  path: "/purchaseDetailsVip/:vip",
  component: purchaseDetails,
  name: "purchaseDetailsVip",
  meta: {
    title: "采购款明细",
    foot: true
  }
}, {
  path: "/debtorAudit",
  component: debtorAudit,
  name: "debtorAudit",
  meta: {
    title: "售后审核",
    foot: true
  }
}, {
  path: "/creditorindex",
  component: creditorindex,
  name: "creditorindex",
  meta: {
    title: "债权人",
    foot: true
  }
}, {
  path: "/vipAdd/:debtId?",
  component: vipAdd,
  name: "vipAdd",
  meta: {
    title: "VIP",
    foot: true
  }
}, {
  path: "/vipAddindex",
  component: vipAddindex,
  name: "vipAddindex",
  meta: {
    title: "添加VIP",
    foot: true
  }
}, {
  path: "/vipIndex",
  component: vipIndex,
  name: "vipIndex",
  meta: {
    title: "VIP",
    foot: true
  }
}, {
  path: "/vipBindRecord",
  component: vipBindRecord,
  name: "vipBindRecord",
  meta: {
    title: "绑定记录",
    foot: true
  }
},
//置换亿栈搜索
{
  path: "/debtSearch/:debtSearch/:debtId?/:debtCId?",
  component: debtSearch,
  name: "debtSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/debtShopCode",
  component: debtShopCode,
  name: "debtShopCode",
  meta: {
    title: "店铺二维码",
    foot: true
  }
}, {
  path: "/storeSign/:id",
  component: storeSign,
  name: "storeSign",
  meta: {
    title: "门店打卡",
    foot: true
  }
}, {
  path: "/storeSignDetail",
  component: storeSignDetail,
  name: "storeSignDetail",
  meta: {
    title: "打卡明细",
    foot: true
  }
}, {
  path: "/publicWelfareFund/index",
  component: publicWelfare,
  name: "publicWelfare",
  meta: {
    title: "爱心公益",
    foot: true
  }
}, {
  path: "/publicWelfareFund/donations",
  component: welfareDonations,
  name: "welfareDonations",
  meta: {
    title: "公益支出",
    foot: true
  }
}, {
  path: "/publicWelfareFund/record/:type",
  component: welfareRecord,
  name: "welfareRecord",
  meta: {
    title: "记录",
    foot: true
  }
}, {
  path: "/recommendedCashback",
  component: function component() {
    return import("../views/community_buying/recommend/cash_back.vue");
  },
  name: "recommendedCashback",
  meta: {
    title: "推荐返现",
    foot: true
  }
}, {
  path: "/tagCloud/index",
  component: function component() {
    return import("../views/dev-plug/tagCloud/index.vue");
  },
  name: "tagCloudIndex",
  meta: {
    title: "淘逛共享商城",
    foot: false
  }
}, {
  path: "/tagCloud/search",
  component: function component() {
    return import("../views/dev-plug/tagCloud/search.vue");
  },
  name: "tagCloudSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/gratitudeAward",
  component: function component() {
    return import("../views/extension/gratitude-award.vue");
  },
  name: "gratitudeAward",
  meta: {
    title: "感恩奖",
    foot: true
  }
}, {
  path: "/newDistributor/index",
  component: newDistributor,
  name: "newDistributor",
  meta: {
    title: "关联收益",
    foot: true
  }
}, {
  path: "/scanpicture-goods",
  component: function component() {
    return import("../views/goods/scanpicture-goods/scanpicture-goods.vue");
  },
  name: "scanpictureGoods",
  meta: {
    title: "搜索列表",
    foot: true
  }
}, {
  path: "/ywmGroup/myGroups",
  component: function component() {
    return import("../views/dev-plug/ywm-groups/myGroups.vue");
  },
  name: "ywmGroupsMyGroups",
  meta: {
    title: "我的拼团",
    foot: true
  }
}, {
  path: "/ywmGroup/Detailed",
  component: function component() {
    return import("../views/dev-plug/ywm-groups/Detailed.vue");
  },
  name: "ywmGroupsDetailed",
  meta: {
    title: "开团卡明细",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/ywmGroup/list/:id?",
  component: function component() {
    return import("../views/dev-plug/ywm-groups/group_list.vue");
  },
  name: "ywmGroupList",
  meta: {
    title: "拼团列表",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/unionPay/:payid",
  component: unionPay,
  name: "unionPay",
  meta: {
    title: "银联支付",
    foot: true
  }
}, {
  path: "/pointAward/:key?",
  component: pointAward,
  name: "pointAward",
  meta: {
    title: "见点奖励",
    foot: true
  }
}, {
  path: "/subscribe",
  component: function component() {
    return import("../views/subscribe/index.vue");
  },
  name: "subscribe",
  meta: {
    title: "认购活动",
    foot: false
  }
}, {
  path: "/subscribeReward",
  component: function component() {
    return import("../views/subscribe/reward.vue");
  },
  name: "subscribeReward",
  meta: {
    title: "经销商认购奖励",
    foot: true
  }
}, {
  path: "/poor_reward",
  component: function component() {
    return import("../views/subscribe/poor_reward.vue");
  },
  name: "poor_reward",
  meta: {
    title: "激活奖励",
    foot: true
  }
}, {
  path: "/subscribeDesc",
  component: function component() {
    return import("../views/subscribe/description.vue");
  },
  name: "subscribeDesc",
  meta: {
    title: "规则说明",
    foot: true
  }
}, {
  path: "/subscribeRewardRecord",
  component: function component() {
    return import("../views/subscribe/rewardRecord.vue");
  },
  name: "subscribeRewardRecord",
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  path: "/subscribeRecord",
  component: function component() {
    return import("../views/subscribe/record.vue");
  },
  name: "subscribeRecord",
  meta: {
    title: "认购记录",
    foot: true
  }
}, {
  path: "/subscribeRecordDetail/:id",
  component: function component() {
    return import("../views/subscribe/detail.vue");
  },
  name: "subscribeRecordDetail",
  meta: {
    title: "认购详情",
    foot: true
  }
}, {
  path: "/loveTransform/:value",
  component: function component() {
    return import("../views/member/love_transform/index.vue");
  },
  name: "loveTransform",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/dataStatistics",
  component: function component() {
    return import("../views/member/data_statistics/index.vue");
  },
  name: "dataStatistics",
  meta: {
    title: "数据统计",
    foot: true
  }
}, {
  path: "/audio_book",
  name: "audio_book",
  component: function component() {
    return import("../views/audio_book/list.vue");
  },
  meta: {
    title: "有声书",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/audio_book/info",
  name: "audio_book_info",
  component: function component() {
    return import("../views/audio_book/info.vue");
  },
  meta: {
    title: "有声书",
    foot: true
  }
}, {
  path: "/crowd_activity",
  name: "crowd_activity",
  component: function component() {
    return import("../views/crowd_activity/index.vue");
  },
  meta: {
    title: "众筹活动",
    foot: true
  }
}, {
  path: "/crowd_activity_good/:id/:uid?",
  name: "crowd_activity_good",
  component: function component() {
    return import("../views/crowd_activity/goods.vue");
  },
  meta: {
    title: "众筹活动",
    foot: true
  }
}, {
  path: "/crowd_activity_funding/:id?",
  name: "crowd_activity_funding",
  component: function component() {
    return import("../views/crowd_activity/funding.vue");
  },
  meta: {
    title: "众筹活动",
    foot: true
  }
}, {
  path: "/crowd_activity_Order/:id/:uid?",
  name: "crowd_activity_Order",
  component: function component() {
    return import("../views/crowd_activity/order.vue");
  },
  meta: {
    title: "众筹活动",
    foot: true
  }
}, {
  path: "/crowd_activity_OrderPay/:sn",
  name: "crowd_activity_OrderPay",
  component: function component() {
    return import("../views/crowd_activity/pay.vue");
  },
  meta: {
    title: "订单支付",
    foot: true
  }
}, {
  path: "/personalCredit",
  component: personalCredit,
  name: "personalCredit",
  meta: {
    title: "个人额度",
    foot: true
  }
},
//面对面服务插件页面
{
  path: "/faceServe",
  component: function component() {
    return import("../views/faceToFace/faceServe.vue");
  },
  name: "faceServe",
  meta: {
    title: "面对面服务",
    foot: true
  }
}, {
  path: "/othersPage/:id",
  component: function component() {
    return import("../views/others/othersPage.vue");
  },
  name: "othersPage",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/commission",
  component: function component() {
    return import("../views/extension/commission/index.vue");
  },
  name: "commissionIntegral",
  meta: {
    foot: true
  }
},
// customerCenter 客户中心
{
  path: "/customerCenterIndex",
  component: function component() {
    return import("../views/others/customerCenter/customerCenterIndex.vue");
  },
  name: "customerCenterIndex",
  meta: {
    title: "客户中心",
    foot: true
  }
}, {
  path: "/customerCenterTrack/:is_type",
  component: function component() {
    return import("../views/others/customerCenter/customerCenterTrack.vue");
  },
  name: "customerCenterTrack",
  meta: {
    title: "轨迹追踪",
    foot: true
  }
}, {
  path: "/customerCenterRecord/:id?",
  component: function component() {
    return import("../views/others/customerCenter/customerCenterRecord.vue");
  },
  name: "customerCenterRecord",
  meta: {
    title: "轨迹追踪",
    foot: true
  }
}, {
  path: "/customerCenterUser/:id",
  component: function component() {
    return import("../views/others/customerCenter/customerCenterUser.vue");
  },
  name: "customerCenterUser",
  meta: {
    title: "用户信息",
    foot: true
  }
}, {
  path: "/equityPoints",
  component: function component() {
    return import("../views/others/equityPoints.vue");
  },
  name: "equityPoints",
  meta: {
    foot: true,
    title: "权益积分"
  }
},
// 银典支付start
{
  path: "/addSilverBank",
  component: function component() {
    return import("../views/others/silverPointPay/addSilverBank.vue");
  },
  name: "addSilverBank",
  meta: {
    title: "添加银行卡",
    foot: true
  }
}, {
  path: "/SilverPay/:order_pay_id",
  component: function component() {
    return import("../views/others/silverPointPay/SilverPay.vue");
  },
  name: "SilverPay",
  meta: {
    title: "快捷支付-银典",
    foot: true
  }
}, {
  path: "/SilverBankList/:option?",
  component: function component() {
    return import("../views/others/silverPointPay/SilverBankList.vue");
  },
  name: "SilverBankList",
  meta: {
    title: "银行卡列表",
    foot: true
  }
}, {
  path: "/storeManageDiyform",
  component: storeManageDiyform,
  name: "storeManageDiyform",
  meta: {
    title: "自定义表单",
    foot: true
  }
}, {
  path: "/storeManageFormDetail/:form_id",
  component: storeManageFormDetail,
  name: "storeManageFormDetail",
  meta: {
    title: "查看数据",
    foot: true
  }
}, {
  path: "/formDetail/:id",
  component: formDetail,
  name: "formDetail",
  meta: {
    title: "查看详情",
    foot: true
  }
},
// 自提点海报中转页
{
  path: "/deliverPoster/:id",
  component: function component() {
    return import("../views/others/deliverPoster.vue");
  },
  name: "deliverPoster",
  meta: {
    title: "",
    foot: true
  }
},
//周边游
{
  path: "/tourAround/travelIndex",
  component: function component() {
    return import("../views/tourAround/travelIndex/travelIndex.vue");
  },
  name: "travelIndex",
  meta: {
    title: "首页",
    foot: false
  }
}, {
  path: "/tourAround/rankingList",
  component: function component() {
    return import("../views/tourAround/rankingList/rankingList.vue");
  },
  name: "rankingList",
  meta: {
    title: "排行榜",
    foot: true
  }
}, {
  path: "/tourAround/categories",
  component: function component() {
    return import("../views/tourAround/categories/categories.vue");
  },
  name: "tourAroundCategories",
  meta: {
    title: "全部分类",
    foot: true
  }
}, {
  path: "/tourAround/goodsDetails",
  component: function component() {
    return import("../views/tourAround/goodsDetails/goodsDetails.vue");
  },
  name: "tourAroundGoodsDetails",
  meta: {
    title: "商品详情",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/tourAround/storeList",
  component: function component() {
    return import("../views/tourAround/storeList/storeList.vue");
  },
  name: "tourAroundStoreList",
  meta: {
    title: "适用门店",
    foot: true
  }
}, {
  path: "/tourAround/tourSearch",
  component: function component() {
    return import("../views/tourAround/tourSearch/tourSearch.vue");
  },
  name: "tourAroundTourSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/tourAround/tourCityList",
  component: function component() {
    return import("../views/tourAround/tourCityList/tourCityList.vue");
  },
  name: "tourCityList",
  meta: {
    title: "定位",
    foot: true
  }
}, {
  path: "/tourAround/tourSearchPage",
  component: function component() {
    return import("../views/tourAround/tourSearchPage/tourSearchPage.vue");
  },
  name: "tourSearchPage",
  meta: {
    title: "搜索列表",
    foot: true
  }
},
// 智E+支付 start
{
  path: "/wisdomBind/:option/:to?",
  component: function component() {
    return import("../views/others/wisdom/wisdomBind.vue");
  },
  name: "wisdomBind",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/wisdomBindTask",
  component: function component() {
    return import("../views/others/wisdom/wisdomBindTask.vue");
  },
  name: "wisdomBindTask",
  meta: {
    title: "提现",
    foot: true
  }
}, {
  path: "/addWisdomBank/:to?",
  component: function component() {
    return import("../views/others/wisdom/addWisdomBank.vue");
  },
  name: "addWisdomBank",
  meta: {
    title: "添加银行卡",
    foot: true
  }
},
//智E+支付 end
{
  path: "/diyCategory",
  component: function component() {
    return import("../views/others/diyCategory/diyCategory.vue");
  },
  name: "diyCategory",
  meta: {
    title: "",
    foot: true
  }
},
//客户管理
{
  path: "/workWechatSidebar/customer",
  component: customerManagement,
  name: "customerManagement",
  meta: {
    title: "客户管理",
    foot: false
  }
}, {
  path: "/wxh5/:status/:wxurl",
  component: wxh5,
  name: "wxh5",
  meta: {
    title: "微信",
    foot: true
  }
}, {
  path: "/wxh5auth",
  component: wxh5,
  name: "wxh5",
  meta: {
    title: "微信",
    foot: true
  }
}, {
  path: "/lunarCalendar/almanac",
  component: function component() {
    return import("../views/dev-plug/lunarCalendar/almanac.vue");
  },
  name: "almanac",
  meta: {
    title: "黄历",
    foot: false
  }
}, {
  path: "/lunarCalendar/goodday",
  component: function component() {
    return import("../views/dev-plug/lunarCalendar/lunarGoodDay.vue");
  },
  name: "lunarGoodDay",
  meta: {
    title: "择日",
    foot: true
  }
}, {
  path: "/lunarCalendar/perpetual",
  component: function component() {
    return import("../views/dev-plug/lunarCalendar/perpetual.vue");
  },
  name: "lunarPerpetual",
  meta: {
    title: "万年历",
    foot: false
  }
}];