import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/new_integral",
  component: function component() {
    return import("../../../views/member/new_integral/index.vue");
  },
  name: "new_integral",
  meta: {
    foot: true
  }
}];