import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 共享直播 路由表
export default [{
  path: "/shareRoom/list",
  //直播列表
  component: function component() {
    return import("../../../views/dev-plug/shareRoom/list.vue");
  },
  name: "shareRoomList",
  meta: {
    title: "直播列表",
    foot: true
  }
}, {
  path: "/shareRoom/player",
  //直播间
  component: function component() {
    return import("../../../views/dev-plug/shareRoom/player.vue");
  },
  name: "shareRoomPlayer",
  meta: {
    title: "直播间",
    foot: true
  }
}, {
  path: "/shareRoom/foreshow",
  //直播预告
  component: function component() {
    return import("../../../views/dev-plug/shareRoom/foreshow.vue");
  },
  name: "shareRoomForeshow",
  meta: {
    title: "直播预告",
    foot: true
  }
}];