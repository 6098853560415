import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//商品套餐二开，礼包套餐
export default [{
  path: "/integralShiftIndex",
  component: function component() {
    return import("../../../views/integralShift/integralShiftIndex.vue");
  },
  name: "integralShiftIndex",
  meta: {
    title: "消费积分兑换池",
    foot: true
  }
}, {
  path: "/integralShiftRecord/:pool_id",
  component: function component() {
    return import("../../../views/integralShift/integralShiftRecord.vue");
  },
  name: "integralShiftRecord",
  meta: {
    title: "兑换记录",
    foot: true
  }
}, {
  path: "/integralShiftExchange",
  component: function component() {
    return import("../../../views/integralShift/integralShiftExchange.vue");
  },
  name: "integralShiftExchange",
  meta: {
    title: "福利增值兑换",
    foot: true
  }
}];