import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/random_shot",
  name: "random_shot",
  component: function component() {
    return import("../../../views/member/random_shot/index.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/random_shot_details/:form_id?",
  name: "random_shot_details",
  component: function component() {
    return import("../../../views/member/random_shot/details.vue");
  },
  meta: {
    foot: true,
    title: "查看详情"
  }
}, {
  path: "/random_shot_list",
  name: "random_shot_list",
  component: function component() {
    return import("../../../views/member/random_shot/list.vue");
  },
  meta: {
    foot: true,
    title: "随手拍"
  }
}];