import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 百网-存货服务 路由表
export default [
//百网-存货服务插件页面
{
  path: "/stockService",
  component: function component() {
    return import("../../../views/member/stockService/index.vue");
  },
  name: "stockService",
  meta: {
    title: "存货服务",
    foot: true
  }
}, {
  path: "/stockAggregatePage",
  component: function component() {
    return import("../../../views/member/stockService/stockAggregatePage.vue");
  },
  name: "stockAggregatePage",
  meta: {
    title: "存货聚合页",
    foot: false
  }
}, {
  path: "/myInventory/:name?",
  component: function component() {
    return import("../../../views/member/stockService/myInventory.vue");
  },
  name: "myInventory",
  meta: {
    title: "我的存货",
    foot: true
  }
}, {
  path: "/inventoryDetails/:name?",
  component: function component() {
    return import("../../../views/member/stockService/inventoryDetails.vue");
  },
  name: "inventoryDetails",
  meta: {
    title: "存货明细",
    foot: true
  }
}, {
  path: "/revenueDetails",
  component: function component() {
    return import("../../../views/member/stockService/revenueDetails.vue");
  },
  name: "revenueDetails",
  meta: {
    title: "收入明细",
    foot: true
  }
}, {
  path: "/financialStatements",
  component: function component() {
    return import("../../../views/member/stockService/financialStatements.vue");
  },
  name: "financialStatements",
  meta: {
    title: "收入明细",
    foot: true
  }
}];