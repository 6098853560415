import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 门店上级审核、门店云库存
export default [{
  path: "/cloudStock",
  component: function component() {
    return import("../../../views/o2o/cloudStock/index.vue");
  },
  name: "cloudStock",
  meta: {
    title: "门店云库存",
    foot: true
  }
}, {
  path: "/cloudStock_list/:stock_id?",
  component: function component() {
    return import("../../../views/o2o/cloudStock/list.vue");
  },
  name: "cloudStock_list",
  meta: {
    title: "查看明细",
    foot: true
  }
}, {
  path: "/store_applyfor",
  component: function component() {
    return import("../../../views/o2o/cloudStock/apply_for.vue");
  },
  name: "store_applyfor",
  meta: {
    title: "门店审核",
    foot: true
  }
}, {
  path: "/store_applyfor_details/:id?",
  component: function component() {
    return import("../../../views/o2o/cloudStock/details.vue");
  },
  name: "store_applyfor_details",
  meta: {
    title: "门店申请详情",
    foot: true
  }
}];