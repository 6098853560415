import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/consensus_activity",
  name: "consensus_activity",
  component: function component() {
    return import("../../../views/consensus_activity/index.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/consensus_activity_app",
  name: "consensus_activity_app",
  component: function component() {
    return import("../../../views/consensus_activity/app/index.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/consensus_activity_team",
  name: "consensus_activity_team",
  component: function component() {
    return import("../../../views/consensus_activity/app/team.vue");
  },
  meta: {
    foot: true,
    title: "团队数据"
  }
}, {
  path: "/consensus_activity_exchange",
  name: "consensus_activity_exchange",
  component: function component() {
    return import('../../../views/consensus_activity/exchange.vue');
  },
  meta: {
    foot: true,
    title: "预约"
  }
}, {
  path: "/consensus_activity_record",
  name: "consensus_activity_record",
  component: function component() {
    return import('../../../views/consensus_activity/record.vue');
  },
  meta: {
    foot: true,
    title: "活动记录"
  }
}, {
  path: "/consensus_activity_my",
  name: "consensus_activity_my",
  component: function component() {
    return import('../../../views/consensus_activity/my.vue');
  },
  meta: {
    foot: true,
    title: "我的"
  }
}, {
  path: "/consensus_activity_integrate/:num?/:count?",
  name: "consensus_activity_integrate",
  component: function component() {
    return import('../../../views/consensus_activity/integrate.vue');
  },
  meta: {
    foot: true
  }
}, {
  path: "/consensus_activity_withdraw/:num?/:open?",
  name: "consensus_activity_withdraw",
  component: function component() {
    return import('../../../views/consensus_activity/withdraw.vue');
  },
  meta: {
    foot: true
  }
}, {
  path: "/consensus_activity_asset_withdraw/:num?/:name?",
  name: "consensus_activity_asset_withdraw",
  component: function component() {
    return import('../../../views/consensus_activity/asset_withdraw.vue');
  },
  meta: {
    foot: true
  }
}, {
  path: "/consensus_activity_asset/:num?",
  name: "consensus_activity_asset",
  component: function component() {
    return import('../../../views/consensus_activity/asset.vue');
  },
  meta: {
    foot: true
  }
}, {
  path: "/consensus_activity_fomo",
  name: "consensus_activity_fomo",
  component: function component() {
    return import('../../../views/consensus_activity/fomo.vue');
  },
  meta: {
    title: "Fomo加权分红",
    foot: true
  }
}, {
  path: "/consensus_activity_earnings",
  name: "consensus_activity_earnings",
  component: function component() {
    return import('../../../views/consensus_activity/earnings.vue');
  },
  meta: {
    title: "收益记录",
    foot: true
  }
}, {
  path: "/consensus_activity_mass_intergral",
  name: "consensus_activity_mass_intergral",
  component: function component() {
    return import('../../../views/consensus_activity/mass_intergral.vue');
  },
  meta: {
    title: "质积分活动",
    foot: true
  }
}, {
  path: "/consensus_activity_user",
  name: "consensus_activity_user",
  component: function component() {
    return import('../../../views/consensus_activity/user.vue');
  },
  meta: {
    title: "有效用户",
    foot: true
  }
}, {
  path: "/consensus_activity_fomo_pool",
  name: "consensus_activity_fomo_pool",
  component: function component() {
    return import('../../../views/consensus_activity/fomo_pool.vue');
  },
  meta: {
    title: "Fomo奖池",
    foot: true
  }
}];