import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 随叫随到服务 #12064
export default [{
  path: "/anytimeRecord",
  component: function component() {
    return import("../../../views/extension/anytime_service/record/index.vue");
  },
  name: "anytimeRecord",
  meta: {
    title: "随叫随到服务",
    foot: true
  }
}, {
  path: "/anytimeService/:type?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/index.vue");
  },
  name: "anytimeService",
  meta: {
    title: "随叫随到服务",
    foot: true
  }
}, {
  path: "/anytimeSearch/:name?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/search.vue");
  },
  name: "anytimeSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/anytimePublisherList/:type/:id?/:classify_id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/publisher_list.vue");
  },
  name: "anytimePublisherList",
  meta: {
    title: "发布者需求列表",
    foot: true
  }
}, {
  path: "/anytimeIndividualNeeds/:type/:order_id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/individual_needs.vue");
  },
  name: "anytimeIndividualNeeds",
  meta: {
    title: "个人需求",
    foot: true
  }
}, {
  path: "/anytimeTechnicianHome/:id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/technician_home.vue");
  },
  name: "anytimeTechnicianHome",
  meta: {
    title: "技能师傅主页",
    foot: true
  }
}, {
  path: "/anytimeSkillService/:id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/skill_service.vue");
  },
  name: "anytimeSkillService",
  meta: {
    title: "技能服务",
    foot: true
  }
}, {
  path: "/anyTerpriseInformation/:id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/enterprise_information.vue");
  },
  name: "anyTerpriseInformation",
  meta: {
    title: "企业需求",
    foot: true
  }
}, {
  path: "/anytimeMy",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/index.vue");
  },
  name: "anytimeMy",
  meta: {
    title: "我的",
    foot: true
  }
}, {
  path: "/anytimeRoleApply/:apply_for_type?/:uid?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/role_apply.vue");
  },
  name: "anytimeRoleApply",
  meta: {
    title: "发布者申请",
    foot: true
  }
}, {
  path: "/anytimePromulgatorInfo/:edit?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/promulgator_manage/info.vue");
  },
  name: "anytimePromulgatorInfo",
  meta: {
    title: "发布者信息",
    foot: true
  }
}, {
  path: "/anytimeDemand/:type/:demand_id?/:status?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/promulgator_manage/demand.vue");
  },
  name: "anytimeDemand",
  meta: {
    title: "需求发布",
    foot: true
  }
}, {
  path: "/anytimeDemandManage",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/promulgator_manage/manage.vue");
  },
  name: "anytimeDemandManage",
  meta: {
    title: "需求管理",
    foot: true
  }
}, {
  path: "/anytimeArea",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/area/index.vue");
  },
  name: "anytimeArea",
  meta: {
    title: "技能管理",
    foot: true
  }
}, {
  path: "/anytimeArea/order",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/area/order.vue");
  },
  name: "anytimeAreaOrder",
  meta: {
    title: "技能师傅订单",
    foot: true
  }
}, {
  path: "/anytimeSkillMaster/manage",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/skill_master/manage.vue");
  },
  name: "anytimeSkillMasterManage",
  meta: {
    title: "技能管理",
    foot: true
  }
}, {
  path: "/anytimeSkillMaster/release/:id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/skill_master/release.vue");
  },
  name: "anytimeSkillMasterRelease",
  meta: {
    title: "技能发布",
    foot: true
  }
}, {
  path: "/anytimeSkillMaster/workTime/:aplly_for/:aid?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/skill_master/work_time.vue");
  },
  name: "anytimeSkillMasterWork",
  meta: {
    title: "添加工作时间",
    foot: true
  }
}, {
  path: "/anytimeSkillMaster/order",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/skill_master/order.vue");
  },
  name: "anytimeSkillMasterOrder",
  meta: {
    title: "技能师傅接单",
    foot: true
  }
}, {
  path: "/anytimeSkillMaster/:order_id?/:order_type?/:name?/:is_evaluate?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/skill_master/order_detail.vue");
  },
  name: "anytimeSkillMasterOrderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/anyOrderIndex",
  component: function component() {
    return import("../../../views/extension/anytime_service/order/index.vue");
  },
  name: "anyOrderIndex",
  meta: {
    title: "订单管理",
    foot: true
  }
}, {
  path: "/anyOrderDetail/:type?/:order_id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/order/order_detail.vue");
  },
  name: "anyOrderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/anyEnterpriseApplication",
  component: function component() {
    return import("../../../views/extension/anytime_service/enterprise_application/index.vue");
  },
  name: "anyEnterpriseApplication",
  meta: {
    title: "企业申请",
    foot: true
  }
}, {
  path: "/anyDemandPublish/:type?/:id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/enterprise_application/demand_publish.vue");
  },
  name: "anyDemandPublish",
  meta: {
    title: "需求发布",
    foot: true
  }
}, {
  path: "/anyDemandManagement",
  component: function component() {
    return import("../../../views/extension/anytime_service/enterprise_application/demand_management.vue");
  },
  name: "anyDemandManagement",
  meta: {
    title: "需求管理",
    foot: true
  }
}, {
  path: "/anyEnterprisePackage",
  component: function component() {
    return import("../../../views/extension/anytime_service/enterprise_application/enterprise_package.vue");
  },
  name: "anyEnterprisePackage",
  meta: {
    title: "企业套餐",
    foot: true
  }
}, {
  path: "/anyPackageRecord",
  component: function component() {
    return import("../../../views/extension/anytime_service/enterprise_application/package_record.vue");
  },
  name: "anyPackageRecord",
  meta: {
    title: "套餐记录",
    foot: true
  }
}, {
  path: "/anyOrder/:id?",
  component: function component() {
    return import("../../../views/extension/anytime_service/home/order.vue");
  },
  name: "anyOrder",
  meta: {
    title: "提交订单",
    foot: true
  }
}, {
  path: "/anytimeMyRecord/:name?",
  component: function component() {
    return import("../../../views/extension/anytime_service/my/record.vue");
  },
  name: "anytimeMyRecord",
  meta: {
    title: "申请记录",
    foot: true
  }
}];