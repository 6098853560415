var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "button",
          on: {
            click: function($event) {
              _vm.show = true
            }
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "icon",
              attrs: {
                viewBox: "0 0 1024 1024",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M512 128a378.88 378.88 0 0 1 160 36.608V224h182.912A446.976 446.976 0 0 0 512 64a446.976 446.976 0 0 0-342.976 160H258.56A382.272 382.272 0 0 1 512 128zM512 896a378.88 378.88 0 0 1-160-36.608V800H169.024A446.976 446.976 0 0 0 512 960a446.976 446.976 0 0 0 342.912-160h-89.536A381.888 381.888 0 0 1 512 896zM640 480a32 32 0 0 0 0-64H560.32l-1.024-1.088 102.4-102.4a33.472 33.472 0 0 0-47.296-47.296L512 367.68l-102.4-102.4a33.472 33.472 0 0 0-47.296 47.296l102.4 102.4-1.024 1.024H384a32 32 0 0 0 0 64h96v64H384a32 32 0 0 0 0 64h96v128a32 32 0 0 0 64 0v-128H640a32 32 0 0 0 0-64H544v-64H640zM992 608c0-59.584-40.896-109.184-96-123.456V361.856c8.832 5.504 16.192 13.12 21.632 22.144h69.888A128 128 0 0 0 896 292.544V288a32 32 0 0 0-64 0v4.544c-55.104 14.272-96 63.936-96 123.456s40.896 109.184 96 123.456v122.688a66.304 66.304 0 0 1-21.632-22.144H740.48c11.648 44.8 46.784 79.808 91.52 91.52v4.48a32 32 0 0 0 64 0v-4.48a127.808 127.808 0 0 0 96-123.52z m-191.488-192c0-23.104 12.992-42.496 31.488-53.568v107.2a62.72 62.72 0 0 1-31.488-53.632zM896 661.632V554.432c18.496 11.072 31.488 30.464 31.488 53.568s-12.992 42.496-31.488 53.632zM192 736c43.456 0 81.728-38.08 104.832-96H230.08c-10.688 19.904-23.744 32-38.08 32-20.864 0-39.232-25.344-50.88-64H192a32 32 0 0 0 0-64h-62.72a400.256 400.256 0 0 1 0-64H192a32 32 0 0 0 0-64h-50.88c11.712-38.656 30.016-64 50.88-64 14.336 0 27.392 12.16 38.08 32h66.752C273.664 326.08 235.456 288 192 288c-50.944 0-94.656 52.544-115.2 128H64a32 32 0 0 0 0 64h1.856C64.96 490.56 64 501.056 64 512s0.96 21.44 1.856 32H64a32 32 0 0 0 0 64h12.8c20.544 75.52 64.256 128 115.2 128z",
                  fill: "#2479e9",
                  "p-id": "2286"
                }
              })
            ]
          )
        ]
      ),
      _c("van-action-sheet", {
        attrs: {
          "close-on-click-action": "",
          description: "Elige la moneda que utilizas.",
          "cancel-text": "Cancelación",
          actions: _vm.actions
        },
        on: { select: _vm.onSelect },
        model: {
          value: _vm.show,
          callback: function($$v) {
            _vm.show = $$v
          },
          expression: "show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }