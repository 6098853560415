import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 视频点播
var CourseIndex = function CourseIndex(r) {
  return require(["../../../views/member/course/course_index"], r);
};
var CourseSearch = function CourseSearch(r) {
  return require(["../../../views/member/course/course_search"], r);
};
var CourseManage = function CourseManage(r) {
  return require(["../../../views/member/course/course_manage"], r);
};
var CourseDetail = function CourseDetail(r) {
  return require(["components/goods/course"], r);
};
var CourseReward = function CourseReward(r) {
  return require(["../../../views/member/course/course_reward"], r);
};
var CourseMy = function CourseMy(r) {
  return require(["../../../views/member/course/course_mycourse"], r);
};
var CourseHistory = function CourseHistory(r) {
  return require(["../../../views/member/course/course_history"], r);
};
var CourseMemberUpdate = function CourseMemberUpdate(r) {
  return require(["../../../views/member/course/course_member_level"], r);
};
export default [{
  path: "/video_on_demand/course_list",
  name: "video_on_demand_list",
  component: function component() {
    return import("../../../views/video_on_demand/index.vue");
  },
  meta: {
    foot: true,
    title: "课程目录"
  }
}, {
  path: "/member/coursemanage",
  component: CourseManage,
  name: "CourseManage",
  meta: {
    title: "我的课程",
    foot: false
  }
}, {
  path: "/member/coursedetail/:id",
  component: CourseDetail,
  name: "CourseDetail",
  meta: {
    title: "课程详情",
    foot: true
  }
}, {
  path: "/member/coursereward",
  component: CourseReward,
  name: "CourseReward",
  meta: {
    title: "我的打赏",
    foot: true
  }
}, {
  path: "/member/mycourse",
  component: CourseMy,
  name: "CourseMy",
  meta: {
    title: "我的课程",
    foot: true
  }
}, {
  path: "/member/coursehistory",
  component: CourseHistory,
  name: "CourseHistory",
  meta: {
    title: "观看历史",
    foot: true
  }
}, {
  path: "/member/courseindex",
  component: CourseIndex,
  name: "CourseIndex",
  meta: {
    title: "课程",
    foot: false
  }
}, {
  path: "/member/courselevel/:id/:goods",
  component: CourseMemberUpdate,
  name: "CourseMemberUpdate",
  meta: {
    title: "升级会员",
    foot: true
  }
}, {
  path: "/member/coursesearch/:goods_type",
  component: CourseSearch,
  name: "CourseSearch",
  meta: {
    title: "精选推荐课程",
    foot: true
  }
}];