var ExchangeBond = function ExchangeBond(r) {
  return require(["../../../views/extension/exchange_bond/index.vue"], r);
};
var MemberExchangeBond = function MemberExchangeBond(r) {
  return require(["../../../views/extension/exchange_bond/member_bond.vue"], r);
};
export default [{
  path: "/extension/exchange_bond",
  name: "extension_exchange_bond",
  component: ExchangeBond,
  meta: {
    foot: true
  }
}, {
  path: "/member/exchange_bond",
  name: "member_exchange_bond",
  component: MemberExchangeBond,
  meta: {
    foot: true
  }
}];