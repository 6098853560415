import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/customerStatistics",
  component: function component() {
    return import("../../../views/extension/customer_statistics/index.vue");
  },
  name: "customerStatistics",
  meta: {
    title: "客户统计",
    foot: true
  }
}, {
  path: "/customerStatisticsOrder/:id?",
  component: function component() {
    return import("../../../views/extension/customer_statistics/order.vue");
  },
  name: "customerStatisticsOrder",
  meta: {
    title: "客户统计",
    foot: true
  }
}, {
  path: "/customerStatisticsList/:id?/:type?",
  component: function component() {
    return import("../../../views/extension/customer_statistics/list.vue");
  },
  name: "customerStatisticsList",
  meta: {
    title: "客户列表",
    foot: true
  }
}];