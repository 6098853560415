import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/jianzhimaoAgentSign",
  component: function component() {
    return import("../../../views/others/jianzhimaoAgentSign.vue");
  },
  name: "jianzhimaoAgentSign",
  meta: {
    title: "代理签约",
    foot: true
  }
}];