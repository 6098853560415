import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//律正 -- 任务包
{
  path: "/taskPackage/taskPackageIndex",
  component: function component() {
    return import("../../../views/taskPackage/taskPackageIndex.vue");
  },
  name: "taskPackageIndex",
  meta: {
    title: "普法任务",
    foot: true
  }
}, {
  path: "/taskPackage/taskPackageMy",
  component: function component() {
    return import("../../../views/taskPackage/taskPackageMy.vue");
  },
  name: "taskPackageMy",
  meta: {
    title: "任务包",
    foot: true
  }
}, {
  path: "/taskPackage/task/:id",
  component: function component() {
    return import("../../../views/taskPackage/task.vue");
  },
  name: "taskPackageTask",
  meta: {
    title: "任务包",
    foot: true
  }
}, {
  path: "/taskPackage/middle",
  component: function component() {
    return import("../../../views/taskPackage/middle.vue");
  },
  name: "taskPackageMiddle",
  meta: {
    title: "任务包",
    foot: true
  }
}, {
  path: "/taskPackage/record/:type/:id?",
  component: function component() {
    return import("../../../views/taskPackage/record.vue");
  },
  name: "taskPackageRecord",
  meta: {
    title: "",
    foot: true
  }
}];