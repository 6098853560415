import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/priceDifferencePool/index",
  name: "priceDifferencePool",
  component: function component() {
    return import("../../../views/priceDifferencePool/index.vue");
  },
  meta: {
    title: "资金池",
    foot: true
  }
}];