import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/Agents_dividend_tx",
  name: "Agents_dividend_tx",
  component: function component() {
    return import("../../../views/extension/Agents_dividend_tx");
  },
  meta: {
    title: "代理商分红tx",
    foot: true
  }
}];