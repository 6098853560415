import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/member/line_up_free",
  component: function component() {
    return import("../../../views/member/line_up_free/index");
  },
  name: "line_up_free",
  meta: {
    title: "排队补贴",
    foot: true
  }
}];