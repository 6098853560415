import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//健康检测设备
export default [{
  path: "/health-check",
  name: "health-check",
  component: function component() {
    return import("../../../views/member/checkout_equipment/index.vue");
  },
  meta: {
    title: "评估结果",
    foot: true
  }
}, {
  path: "/detection_result_detail/:id?",
  name: "detection_result_detail",
  component: function component() {
    return import("../../../views/member/checkout_equipment/detail.vue");
  },
  meta: {
    title: "评估结果详情",
    foot: true
  }
}];