import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @file
 * Created by liang into on 19/10/30.
 */
import baseRewardTemplate from "./mirco/baseRewardTemplate/index"; //通用列表模板

import BlockChain from "./blockchain";
import customizedPlugin from "./customizedPlugin";
import couponStoreRouter from "./mirco/coupon-store/index"; //消费券
import vendingMachineRouter from "./mirco/vending_machine/index"; //自动售货机
import sachertorteRouter from "./mirco/sachertorte/index"; //蛋糕叔叔
import redeemCodeRouter from "./mirco/redeem_code/index"; //兑换码
import taskPackageRouter from "./mirco/task-package/index";
import storePriceTagRouter from "./mirco/store-price-tag/index";
import newPosterRouter from "./mirco/newPoster/index";
import stockServiceRouter from "./mirco/stock-service/index"; //百网-存货服务
import checkoutEquipment from "./mirco/checkout-equipment"; //监控设备检测
import sign_pluginRouter from "./mirco/sign_plugin/index"; //商城电子合同2.0
import storeRouter from "./mirco/store/index"; // 门店上级审核、门店云库存
import chaining from "./mirco/chaining/index"; //链动
import exchangeBondRouter from "./mirco/exchange-bond/index"; //兑换卷
import differenceReward from "./mirco/difference-reward/index";
import sino_skill from "./mirco/sino_skill/index";
import dealerTeamReward from "./mirco/dealer-team-reward/index";
import anytimeService from "./mirco/anytime-service/index"; //随叫随到服务
import ecologicalPoint from "./mirco/ecological-point/index";
import unionCpsPlu from "./mirco/union-cps/index"; //聚推联盟cps
import water_machineRouter from "./mirco/water-machine/index";
import supportCenter from "./mirco/support-center/index";
import energyValue from "./mirco/energy-value/index";
import levelCompel from "./mirco/level-compel/index"; //新客下单
import consensusActivity from "./mirco/consensus_activity/index"; //共识活动
import drinkMachind from "./mirco/drink-machine/index";
import courseRouter from "./mirco/course/index"; //课程分发
import phoneBillRecharge from "./mirco/phone-bill-recharge/index";
import chainStore from "./mirco/chain-store/index";
import yzSupplyLease from "./mirco/yz-supply-lease/index"; //课程分发
import communityMarket from "./mirco/community-marketing/index"; //社群营销
import coffeeMachine from "./mirco/coffee-machine/index";
import energyMachine from "./mirco/energy-machine/index"; //能量仓
import tagBalance from "./mirco/tag-balance/index";
import shareRoom from "./mirco/share-room/index";
import customerStatistics from "./mirco/customer-statistics/index";
import video_on_demand from "./mirco/video_on_demand/index";
import priceDifferencePool from "./mirco/price-difference-pool/index";
import period_pay from "./mirco/period_pay/index";
import videoCourse from "./mirco/video-course/index";
import ServiceStationDividend from "./mirco/service_station_dividend/index";
import outArea from "./mirco/out-area/index";
import RandomShot from "./mirco/random_shot/index";
import rankingListIndex from "./mirco/ranking-list/index";
import capital_pool from "./mirco/capital_pool/index";
import distribution_fund from "./mirco/distribution-fund/index";
import freezeCoupon from "./mirco/freeze-coupon/index";
import consumptionDividend from "./mirco/consumption-dividend/index";
import videoSharer from "./mirco/video-sharer/index";
import quickenIncome from "./mirco/quicken-income/index";
import reward_pool_dividend from "./mirco/reward-pool-dividend/index";
import bigScreen from "./mirco/big-screen/index";
import Agents_dividend_tx from "./mirco/Agents-dividend-tx/index";
import numberCollection from "./mirco/number-collection/index";
import new_integral from "./mirco/new_integral/index";
import accountFjyx from "./mirco/account-fjyx/index";
import shopsProfits from "./mirco/shops-profits/index";
import meituan_refund from "./mirco/meituan-refund/index";
import expressSignature from "./mirco/express-signature/index";
import tiktok_writeoff from "./mirco/tiktok-writeoff/index";
import agency_reward from "./mirco/donationProject/index";
import customCluster from "./mirco/custom-cluster/index";
import manualOrder from "./mirco/manual-order/index";
import jianzhimao_withdraw from "./mirco/jianzhimao-withdraw/index";
import love_release_bonu from "./mirco/love-release-bonu/index";
import line_up_free from "./mirco/line-up-free/index";
import distributionCoupon from "./mirco/distribution-coupon/index";
import giftPackage from "./mirco/gift-package/index";
import txTeamPerformance from "./mirco/tx-team-performance/index";
import syncmain from "./mirco/sync-main/index";
import integralShift from "./mirco/integral-shift/index";
import gradevin from "./mirco/gradevin/index";
import cloud_pay_money_sign from "./mirco/cloud-pay-money/index";
import yccMoney from "./mirco/ycc-money/index";
import coupon_scan_share from "./mirco/coupon-scan-share/index";
import assessment from "./mirco/assessment/index";
import platform_order_import from "./mirco/platform-order-import/index";
import id_shop from "./mirco/id-shop/index";

// 根目录
var rootPath = "";
var logistics = function logistics(r) {
  return require(["../views/logistics/index"], r);
};
var conferenceList = function conferenceList(r) {
  return require.ensure([], function () {
    return r(require("@/views/conference/home"));
  }, "conferenceList");
};
var activity = function activity(r) {
  return require(["../views/conference/activity"], r);
};
var MyActivityInfo = function MyActivityInfo(r) {
  return require(["../views/conference/my_activity_info"], r);
};
var conferenceSign = function conferenceSign(r) {
  return require(["../views/conference/conferenceSign"], r);
};
var ActivityTaskList = function ActivityTaskList(r) {
  return require(["../views/conference/activity_task_list"], r);
};
var userAgreement = function userAgreement(r) {
  return require(["../views/member/user_agreement"], r);
};
var goodsGroup = function goodsGroup(r) {
  return require(["../views/goods/goodsGroup"], r);
};

// 案例库
var caseLibrary = function caseLibrary(r) {
  return require(["../views/others/case_library/case_library"], r);
};
var caseLibraryHome = function caseLibraryHome(r) {
  return require(["../views/others/case_library/home"], r);
};
var caseLibraryCategory = function caseLibraryCategory(r) {
  return require(["../views/others/case_library/category"], r);
};
var caseLibraryLabel = function caseLibraryLabel(r) {
  return require(["../views/others/case_library/label"], r);
};
var caseLibrarySubjectList = function caseLibrarySubjectList(r) {
  return require(["../views/others/case_library/subject_list"], r);
};
var caseLibrarySubjectDetail = function caseLibrarySubjectDetail(r) {
  return require(["../views/others/case_library/subject_detail"], r);
};
var caseLibraryDetail = function caseLibraryDetail(r) {
  return require(["../views/others/case_library/case_detail"], r);
};
var caseLibraryMypublish = function caseLibraryMypublish(r) {
  return require(["../views/others/case_library/label"], r);
};
var caseLibraryNewMobile = function caseLibraryNewMobile(r) {
  return require(["../views/others/case_library/new_mobile"], r);
};
var caseLibraryAreaMobile = function caseLibraryAreaMobile(r) {
  return require(["../views/others/case_library/new_mobile"], r);
};
var caseLibraryRankingMobile = function caseLibraryRankingMobile(r) {
  return require(["../views/others/case_library/ranking_mobile"], r);
};
// 应用市场
var applicationMarket = function applicationMarket(r) {
  return require(["../views/others/application_market/application_market"], r);
};
var bindingSite = function bindingSite(r) {
  return require(["../views/others/application_market/binding_site"], r);
};
var changeSite = function changeSite(r) {
  return require(["../views/others/application_market/change_site"], r);
};
var o2oHome = function o2oHome(r) {
  return require(["../views/o2o/home"], r);
};
var o2oStore_v2 = function o2oStore_v2(r) {
  return require(["../views/o2o/store/store_v2"], r);
};
var o2oLocation = function o2oLocation(r) {
  return require(["../views/o2o/location/home"], r);
};
var o2oLocation_loc = function o2oLocation_loc(r) {
  return require(["../views/o2o/location/location"], r);
};
var o2oCity = function o2oCity(r) {
  return require(["../views/o2o/location/city"], r);
};
var o2oSearch = function o2oSearch(r) {
  return require(["../views/o2o/search"], r);
};
var o2oSearchMultiple = function o2oSearchMultiple(r) {
  return require(["../views/o2o/search_multiple"], r);
};
var o2oCategory = function o2oCategory(r) {
  return require(["../views/o2o/category"], r);
};
var StoreApply = function StoreApply(r) {
  return require(["../views/o2o/store/store_apply"], r);
};
var StoreWithdrawals = function StoreWithdrawals(r) {
  return require(["../views/o2o/store/withdrawals"], r);
};
var StoreManage = function StoreManage(r) {
  return require(["../views/o2o/store_manage"], r);
};
var StoreSearch = function StoreSearch(r) {
  return require(["../views/o2o/store/store_search"], r);
};
//门店拼团
var o2oGroupActivitylist = function o2oGroupActivitylist(r) {
  return require(["../views/o2o/o2oGroup/o2oGroup_activitylist"], r);
};
var openGroupList = function openGroupList(r) {
  return require(["../views/o2o/o2oGroup/open_group_list"], r);
};
var openGroupDetail = function openGroupDetail(r) {
  return require(["../views/o2o/o2oGroup/openGroup_detail"], r);
};
var groupDataStatistics = function groupDataStatistics(r) {
  return require(["../views/o2o/o2oGroup/groupDataStatistics"], r);
};
var createGroup = function createGroup(r) {
  return require(["../views/o2o/o2oGroup/createGroup"], r);
};
var qrCode = function qrCode(r) {
  return require(["../views/o2o/qr_code"], r);
};
var collectionCode = function collectionCode(r) {
  return require(["../views/o2o/collectionCode"], r);
};
var o2oGrouplist = function o2oGrouplist(r) {
  return require(["../views/o2o/o2o_grouplist"], r);
};
// const privacy = r => require(["../views/o2o/privacy"], r);
var cloudHorn = function cloudHorn(r) {
  return require(["../views/o2o/cloud_horn"], r);
};
var zkzDevice = function zkzDevice(r) {
  return require(["../views/o2o/zkzDevice"], r);
};
var Home = function Home(r) {
  return require(["../views/home/home"], r);
};
var Category = function Category(r) {
  return require(["../views/category/category"], r);
};
var CategoryChild = function CategoryChild(r) {
  return require(["../views/category/category_child"], r);
};
var Catelist = function Catelist(r) {
  return require(["../views/category/catelist"], r);
};
var Brand = function Brand(r) {
  return require(["../views/category/brand"], r);
};
var BrandGoods = function BrandGoods(r) {
  return require(["../views/category/brandgoods"], r);
};
var Cart = function Cart(r) {
  return require(["../views/cart/cart"], r);
};
var CartShare = function CartShare(r) {
  return require(["../views/cart/cart_share"], r);
};
var Settlement = function Settlement(r) {
  return require(["../views/cart/settlement"], r);
};
var newDiy = function newDiy(r) {
  return require(["../views/new_diy"], r);
};
var Share = function Share(r) {
  return require(["../views/home/share"], r);
};
var CloseSite = function CloseSite(r) {
  return require(["../views/close_site"], r);
};
var DiyForm = function DiyForm(r) {
  return require(["../views/diy_form"], r);
};
var LimitedTimeGoods = function LimitedTimeGoods(r) {
  return require(["../views/home/limited_time_goods"], r);
};
// 收银台
var cashier = function cashier(r) {
  return require(["../views/cashier/cashier"], r);
};
var cashier_pay = function cashier_pay(r) {
  return require(["../views/cashier/pay"], r);
};
var cashier_qr = function cashier_qr(r) {
  return require(["../views/cashier/qrcode"], r);
};
var cashier_withdrawals = function cashier_withdrawals(r) {
  return require(["../views/cashier/withdrawals"], r);
};
var cashier_stat = function cashier_stat(r) {
  return require(["../views/cashier/stat"], r);
};
var show_balances = function show_balances(r) {
  return require(["../views/cashier/showBalances"], r);
};
var cashier_income = function cashier_income(r) {
  return require(["../views/cashier/income"], r);
};
// article
var Notice = function Notice(r) {
  return require(["../views/article/notice"], r);
};
var ArticleContent = function ArticleContent(r) {
  return require(["../views/article/articleContent"], r);
};
var NoticeByCategory = function NoticeByCategory(r) {
  return require(["../views/article/notice_by_category"], r);
};
var NoticeReport = function NoticeReport(r) {
  return require(["../views/article/notice_report"], r);
};
var payList = function payList(r) {
  return require(["../views/article/pay_list"], r);
};
var payEssay = function payEssay(r) {
  return require(["../views/article/pay_essay"], r);
};
// 期刊杂志
var periodicalIndex = function periodicalIndex(r) {
  return require(["../views/article/periodicalIndex"], r);
};
var periodicalDetail = function periodicalDetail(r) {
  return require(["../views/article/periodicalDetail"], r);
};
var periodicalWatch = function periodicalWatch(r) {
  return require(["../views/article/periodicalWatch"], r);
};

//申请招商员
var ApplyForMerchants = function ApplyForMerchants(r) {
  return require(["../views/extension/apply_for_merchants/apply_for_merchants"], r);
};
// Member
var MemberBank = function MemberBank(r) {
  return require(["../views/member/bank/bank_info"], r);
};
var BankCard = function BankCard(r) {
  return require(["../views/member/bank/bankCard"], r);
};
var Member = function Member(r) {
  return require(["../views/member/member"], r);
};
var morePlugins = function morePlugins(r) {
  return require(["../views/member/plugins_page"], r);
};
// const Po = r => require(['../views/member/po'], r)
// const Tabs = r => require(['../views/member/tabs'], r)
// const Info = r => require(["../views/member/info/info_v2"], r);
var Editmobile = function Editmobile(r) {
  return require(["../views/member/info/member_editmobile_v2"], r);
};
var WithdrawEditmobile = function WithdrawEditmobile(r) {
  return require(["../views/member/info/member_withdraw_editmobile"], r);
};
var scanEditmobile = function scanEditmobile(r) {
  return require(["../views/member/info/scan_editmobile"], r);
};
var codePage = function codePage(r) {
  return require(["../views/member/tool/code_page"], r);
};
// //visit
// const Visit = r => require(['../views/member/visit/Visit'], r)

var AlterAddress = function AlterAddress(r) {
  return require(["../views/member/address/alterAddress_v2"], r);
};
var AppendAddress = function AppendAddress(r) {
  return require(["../views/member/address/appendAddress_v2"], r);
};
// const Balance = r => require(["../views/member/balance/member_balance"], r);
var Balance = function Balance(r) {
  return require(["../views/member/balance/member_balance_v2"], r);
};
var Integral = function Integral(r) {
  return require(["../views/member/integral/member_integral"], r);
};
var IntegralV2 = function IntegralV2(r) {
  return require(["../views/member/integral_v2/member_integral_v2"], r);
};
var IntegralV2Transfer = function IntegralV2Transfer(r) {
  return require(["../views/member/integral_v2/member_integral_v2_transfer"], r);
};
var InfoCostom = function InfoCostom(r) {
  return require(["../views/member/info/info_custom"], r);
};
var BalancePassword = function BalancePassword(r) {
  return require(["../views/member/info/balance_password_v2"], r);
};
var SetBalancePassword = function SetBalancePassword(r) {
  return require(["../views/member/info/set_balance_password_v2"], r);
};
var MemberCancel = function MemberCancel(r) {
  return require(["../views/member/info/member_cancel"], r);
};
var MemberCancelContent = function MemberCancelContent(r) {
  return require(["../views/member/info/member_cancel_content"], r);
};

// score
var IntegralActivity = function IntegralActivity(r) {
  return require(["../views/member/score/integral_activity"], r);
};
var IntegralGeneralization = function IntegralGeneralization(r) {
  return require(["../views/member/score/integral_generalization"], r);
};
var IntegralGetRecord = function IntegralGetRecord(r) {
  return require(["../views/member/score/integral_get_record"], r);
};
var IntegralPresent = function IntegralPresent(r) {
  return require(["../views/member/score/integral_present"], r);
};
var IntegralRecord = function IntegralRecord(r) {
  return require(["../views/member/score/integral_record"], r);
};

// sign
var Sign = function Sign(r) {
  return require(["../views/member/sign/member_sign"], r);
};
var Signrules = function Signrules(r) {
  return require(["../views/member/sign/member_signrules"], r);
};
var Signrecord = function Signrecord(r) {
  return require(["../views/member/sign/member_signrecord"], r);
};

// member_integraldetailed
var Integraldetail = function Integraldetail(r) {
  return require(["../views/member/integral/member_integraldetailed"], r);
};
var IntegralList = function IntegralList(r) {
  return require(["../views/member/integral/member_integrallist"], r);
};
var Balance_recharge = function Balance_recharge(r) {
  return require(["../views/member/balance/member_balance_recharge"], r);
};
// 门店独立余额充值
var storeBalance = function storeBalance(r) {
  return require(["../views/member/balance/member_balance_recharge_store"], r);
};
var Balance_transfer = function Balance_transfer(r) {
  return require(["../views/member/balance/member_balance_transfer"], r);
};
// const Balance_withdrawals = r => require(["../views/member/balance/member_balance_withdrawals"], r);
var Balance_withdrawals_v2 = function Balance_withdrawals_v2(r) {
  return require(["../views/member/balance/member_balance_withdrawals_v2"], r);
};
var cmcPayInfo = function cmcPayInfo(r) {
  return require(["../views/member/balance/cmcPayInfo"], r);
};
// 保单管理
var Balance_manage = function Balance_manage(r) {
  return require(["../views/member/balance/member_balance_manage"], r);
};
// 保单管理的详情
var Balance_manageDetail = function Balance_manageDetail(r) {
  return require(["../views/member/balance/member_balance_manageDetail"], r);
};
// 保单添加
var Add_Balance_manage = function Add_Balance_manage(r) {
  return require(["../views/member/balance/add_member_balance"], r);
};
// 编辑保单
var Edit_Balance_manage = function Edit_Balance_manage(r) {
  return require(["../views/member/balance/edit_member_balance"], r);
};
// const Detailed = r => require(["../views/member/balance/member_balance_detailed"], r);
var Detailed = function Detailed(r) {
  return require(["../views/member/balance/member_balance_detailed_v2"], r);
};
// 保单支付
var policyPay = function policyPay(r) {
  return require(["../views/member/balance/policy_pay"], r);
};
// const Screen = r => require(['../views/member/balance/member_balance_screen'], r);
var Details = function Details(r) {
  return require(["../views/member/balance/member_balance_details"], r);
};
// const Income = r => require(['../views/member/income/member_income'], r)
// const Withdrawal = r => require(["../views/member/income/member_income_withdrawal"], r);
var Withdrawal = function Withdrawal(r) {
  return require(["../views/member/income/member_income_withdrawal_v2"], r);
};
var Incomedetails = function Incomedetails(r) {
  return require(["../views/member/income/member_income_incomedetails"], r);
};
var IncomedetailsInfo = function IncomedetailsInfo(r) {
  return require(["../views/member/income/member_income_incomedetails_info"], r);
};
var PresentationRecord = function PresentationRecord(r) {
  return require(["../views/member/income/member_income_presentationRecord"], r);
};
var PresentationDetails = function PresentationDetails(r) {
  return require(["../views/member/income/member_income_presentationRecord_presentationDetails"], r);
};
var Supplier = function Supplier(r) {
  return require(["../views/member/supplier/supplier"], r);
};
var CreditInfo = function CreditInfo(r) {
  return require(["../views/member/credit/credit_info"], r);
};
var CommodityManagement = function CommodityManagement(r) {
  return require(["../views/member/supplier/commodity_management/management"], r);
};
var CommodityEditor = function CommodityEditor(r) {
  return require(["../views/member/supplier/commodity_management/editor"], r);
};
var CommodityRelease = function CommodityRelease(r) {
  return require(["../views/member/supplier/commodity_management/release"], r);
};
var CommodityCoupon = function CommodityCoupon(r) {
  return require(["../views/member/supplier/commodity_coupon/management"], r);
};
var CommodityCouponEdit = function CommodityCouponEdit(r) {
  return require(["../views/member/supplier/commodity_coupon/edit"], r);
};
var CommodityCouponGive = function CommodityCouponGive(r) {
  return require(["../views/member/supplier/commodity_coupon/give_out"], r);
};
var GoodsClassify = function GoodsClassify(r) {
  return require(["../views/member/supplier/classify/goods_classify"], r);
};
var AddClassify = function AddClassify(r) {
  return require(["../views/member/supplier/classify/add_classify"], r);
};
var FirstEdit = function FirstEdit(r) {
  return require(["../views/member/supplier/classify/first_edit"], r);
};
var Income = function Income(r) {
  return require(["../views/member/income/member_income_v2"], r);
};
var Message = function Message(r) {
  return require(["../views/member/message_list"], r);
};
var MessageInfo = function MessageInfo(r) {
  return require(["../views/member/message_info"], r);
};

// order
// order_after_express_info
var OrderExpress = function OrderExpress(r) {
  return require(["../views/member/order/order_after_express_info"], r);
};
var OrderList = function OrderList(r) {
  return require(["../views/member/order/order_list"], r);
};
var Goodsinfo = function Goodsinfo(r) {
  return require(["../views/member/order/order_detail"], r);
};
var Refund = function Refund(r) {
  return require(["../views/member/order/order_list_refund"], r);
};
var Logistics = function Logistics(r) {
  return require(["../views/member/order/order_list_logistics"], r);
};
var track = function track(r) {
  return require(["../views/member/order/order_list_track"], r);
};
var Evaluate = function Evaluate(r) {
  return require(["../views/comment/evaluate"], r);
};
var ReplyEvaluate = function ReplyEvaluate(r) {
  return require(["../views/member/order/order_list_replyEvaluate"], r);
};
var Addevaluate = function Addevaluate(r) {
  return require(["../views/comment/addevaluate"], r);
};
var Aftersales = function Aftersales(r) {
  return require(["../views/member/order/order_aftersales"], r);
};
var Aftersaleslist = function Aftersaleslist(r) {
  return require(["../views/member/order/order_aftersaleslist"], r);
};
var OrderPay = function OrderPay(r) {
  return require(["../views/member/order/order_payment"], r);
};
var OrderVerification = function OrderVerification(r) {
  return require(["../views/member/order/order_verification"], r);
};
var OrderCash = function OrderCash(r) {
  return require(["../views/member/order/order_cash"], r);
};
var OrderRecord = function OrderRecord(r) {
  return require(["../views/member/order/order_record"], r);
};
var NegotiationRecord = function NegotiationRecord(r) {
  return require(["../views/member/order/negotiation_record"], r);
};
var PayErr = function PayErr(r) {
  return require(["../views/member/order/order_payErr"], r);
};
var PayYes = function PayYes(r) {
  return require(["../views/member/order/order_payYes"], r);
};
var Blacklist = function Blacklist(r) {
  return require(["../views/member/order/order_blacklist"], r);
};
var Mandate = function Mandate(r) {
  return require(["../views/member/order/order_mandate"], r);
};

//同城配送物流详情
var OrderLogisticsDetails = function OrderLogisticsDetails(r) {
  return require(["../views/member/order/order_logistics_details"], r);
};

// Extension
var Manage = function Manage(r) {
  return require(["../views/extension/manage"], r);
};
var Extension = function Extension(r) {
  return require.ensure([], function () {
    return r(require("@/views/extension/index"));
  }, "Extension");
};
// const Distribution = r => require(["../views/extension/distribution"], r);
var Distribution = function Distribution(r) {
  return require(["../views/extension/distribution_v2"], r);
};
var Commission = function Commission(r) {
  return require(["../views/extension/commission"], r);
};
var Unsettled = function Unsettled(r) {
  return require(["../views/extension/unsettled"], r);
};
var AlreadySettled = function AlreadySettled(r) {
  return require(["../views/extension/alreadySettled"], r);
};
var NotPresent = function NotPresent(r) {
  return require(["../views/extension/notPresent"], r);
};
var Present = function Present(r) {
  return require(["../views/extension/present"], r);
};
var DistributionOrder = function DistributionOrder(r) {
  return require(["../views/extension/distributionOrder"], r);
};
var AwardRanking = function AwardRanking(r) {
  return require(["../views/extension/spread/awardRanking"], r);
};
var ExtensionPage = function ExtensionPage(r) {
  return require(["../views/extension/spread/extension"], r);
};
var RecordOfAwards = function RecordOfAwards(r) {
  return require(["../views/extension/spread/recordOfAwards"], r);
};
var MyIncome = function MyIncome(r) {
  return require(["../views/extension/spread/my_income"], r);
};
var myEarnings = function myEarnings(r) {
  return require(["../views/extension/spread/my_earnings"], r);
};
var earningList = function earningList(r) {
  return require(["../views/extension/spread/earning_list"], r);
};
var stockholderBonus = function stockholderBonus(r) {
  return require(["../views/extension/stockholder-bonus/stockholder_bonus"], r);
};
var stockholderAchievement = function stockholderAchievement(r) {
  return require(["../views/extension/stockholder-bonus/achievement"], r);
};
var point_activity_award_center = function point_activity_award_center(r) {
  return require(["../views/extension/pointActivityAwardCenter"], r);
};
var rightsInterests = function rightsInterests(r) {
  return require(["../views/extension/rightsInterests"], r);
};
//股东奖励分红
var BonusDividend = function BonusDividend(r) {
  return require(["../views/bonus_dividend/bonus_dividend"], r);
};
var Exchange = function Exchange(r) {
  return require(["../views/bonus_dividend/exchange"], r);
};
//区域选择
var Selectionarea = function Selectionarea(r) {
  return require(["../views/regionalmanage/selectionarea"], r);
};
//区域分红
var Regionaldividend = function Regionaldividend(r) {
  return require(["../views/regionalmanage/regionaldividend"], r);
};
//区域分红详情
var Detailsdividends = function Detailsdividends(r) {
  return require(["../views/regionalmanage/detailsdividends"], r);
};
// plug
// const Marketing = r => require(['../views/member/plug/marketing'], r)
// const MessageSettings = r => require(['../views/member/plug/messageSettings'], r)
// income
var DistributionCommission = function DistributionCommission(r) {
  return require(["../views/member/income/distributionCommission"], r);
};
var ExtendIncome = function ExtendIncome(r) {
  return require(["../views/member/income/extendIncome"], r);
}; // new
var MerchantsDividendRatio = function MerchantsDividendRatio(r) {
  return require(["../views/member/income/merchants/merchants_dividend_ratio"], r);
};
var ShareholderCenter = function ShareholderCenter(r) {
  return require(["../views/member/income/shareholderCenter/shareholderCenter"], r);
};
var TeamAgentCenter = function TeamAgentCenter(r) {
  return require(["../views/member/income/teamAgentCenter/teamAgentCenter"], r);
};
var TeamAgentOrder = function TeamAgentOrder(r) {
  return require(["../views/member/income/teamAgentCenter/order.vue"], r);
};
var newAgentCenter = function newAgentCenter(r) {
  return require(["../views/member/income/teamAgentCenter/newAgentCenter"], r);
};
var TeamPerformance = function TeamPerformance(r) {
  return require(["../views/member/income/teamAgentCenter/teamPerformance"], r);
};
var newTeamPerformance = function newTeamPerformance(r) {
  return require(["../views/member/income/teamAgentCenter/newteamPerformance"], r);
};
var TeamCode = function TeamCode(r) {
  return require(["../views/member/income/teamAgentCenter/teamCode"], r);
};
var newTeamCode = function newTeamCode(r) {
  return require(["../views/member/income/teamAgentCenter/newteamCode"], r);
};
var TeamClient = function TeamClient(r) {
  return require(["../views/member/income/teamAgentCenter/teamClient"], r);
};
var newTeamClient = function newTeamClient(r) {
  return require(["../views/member/income/teamAgentCenter/newteamClient"], r);
};
var CodeHistory = function CodeHistory(r) {
  return require(["../views/member/income/teamAgentCenter/codeHistory"], r);
};
var newCodeHistory = function newCodeHistory(r) {
  return require(["../views/member/income/teamAgentCenter/newcodeHistory"], r);
};
var InvestmentCenter = function InvestmentCenter(r) {
  return require(["../views/member/income/investmentCenter/investmentCenter"], r);
};
var RegionalAgencyCenter = function RegionalAgencyCenter(r) {
  return require(["../views/member/income/regionalAgencyCenter/regionalAgencyCenter"], r);
};
var ApplyRegionalAgency = function ApplyRegionalAgency(r) {
  return require(["../views/member/income/regionalAgencyCenter/applyRegionalAgency"], r);
};
var FixedReward = function FixedReward(r) {
  return require(["../views/member/income/fixedReward/fixed_reward"], r);
};
var CourseIncome = function CourseIncome(r) {
  return require(["../views/member/income/course/index"], r);
};
var Helpcenter = function Helpcenter(r) {
  return require(["../views/member/helpcenter"], r);
};

// 经销商红包奖励
var FranchiserRed = function FranchiserRed(r) {
  return require(["../views/member/income/teamAgentCenter/franchiser_red"], r);
};
var newFranchiserRed = function newFranchiserRed(r) {
  return require(["../views/member/income/teamAgentCenter/newfranchiser_red"], r);
};
var RedReward = function RedReward(r) {
  return require(["../views/member/income/red_reward"], r);
};

//每日红包
var DailyRed = function DailyRed(r) {
  return require(["../views/others/daily_red/daily_red"], r);
};
var GetRedRecord = function GetRedRecord(r) {
  return require(["../views/others/daily_red/get_red_record"], r);
};
var RedAmount = function RedAmount(r) {
  return require(["../views/others/daily_red/red_amount"], r);
};
var redRank = function redRank(r) {
  return require(["../views/others/daily_red/redRank"], r);
};
// 每日红包二开
var DailyRedHome = function DailyRedHome(r) {
  return require(["../views/others/daily_red/daily_red_home"], r);
};

// microshop
var MicroShop_apply = function MicroShop_apply(r) {
  return require(["../views/member/microshop/microShop_apply.vue"], r);
};
var MicroShop_apply_reg_info = function MicroShop_apply_reg_info(r) {
  return require(["../views/member/microshop/microShop_apply_reg_info.vue"], r);
};
var MicroShop_baseInfo = function MicroShop_baseInfo(r) {
  return require(["../views/member/microshop/baseInfo.vue"], r);
};
var MicroShop_Category = function MicroShop_Category(r) {
  return require(["../views/member/microshop/category/category.vue"], r);
};
var Catelist1 = function Catelist1(r) {
  return require(["../views/member/microshop/category/catelist.vue"], r);
};
var MicroShop_home = function MicroShop_home(r) {
  return require(["../views/member/microshop/home/home"], r);
};
var MicroShop_ShopKeeperCenter = function MicroShop_ShopKeeperCenter(r) {
  return require(["../views/member/microshop/shopKeeperCenter"], r);
};
var MicroShop_orderDetail = function MicroShop_orderDetail(r) {
  return require(["../views/member/microshop/orderDetail"], r);
};
var MicroShop_shareList = function MicroShop_shareList(r) {
  return require(["../views/member/microshop/shareList.vue"], r);
};
var MicroShop_shareDetail = function MicroShop_shareDetail(r) {
  return require(["../views/member/microshop/shareDetail"], r);
};
var MicroShop_selectedGoods = function MicroShop_selectedGoods(r) {
  return require(["../views/member/microshop/selectedGoods"], r);
};
// microshop 分享出去的模块
var MicroShop_Share_home = function MicroShop_Share_home(r) {
  return require(["../views/member/microshop/microshophome/micros_shop_home"], r);
};
var MicroShop_ShareCategory = function MicroShop_ShareCategory(r) {
  return require(["../views/member/microshop/categoryshare/category"], r);
};
// 添加商品的分类
var ManageShop = function ManageShop(r) {
  return require(["../views/member/microshop/manage_shop"], r);
};
var MicroShop_AddCategory = function MicroShop_AddCategory(r) {
  return require(["../views/member/microshop/category_add/category"], r);
};
var CatelistShare = function CatelistShare(r) {
  return require(["../views/member/microshop/categoryshare/catelist.vue"], r);
};

// love 爱心值
var Love_index = function Love_index(r) {
  return require(["../views/love/love_index"], r);
};
var Love_list = function Love_list(r) {
  return require(["../views/love/love_list"], r);
};
var Love_transfer = function Love_transfer(r) {
  return require(["../views/love/love_transfer"], r);
};
var Love_trading_sell = function Love_trading_sell(r) {
  return require(["../views/love/love_trading_sell"], r);
};
var Love_trading = function Love_trading(r) {
  return require(["../views/love/love_trading"], r);
};
var Love_changein = function Love_changein(r) {
  return require(["../views/love/love_changein"], r);
};
var Love_changeto = function Love_changeto(r) {
  return require(["../views/love/love_changeto"], r);
};
var Love_give = function Love_give(r) {
  return require(["../views/love/love_give"], r);
};
var Love_agent = function Love_agent(r) {
  return require(["../views/love/love_agent"], r);
};
var Love_push = function Love_push(r) {
  return require(["../views/love/love_push"], r);
};
var Love_cash = function Love_cash(r) {
  return require(["../views/love/love_cash"], r);
};
var Love_record = function Love_record(r) {
  return require(["../views/love/love_record"], r);
};
var Love_details = function Love_details(r) {
  return require(["../views/love/love_details"], r);
};
var Love_activation = function Love_activation(r) {
  return require(["../views/love/love_activation"], r);
};
var Love_explain = function Love_explain(r) {
  return require(["../views/love/love_explain"], r);
};
var LoveWithdrawals = function LoveWithdrawals(r) {
  return require(["../views/love/love_withdrawals"], r);
};
var lovePeriod = function lovePeriod(r) {
  return require(["../views/love/love_period/love_period"], r);
};
var lovePerformance = function lovePerformance(r) {
  return require(["../views/love/love_performance/love_performance"], r);
};
var LoveGiftBag = function LoveGiftBag(r) {
  return require(["../views/love/love_gift_bag"], r);
};
var LoveCycleReceive = function LoveCycleReceive(r) {
  return require(["../views/love/love_cycle_receive"], r);
};
var LoveCashbackRecord = function LoveCashbackRecord(r) {
  return require(["../views/love/love_cashback_record"], r);
};
var LoveGiftQueue = function LoveGiftQueue(r) {
  return require(["../views/love/love_gift_queue"], r);
};
var Love_Speed_Released = function Love_Speed_Released(r) {
  return require(["../views/love/love_speed_released/love_speed_released"], r);
};
var Love_active_froze = function Love_active_froze(r) {
  return require(["../views/love/love_active_froze"], r);
};
// 消费积分-爱心值
var Integral_love = function Integral_love(r) {
  return require(["../views/love/integral_love/integral_love"], r);
};
var Integral_invest = function Integral_invest(r) {
  return require(["../views/love/integral_love/Integral_love_invest"], r);
};
var Integral_love_detail = function Integral_love_detail(r) {
  return require(["../views/love/integral_love/Integral_love_detail"], r);
};
var Integral_love_transfer = function Integral_love_transfer(r) {
  return require(["../views/love/integral_love/Integral_love_transfer"], r);
};
var Integral_love_cash = function Integral_love_cash(r) {
  return require(["../views/love/integral_love/Integral_love_cash"], r);
};
var Integral_change = function Integral_change(r) {
  return require(["../views/love/integral_love/Integral_love_change"], r);
};

//  爱心币，信用值
var CreditValeuIndex = function CreditValeuIndex(r) {
  return require(["../views/love/credit_value/credit_value_index"], r);
};
var CreditRecharge = function CreditRecharge(r) {
  return require(["../views/love/credit_value/recharge.vue"], r);
};
var CreditGive = function CreditGive(r) {
  return require(["../views/love/credit_value/give.vue"], r);
};
var ActivateHistory = function ActivateHistory(r) {
  return require(["../views/love/credit_value/activate_history.vue"], r);
};
var GiveHistory = function GiveHistory(r) {
  return require(["../views/love/credit_value/give_history.vue"], r);
};
var ViewDetails = function ViewDetails(r) {
  return require(["../views/love/credit_value/view_details.vue"], r);
};

// tool
var Footprint = function Footprint(r) {
  return require(["../views/member/tool/footprint"], r);
};
var Collection = function Collection(r) {
  return require(["../views/member/tool/collection"], r);
};
var MyRelationship = function MyRelationship(r) {
  return require(["../views/member/tool/my_relationship_v2"], r);
};
var MyRelationshipAmount = function MyRelationshipAmount(r) {
  return require(["../views/member/tool/my_relationship_amount"], r);
};
var MyRelationshipDetail = function MyRelationshipDetail(r) {
  return require(["../views/member/tool/my_relationship_detail"], r);
};
var OfflineSearch = function OfflineSearch(r) {
  return require(["../views/member/tool/search"], r);
};
var MyEvaluation = function MyEvaluation(r) {
  return require(["../views/comment/myEvaluation"], r);
};
var Comment = function Comment(r) {
  return require(["../views/member/tool/comment"], r);
};
var EvaluationDetails = function EvaluationDetails(r) {
  return require(["../views/member/tool/evaluationDetails"], r);
};
// 我的朋友
var MyFriendApply = function MyFriendApply(r) {
  return require(["../views/member/tool/my_friends"], r);
};

// 兑换中心
var CouponExchange = function CouponExchange(r) {
  return require(["../views/member/coupon_exchange/index"], r);
};

// Other
var Address = function Address(r) {
  return require(["../views/member/address/addresslist_v2"], r);
};
var Search = function Search(r) {
  return require(["../views/search"], r);
};
var SearchAll = function SearchAll(r) {
  return require(["../views/searchall"], r);
};
// const Goods = r => require(['../views/goods/goods'], r)
var Goods = function Goods(r) {
  return require(["../views/goods/goods_v2"], r);
};
var GoodsComment = function GoodsComment(r) {
  return require(["../components/goods/children/commentList"], r);
};
var PackageGoods = function PackageGoods(r) {
  return require(["../views/goods/package_goods"], r);
};
var GoodsO2O = function GoodsO2O(r) {
  return require(["../views/goods/goods_v2"], r);
};
var GoodsOrder = function GoodsOrder(r) {
  return require(["../views/goods/goods_order"], r);
};
var GoodsOrderDiyForm = function GoodsOrderDiyForm(r) {
  return require(["../views/goods/diyFormPage"], r);
};
var Goodstabs = function Goodstabs(r) {
  return require(["../views/goods/goodstabs"], r);
};
// const Register = r => require(["../views/register/register_v2"], r);
var RegisterAgreement = function RegisterAgreement(r) {
  return require(["../views/register/register_agreement"], r);
};
// const Findpwd = r => require(["../views/register/findpwd"], r);
var RegisterGuide = function RegisterGuide(r) {
  return require(["../views/register/guide"], r);
};
var NotFound = function NotFound(r) {
  return require(["../views/notfound"], r);
};
// alipay_detail.vue
var Alipay = function Alipay(r) {
  return require(["../views/member/pay/alipay_detail"], r);
};
var AlipayClockin = function AlipayClockin(r) {
  return require(["../views/member/pay/alipay_detail"], r);
};

//微信支付中间页
var Wxpay = function Wxpay(r) {
  return require(["../views/member/pay/wxpay_detail"], r);
};

//选品专辑
var bountique_album = function bountique_album(r) {
  return require(["../views/boutique_album/index.vue"], r);
};

// Coupon
var Coupon = function Coupon(r) {
  return require(["../views/coupon/coupon_index"], r);
};
var CouponStore = function CouponStore(r) {
  return require(["../views/coupon/coupon_store"], r);
};
var CouponIndependent = function CouponIndependent(r) {
  return require(["../views/coupon/coupon_independent_share"], r);
};
var CouponInfo = function CouponInfo(r) {
  return require(["../views/coupon/coupon_info"], r);
};
var CouponShareCode = function CouponShareCode(r) {
  return require(["../views/coupon/coupon_share_code"], r);
};
var CouponShareShow = function CouponShareShow(r) {
  return require(["../views/coupon/shareShow"], r);
};
var CouponShareGet = function CouponShareGet(r) {
  return require(["../views/coupon/shareGet"], r);
};
var CouponCheckList = function CouponCheckList(r) {
  return require(["../views/coupon/checkList"], r);
};

// Coupon-share
var CouponShare = function CouponShare(r) {
  return require(["../views/coupon/coupon_share"], r);
};
var getCoupon = function getCoupon(r) {
  return require(["../views/coupon/get_coupon"], r);
};
// Coupon_details
var coupon_v2_detail = function coupon_v2_detail(r) {
  return require(["../views/coupon/coupon_v2_detail"], r);
};

// comment
var CommentDetails = function CommentDetails(r) {
  return require(["../views/comment/CommentDetails"], r);
};
var ReCommentDetails = function ReCommentDetails(r) {
  return require(["../views/comment/ReCommentDetails"], r);
};
var OtherCommentDetails = function OtherCommentDetails(r) {
  return require(["../views/comment/OtherCommentDetails"], r);
};

// service

//代理商分红
var AgentBonus = function AgentBonus(r) {
  return require(["../views/extension/agency-bonus/agent_bonus"], r);
};
var GroupOrder = function GroupOrder(r) {
  return require(["../views/extension/agency-bonus/group_orders"], r);
};
var AgentCustomer = function AgentCustomer(r) {
  return require(["../views/extension/agency-bonus/my_customer"], r);
};

// Enterpr
var EnterpriseIndex = function EnterpriseIndex(r) {
  return require(["../views/member/enterprise/enterprise_index"], r);
};
var EnterpriseApply = function EnterpriseApply(r) {
  return require(["../views/member/enterprise/enterprose_apply"], r);
};
var EnterpriseSupplier = function EnterpriseSupplier(r) {
  return require(["../views/member/enterprise/enterprise_supplier"], r);
};
var EnterpriseSupplierInfo = function EnterpriseSupplierInfo(r) {
  return require(["../views/member/enterprise/enterprise_supplier_info"], r);
};
var EnterpriseCenterApply = function EnterpriseCenterApply(r) {
  return require(["../views/member/enterprise/enterprose_center_apply"], r);
};
var EnterpriseCenterSupplier = function EnterpriseCenterSupplier(r) {
  return require(["../views/member/enterprise/enterprise_center_supplier"], r);
};
//招商-我的门店
var EnterpriseStore = function EnterpriseStore(r) {
  return require(["../views/member/enterprise/enterprise_store"], r);
};

// 积分商城中转页
var integral_shop = function integral_shop(r) {
  return require(["../views/others/integral_shop"], r);
};

// 订单全返
var SingleReturnCenter = function SingleReturnCenter(r) {
  return require(["../views/member/income/singleReturn/single_return_center"], r);
};
var SingleReturnListInfo = function SingleReturnListInfo(r) {
  return require(["../views/member/income/singleReturn/single_return_list_info"], r);
};

// 团队等级全返
var TeamLevelReturnCenter = function TeamLevelReturnCenter(r) {
  return require(["../views/member/income/teamLevelReturn/teamlevel_return_center"], r);
};
var TeamLevelReturnListInfo = function TeamLevelReturnListInfo(r) {
  return require(["../views/member/income/teamLevelReturn/teamlevel_return_list_info"], r);
};

// 满额全返
var FullReturnCenter = function FullReturnCenter(r) {
  return require(["../views/member/income/fullReturn/full_return_center"], r);
};
var FullReturnListInfo = function FullReturnListInfo(r) {
  return require(["../views/member/income/fullReturn/full_return_list_info"], r);
};
var full_return_center_new_record = function full_return_center_new_record(r) {
  return require(["../views/member/income/fullReturn/full_return_center_new_record.vue"], r);
};
// 股东分红
var ShareholderDividend = function ShareholderDividend(r) {
  return require(["../views/member/income/shareholderDividend/shareholder_dividend"], r);
};
// 股东奖励
var ShareholderReward = function ShareholderReward(r) {
  return require(["../views/member/income/ShareholderReward/Shareholder_reward"], r);
};

// 分销管理奖励
var DistributionHome = function DistributionHome(r) {
  return require(["../views/member/income/distributionIncentive/distributionHome"], r);
};
// 分销管理奖励详情
var DistributionDetail = function DistributionDetail(r) {
  return require(["../views/member/income/distributionIncentive/distributionDetail"], r);
};
// 分销商分红
var LevelReturn = function LevelReturn(r) {
  return require(["../views/member/income/levelReturn/level_return"], r);
};

// 排行榜 old
// const RankingIndex = r => require(["../views/ranking/index"], r);
// const RankingListFirst = r =>
//   require(["../views/ranking/list_first_ranking"], r);
// const RankingListSecond = r =>
//   require(["../views/ranking/list_second_ranking"], r);

//排行榜 new
var RankingIndex = function RankingIndex(r) {
  return require(["../views/extension/ranking/ranking"], r);
};
// const RankingListFirst = r => require(['../views/ranking/list_first_ranking'], r);
// const RankingListSecond = r => require(['../views/ranking/list_second_ranking'], r);

// 早起打卡
var ClockPunch = function ClockPunch(r) {
  return require(["../views/clockin/clockin"], r);
};
// 早起打卡挑战规则
var ClockPunchRule = function ClockPunchRule(r) {
  return require(["../views/clockin/clockinrule"], r);
};
// 早起打卡我的战绩
var ClockPunchRecord = function ClockPunchRecord(r) {
  return require(["../views/clockin/clockinrecord"], r);
};

// 我的关系筛选
var MyRelationshipSearch = function MyRelationshipSearch(r) {
  return require(["../views/member/tool/my_relationship_v2_search"], r);
};

// o2o首页商家
var HomeSeller = function HomeSeller(r) {
  return require(["../views/o2o/home_seller"], r);
};

// o2o首页商家详情
// const HomeSellerDetail = r => require(['../views/o2o/home_seller_detail'], r)

// o2o首页商家地址
//const HomeSellerAddress = r => require(["../views/o2o/home_seller_address"], r);

// 代付
var PayAnother = function PayAnother(r) {
  return require(["../views/member/pay/pay_another"], r);
};

// 代付详情
var PayAnotherDetail = function PayAnotherDetail(r) {
  return require(["../views/member/pay/pay_another_detail"], r);
};
var MyInfo = function MyInfo(r) {
  return require(["../views/member/address/myinfo"], r);
};

// 充值码
var RechargeCode = function RechargeCode(r) {
  return require(["../views/qr_recharge/index"], r);
};

// 音频列表

var VoiceList = function VoiceList(r) {
  return require(["../views/member/course/voice/voice_list"], r);
};

// 音频详情

var VoiceDetail = function VoiceDetail(r) {
  return require(["../views/member/course/voice/voice_detail"], r);
};

// 推广申请

var ExtensionApply = function ExtensionApply(r) {
  return require(["../views/extension/extensionApple"], r);
};

// 会员等级

var MemberGradeList = function MemberGradeList(r) {
  return require(["../views/member/member_grade/member_grade_list"], r);
};

// 会员详情

var MemberGradeListDetail = function MemberGradeListDetail(r) {
  return require(["../views/member/member_grade/member_grade_detail"], r);
};

// 待退还

var OrderReturn = function OrderReturn(r) {
  return require(["../views/member/order/order_refund"], r);
};

// 待退还中

var OrderReturnCheck = function OrderReturnCheck(r) {
  return require(["../views/member/order/order_return_check"], r);
};

// 待确定

var OrderReturnSure = function OrderReturnSure(r) {
  return require(["../views/member/order/order_return_sure"], r);
};

// 租赁归还售后详情

var OrderReturnAftersalesDetail = function OrderReturnAftersalesDetail(r) {
  return require(["../views/member/order/order_return_after_detail"], r);
};

// 会员权益退款迁移

var MemberRefundsMigration = function MemberRefundsMigration(r) {
  return require(["../views/member/member_grade/member_grade_refunds_migration"], r);
};

// 商品详情品牌

var GoodsBrand = function GoodsBrand(r) {
  return require(["../views/goods/goods_brand"], r);
};

// 柜子商品详情

var CupGoods = function CupGoods(r) {
  return require(["../views/goods/goods_v2"], r);
};

//区域代理招商
var RegionalAgency = function RegionalAgency(r) {
  return require(["../views/extension/regional_agency/regional_agency"], r);
};
var MyAgency = function MyAgency(r) {
  return require(["../views/extension/regional_agency/my_agency"], r);
};

// 区域选择

var ZoneChoose = function ZoneChoose(r) {
  return require(["../views/home/home_zone"], r);
};

// 商品详情供应商店铺

var SupplierShop = function SupplierShop(r) {
  return require(["../views/member/supplier/goods_supplier"], r);
};
var SupplierShop2 = function SupplierShop2(r) {
  return require(["../views/member/supplier/goods_supplier2"], r);
};

// 供应商店铺信息
var SupplierInfo = function SupplierInfo(r) {
  return require(["../views/member/supplier/supplier_info"], r);
};
// 转账

var TransferAccount = function TransferAccount(r) {
  return require(["../views/member/pay/pay_transfer_account"], r);
};

// 供应商管理
var SupplierCenter = function SupplierCenter(r) {
  return require(["../views/member/supplier/supplier_center"], r);
};

// 供应商--信息管理页面

var SupInfoManage = function SupInfoManage(r) {
  return require(["../views/member/supplier/info_manage"], r);
};

// 排队奖励记录

var QueueRecord = function QueueRecord(r) {
  return require(["../views/extension/spread/reward_record"], r);
};

// 冻结币
var FrozenCoin = function FrozenCoin(r) {
  return require(["../views/member/frozen/frozen_coin"], r);
};
var RechargeCoin = function RechargeCoin(r) {
  return require(["../views/member/frozen/recharge_coin"], r);
};
var LookDetailsCoin = function LookDetailsCoin(r) {
  return require(["../views/member/frozen/look_details_coin"], r);
};
var PutForwardCoin = function PutForwardCoin(r) {
  return require(["../views/member/frozen/put_forward_coin"], r);
};

// 登录中专页面
var LoginValidate = function LoginValidate(r) {
  return require(["../views/login/login_validation"], r);
};

// 文章列表
var ArticleList = function ArticleList(r) {
  return require(["../views/article/articleList"], r);
};

// 供应商搜素
var SupplierSearch = function SupplierSearch(r) {
  return require(["../views/member/supplier/search"], r);
};

// 共享链
var RewardQueue = function RewardQueue(r) {
  return require(["../views/extension/share_link/reward_queue"], r);
};
var RewardDetail = function RewardDetail(r) {
  return require(["../views/extension/share_link/reward_detail"], r);
};
var RewardProcess = function RewardProcess(r) {
  return require(["../views/extension/share_link/reward_process"], r);
};
var share_chain_index = function share_chain_index(r) {
  return require(["../views/share_chain/share_chain_index"], r);
};
var share_chain_dealDetail = function share_chain_dealDetail(r) {
  return require(["../views/share_chain/share_chain_dealDetail"], r);
};

// mryt
var MRYT = function MRYT(r) {
  return require(["../views/extension/mryt/mryt"], r);
};
var SaleCommission = function SaleCommission(r) {
  return require(["../views/extension/mryt/sale_commission"], r);
};
var AwardRecord = function AwardRecord(r) {
  return require(["../views/extension/mryt/award_record"], r);
};
var AwardDetail = function AwardDetail(r) {
  return require(["../views/extension/mryt/award_detail"], r);
};
//连锁店--首页
var ChainShopIndex = function ChainShopIndex(r) {
  return require(["../views/member/chain_shop/chain_shop_index"], r);
};
var ChainShopInfo = function ChainShopInfo(r) {
  return require(["../views/member/chain_shop/chain_shop_info"], r);
};

//手动分红
var Profit = function Profit(r) {
  return require(["../views/extension/profit/Profit.vue"], r);
};

//武昌医院--会务活动
var MeetingIndex = function MeetingIndex(r) {
  return require(["../views/conference/meeting/entry_record"], r);
}; //报名记录
var MeetingIndexDetail = function MeetingIndexDetail(r) {
  return require(["../views/conference/meeting/entry_record_detail"], r);
}; //报名记录详情
var MeetingExtendList = function MeetingExtendList(r) {
  return require(["../views/conference/meeting/extend_enroll_list"], r);
}; //推广报名列表
var MeetingExtendDetail = function MeetingExtendDetail(r) {
  return require(["../views/conference/meeting/extend_enroll_detail"], r);
}; //推广报名详情

//入驻商品
var EnterShop = function EnterShop(r) {
  return require(["../views/member/enter_shop/enter_shop"], r);
};
var EnterProtocol = function EnterProtocol(r) {
  return require(["../views/member/enter_shop/enter_protocol"], r);
};
var SubmitSucceed = function SubmitSucceed(r) {
  return require(["../views/member/enter_shop/submit_succeed"], r);
};
var ApplyMerchant = function ApplyMerchant(r) {
  return require(["../views/member/enter_shop/apply_merchant"], r);
};
var TruckList = function TruckList(r) {
  return require(["../views/member/enter_shop/truck_list"], r);
};
//爱心值充值
var LoveRecharge = function LoveRecharge(r) {
  return require(["../views/love/love_recharge"], r);
};

//区间消费返点
var consumerReturnPoint = function consumerReturnPoint(r) {
  return require(["../views/extension/consumer_return_point/consumer_return_point"], r);
};
//推广中心--专区商品
var ExtensionGoods = function ExtensionGoods(r) {
  return require(["../views/extension/extension_goods"], r);
};
//团队销量管理
var salesManagement = function salesManagement(r) {
  return require(["../views/extension/teamSales/sales_management"], r);
};
//398二期：团队数据统计
var TeamDataCount = function TeamDataCount(r) {
  return require(["../views/extension/mryt/team_data_count"], r);
};
//398 一卡通
var CardIndex = function CardIndex(r) {
  return require(["../views/o2o/card/card_index"], r);
};
var CardOpenMembership = function CardOpenMembership(r) {
  return require(["../views/o2o/card/card_open_membership"], r);
};
var CardChooseMembership = function CardChooseMembership(r) {
  return require(["../views/o2o/card/card_choose_membership_time"], r);
};
var CardRight = function CardRight(r) {
  return require(["../views/o2o/card/card_rights"], r);
};
var NewCard = function NewCard(r) {
  return require(["../views/o2o/card/new_card"], r);
};
var NewCardList = function NewCardList(r) {
  return require(["../views/o2o/card/newcard_list"], r);
};
//门店优惠券
var StoreCoupon = function StoreCoupon(r) {
  return require(["../views/o2o/store/store_coupon"], r);
};

//酒店优惠券
var hotelCoupon = function hotelCoupon(r) {
  return require(["../views/hotel/hotel_coupon"], r);
};

//邀请页面
var InputVisit = function InputVisit(r) {
  return require(["../views/member/visit/inputVisit"], r);
};
var Inviter = function Inviter(r) {
  return require(["../views/member/visit/inviter"], r);
};

//发票中心
var invoiceCenter = function invoiceCenter(r) {
  return require(["../views/invoice/invoice-center"], r);
};
//发票编辑或者添加
var invoiceOperation = function invoiceOperation(r) {
  return require(["../views/invoice/invoiceOperation"], r);
};
//新发票详情
var invoiceDetails = function invoiceDetails(r) {
  return require(["../views/invoice/invoice-details"], r);
};
//发票详情
var Invoice = function Invoice(r) {
  return require(["../views/invoice/invoice"], r);
};

//酒店相关页面
var HotelIndex = function HotelIndex(r) {
  return require(["../views/hotel/index"], r);
};
var HotelSearch = function HotelSearch(r) {
  return require(["../views/hotel/search"], r);
};
var HotelHome = function HotelHome(r) {
  return require(["../views/hotel/homepage"], r);
};
var HotelChooseRoom = function HotelChooseRoom(r) {
  return require(["../views/hotel/choose_room"], r);
};
// const HotelGood= r => require(['../views/hotel/goods'], r);
var HotelGoodsOrder = function HotelGoodsOrder(r) {
  return require(["../views/hotel/goods_order"], r);
};
// const HotelOrderList = r => require(['../views/hotel/order_list'], r);
// const HotelOrderDetail = r => require(['../views/hotel/order_detail'], r);
var HotelManage = function HotelManage(r) {
  return require(["../views/hotel/manage"], r);
};

// 增加酒店供应链搜索页
var HotelSupplySearch = function HotelSupplySearch(r) {
  return require(["../views/hotelSupply/search"], r);
};
// 增加酒店供应链首页
var HotelSupplyIndex = function HotelSupplyIndex(r) {
  return require(["../views/hotelSupply/index"], r);
};
// 增加酒店供应链酒店主页
var HotelSupplyHome = function HotelSupplyHome(r) {
  return require(["../views/hotelSupply/homepage"], r);
};
// 增加酒店供应链酒店房型详情页
var hotelSupplyGoods = function hotelSupplyGoods(r) {
  return require(["../views/hotelSupply/hotelSupplyGoods"], r);
};
// 增加酒店供应链酒店房型下单页
var HotelSupplyGoodsOrder = function HotelSupplyGoodsOrder(r) {
  return require(["../views/hotelSupply/goods_order"], r);
};
// 增加酒店供应酒店详情页
var hotelOrder = function hotelOrder(r) {
  return require(["../views/hotelSupply/hotelOrder"], r);
};
//营业额
var Performance = function Performance(r) {
  return require(["../views/extension/performance/Performance"], r);
};

//合伙人团队
var PartnershipTeam = function PartnershipTeam(r) {
  return require(["../views/extension/team_recorded/partnership_team"], r);
};
var ManagementRecord = function ManagementRecord(r) {
  return require(["../views/extension/team_recorded/management_record"], r);
};
var TaskList = function TaskList(r) {
  return require(["../views/extension/team_recorded/task_list"], r);
};

//消费红包
var ConsumePacket = function ConsumePacket(r) {
  return require(["../views/extension/consume_packet/index"], r);
};
var ConsumePacketSuccess = function ConsumePacketSuccess(r) {
  return require(["../views/extension/consume_packet/success"], r);
};
//团队分销商统计
var TeamDistribution = function TeamDistribution(r) {
  return require(["../views/member/team_distribution/team_distribution"], r);
};

//聚合/易合支付 Bank card settlement
var AggregateIndReg = function AggregateIndReg(r) {
  return require(["../views/member/aggregate_payment/aggregate_ind_reg"], r);
};
var AggregateIndRegEdit = function AggregateIndRegEdit(r) {
  return require(["../views/member/aggregate_payment/aggregate_ind_reg_edit"], r);
};
var AggregateMeReg = function AggregateMeReg(r) {
  return require(["../views/member/aggregate_payment/aggregate_mer_reg"], r);
};
var AggregateMeRegEdit = function AggregateMeRegEdit(r) {
  return require(["../views/member/aggregate_payment/aggregate_mer_reg_edit"], r);
};
var AggregateEntReg = function AggregateEntReg(r) {
  return require(["../views/member/aggregate_payment/aggregate_ent_reg"], r);
};
var AggregateEntRegEdit = function AggregateEntRegEdit(r) {
  return require(["../views/member/aggregate_payment/aggregate_ent_reg_edit"], r);
};
var AggregateBankCardSet = function AggregateBankCardSet(r) {
  return require(["../views/member/aggregate_payment/aggregate_bank_card_set"], r);
};
var AggregateAuditPart = function AggregateAuditPart(r) {
  return require(["../views/member/aggregate_payment/aggregate_audit_part"], r);
};
var CompanyEdit = function CompanyEdit(r) {
  return require(["../views/member/aggregate_payment/company_edit"], r);
};
var IndividualEdit = function IndividualEdit(r) {
  return require(["../views/member/aggregate_payment/individual_edit"], r);
};
var ShowApply = function ShowApply(r) {
  return require(["../views/member/aggregate_payment/show_apply"], r);
};

//口令红包
var redEnvelope = function redEnvelope(r) {
  return require(["../views/member/redEnvelope/redEnvelope"], r);
};
//口令红包 邀请记录
var redEnvelopeRecord = function redEnvelopeRecord(r) {
  return require(["../views/member/redEnvelope/record"], r);
};

//挂单
var EntryExchange = function EntryExchange(r) {
  return require(["../views/extension/entry_orders/coupon_exchange"], r);
};
var EntryVolume = function EntryVolume(r) {
  return require(["../views/extension/entry_orders/entry_volume"], r);
};
var MoneyDetail = function MoneyDetail(r) {
  return require(["../views/extension/entry_orders/money_detail"], r);
};
var MyVolume = function MyVolume(r) {
  return require(["../views/extension/entry_orders/my_volume"], r);
};
var VolumeDetail = function VolumeDetail(r) {
  return require(["../views/extension/entry_orders/volume_detail"], r);
};
var WholesaleVolume = function WholesaleVolume(r) {
  return require(["../views/extension/entry_orders/wholesale_volume"], r);
};
var paddingOrder_Home = function paddingOrder_Home(r) {
  return require(["../views/extension/entry_orders/paddingOrder_Home"], r);
};
var CouponExrecord = function CouponExrecord(r) {
  return require(["../views/extension/entry_orders/coupon_exrecord"], r);
};

// 活动聚合页
var businessActivity = function businessActivity(r) {
  return require(["../views/Activity/Activity"], r);
};
// 活动聚合页
var ActivityDetail = function ActivityDetail(r) {
  return require(["../views/Activity/activity_detail"], r);
};
// 素材中心
var materialCenter = function materialCenter(r) {
  return require(["../views/others/material_center"], r);
};
var materialDetail = function materialDetail(r) {
  return require(["../views/others/materialDetail"], r);
};
var materialSearch = function materialSearch(r) {
  return require(["../views/others/material_search"], r);
};
//虚拟币
var addressManage = function addressManage(r) {
  return require(["../views/others/virtual/address_manage"], r);
};
var addressAdd = function addressAdd(r) {
  return require(["../views/others/virtual/address_add"], r);
};
var reviseAddress = function reviseAddress(r) {
  return require(["../views/others/virtual/revise_address"], r);
};
var handWithdraw = function handWithdraw(r) {
  return require(["../views/others/virtual/hand_withdraw"], r);
};
// 经销商奖励
var DealerAward = function DealerAward(r) {
  return require(["../views/extension/dealer_award/dealer_award"], r);
};
var DriverDetail = function DriverDetail(r) {
  return require(["../views/extension/dealer_award/dealer_detail"], r);
};
var WeightedDividend = function WeightedDividend(r) {
  return require(["../views/extension/dealer_award/WeightedDividend"], r);
};
//经销商平级奖（七件事平级奖开发）
var DealerPeerAward = function DealerPeerAward(r) {
  return require(["../views/extension/dealer_award/peerAward"], r);
};
var peerAwardes_withdraw = function peerAwardes_withdraw(r) {
  return require(["../views/extension/dealer_award/peerAwardes_withdraw"], r);
};

//团队管理奖
var TeamManageReward = function TeamManageReward(r) {
  return require(["../views/extension/team_manage_reward/rewards_record"], r);
};
var Lottery = function Lottery(r) {
  return require(["../views/others/lottery"], r);
};
var LotteryRecord = function LotteryRecord(r) {
  return require(["../views/others/lottery_record"], r);
};
// 分销订单统计
var DistributionOrders = function DistributionOrders(r) {
  return require(["../views/member/distribution_orders/distribution_orders"], r);
};
var ServiceStation = function ServiceStation(r) {
  return require(["../views/serviceStation/index"], r);
}; //服务站

var CommissionList = function CommissionList(r) {
  return require(["../views/serviceStation/commissionList"], r);
}; //提成明细

// 自定义队列
var CustomQueue = function CustomQueue(r) {
  return require(["../views/extension/custom_queue/custom_queue"], r);
};

//权益商品
var RightGood = function RightGood(r) {
  return require(["../views/member/member_grade/right_good"], r);
};
//保单
var policyManage = function policyManage(r) {
  return require(["../views/others/policy/policy_manage"], r);
};
// 没用到
// const policyAdd = r => require(['../views/others/policy/policy_add'], r);

//小视频
var myVideo = function myVideo(r) {
  return require(["../views/video_goods/little_video/my_video"], r);
};
var VideoList = function VideoList(r) {
  return require(["../views/video_goods/little_video/video_list"], r);
};
var VideoDetail = function VideoDetail(r) {
  return require(["../views/video_goods/little_video/video_detail"], r);
};
var videoAgreement = function videoAgreement(r) {
  return require(["../views/video_goods/video_agreement"], r);
}; //小视频的用户协议，非协议内容
var releaseVideo = function releaseVideo(r) {
  return require(["../views/video_goods/release_video"], r);
}; //小视频的用户协议，非协议内容
var addVideoGood = function addVideoGood(r) {
  return require(["../views/video_goods/video_good"], r);
}; //选择添加视频上商品
var VideoReward = function VideoReward(r) {
  return require(["../views/video_goods/video_reward"], r);
}; //商品奖励记录

//团队业绩奖励
var TeamPerformanceAward = function TeamPerformanceAward(r) {
  return require(["../views/extension/team_performance_award/index"], r);
};

//广告中心
var advertiser = function advertiser(r) {
  return require(["../views/advertising_market/advertiser"], r);
}; //广告主
var addAdvertising = function addAdvertising(r) {
  return require(["../views/advertising_market/add_advertising"], r);
}; //添加广告
var advertisingFee = function advertisingFee(r) {
  return require(["../views/advertising_market/advertising_fee"], r);
}; //广告费列表
var placedAdvertising = function placedAdvertising(r) {
  return require(["../views/advertising_market/placed_advertising"], r);
}; //投放的广告

// 电子合同
var signIndex = function signIndex(r) {
  return require(["../views/others/sign/index"], r);
};
var signBusiness = function signBusiness(r) {
  return require(["../views/others/sign/sign_business"], r);
};
var signPerson = function signPerson(r) {
  return require(["../views/others/sign/sign_person"], r);
};
//const signGuide = r => require(["../views/others/sign/guide"], r);
var personBank = function personBank(r) {
  return require(["../views/others/sign/person_bank"], r);
};
var identifyResult = function identifyResult(r) {
  return require(["../views/others/sign/identify_result"], r);
};
var faceResult = function faceResult(r) {
  return require(["../views/others/sign/face_result"], r);
};
var signCompany = function signCompany(r) {
  return require(["../views/others/sign/company"], r);
};
var contractBills = function contractBills(r) {
  return require(["../views/others/sign/contract_bills"], r);
};
var staffManagement = function staffManagement(r) {
  return require(["../views/others/sign/staff_management"], r);
};
var addStaff = function addStaff(r) {
  return require(["../views/others/sign/add_staff"], r);
};
var contractList = function contractList(r) {
  return require(["../views/others/sign/contract_list"], r);
};
var companyContractList = function companyContractList(r) {
  return require(["../views/others/sign/company_contract_list"], r);
};
var signMission = function signMission(r) {
  return require(["../views/others/sign/mission"], r);
};
var signPage = function signPage(r) {
  return require(["../views/others/sign/sign_page"], r);
};
//电子合同优化页面
var signSeeTemplate = function signSeeTemplate(r) {
  return require(["../views/others/sign/sign_see_template"], r);
};
var launchSign = function launchSign(r) {
  return require(["../views/others/sign/launch_sign"], r);
};
var contractFilling = function contractFilling(r) {
  return require(["../views/others/sign/contract_filling"], r);
};

// 电子合同插件
var signIndexPlu = function signIndexPlu(r) {
  return require(["../views/others/sign_plugin/index"], r);
};
var personBankPlu = function personBankPlu(r) {
  return require(["../views/others/sign_plugin/person_bank"], r);
};
var identifyResultPlu = function identifyResultPlu(r) {
  return require(["../views/others/sign_plugin/identify_result"], r);
};
var signCompanyPlu = function signCompanyPlu(r) {
  return require(["../views/others/sign_plugin/company"], r);
};
var contractListPlu = function contractListPlu(r) {
  return require(["../views/others/sign_plugin/contract_list"], r);
};
var signMissionPlu = function signMissionPlu(r) {
  return require(["../views/others/sign_plugin/mission"], r);
};
var signPagePlu = function signPagePlu(r) {
  return require(["../views/others/sign_plugin/sign_page"], r);
};

// 客服
//const chatIndex = r => require(['../views/others/chat/chat'], r)
//const employeeChat = r => require(['../views/others/chat/employeeChat'], r)
var chatList = function chatList(r) {
  return require(["../views/others/chat/chat_list"], r);
};
var chatWindow = function chatWindow(r) {
  return require(["../views/others/chat/chatWindow"], r);
};
var PersonalProfile = function PersonalProfile(r) {
  return require(["../views/others/chat/yun_customer_service/personalProfile"], r);
};

// 知识库
var knowledgeIndex = function knowledgeIndex(r) {
  return require(["../views/article/knowledge/knowledge_index"], r);
};
var knowledgeWhole = function knowledgeWhole(r) {
  return require(["../views/article/knowledge/knowledge_whole"], r);
};
var knowledgeList = function knowledgeList(r) {
  return require(["../views/article/knowledge/knowledge_list"], r);
};
var knowledgeSearch = function knowledgeSearch(r) {
  return require(["../views/article/knowledge/knowledge_search"], r);
};
var knowledgeDetail = function knowledgeDetail(r) {
  return require(["../views/article/knowledge/knowledge_detail"], r);
};

//签到认购
var SignInSubscribe = function SignInSubscribe(r) {
  return require(["../views/sigin_in_subscribe/index"], r);
};
var SignInSubscribeTrading = function SignInSubscribeTrading(r) {
  return require(["../views/sigin_in_subscribe/trading"], r);
};
//签到认购-app
var SiginInApp = function SiginInApp(r) {
  return require(['../views/sigin_in_subscribe/app/home.vue'], r);
};

//微社区
var MicroHome = function MicroHome(r) {
  return require(["../views/micro_communities/home"], r);
};
var MicroIndex = function MicroIndex(r) {
  return require(["../views/micro_communities/microIndex"], r);
};
var MicroRelease = function MicroRelease(r) {
  return require(["../views/micro_communities/microRelease"], r);
};
var Microsearchindex = function Microsearchindex(r) {
  return require(["../views/micro_communities/microsearchindex"], r);
};
var Microclassification = function Microclassification(r) {
  return require(["../views/micro_communities/microclassification"], r);
};
var Microcommentdetails = function Microcommentdetails(r) {
  return require(["../views/micro_communities/microcommentdetails"], r);
};
var microhomepage = function microhomepage(r) {
  return require(["../views/micro_communities/microhomepage"], r);
};
var MicroEdit = function MicroEdit(r) {
  return require(["../views/micro_communities/microEdit"], r);
};
var microAttention = function microAttention(r) {
  return require(["../views/micro_communities/micro_attention"], r);
};
var microFanlist = function microFanlist(r) {
  return require(["../views/micro_communities/microFanlist"], r);
};
//历史拼团
var historyGroup = function historyGroup(r) {
  return require(["../views/others/history_group/index"], r);
};
//拼团
var GroupDetail = function GroupDetail(r) {
  return require(["../views/goods/group/group_detail"], r);
};
var GroupList = function GroupList(r) {
  return require(["../views/goods/group/group_list"], r);
};
// 珍惠拼 财务页面
var zhpFinance = function zhpFinance(r) {
  return require(["../views/goods/group/zhpFinance"], r);
};
// const GroupGoods = r => require(["../components/goods/group_good"], r); 没用
var MyGroups = function MyGroups(r) {
  return require(["../views/goods/group/mygroups"], r);
};
// 新拼团
var groupContribution = function groupContribution(r) {
  return require(["../views/goods/group/newGroup/contribution"], r);
};
var groupContributionDetails = function groupContributionDetails(r) {
  return require(["../views/goods/group/newGroup/contributionDetail"], r);
};
var groupContributionDetailslog = function groupContributionDetailslog(r) {
  return require(["../views/goods/group/newGroup/groupContributionDetailslog"], r);
};
var groupcblog = function groupcblog(r) {
  return require(["../views/goods/group/newGroup/groupcblog"], r);
};
var pastLog = function pastLog(r) {
  return require(["../views/goods/group/newGroup/pastLog"], r);
};
var groupRedeem = function groupRedeem(r) {
  return require(["../views/goods/group/newGroup/redeem"], r);
};
var zhpgroupDetail = function zhpgroupDetail(r) {
  return require(["../views/goods/group/newGroup/zhpgroupDetail"], r);
};
var redeemLog = function redeemLog(r) {
  return require(["../views/goods/group/newGroup/redeemLog"], r);
};
//拼购（和拼团不一样）
var GroupBuyList = function GroupBuyList(r) {
  return require(["../views/goods/group_buy/group_buy_list"], r);
};
var myGroupBuy = function myGroupBuy(r) {
  return require(["../views/goods/group_buy/my_group_buy"], r);
};
var groupBuyDetail = function groupBuyDetail(r) {
  return require(["../views/goods/group_buy/group_buy_detail"], r);
};
// const groupBuyGoodslist = r => require(['../views/goods/group_buy/group_buy_goodslist'], r); 不用
var groupBuy = function groupBuy(r) {
  return require(["../components/goods/group_buy"], r);
};

// 打包一口价
var fixedPrice = function fixedPrice(r) {
  return require(["../views/others/fixedPrice"], r);
};

//会务活动
var MyActivity = function MyActivity(r) {
  return require(["../views/Conference_activities/my_activities/my_index"], r);
};
var Mylove = function Mylove(r) {
  return require(["../views/Conference_activities/my_activities/my_love"], r);
};
var Registration = function Registration(r) {
  return require(["../views/Conference_activities/Registration_status"], r);
};
var RegistrationDetail = function RegistrationDetail(r) {
  return require(["../views/Conference_activities/my_activities/Registration_detail"], r);
};
var Myshaky = function Myshaky(r) {
  return require(["../views/Conference_activities/my_activities/my_shaky"], r);
};
var lecturerDetail = function lecturerDetail(r) {
  return require(["../views/Conference_activities/lecturer_detail"], r);
};
var enroll = function enroll(r) {
  return require(["../views/Conference_activities/enroll"], r);
};
var activityHome = function activityHome(r) {
  return require(["../views/Conference_activities/activity_home"], r);
};
var enrollEnter = function enrollEnter(r) {
  return require(["../views/Conference_activities/enroll_enter"], r);
};
var searchResult = function searchResult(r) {
  return require(["../views/Conference_activities/search_result"], r);
};
var signIn = function signIn(r) {
  return require(["../views/Conference_activities/sign_in"], r);
};
var Sort = function Sort(r) {
  return require(["../views/Conference_activities/sort"], r);
};

//广告市场
var admarketHome = function admarketHome(r) {
  return require(["../views/member/adMarket/home"], r);
};
var MyDevice = function MyDevice(r) {
  return require(["../views/member/adMarket/myDevice"], r);
};
var DeviceManger = function DeviceManger(r) {
  return require(["../views/member/adMarket/deviceManger"], r);
};
var ADApply = function ADApply(r) {
  return require(["../views/member/adMarket/AD_Apply"], r);
};
var DeviceDetail = function DeviceDetail(r) {
  return require(["../views/member/adMarket/deviceDetail"], r);
};
var managerCommission = function managerCommission(r) {
  return require(["../views/member/adMarket/managerCommission"], r);
};

//语音商品
var VoiceGood = function VoiceGood(r) {
  return require(["../views/goods/voice_good/good_detail"], r);
};
var MyVoices = function MyVoices(r) {
  return require(["../views/goods/voice_good/my_voices"], r);
};
var Payrecharge = function Payrecharge(r) {
  return require(["../views/member/payrecharge/payrecharge"], r);
};
// 第三方支付汇聚
var BankCardInformation = function BankCardInformation(r) {
  return require(["../views/sanpay/bankcardinformation"], r);
};
var BankCardinEdit = function BankCardinEdit(r) {
  return require(["../views/sanpay/bankcardinedit"], r);
};
var BankCardinAdd = function BankCardinAdd(r) {
  return require(["../views/sanpay/bankcardinadd"], r);
};
// 归还详情
var OrderRuturnInfo = function OrderRuturnInfo(r) {
  return require(["../views/member/order/orderInfo/order_ruturn_info"], r);
};
// 调查表
var survey = function survey(r) {
  return require(["../views/others/survey"], r);
};
//柯怡 管理奖
var ManageAward = function ManageAward(r) {
  return require(["../views/extension/manage_share/manage_award"], r);
};
//柯怡 分享奖
var ShareAward = function ShareAward(r) {
  return require(["../views/extension/manage_share/share_award"], r);
};
//平级奖
var PeersAward = function PeersAward(r) {
  return require(["../views/extension/peers_award/peers_award"], r);
};
var SelfCarryApply = function SelfCarryApply(r) {
  return require(["../views/member/self_carry/selfCarry_apply"], r);
};
//杨朗 自提点列表
var SelfCarry_info = function SelfCarry_info(r) {
  return require(["../views/member/self_carry/selfCarry_info"], r);
};
//杨朗 自提点详情
var selfCarry_reward = function selfCarry_reward(r) {
  return require(["../views/member/self_carry/selfCarry_reward"], r);
};
var Bonus = function Bonus(r) {
  return require(["../views/others/bonus"], r);
};
//收益奖
var revenus_bonus = function revenus_bonus(r) {
  return require(["../views/others/revenus_bonus"], r);
};

//直荐奖
var recommend_reward = function recommend_reward(r) {
  return require(["../views/extension/recommend_reward/index"], r);
};
//分享奖
var share_reward = function share_reward(r) {
  return require(["../views/extension/share_reward/index"], r);
};

// 预约
var Appointment = function Appointment(r) {
  return require(["../views/appointment/appointment_index"], r);
};
var showAppointment = function showAppointment(r) {
  return require(["../views/appointment/showAppointment"], r);
};
var AppointmentSearch = function AppointmentSearch(r) {
  return require(["../views/appointment/search"], r);
};
var ProjectDetails = function ProjectDetails(r) {
  return require(["../views/appointment/project_details"], r);
};
var ProjectDetailsStoreRecord = function ProjectDetailsStoreRecord(r) {
  return require(["../views/appointment/ProjectDetailsStoreRecord"], r);
};
var ProjectList = function ProjectList(r) {
  return require(["../views/appointment/project_list"], r);
};
var TechnicianDetails = function TechnicianDetails(r) {
  return require(["../views/appointment/technician_details"], r);
};
var HotTechnician = function HotTechnician(r) {
  return require(["../views/appointment/hot_technician"], r);
};
var AppointmentMine = function AppointmentMine(r) {
  return require(["../views/appointment/mine"], r);
};
var TechnicianTime = function TechnicianTime(r) {
  return require(["../views/appointment/technician/add_work_time"], r);
};
var TechnicianWork = function TechnicianWork(r) {
  return require(["../views/appointment/technician/work_day"], r);
};
var AppointmentProject = function AppointmentProject(r) {
  return require(["../views/appointment/technician/appointment_project"], r);
};
var AddTechnician = function AddTechnician(r) {
  return require(["../views/appointment/manager/add_technician"], r);
};
var StoresTechnician = function StoresTechnician(r) {
  return require(["../views/appointment/manager/stores_technician"], r);
};
var ClientAppointment = function ClientAppointment(r) {
  return require(["../views/appointment/client/appointment"], r);
};
var ClientEvaluate = function ClientEvaluate(r) {
  return require(["../views/appointment/client/evaluate"], r);
};
var MakeAppointment = function MakeAppointment(r) {
  return require(["../views/appointment/client/make_appointment"], r);
};
var ClientProject = function ClientProject(r) {
  return require(["../views/appointment/client/project"], r);
};
var ClientVerification = function ClientVerification(r) {
  return require(["../views/appointment/client/verification"], r);
};
var AppointmentExtends = function AppointmentExtends(r) {
  return require(["../views/appointment/appointment_extends"], r);
};

// 分销队列
var DistributionIndex = function DistributionIndex(r) {
  return require(["../views/others/distribution_alignment/index"], r);
};
var DistributionSearch = function DistributionSearch(r) {
  return require(["../views/others/distribution_alignment/search"], r);
};
var DistributionStatistics = function DistributionStatistics(r) {
  return require(["../views/others/distribution_alignment/activity_statistics"], r);
};
var DistributionActivityDetail = function DistributionActivityDetail(r) {
  return require(["../views/others/distribution_alignment/activity_detail"], r);
};
var DistributionCommissionDetail = function DistributionCommissionDetail(r) {
  return require(["../views/others/distribution_alignment/commission_detail"], r);
};
var DistributionParticipant = function DistributionParticipant(r) {
  return require(["../views/others/distribution_alignment/participant"], r);
};
// const DistributionGoods = r => require(["../views/others/distribution_alignment/goods"], r);

// 多包裹
var multiplePackages = function multiplePackages(r) {
  return require(["../views/member/order/multiple_packages"], r);
};

// 宣传单
var advertisingPage = function advertisingPage(r) {
  return require(["../views/others/advertisingPage"], r);
};
//门店附近商品
var introduceNear = function introduceNear(r) {
  return require(["../views/o2o/introduce_good/introduce_near"], r);
};
var nearSearch = function nearSearch(r) {
  return require(["../views/o2o/introduce_good/near_search"], r);
};
var addNear = function addNear(r) {
  return require(["../views/o2o/introduce_good/add_near"], r);
};

//时间轴
var TimerShaft = function TimerShaft(r) {
  return require(["../views/timer_shaft/index.vue"], r);
};

//拍卖
var auctioneer = function auctioneer(r) {
  return require(["../views/auction/auctioneer-manage/auctioneer"], r);
};
var auctionCollect = function auctionCollect(r) {
  return require(["../views/auction/auction-mine/auction_collect"], r);
};
var myIndex = function myIndex(r) {
  return require(["../views/auction/auction-mine/my_index"], r);
};
var myAuction = function myAuction(r) {
  return require(["../views/auction/auction-mine/my_auction"], r);
};
var myMoney = function myMoney(r) {
  return require(["../views/auction/auctioneer-manage/my_money"], r);
};
var passwordChange = function passwordChange(r) {
  return require(["../views/auction/auctioneer-manage/password_change"], r);
};
var setPassword = function setPassword(r) {
  return require(["../views/auction/auctioneer-manage/set_password"], r);
};
var shopShare = function shopShare(r) {
  return require(["../views/auction/auctioneer-manage/shop_share"], r);
};
var goodManage = function goodManage(r) {
  return require(["../views/auction/auctioneer-manage/good_manage"], r);
};
var publishFirst = function publishFirst(r) {
  return require(["../views/auction/auctioneer-publish/publish_first"], r);
};
var publishSecond = function publishSecond(r) {
  return require(["../views/auction/auctioneer-publish/publish_second"], r);
};
var auctionIndex = function auctionIndex(r) {
  return require(["../views/auction/auction_index"], r);
};
var auctionMore = function auctionMore(r) {
  return require(["../views/auction/auction_more"], r);
};
var auctionSearch = function auctionSearch(r) {
  return require(["../views/auction/auction_search"], r);
};
var auctionVip = function auctionVip(r) {
  return require(["../views/auction/auction_vip"], r);
};
var auctionApply = function auctionApply(r) {
  return require(["../views/auction/auction-apply/auction_apply"], r);
};
var auctionGood = function auctionGood(r) {
  return require(["../views/auction/auction_good"], r);
};
var auctionRecord = function auctionRecord(r) {
  return require(["../views/auction/auction_record"], r);
};
var auctioneerShop = function auctioneerShop(r) {
  return require(["../views/auction/auctioneer-shop/auctioneer_shop"], r);
};
var auctioneerShopSearch = function auctioneerShopSearch(r) {
  return require(["../views/auction/auctioneer-shop/auctioneer_shop_search"], r);
};
var auctionLove = function auctionLove(r) {
  return require(["../views/auction/auction-mine/auction_love"], r);
};
var auctionLoveDetail = function auctionLoveDetail(r) {
  return require(["../views/auction/auction-mine/auction_love_detail"], r);
};
var auctionLoveCash = function auctionLoveCash(r) {
  return require(["../views/auction/auction-mine/auction_love_cash"], r);
};
var auctionRecharge = function auctionRecharge(r) {
  return require(["../views/auction/auction-mine/auction_recharge"], r);
};
var notWithdraw = function notWithdraw(r) {
  return require(["../views/auction/auction-mine/notWithdraw"], r);
};
var auctionWithdraw = function auctionWithdraw(r) {
  return require(["../views/auction/auction-mine/auctionWithdraw"], r);
};

//const auctiongoodsorder = r => require(["../views/auction/auction-mine/auction_goods_order"], r);
//画册
var pictureAlbum = function pictureAlbum(r) {
  return require(["../views/others/picture_album/picture_album"], r);
};
//优惠券二维码
var qrCoupon = function qrCoupon(r) {
  return require(["../views/qr_coupon/qr_coupon"], r);
};

//易加油
var easyRefuel = function easyRefuel(r) {
  return require(["../views/easy-refuel/easy_refuel"], r);
};
var easySearch = function easySearch(r) {
  return require(["../views/easy-refuel/search"], r);
};
// 快捷支付
var chooseBank = function chooseBank(r) {
  return require(["../views/quick_pay/choose_bank"], r);
};
var addBankSecond = function addBankSecond(r) {
  return require(["../views/quick_pay/add_bank_second"], r);
};
var addBankFirst = function addBankFirst(r) {
  return require(["../views/quick_pay/add_bank_first"], r);
};
var bindBank = function bindBank(r) {
  return require(["../views/quick_pay/bind_bank"], r);
};
var memberchooseBank = function memberchooseBank(r) {
  return require(["../views/quick_pay/member_choose_bank"], r);
};
var memberaddBankSecond = function memberaddBankSecond(r) {
  return require(["../views/quick_pay/member_add_bank_second"], r);
};
var memberaddBankFirst = function memberaddBankFirst(r) {
  return require(["../views/quick_pay/member_add_bank_first"], r);
};
var memberbindBank = function memberbindBank(r) {
  return require(["../views/quick_pay/member_bind_bank"], r);
};
var memberRemoveBank = function memberRemoveBank(r) {
  return require(["../views/quick_pay/member_remove_bank"], r);
};
var quickIndex = function quickIndex(r) {
  return require(["../views/quick_pay/quick_index"], r);
};
//推广小助手
var extendHelper = function extendHelper(r) {
  return require(["../views/extend-helper/extend_helper"], r);
};
var extendRecord = function extendRecord(r) {
  return require(["../views/extend-helper/extend_helper_record"], r);
};
var extendExplain = function extendExplain(r) {
  return require(["../views/extend-helper/extend_explain"], r);
};
var popularAssistant = function popularAssistant(r) {
  return require(["../views/extend-helper/popular_assistant"], r);
};
// 能量舱
var powerCabin = function powerCabin(r) {
  return require(["../views/power_cabin/power_cabin"], r);
};
// 没用到
// const powerCheck = r => require(['../views/power_cabin/power_check'], r);
// const powerResult = r => require(['../views/power_cabin/power_result'], r);
var powerStore = function powerStore(r) {
  return require(["../views/power_cabin/power_store"], r);
};
var powerSell = function powerSell(r) {
  return require(["../views/power_cabin/power_sell"], r);
};
var powerMember = function powerMember(r) {
  return require(["../views/power_cabin/power_member"], r);
};
var powerEquipment = function powerEquipment(r) {
  return require(["../views/power_cabin/power_equipment"], r);
};
var powerDetail = function powerDetail(r) {
  return require(["../views/power_cabin/power_detail"], r);
};
var bindEquipment = function bindEquipment(r) {
  return require(["../views/power_cabin/bind_equipment"], r);
};
var powerNotes = function powerNotes(r) {
  return require(["../views/power_cabin/power_notes"], r);
};
var powerAddMeb = function powerAddMeb(r) {
  return require(["../views/power_cabin/power_addMeb"], r);
};
var cardDetail = function cardDetail(r) {
  return require(["../views/power_cabin/card_detail"], r);
};
var powerCancellation = function powerCancellation(r) {
  return require(["../views/power_cabin/power_cancellation"], r);
};
var Cancellation = function Cancellation(r) {
  return require(["../views/power_cabin/cancellation"], r);
};
var addFamily = function addFamily(r) {
  return require(["../views/power_cabin/add_family"], r);
};
var memberCard = function memberCard(r) {
  return require(["../views/power_cabin/member_card"], r);
};
// 津贴管理
var subsidyManage = function subsidyManage(r) {
  return require(["../views/extension/subsidy_manage/subsidy_manage"], r);
};
//团队销售佣金
var teamSell = function teamSell(r) {
  return require(["../views/extension/team_sell/team_sell"], r);
};
var teamActive = function teamActive(r) {
  return require(["../views/extension/team_sell/team_active"], r);
};
var teamCommission = function teamCommission(r) {
  return require(["../views/extension/team_sell/team_commission"], r);
};
var activeRecord = function activeRecord(r) {
  return require(["../views/extension/team_sell/active_record"], r);
};
var teamLimit = function teamLimit(r) {
  return require(["../views/extension/team_sell/team_limit"], r);
};
var predictIncome = function predictIncome(r) {
  return require(["../views/extension/team_sell/predict_income"], r);
};
// 门店股东
var storeShareholder = function storeShareholder(r) {
  return require(["../views/extension/store_shareholder/index"], r);
};
// 云仓
var cloudIndex = function cloudIndex(r) {
  return require(["../views/others/cloudwarehouse/cloud_index"], r);
};
// 提货区
var pickUpArea = function pickUpArea(r) {
  return require(["../views/others/cloudwarehouse/pickUpArea"], r);
};
// 提货详情
var pickUp = function pickUp(r) {
  return require(["../views/others/cloudwarehouse/pickUp"], r);
};
// 云仓下单页
var cloudOrder = function cloudOrder(r) {
  return require(["../views/others/cloudwarehouse/cloudOrder"], r);
};
// 提货订单
var cloudOrderList = function cloudOrderList(r) {
  return require(["../views/others/cloudwarehouse/cloudOrderList"], r);
};
// 订单详情
var cloudOrderDetail = function cloudOrderDetail(r) {
  return require(["../views/others/cloudwarehouse/cloudOrderDetail"], r);
};
// 解冻记录
var cloudRecord = function cloudRecord(r) {
  return require(["../views/others/cloudwarehouse/cloudRecord"], r);
};
// 云仓释放聚合页
var cloudRelease = function cloudRelease(r) {
  return require(["../views/others/cloudwarehouse/cloudRelease"], r);
};
// 查看详情 cloudReleaseLog
var cloudReleaseLog = function cloudReleaseLog(r) {
  return require(["../views/others/cloudwarehouse/cloudReleaseLog"], r);
};

// 余额明细 balanceDetail
var balanceDetail = function balanceDetail(r) {
  return require(["../views/others/cloudwarehouse/balanceDetail"], r);
};

// 上级奖
var superior = function superior(r) {
  return require(["../views/extension/superior"], r);
};

// 厂家店铺
var managementIndex = function managementIndex(r) {
  return require(["../views/others/managementIndex/managementIndex"], r);
};
// 厂家公告
var proclamation = function proclamation(r) {
  return require(["../views/others/managementIndex/proclamation"], r);
};
// 厂家列表
var managementLis = function managementLis(r) {
  return require(["../views/others/managementIndex/managementLis"], r);
};
// 商品详情 managementGoods
// const managementGoods = r => require(["../views/others/managementIndex/managementGoods"], r);
//微信会员卡
var wechatCard = function wechatCard(r) {
  return require(["../views/wechat_card/wechat_card"], r);
};

//全国油站
var oilStation = function oilStation(r) {
  return require(["../views/others/oil_station/oil_station"], r);
};

//提货卡
var pickUpCardIndex = function pickUpCardIndex(r) {
  return require(["../views/member/pick_up_card/pick_up_card_index"], r);
};
var pickUpCardReimburse = function pickUpCardReimburse(r) {
  return require(["../views/member/pick_up_card/pick_up_card_reimburse"], r);
};
var pickUpCardOrderList = function pickUpCardOrderList(r) {
  return require(["../views/member/pick_up_card/pick_up_card_order_list"], r);
};
var pickUpCardMy = function pickUpCardMy(r) {
  return require(["../views/member/pick_up_card/pick_up_card_my"], r);
};
var pickUpCardMyDet = function pickUpCardMyDet(r) {
  return require(["../views/member/pick_up_card/pick_up_card_my_det"], r);
};

//门店消费卡
var storeConsumeIndex = function storeConsumeIndex(r) {
  return require(["../views/o2o/store_consume/index"], r);
};
var storeConsumeStore = function storeConsumeStore(r) {
  return require(["../views/o2o/store_consume/use_store"], r);
};
var storeConsumeShow = function storeConsumeShow(r) {
  return require(["../views/o2o/store_consume/show_code"], r);
};
var storeConsumeUse = function storeConsumeUse(r) {
  return require(["../views/o2o/store_consume/use_code"], r);
};
var storeConsumeIncome = function storeConsumeIncome(r) {
  return require(["../views/o2o/store_consume/store_consume"], r);
};
var couponLog = function couponLog(r) {
  return require(["../views/o2o/card/couponLog"], r);
};
var videoQuestion = function videoQuestion(r) {
  return require(["../views/member/video_question/video_question"], r);
};
var mvideoAnswer = function mvideoAnswer(r) {
  return require(["../views/member/video_question/answer"], r);
};
var videoApply = function videoApply(r) {
  return require(["../views/member/video_question/apply"], r);
};
var videoWhtch = function videoWhtch(r) {
  return require(["../views/member/video_question/watch"], r);
};
var videoRecord = function videoRecord(r) {
  return require(["../views/member/video_question/videoRecord"], r);
};
var videoRecordList = function videoRecordList(r) {
  return require(["../views/member/video_question/videoRecordList"], r);
};

// 下级足迹
var footprintMember = function footprintMember(r) {
  return require(["../views/others/footprint/index"], r);
};
var footprintMemberDetail = function footprintMemberDetail(r) {
  return require(["../views/others/footprint/detail"], r);
};

//使用H5页面中转，再跳转到Scheme实现打开小程序
var wxSchemeTool = function wxSchemeTool(r) {
  return require(["../views/home/wxSchemeTool"], r);
};

//圈子社区
var circleIndex = function circleIndex(r) {
  return require(["../views/others/circleCommunity/circleIndex"], r);
};
//圈子搜索页
var circleSearch = function circleSearch(r) {
  return require(["../views/others/circleCommunity/circleSearch"], r);
};
//付费记录
var circlePayRecords = function circlePayRecords(r) {
  return require(["../views/others/circleCommunity/circlePayRecords"], r);
};
//创建圈子
var circleIssue = function circleIssue(r) {
  return require(["../views/others/circleCommunity/circleIssue"], r);
};
//我的设置
var circleMySet = function circleMySet(r) {
  return require(["../views/others/circleCommunity/circleMySet"], r);
};
//我的关注
var circleMyAttention = function circleMyAttention(r) {
  return require(["../views/others/circleCommunity/circleMyAttention"], r);
};
//我的发布
var circleMyRelease = function circleMyRelease(r) {
  return require(["../views/others/circleCommunity/circleMyRelease"], r);
};
//圈子详情
var circleDetails = function circleDetails(r) {
  return require(["../views/others/circleCommunity/circleDetails"], r);
};
//我的圈子
var circleMyCircle = function circleMyCircle(r) {
  return require(["../views/others/circleCommunity/circleMyCircle"], r);
};
//圈子简介
var circleIntroduce = function circleIntroduce(r) {
  return require(["../views/others/circleCommunity/circleIntroduce"], r);
};
//圈子推广中心
var circleExtend = function circleExtend(r) {
  return require(["../views/others/circleCommunity/circleExtend"], r);
};
//圈子审核列表
var circleChecklist = function circleChecklist(r) {
  return require(["../views/others/circleCommunity/circleChecklist"], r);
};
//圈子成员管理
var circleMember = function circleMember(r) {
  return require(["../views/others/circleCommunity/circleMember"], r);
};
//圈子设置
var circleSetting = function circleSetting(r) {
  return require(["../views/others/circleCommunity/circleSetting"], r);
};
//圈子选择帖子或视频
var circleSelect = function circleSelect(r) {
  return require(["../views/others/circleCommunity/circleSelect"], r);
};
//圈子发布视频
var circlereleaseVideo = function circlereleaseVideo(r) {
  return require(["../views/others/circleCommunity/circlereleaseVideo"], r);
};
//圈子发布帖子
var circleTopic = function circleTopic(r) {
  return require(["../views/others/circleCommunity/circleTopic"], r);
};
//圈子选择商品
var circleVideogood = function circleVideogood(r) {
  return require(["../views/others/circleCommunity/circleVideogood"], r);
};
//帖子详情
var topicDetails = function topicDetails(r) {
  return require(["../views/others/circleCommunity/topicDetails"], r);
};
var circleVideodetail = function circleVideodetail(r) {
  return require(["../views/others/circleCommunity/circleVideodetail"], r);
};
//圈子资料编辑
var circleEdit = function circleEdit(r) {
  return require(["../views/others/circleCommunity/circleEdit"], r);
};
//* 圈子支付费用订单页 没用
// const circlePay = r => require(["../views/others/circleCommunity/circlePay"], r);

// 淘京拼
var cpsIndex = function cpsIndex(r) {
  return require(["../views/others/cpsIndex"], r);
};
// 淘京拼详情
var cpsDetail = function cpsDetail(r) {
  return require(["../views/others/cpsDetail"], r);
};
// 淘京拼列表
var cpsList = function cpsList(r) {
  return require(["../views/others/cpsList"], r);
};
// 淘京拼列表
var cpsCard = function cpsCard(r) {
  return require(["../views/others/cpsCard"], r);
};
// 淘京拼卡密
var cpsPwd = function cpsPwd(r) {
  return require(["../views/others/cpsPwd"], r);
};
// cps推广页
var cpsExtension = function cpsExtension(r) {
  return require(["../views/others/cpsExtension"], r);
};
//销售报表
var salesTable = function salesTable(r) {
  return require(["../views/others/sales_table"], r);
};
//商品排行榜
var shopRanking = function shopRanking(r) {
  return require(["../views/others/shopRanking/index"], r);
};
var shopRankingList = function shopRankingList(r) {
  return require(["../views/others/shopRanking/list"], r);
};
// 门店拓客卡
var tokerIndex = function tokerIndex(r) {
  return require(["../views/o2o/toker/tokerIndex"], r);
};
var memberTokerCard = function memberTokerCard(r) {
  return require(["../views/o2o/toker/memberTokerCard"], r);
};
var tokerSetMeal = function tokerSetMeal(r) {
  return require(["../views/o2o/toker/tokerSetMeal"], r);
};
var tokerCode = function tokerCode(r) {
  return require(["../views/o2o/toker/tokerCode"], r);
};
var tokerMerchant = function tokerMerchant(r) {
  return require(["../views/o2o/toker/tokerMerchant"], r);
};
//* 升级码
var MemberUpgradeCode = function MemberUpgradeCode(r) {
  return require(["../views/member/upgrade_code/upgrade_code"], r);
};
var MemberUpgradeCodeGroupDetails = function MemberUpgradeCodeGroupDetails(r) {
  return require(["../views/member/upgrade_code/group_details"], r);
};
var MemberUpgradeCodeUsedLog = function MemberUpgradeCodeUsedLog(r) {
  return require(["../views/member/upgrade_code/used_log"], r);
};
var MemberUpgradeResult = function MemberUpgradeResult(r) {
  return require(["../views/member/upgrade_code/upgrade_result"], r);
};

//* 云库存
var CloudStock = function CloudStock(r) {
  return require(["../views/others/stock/index"], r);
};
var CloudStockRelatedLink = function CloudStockRelatedLink(r) {
  return require(["../views/others/stock/related_link"], r);
};
var CloudStockPromotionCenter = function CloudStockPromotionCenter(r) {
  return require(["../views/others/stock/promotion_center"], r);
};
var CloudStockGoodsGuide = function CloudStockGoodsGuide(r) {
  return require(["../views/others/stock/guide"], r);
};
var ClousStockMyInventory = function ClousStockMyInventory(r) {
  return require(["../views/others/stock/my_inventory"], r);
};
var ClousStockDetails = function ClousStockDetails(r) {
  return require(["../views/others/stock/details"], r);
};

//商店联盟（推广中心）
var leagueExtends = function leagueExtends(r) {
  return require(["../views/o2o/store_bussiness/leagueExtends"], r);
};
//店商联盟（会员中心）
var LeagueStoreIndex = function LeagueStoreIndex(r) {
  return require(["../views/o2o/store_bussiness/index"], r);
};
var LeagueOperatingCenter = function LeagueOperatingCenter(r) {
  return require(["../views/o2o/store_bussiness/operating_center"], r);
};
var LeagueOperatingStore = function LeagueOperatingStore(r) {
  return require(["../views/o2o/store_bussiness/operating_store"], r);
};
var LeagueServiceCenter = function LeagueServiceCenter(r) {
  return require(["../views/o2o/store_bussiness/service_center"], r);
};
var LeagueTaskCenter = function LeagueTaskCenter(r) {
  return require(["../views/o2o/store_bussiness/task_center"], r);
};
var LeagueTaskProgress = function LeagueTaskProgress(r) {
  return require(["../views/o2o/store_bussiness/task_progress"], r);
};
// 直播授权码
var liveAuthorization = function liveAuthorization(r) {
  return require(["../views/member/liveAuthorization/liveAuthorization"], r);
};
var liveAuthorization_detail = function liveAuthorization_detail(r) {
  return require(["../views/member/liveAuthorization/liveAuthorization_detail"], r);
};
var liveAuthorization_collection = function liveAuthorization_collection(r) {
  return require(["../views/member/liveAuthorization/liveAuthorization_collection"], r);
};
var liveAuthorization_scanCode = function liveAuthorization_scanCode(r) {
  return require(["../views/member/liveAuthorization/liveAuthorization_scanCode"], r);
};
var liveAuthorization_extension = function liveAuthorization_extension(r) {
  return require(["../views/member/liveAuthorization/liveAuthorization_extension"], r);
};
var liveAuthorization_pay = function liveAuthorization_pay(r) {
  return require(["../views/member/liveAuthorization/liveAuthorization_pay"], r);
};

// 航班信息收集
var flightInformation = function flightInformation(r) {
  return require(["../views/others/flightInformation"], r);
};
var flightInformationDetailList = function flightInformationDetailList(r) {
  return require(["../views/others/flightInformationDetailList"], r);
};
var flightInformationDetail = function flightInformationDetail(r) {
  return require(["../views/others/flightInformationDetail"], r);
};
// 问卷调查
var questionnaireIndex = function questionnaireIndex(r) {
  return require(["../views/others/question/questionnaireIndex"], r);
};
var questionsSearch = function questionsSearch(r) {
  return require(["../views/others/question/questionsSearch"], r);
};
var questions = function questions(r) {
  return require(["../views/others/question/questions"], r);
};
var surveyResult = function surveyResult(r) {
  return require(["../views/others/question/surveyResult"], r);
};
var questioHistory = function questioHistory(r) {
  return require(["../views/others/question/questioHistory"], r);
};
var history_details = function history_details(r) {
  return require(["../views/others/question/history_details"], r);
};
//扫码支付
var scancodeAccount = function scancodeAccount(r) {
  return require(["../views/member/pay/pay_scancode_account"], r);
};

//更新页面
var DownloadUpdate = function DownloadUpdate(r) {
  return require(["../views/others/update/update"], r);
};

// 多门店核销
var StoreVerification = function StoreVerification(r) {
  return require(["../views/appointment/project_verification/store_verification"], r);
};
// 核销记录
var WriteOffRecord = function WriteOffRecord(r) {
  return require(["../views/appointment/project_verification/write_off_record"], r);
};
// 门店记录
var StoreRecords = function StoreRecords(r) {
  return require(["../views/appointment/project_verification/store_records"], r);
};
// 核销员记录
var VerificationRecord = function VerificationRecord(r) {
  return require(["../views/appointment/project_verification/verification_record"], r);
};
// 商家核销
var BusinessVerification = function BusinessVerification(r) {
  return require(["../views/appointment/project_verification/business_verification"], r);
};
// 会员核销页面
var WriteOff = function WriteOff(r) {
  return require(["../views/appointment/project_verification/write_off"], r);
};
// 我的项目
var MyProject = function MyProject(r) {
  return require(["../views/appointment/project_verification/my_project"], r);
};
// 项目详情
var VerificationProjectDetails = function VerificationProjectDetails(r) {
  return require(["../views/appointment/project_verification/project_details"], r);
};
var VerificationStoreList = function VerificationStoreList(r) {
  return require(["../views/appointment/sotoreList"], r);
};
// 项目列表
var VerificationProjectList = function VerificationProjectList(r) {
  return require(["../views/appointment/project_verification/project_list"], r);
};
// 更多门店
var MoreStores = function MoreStores(r) {
  return require(["../views/appointment/project_verification/more_stores"], r);
};
//  搜索项目
var SearchProject = function SearchProject(r) {
  return require(["../views/appointment/project_verification/search_project"], r);
};
// 项目分类列表
var CategoryProjectList = function CategoryProjectList(r) {
  return require(["../views/appointment/project_verification/category_project_list"], r);
};

// cps 订单
var cpsOrderList = function cpsOrderList(r) {
  return require(["../views/member/order/cps/cpsOrder.vue"], r);
};

//* 定金阶梯团
var DepositGroupRoutes = [{
  path: "/depositGroupActivity",
  component: function component(r) {
    return require(["../views/others/deposit_group/activity_index"], r);
  },
  name: "depositGroupActivity",
  meta: {
    title: "定金阶梯",
    foot: true
  }
}, {
  path: "/depositGroupRanking",
  component: function component(r) {
    return require(["../views/others/deposit_group/ranking"], r);
  },
  name: "depositGroupRanking",
  meta: {
    title: "定金阶梯排行榜",
    foot: true
  }
}];
//* 招商专员
var MerchantsCommissioner = function MerchantsCommissioner(r) {
  return require(["../views/extension/merchants-commissioner/index"], r);
};
//* 新媒体广告插件
var NewMediaAdRoutes = [{
  path: "/newMediaGuide",
  component: function component(r) {
    return require(["../views/others/new_media/guide"], r);
  },
  name: "newMediaGuide",
  meta: {
    title: "新媒体",
    foot: false
  }
}, {
  path: "/newMediaPublish",
  component: function component(r) {
    return require(["../views/others/new_media/publish"], r);
  },
  name: "newMediaPublish",
  meta: {
    title: "发布内容",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/newMediaMy",
  component: function component(r) {
    return require(["../views/others/new_media/my"], r);
  },
  name: "newMediaMy",
  meta: {
    title: "我的",
    foot: false
  }
}, {
  path: "/newMediaAdDetails",
  component: function component(r) {
    return require(["../views/others/new_media/ad_details"], r);
  },
  name: "newMediaAdDetails",
  meta: {
    title: "内容详情",
    foot: true
  }
}, {
  path: "/newMediaAdVideo",
  component: function component(r) {
    return require(["../views/others/new_media/ad_video"], r);
  },
  name: "newMediaAdVideo",
  meta: {
    title: "视频",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/newMediaFans",
  component: function component(r) {
    return require(["../views/others/new_media/fans"], r);
  },
  name: "newMediaFans",
  meta: {
    title: "粉丝",
    foot: true
  }
}, {
  path: "/newMediaPoints",
  component: function component(r) {
    return require(["../views/others/new_media/points"], r);
  },
  name: "newMediaPoints",
  meta: {
    title: "查看明细",
    foot: true
  }
}, {
  path: "/newMediaExchange",
  component: function component(r) {
    return require(["../views/others/new_media/exchange"], r);
  },
  name: "newMediaExchange",
  meta: {
    title: "兑换流量值",
    foot: true
  }
}, {
  path: "/newMediaRechange",
  component: function component(r) {
    return require(["../views/others/new_media/rechange"], r);
  },
  name: "newMediaRechange",
  meta: {
    title: "充值流量值",
    foot: true
  }
}];

// 推广中心 ---> 门店奖励
var ExtendStoreRewards = function ExtendStoreRewards(r) {
  return require(["../views/extend-helper/storeRewards.vue"], r);
};
// 推广中心 ---> 提成概括
var CommissionSummary = function CommissionSummary(r) {
  return require(["../views/extend-helper/commissionSummary.vue"], r);
};
// 推广中心 ---> 门店订单列表
var StoreOrder = function StoreOrder(r) {
  return require(["../views/extend-helper/storeOrder.vue"], r);
};
// 推广中心 ---> 门店订单列表
var CashierOrder = function CashierOrder(r) {
  return require(["../views/extend-helper/cashierOrder.vue"], r);
};
// 易宝代付签约
var YeePay = function YeePay(r) {
  return require(["../views/extension/yee_pay/yee_pay.vue"], r);
};

// 高灯代发薪
var HighLight = function HighLight(r) {
  return require(["../views/extension/high_light/high_light"], r);
};

// 免单记录
var FreeRecord = function FreeRecord(r) {
  return require(["../views/others/free_of_charge/free_record"], r);
};

// 免单详情
var FreeDetails = function FreeDetails(r) {
  return require(["../views/others/free_of_charge/free_details"], r);
};

// 免单抽奖
var FreeLottery = function FreeLottery(r) {
  return require(["../views/others/free_of_charge/free_lottery"], r);
};

// 权益赠送
var InterestsGive = function InterestsGive(r) {
  return require(["../views/member/interests_give/interests_give"], r);
};

//授权查询
var authorizationQuery = function authorizationQuery(r) {
  return require(["../views/others/authorization_query/authorization_query"], r);
};

// 星级门店
var starStore = function starStore(r) {
  return require(["../views/extension/star_store/star_store"], r);
};

// 供应商列表
var supplierShopList = function supplierShopList(r) {
  return require(["../views/member/supplier/supplier_shopList"], r);
};
//资金池
var Price_Pool = function Price_Pool(r) {
  return require(["../views/member/price_pool/price_pool"], r);
};
//红包列表
var redPacket = function redPacket(r) {
  return require(["../views/member/price_pool/redPacket"], r);
};
// 通证支付
var Throughpay = function Throughpay(r) {
  return require(["../views/member/Throughpay"], r);
};

//好灵工-注册
var WorkerWithdraw = function WorkerWithdraw(r) {
  return require(["../views/dev-plug/register"], r);
};
//好灵工-注册类型
var WorkerWithdrawType = function WorkerWithdrawType(r) {
  return require(["../views/dev-plug/register/type"], r);
};

//律师平台
var lawyerPlatform = function lawyerPlatform(r) {
  return require(["../views/member/lawyerPlatform"], r);
};
var lawyerForm = function lawyerForm(r) {
  return require(["../views/member/lawyerPlatform/lawyerForm"], r);
};
var lawyerOrder = function lawyerOrder(r) {
  return require(["../views/member/lawyerPlatform/order/order"], r);
};
var lawyerConsult = function lawyerConsult(r) {
  return require(['../views/member/lawyerPlatform/consult'], r);
};
var lawyerPlatformApplyFor = function lawyerPlatformApplyFor(r) {
  return require(["../views/member/lawyerPlatform/apply_for"], r);
};
var operators = function operators(r) {
  return require(['../views/member/lawyerPlatform/operators'], r);
};
var enterpriseAdministration = function enterpriseAdministration(r) {
  return require(['../views/member/lawyerPlatform/enterprise'], r);
};
var enterpriseAdministrationAdd = function enterpriseAdministrationAdd(r) {
  return require(['../views/member/lawyerPlatform/add_enterprise'], r);
};
var lawOffice = function lawOffice(r) {
  return require(['../views/member/lawyerPlatform/lawOffice/index'], r);
};
var lawOfficeList = function lawOfficeList(r) {
  return require(['../views/member/lawyerPlatform/lawOffice/law_list'], r);
};
var lawYerManagement = function lawYerManagement(r) {
  return require(['../views/member/lawyerPlatform/lawyer'], r);
};
var lawYerManagementEdit = function lawYerManagementEdit(r) {
  return require(['../views/member/lawyerPlatform/lawyer/edit'], r);
};
var lawyerDetail = function lawyerDetail(r) {
  return require(['../views/member/lawyerPlatform/detail'], r);
};
var lawyerFirmMessage = function lawyerFirmMessage(r) {
  return require(['../views/member/lawyerPlatform/lawOffice/message'], r);
};
var lawyerSearch = function lawyerSearch(r) {
  return require(['../views/member/lawyerPlatform/search_list'], r);
};
var lawyerShare = function lawyerShare(r) {
  return require(['../views/member/lawyerPlatform/share_bonus'], r);
};
var lawyerChart = function lawyerChart(r) {
  return require(["../views/member/lawyerPlatform/chat"], r);
};

// 页面路由
var routes = [{
  name: "citypickerPage",
  path: "/citypickerPage",
  component: function component() {
    return import("../views/shop_base/citypickerPage.vue");
  },
  meta: {
    title: "选择城市",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/logistics",
  component: logistics,
  name: "logistics",
  meta: {
    title: "物流信息",
    foot: false
  }
},
// 供应商列表
{
  path: "/supplier_shopList",
  component: supplierShopList,
  name: "supplierShopList",
  meta: {
    title: "供应商列表",
    foot: true
  }
},
//好灵工注册
{
  path: "/WorkerWithdraw/:id?/:path?",
  component: WorkerWithdraw,
  name: 'worker_withdraw',
  meta: {
    title: "注册"
  }
},
//好灵工注册类型
{
  path: "/WorkerWithdrawType/:path?",
  component: WorkerWithdrawType,
  name: 'WorkerWithdrawType',
  meta: {
    title: "注册"
  }
},
// 支付后跳转页面
{
  path: "/member/payrecharge",
  component: Payrecharge,
  name: "payrecharge",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "",
  redirect: {
    name: "home"
  },
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/user_agreement",
  component: userAgreement,
  name: "userAgreement",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/goodsGroup/:goodtype?",
  component: goodsGroup,
  name: "goodsGroup",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/lawyer_platform",
  component: lawyerPlatform,
  name: "lawyerPlatform",
  meta: {
    title: "律师平台",
    foot: false
  }
}, {
  path: "/lawyerSearch",
  component: lawyerSearch,
  name: "lawyerSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/lawyerFirmMessage/:id",
  component: lawyerFirmMessage,
  name: "lawyerFirmMessage",
  meta: {
    title: "消息",
    foot: true
  }
}, {
  path: "/lawYerManagement",
  component: lawYerManagement,
  name: "lawYerManagement",
  meta: {
    title: "律师管理",
    foot: true
  }
}, {
  path: "/lawYerManagement/edit/:id",
  component: lawYerManagementEdit,
  name: "lawYerManagement_edit",
  meta: {
    title: "律师管理",
    foot: true
  }
}, {
  path: "/lawyerChat",
  component: lawyerChart,
  name: "lawyerChart",
  meta: {
    title: "消息",
    foot: true
  }
}, {
  path: "/applyFor",
  component: lawyerPlatformApplyFor,
  name: "lawyerPlatformApplyFor",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/lawyerOrder",
  component: lawyerOrder,
  name: "lawyerOrder",
  meta: {
    title: "订单管理"
  }
}, {
  path: "/lawyerConsult/:consult_type",
  component: lawyerConsult,
  name: "lawyerConsult",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/lawyerForm/:id",
  component: lawyerForm,
  name: "lawyerForm",
  meta: {
    title: ""
  }
}, {
  path: "/lawyer_platform/operators",
  component: operators,
  name: "operators",
  meta: {
    title: "运营商",
    foot: true
  }
}, {
  path: "/lawyer_platform/enterprise",
  component: enterpriseAdministration,
  name: "enterprise",
  meta: {
    title: "企业管理",
    foot: true
  }
}, {
  path: "/lawyer_platform/enterpriseAdd",
  component: enterpriseAdministrationAdd,
  name: "enterpriseAdd",
  meta: {
    title: "企业管理",
    foot: true
  }
}, {
  path: "/lawyer_platform/enterpriseEdit/:id",
  component: enterpriseAdministrationAdd,
  name: "enterpriseEdit",
  meta: {
    title: "企业管理",
    foot: true
  }
}, {
  path: "/lawyerShare/:order_type",
  component: lawyerShare,
  name: "lawyerShare",
  meta: {
    title: "分红记录",
    foot: true
  }
}, {
  path: "/lawyerDetail/:id",
  component: lawyerDetail,
  name: "lawyerDetail",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/lawyer_platform/lawOffice",
  component: lawOffice,
  name: "lawOffice",
  meta: {
    title: "律所管理",
    foot: true
  }
}, {
  path: "/lawOffice/list/:id",
  component: lawOfficeList,
  name: "lawList",
  meta: {
    title: $i18n.t("基本信息"),
    foot: true
  }
}, {
  path: "/case_library",
  component: caseLibrary,
  name: "caseLibrary",
  meta: {
    title: "发布案例",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_home",
  component: caseLibraryHome,
  name: "caseLibraryHome",
  meta: {
    title: "首页",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_category",
  component: caseLibraryCategory,
  name: "caseLibraryCategory",
  meta: {
    title: "分类",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_label/:id",
  component: caseLibraryLabel,
  name: "caseLibraryLabel",
  meta: {
    title: "标签",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_subject_list",
  component: caseLibrarySubjectList,
  name: "caseLibrarySubjectList",
  meta: {
    title: "专题",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_subject_detail/:id",
  component: caseLibrarySubjectDetail,
  name: "caseLibrarySubjectDetail",
  meta: {
    title: "专题详情",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_detail/:id",
  component: caseLibraryDetail,
  name: "caseLibraryDetail",
  meta: {
    title: "案例详情",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_my_publish",
  component: caseLibraryMypublish,
  name: "caseLibraryMypublish",
  meta: {
    title: "我的发布",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_new_mobile",
  component: caseLibraryNewMobile,
  name: "caseLibraryNewMobile",
  meta: {
    title: "案例列表",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_area_mobile",
  component: caseLibraryAreaMobile,
  name: "caseLibraryAreaMobile",
  meta: {
    title: "案例列表",
    foot: true,
    isPC: true
  }
}, {
  path: "/case_library_ranking_mobile",
  component: caseLibraryRankingMobile,
  name: "caseLibraryRankingMobile",
  meta: {
    title: "排行榜",
    foot: true,
    isPC: true
  }
},
// 一口价
{
  path: "/fixedPrice/:id",
  component: fixedPrice,
  name: "fixedPrice",
  meta: {
    title: "一口价",
    foot: true
  }
}, {
  path: "/application_market",
  component: applicationMarket,
  name: "applicationMarket",
  meta: {
    title: "应用市场",
    foot: true
  }
}, {
  path: "/marketSub",
  component: applicationMarket,
  name: "marketSub",
  meta: {
    title: "应用市场",
    foot: true
  }
}, {
  path: "/binding_site",
  component: bindingSite,
  name: "bindingSite",
  meta: {
    title: "新增/绑定站点",
    foot: true
  }
}, {
  path: "/change_site",
  component: changeSite,
  name: "changeSite",
  meta: {
    title: "",
    foot: true
  }
},
// 汇聚 账户信息
{
  path: "/member/bankCardInformation",
  component: BankCardInformation,
  name: "bankCardInformation",
  meta: {
    title: "",
    foot: false
  }
},
// 归还详情
{
  path: "/member/orderruturninfo/:id",
  component: OrderRuturnInfo,
  name: "orderruturninfo",
  meta: {
    title: "归还详情",
    foot: false
  }
}, {
  path: "/member/orderruturninfo/yz_lease_return_info/:id",
  component: OrderRuturnInfo,
  name: "yz_lease_return_info",
  meta: {
    title: "归还详情",
    foot: false
  }
},
// 银行卡信息编辑
{
  path: "/member/bankcardinedit",
  component: BankCardinEdit,
  name: "bankcardinedit",
  meta: {
    title: "",
    foot: false
  }
},
// 银行卡信息添加
{
  path: "/member/bankcardinadd",
  component: BankCardinAdd,
  name: "bankcardinadd",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/wx-scheme-tool",
  component: wxSchemeTool,
  name: "wxSchemeTool",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/home",
  component: Home,
  name: "home",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/limitedTimeGoods",
  component: LimitedTimeGoods,
  name: "limitedTimeGoods",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/CloseSite",
  component: CloseSite,
  name: "CloseSite",
  meta: {
    title: "站点已关闭",
    foot: true
  }
},
// 多包裹
{
  path: "/multiplePackages/:order_id",
  component: multiplePackages,
  name: "multiplePackages",
  meta: {
    title: "物流信息",
    foot: true
  }
}, {
  path: "/newDiy",
  component: newDiy,
  name: "newDiy",
  meta: {
    title: "",
    foot: true
  }
},
// {
//   path: "/diy/:page_id",
//   component: Diy,
//   name: "Diy1"
// },
{
  path: "/diyform/:id",
  component: DiyForm,
  name: "DiyForm"
}, {
  path: "/diyform/:id/:goodsId/:optionsId/:total",
  component: DiyForm,
  name: "activityDiyForm"
}, {
  path: "/share",
  component: Share,
  name: "share",
  meta: {
    title: "分享",
    foot: true
  }
},
// 活动聚合页
{
  path: "/business_activity/business_activity",
  component: businessActivity,
  name: "businessActivity ",
  meta: {
    title: "活动聚合",
    foot: true,
    is_single_share: true
  }
},
// 活动聚合页
{
  path: "/activity_detail",
  component: ActivityDetail,
  name: "ActivityDetail",
  meta: {
    title: "活动详情",
    foot: true
  }
},
//Member
{
  path: "/member/bank",
  component: MemberBank,
  name: "memberBank",
  meta: {
    title: "银行卡",
    foot: true
  }
}, {
  path: "/member/bank_card",
  component: BankCard,
  name: "BankCard",
  meta: {
    title: "银行卡",
    foot: true
  }
}, {
  path: "/member",
  component: Member,
  name: "member",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/morePlugin",
  component: morePlugins,
  name: "morePlugin",
  meta: {
    title: "我的工具",
    foot: true
  }
},
// {
//   path: '/member/tabs',
//   component: Tabs,
//   name: 'tabs',
//   meta: {
//     title: 'tabs',
//     foot: false
//   }
// },
// {
//   path: '/member/po',
//   component: Po,
//   name: 'po',
//   meta: {
//     title: 'po',
//     foot: false
//   }
// },
// {
//   path: "/member/info",
//   component: Info,
//   name: "info",
//   meta: {
//     title: "会员信息",
//     foot: true
//   }
// },
{
  path: "/member/editmobile/:fromTag?",
  component: Editmobile,
  name: "editmobile",
  meta: {
    title: "绑定手机",
    foot: true
  }
}, {
  path: "/member/withdrawEditmobile",
  component: WithdrawEditmobile,
  name: "withdrawEditmobile",
  meta: {
    title: "绑定手机",
    foot: true
  }
}, {
  path: "/member/scaneditmobile",
  component: scanEditmobile,
  name: "scanEditmobile",
  meta: {
    title: "绑定手机",
    foot: true
  }
},
// 猫崽积分 feat#11941
{
  path: "/member/balance/:name?",
  component: Balance,
  name: "cat_integral",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/member/balance",
  component: Balance,
  name: "balance",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/member/detailed",
  component: Detailed,
  name: "detailed",
  meta: {
    title: "余额明细",
    foot: true
  }
}, {
  path: "/wechat_sidebarDetailed",
  component: Detailed,
  name: "wechat_sidebarDetailed",
  meta: {
    title: "余额明细",
    foot: true
  }
}, {
  path: "/member/code_page",
  component: codePage,
  name: "codePage",
  meta: {
    title: "支付码",
    foot: true
  }
}, {
  path: "/member/uid_code",
  component: codePage,
  name: "uidCode",
  meta: {
    title: "会员卡号",
    foot: true
  }
},
// {
//   path: '/member/screen',
//   component: Screen,
//   name: 'screen',
//   meta: {
//     title: '余额筛选',
//     foot: true
//   }
// },
{
  path: "/member/details/:item/:name?",
  component: Details,
  name: "details",
  meta: {
    title: "余额详情",
    foot: true
  }
}, {
  path: "/member/integral",
  component: Integral,
  name: "integral",
  meta: {
    title: "积分",
    foot: true
  }
}, {
  path: "/member/integral_v2",
  component: IntegralV2,
  name: "integral_v2",
  meta: {
    title: "积分",
    foot: true
  }
}, {
  path: "/member/integral_v2Transfer",
  component: IntegralV2Transfer,
  name: "integral_v2_transfer",
  meta: {
    title: "积分转账",
    foot: true
  }
}, {
  path: "/member/income",
  component: Income,
  name: "income",
  meta: {
    title: "收入",
    foot: true
  }
}, {
  path: "/member/balance_recharge",
  component: Balance_recharge,
  name: "balance_recharge",
  meta: {
    title: "充值",
    foot: true
  }
}, {
  path: "/member/balance_recharge/pdp",
  component: Balance_recharge,
  name: "balance_rechargepdp",
  meta: {
    title: "充值",
    foot: true
  }
},
// 门店独立余额充值
{
  path: "/member/balance_recharge/:store_id/:name?",
  component: storeBalance,
  name: "storeBalance",
  meta: {
    title: "充值",
    foot: true
  }
}, {
  path: "/member/balance_transfer",
  component: Balance_transfer,
  name: "balance_transfer",
  meta: {
    title: "转账",
    foot: true
  }
}, {
  path: "/member/cmcPayInfo",
  component: cmcPayInfo,
  name: "cmcPayInfo",
  meta: {
    title: "账号管理",
    foot: true
  }
}, {
  path: "/member/balance_withdrawals",
  component: Balance_withdrawals_v2,
  name: "balance_withdrawals",
  meta: {
    title: "提现",
    foot: true
  }
},
// 插件
{
  path: "/member/balance_withdrawals/pdp",
  component: Balance_withdrawals_v2,
  name: "balance_withdrawalspdp",
  meta: {
    title: "提现",
    foot: true
  }
},
// 保单管理
// Balance_manage
{
  path: "/member/Balance_manage",
  component: Balance_manage,
  name: "balance_manage",
  meta: {
    title: "保单管理",
    foot: true
  }
}, {
  path: "/time_page",
  component: TimerShaft,
  name: "time_page",
  meta: {
    title: '时间轴'
  }
},
// Balance_manageDetail
{
  path: "/member/Balance_manageDetail",
  component: Balance_manageDetail,
  name: "balance_manageDetail",
  meta: {
    title: "保单详情",
    foot: true
  }
},
// 保单添加
// Add_Balance_manage
{
  path: "/member/add_balance_manage",
  component: Add_Balance_manage,
  name: "add_balance_manage",
  meta: {
    title: "保单添加",
    foot: true
  }
},
// 编辑保单
// Edit_Balance_manage
{
  path: "/member/edit_balance_manage/:type",
  component: Edit_Balance_manage,
  name: "edit_balance_manage",
  meta: {
    title: "编辑保单",
    foot: true
  }
}, {
  path: "/member/policyPay/:id/:supplier_id",
  component: policyPay,
  name: "policyPay",
  meta: {
    title: "保单支付",
    foot: true
  }
}, {
  path: "/member/withdrawal",
  component: Withdrawal,
  name: "withdrawal",
  meta: {
    title: "收入提现",
    foot: true
  }
}, {
  path: "/member/incomedetails",
  component: Incomedetails,
  name: "incomedetails",
  meta: {
    title: "收入明细",
    foot: true
  }
}, {
  path: "/member/member_income_incomedetails_info",
  component: IncomedetailsInfo,
  name: "income_details_info",
  meta: {
    title: "收入明细详情",
    foot: true
  }
},
// 修改自定义信息
{
  path: "/member/info_costom",
  component: InfoCostom,
  name: "info_costom",
  meta: {
    title: "修改信息",
    foot: true
  }
},
// 余额支付密码
{
  path: "/member/balance_password",
  component: BalancePassword,
  name: "balance_password",
  meta: {
    title: "修改支付密码",
    foot: true
  }
},
// 账号注销
{
  path: "/member/memberCancel",
  component: MemberCancel,
  name: "MemberCancel",
  meta: {
    title: $i18n.t("账号注销"),
    foot: true
  }
},
// 账号注销协议 聚合APP用
{
  path: "/member/memberCancel/content",
  component: MemberCancelContent,
  name: "MemberCancelContent",
  meta: {
    title: "账号注销",
    foot: true
  }
},
// 设置余额支付密码
{
  path: "/member/set_balance_password",
  component: SetBalancePassword,
  name: "set_balance_password",
  meta: {
    title: "设置支付密码",
    foot: true
  }
},
// 消息列表
{
  path: "/member/message",
  component: Message,
  name: "message",
  meta: {
    title: "消息列表",
    foot: false
  }
}, {
  path: "/member/message_info/:id",
  component: MessageInfo,
  name: "messageInfo",
  meta: {
    title: "消息详情",
    foot: false
  }
}, {
  path: "/member/integraldetail/:id",
  component: Integraldetail,
  name: "integraldetail",
  meta: {
    title: "积分详情",
    foot: true
  }
}, {
  path: "/member/integrallist",
  component: IntegralList,
  name: "integrallist",
  meta: {
    title: "积分列表",
    foot: true
  }
}, {
  path: "/member/presentationRecord",
  component: PresentationRecord,
  name: "presentationRecord",
  meta: {
    title: "我的提成",
    foot: true
  }
}, {
  path: "/member/presentationRecord/:supplier",
  component: PresentationRecord,
  name: "supplierRecord",
  meta: {
    title: "我的提现",
    foot: true
  }
}, {
  path: "/member/presentationDetails/:record_id",
  component: PresentationDetails,
  name: "presentationDetails",
  meta: {
    title: "提现详情",
    foot: true
  }
}, {
  path: "/member/address",
  component: Address,
  name: "address",
  meta: {
    title: "默认地址",
    foot: true
  }
}, {
  path: "/member/alterAddress",
  component: AlterAddress,
  name: "alterAddress",
  meta: {
    title: "修改收货地址",
    foot: true
  }
}, {
  path: "/member/appendAddress",
  component: AppendAddress,
  name: "appendAddress",
  meta: {
    title: "添加收货地址",
    foot: true
  }
},
// income
{
  path: "/member/distributionCommission",
  component: DistributionCommission,
  name: "distributionCommission",
  meta: {
    title: "未提现分销佣金",
    foot: true
  }
},
// tool
{
  path: "/member/footprint",
  component: Footprint,
  name: "footprint",
  meta: {
    title: $i18n.t("我的足迹"),
    foot: true
  }
}, {
  path: "/member/collection",
  component: Collection,
  name: "collection",
  meta: {
    title: $i18n.t("我的收藏"),
    foot: false
  }
}, {
  path: "/member/myrelationship",
  component: MyRelationship,
  name: "myRelationship",
  meta: {
    title: $i18n.t("我的关系"),
    foot: true
  }
}, {
  path: "/member/myrelationship/amount/:type",
  component: MyRelationshipAmount,
  name: "MyRelationshipAmount",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/member/myrelationship/detail/:uid",
  component: MyRelationshipDetail,
  name: "MyRelationshipDetail",
  meta: {
    title: $i18n.t("会员概况"),
    foot: true
  }
}, {
  path: "/member/offlineSearch",
  component: OfflineSearch,
  name: "offlineSearch",
  meta: {
    title: "下线搜索",
    foot: true
  }
}, {
  path: "/member/myEvaluation",
  component: MyEvaluation,
  name: "myEvaluation",
  meta: {
    title: "我的评价",
    foot: true
  }
}, {
  path: "/member/comment",
  component: Comment,
  name: "comment",
  meta: {
    title: "多商品评价",
    foot: true
  }
}, {
  path: "/member/evaluationDetails",
  component: EvaluationDetails,
  name: "evaluationDetails",
  meta: {
    title: "评价详情",
    foot: true
  }
}, {
  path: "/member/myfriends",
  component: MyFriendApply,
  name: "MyFriendApply",
  meta: {
    title: "我的朋友",
    foot: true
  }
}, {
  path: "/member/coupon_exchange",
  component: CouponExchange,
  name: "CouponExchange",
  meta: {
    title: "兑换中心",
    foot: true
  }
}, {
  path: "/member/helpcenter",
  component: Helpcenter,
  name: "helpcenter",
  meta: {
    title: "帮助中心",
    foot: true
  }
},
// score
{
  path: "/member/integral_activity",
  component: IntegralActivity,
  name: "IntegralActivity",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/member/Integral_generalization",
  component: IntegralGeneralization,
  name: "IntegralGeneralization",
  meta: {
    title: "发起赠送",
    foot: true
  }
}, {
  path: "/member/integral_get_record",
  component: IntegralGetRecord,
  name: "IntegralGetRecord",
  meta: {
    title: "领取记录",
    foot: true
  }
}, {
  path: "/member/integral_present",
  component: IntegralPresent,
  name: "IntegralPresent",
  meta: {
    title: "积分推广",
    foot: false
  }
}, {
  path: "/member/integral_record/:activity_id",
  component: IntegralRecord,
  name: "IntegralRecord",
  meta: {
    title: "赠送记录",
    foot: true
  }
},
// Love 爱心值
{
  path: "/love_index",
  component: Love_index,
  name: "love_index",
  meta: {
    title: "",
    foot: true
  }
},
//冯荣成 加速池释放
{
  path: "/love_speed_released",
  component: Love_Speed_Released,
  name: "love_speed_released",
  meta: {
    title: "",
    foot: true
  }
},
//冻结爱心值激活积分
{
  path: "/love_active_froze",
  component: Love_active_froze,
  name: "love_active_froze",
  meta: {
    title: "",
    foot: true
  }
},
//资金池
{
  path: "/price_pool",
  component: Price_Pool,
  name: "price_pool",
  meta: {
    title: "",
    foot: true
  }
},
//红包列表页
{
  path: "/redPacket",
  component: redPacket,
  name: "redPacket",
  meta: {
    title: "领取红包"
  }
}, {
  path: "/love_list",
  component: Love_list,
  name: "love_list",
  meta: {
    title: "明细",
    foot: true
  }
}, {
  path: "/love_transfer/:key?",
  component: Love_transfer,
  name: "love_transfer",
  meta: {
    title: "转账",
    foot: true
  }
}, {
  path: "/love_changein",
  component: Love_changein,
  name: "love_changein",
  meta: {
    title: "转入",
    foot: true
  }
}, {
  path: "/love_changeto",
  component: Love_changeto,
  name: "love_changeto",
  meta: {
    title: "转出",
    foot: true
  }
}, {
  path: "/love_give",
  component: Love_give,
  name: "love_give",
  meta: {
    title: "购物赠送详情",
    foot: true
  }
}, {
  path: "/love_details",
  component: Love_details,
  name: "love_details",
  meta: {
    title: "消费抵扣详情",
    foot: true
  }
}, {
  path: "/love_agent",
  component: Love_agent,
  name: "love_agent",
  meta: {
    title: "代理商奖励详情",
    foot: true
  }
}, {
  path: "/love_push",
  component: Love_push,
  name: "love_push",
  meta: {
    title: "直推奖奖励详情",
    foot: true
  }
}, {
  path: "/love_cash",
  component: Love_cash,
  name: "love_cash",
  meta: {
    title: "收入提现奖励详情",
    foot: true
  }
}, {
  path: "/love_record",
  component: Love_record,
  name: "love_record",
  meta: {
    title: "爱心值激活记录",
    foot: true
  }
}, {
  path: "/love_explain",
  component: Love_explain,
  name: "love_explain",
  meta: {
    title: "爱心值说明",
    foot: true
  }
}, {
  path: "/love_activation/:id",
  component: Love_activation,
  name: "love_activation",
  meta: {
    title: "激活详情",
    foot: true
  }
}, {
  path: "/loveWithdrawals",
  component: LoveWithdrawals,
  name: "loveWithdrawals",
  meta: {
    title: "提现",
    foot: true
  }
}, {
  path: "/lovePeriod",
  component: lovePeriod,
  name: "lovePeriod",
  meta: {
    title: "周期奖励记录",
    foot: true
  }
}, {
  path: "/lovePerformance",
  component: lovePerformance,
  name: "lovePerformance",
  meta: {
    title: "团队奖励记录",
    foot: true
  }
}, {
  path: "/loveGiftBag",
  component: LoveGiftBag,
  name: "loveGiftBag",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/loveCycleReceive",
  component: LoveCycleReceive,
  name: "loveCycleReceive",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/loveCashbackRecord",
  component: LoveCashbackRecord,
  name: "loveCashbackRecord",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/loveGiftQueue",
  component: LoveGiftQueue,
  name: "loveGiftQueue",
  meta: {
    title: "",
    foot: true
  }
},
// 信用值
{
  path: "/creditValeuIndex",
  component: CreditValeuIndex,
  name: "creditValeuIndex",
  meta: {
    title: "信用值",
    foot: true
  }
}, {
  path: "/creditRecharge",
  component: CreditRecharge,
  name: "creditRecharge",
  meta: {
    title: "缴纳服务费",
    foot: true
  }
}, {
  path: "/creditGive",
  component: CreditGive,
  name: "creditGive",
  meta: {
    title: "赠送",
    foot: true
  }
}, {
  path: "/giveHistory",
  component: GiveHistory,
  name: "giveHistory",
  meta: {
    title: "赠送记录",
    foot: true
  }
}, {
  path: "/activateHistory",
  component: ActivateHistory,
  name: "activateHistory",
  meta: {
    title: "激活记录",
    foot: true
  }
}, {
  path: "/viewDetails",
  component: ViewDetails,
  name: "viewDetails",
  meta: {
    title: "查看明细",
    foot: true
  }
},
// article
{
  path: "/notice",
  component: Notice,
  name: "notice",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/articleContent/:id/:isgood?",
  component: ArticleContent,
  name: "articleContent",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/noticeByCategory/:id",
  component: NoticeByCategory,
  name: "noticeByCategory",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/noticeReport/:id",
  component: NoticeReport,
  name: "noticeReport",
  meta: {
    title: "举报",
    foot: true
  }
}, {
  path: "/payEssay",
  component: payEssay,
  name: "payEssay",
  meta: {
    title: "已付费文章",
    foot: false
  }
}, {
  path: "/payList/:id",
  component: payList,
  name: "payList",
  meta: {
    title: "付费列表",
    foot: true
  }
},
// microshop
{
  path: "/microShopShare/home/:shop_id",
  component: MicroShop_Share_home,
  name: "micro_shop_share_home",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShopShare/micro_shop_category",
  component: MicroShop_ShareCategory,
  name: "micro_shop_share_category",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShopShare/micro_share_catelist/:id/",
  component: CatelistShare,
  name: "micro_shop_share_carelist",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShopAdd/micro_shop_category",
  component: MicroShop_AddCategory,
  name: "micro_shop_add_category",
  meta: {
    title: "商品分类",
    foot: true
  }
}, {
  path: "/microShop_apply",
  component: MicroShop_apply,
  name: "microShop_apply",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShop_apply_reg_info",
  component: MicroShop_apply_reg_info,
  name: "microShop_apply_reg_info",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShop/baseinfo",
  component: MicroShop_baseInfo,
  name: "microShop_baseinfo",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShop/micro_shop_category",
  component: MicroShop_Category,
  name: "micro_shop_category",
  meta: {
    title: "",
    foot: true
  }
},
// 参数id用来接收从分类页面传递过来的具体类别参数
{
  path: "/catelist1/:id/",
  component: Catelist1,
  name: "catelist1",
  meta: {
    title: "分类列表",
    foot: true
  }
},
// {path: '/catelist1/:id/:isSelect', component: Catelist1, name: 'catelist1',meta: {	title: '分类列表', foot:true }},
{
  path: "/microShop/home",
  component: MicroShop_home,
  name: "microShop_home",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/microShop/shopKeeperCenter",
  component: MicroShop_ShopKeeperCenter,
  name: "microShop_ShopKeeperCenter",
  meta: {
    title: "用户中心",
    foot: true
  }
}, {
  path: "/microShop/shopQrcode/:shop_id",
  component: function component() {
    return import('../views/member/microshop/shopQrode');
  },
  name: "microShopQrcode",
  meta: {
    title: "微店二维码",
    foot: true
  }
},
// 路由参数用来控制显示哪个时间段的订单
{
  path: "/microShop/orderDetail/:date",
  component: MicroShop_orderDetail,
  name: "microShop_orderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
},
// 路由参数用来控制显示哪个时间段的分红订单 （）
{
  path: "/microShop/shareList/:date",
  component: MicroShop_shareList,
  name: "microShop_shareList",
  meta: {
    title: "分红列表",
    foot: true
  }
},
// 路由参数用来控制显示哪个分红订单的具体详情
{
  path: "/microShop/shareDetail/:orderID",
  component: MicroShop_shareDetail,
  name: "microShop_shareDetail",
  meta: {
    title: "分红详情",
    foot: true
  }
}, {
  path: "/microShop/selectedGoods/:goods",
  component: MicroShop_selectedGoods,
  name: "microShop_selectedGoods",
  meta: {
    title: "已选商品",
    foot: true
  }
}, {
  path: "/microShop/manageShop",
  component: ManageShop,
  name: "manage_shop",
  meta: {
    title: "",
    foot: true
  }
},
// extension
{
  path: "/extension/manage",
  component: Manage,
  name: "manage",
  meta: {
    title: "管理奖",
    foot: true
  }
}, {
  path: "/member/extension",
  component: Extension,
  name: "extension",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/extension/distribution",
  component: Distribution,
  name: "distribution",
  meta: {
    title: "分销商",
    foot: false
  }
}, {
  path: "/extension/new_distribution",
  component: Distribution,
  name: "Newdistribution",
  meta: {
    title: "分销商",
    foot: false
  }
}, {
  path: "/extension/commission",
  component: Commission,
  name: "commission",
  meta: {
    title: "预计佣金",
    foot: true
  }
}, {
  path: "/extension/unsettled",
  component: Unsettled,
  name: "unsettled",
  meta: {
    title: "未结算佣金",
    foot: true
  }
}, {
  path: "/extension/alreadySettled",
  component: AlreadySettled,
  name: "alreadySettled",
  meta: {
    title: "已结算佣金",
    foot: true
  }
}, {
  path: "/extension/notPresent",
  component: NotPresent,
  name: "notPresent",
  meta: {
    title: "未提现佣金",
    foot: true
  }
}, {
  path: "/extension/present",
  component: Present,
  name: "present",
  meta: {
    title: "已提现佣金",
    foot: true
  }
}, {
  path: "/extension/invalid/:type",
  component: Present,
  name: "invalid",
  meta: {
    title: "无效佣金",
    foot: true
  }
}, {
  path: "/extension/distributionOrder",
  component: DistributionOrder,
  name: "distributionOrder",
  meta: {
    title: "分销订单",
    foot: true
  }
}, {
  path: "/extension/extendIncome",
  component: ExtendIncome,
  name: "extendIncome",
  meta: {
    title: "推广收入",
    foot: false
  }
},
// new
{
  path: "/extension/merchantsDividendRatio",
  component: MerchantsDividendRatio,
  name: "merchantsDividendRatio",
  meta: {
    title: "招商员",
    foot: true
  }
},
// new
// 上级奖
{
  path: "/superior",
  component: superior,
  name: "superior",
  meta: {
    title: "上级奖",
    foot: true
  }
}, {
  path: "/shareholderCenter",
  component: ShareholderCenter,
  name: "shareholderCenter",
  meta: {
    title: "股东中心",
    foot: true
  }
}, {
  path: "/teamAgentCenter",
  component: TeamAgentCenter,
  name: "teamAgentCenter",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/TeamAgentOrder",
  component: TeamAgentOrder,
  name: "TeamAgentOrder",
  meta: {
    title: "我的订单",
    foot: true
  }
}, {
  path: "/newAgentCenter",
  component: newAgentCenter,
  name: "newAgentCenter",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/newAgentCenterFjyx",
  component: newAgentCenter,
  name: "newAgentCenterFjyx",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/teamPerformance",
  component: TeamPerformance,
  name: "teamPerformance",
  meta: {
    title: "团队业绩",
    foot: true
  }
}, {
  path: "/newteamPerformance",
  component: newTeamPerformance,
  name: "newteamPerformance",
  meta: {
    title: "团队业绩",
    foot: true
  }
}, {
  path: "/FranchiserRed",
  component: FranchiserRed,
  name: "FranchiserRed",
  meta: {
    title: "红包奖励记录列表",
    foot: true
  }
}, {
  path: "/newFranchiserRed",
  component: newFranchiserRed,
  name: "newFranchiserRed",
  meta: {
    title: "红包奖励记录列表",
    foot: true
  }
}, {
  path: "/teamCode",
  component: TeamCode,
  name: "teamCode",
  meta: {
    title: "我的激活码",
    foot: true
  }
}, {
  path: "/newteamCode",
  component: newTeamCode,
  name: "newteamCode",
  meta: {
    title: "我的激活码",
    foot: true
  }
}, {
  path: "/teamClient",
  component: TeamClient,
  name: "TeamClient",
  meta: {
    title: "我的客户",
    foot: true
  }
}, {
  path: "/newteamClient",
  component: newTeamClient,
  name: "newTeamClient",
  meta: {
    title: "我的客户",
    foot: true
  }
}, {
  path: "/codeHistory",
  component: CodeHistory,
  name: "codeHistory",
  meta: {
    title: "使用记录",
    foot: true
  }
}, {
  path: "/newcodeHistory",
  component: newCodeHistory,
  name: "newcodeHistory",
  meta: {
    title: "使用记录",
    foot: true
  }
}, {
  path: "/investmentCenter",
  component: InvestmentCenter,
  name: "investmentCenter",
  meta: {
    title: "招商中心",
    foot: true
  }
}, {
  path: "/regionalAgencyCenter",
  component: RegionalAgencyCenter,
  name: "regionalAgencyCenter",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/applyRegionalAgency",
  component: ApplyRegionalAgency,
  name: "applyRegionalAgency",
  meta: {
    title: "申请",
    foot: true
  }
}, {
  path: "/fixedReward",
  component: FixedReward,
  name: "fixedReward",
  meta: {
    title: "固定奖励",
    foot: true
  }
}, {
  path: "/courseIncome",
  component: CourseIncome,
  name: "courseIncome",
  meta: {
    title: "讲师分红",
    foot: true
  }
}, {
  path: "/DailyRed",
  component: DailyRed,
  name: "DailyRed",
  meta: {
    title: "",
    foot: false,
    is_single_share: true
  }
}, {
  path: "/GetRedRecord/:tag",
  component: GetRedRecord,
  name: "GetRedRecord",
  meta: {
    title: "红包领取记录",
    foot: true
  }
}, {
  path: "/RedAmount/:tag",
  component: RedAmount,
  name: "RedAmount",
  meta: {
    title: "红包额度记录",
    foot: true
  }
}, {
  path: "/RedReward",
  component: RedReward,
  name: "RedReward",
  meta: {
    title: "经销商红包奖励",
    foot: true
  }
}, {
  path: "/redRank",
  component: redRank,
  name: "redRank",
  meta: {
    title: "红包排名",
    foot: true
  }
},
// 红包二开
{
  path: "/DailyRedHome",
  component: DailyRedHome,
  name: "DailyRedHome",
  meta: {
    title: "红包奖励",
    foot: false,
    is_single_share: true
  }
},
// 推广页
{
  path: "/extensionPage",
  component: ExtensionPage,
  name: "ExtensionPage",
  meta: {
    title: "推广页",
    foot: true
  }
}, {
  path: "/awardRanking",
  component: AwardRanking,
  name: "AwardRanking",
  meta: {
    title: "奖项排行",
    foot: true
  }
}, {
  path: "/recordOfAwards/:id",
  component: RecordOfAwards,
  name: "RecordOfAwards",
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  path: "/myIncome",
  component: MyIncome,
  name: "MyIncome",
  meta: {
    title: "我的收入",
    foot: true
  }
},
// 数据统计
{
  path: "/myEarnings",
  component: myEarnings,
  name: "myEarnings",
  meta: {
    title: "领取收益",
    foot: true
  }
}, {
  path: "/earningList",
  component: earningList,
  name: "earningList",
  meta: {
    title: "领取收益",
    foot: true
  }
}, {
  path: "/stockholderBonus",
  component: stockholderBonus,
  name: "stockholderBonus",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/stockholderAchievement/:type",
  component: stockholderAchievement,
  name: "stockholderAchievement",
  meta: {
    title: "业绩明细",
    foot: true
  }
}, {
  path: "/point_activity_award_center",
  component: point_activity_award_center,
  name: "point_activity_award_center",
  meta: {
    title: "积分活动奖励",
    foot: true
  }
},
//区域选择
{
  path: "/selectionarea",
  component: Selectionarea,
  name: "selectionarea",
  meta: {
    title: "区域选择",
    foot: true
  }
},
//区域分红
{
  path: "/regionaldividend",
  component: Regionaldividend,
  name: "regionaldividend",
  meta: {
    title: "区域分红",
    foot: true
  }
},
//区域分红详情
{
  path: "/detailsdividends",
  component: Detailsdividends,
  name: "detailsdividends",
  meta: {
    title: "区域分红详情",
    foot: true
  }
},
// 信用值
{
  path: "/member/creditInfo",
  component: CreditInfo,
  name: "creditInfo",
  meta: {
    title: "信用值",
    foot: true
  }
},
// supplier
{
  path: "/member/supplier",
  component: Supplier,
  name: "supplier",
  meta: {
    title: "供应商申请",
    foot: true
  }
},
// comment
{
  path: "/CommentDetails/:order_id/:goods_id/:order_goods_id?/:comment_id/:uid",
  component: CommentDetails,
  name: "CommentDetails",
  meta: {
    title: "评价详情",
    foot: true
  }
}, {
  path: "/ReCommentDetails",
  component: ReCommentDetails,
  name: "ReCommentDetails",
  meta: {
    title: "追加评价",
    foot: true
  }
}, {
  path: "/OtherCommentDetails",
  component: OtherCommentDetails,
  name: "othercommentdetails",
  meta: {
    title: "其它评价",
    foot: true
  }
},
// order
{
  path: "/member/alipay/:status/:url?/:sn?",
  component: Alipay,
  name: "alipayCloud",
  meta: {
    title: "支付宝",
    foot: true
  }
}, {
  path: "/member/alipay/:status/:recharge_money/:order_pay_id/:uid/:pid",
  component: Alipay,
  name: "alipay",
  meta: {
    title: "支付宝",
    foot: true
  }
},
// 冻结币--支付
{
  path: "/member/alipay/:status/:recharge_money/:order_pay_id/:uid/:pid/:tag",
  component: Alipay,
  name: "fre_alipay",
  meta: {
    title: "支付宝",
    foot: true
  }
},
// 爱心值--支付
{
  path: "/member/alipay/:status/:recharge_money/:order_pay_id/:uid/:pid/:tag",
  component: Alipay,
  name: "love_alipay",
  meta: {
    title: "支付宝",
    foot: true
  }
},
//汇聚支付宝
{
  path: "/member/HJalipay/:status/:order_pay_id",
  component: Alipay,
  name: "HJalipay",
  meta: {
    title: "支付宝",
    foot: true
  }
}, {
  path: "/member/alipayClockin/:status/:url",
  component: AlipayClockin,
  name: "alipayClockin",
  meta: {
    title: "支付宝",
    foot: true
  }
}, {
  path: "/member/alipayCourse/:status/:url?",
  component: Alipay,
  name: "alipayCourse",
  meta: {
    title: "支付宝",
    foot: true
  }
}, {
  path: "/recharge_phone_bill/:status/:url?",
  component: Alipay,
  name: "recharge_phone_bill",
  meta: {
    title: "充值",
    foot: true
  }
}, {
  path: "/member/wxpay/:status",
  component: Wxpay,
  name: "wxpay",
  meta: {
    title: "微信",
    foot: true
  }
}, {
  path: "/member/orderList/:status/:orderType?",
  component: OrderList,
  name: "orderlist",
  meta: {
    title: "我的订单",
    foot: true
  }
}, {
  path: "/debtOrder/orderList/:status/:member_type?/:debtId?",
  component: OrderList,
  name: "debtOrder",
  meta: {
    title: "我的订单",
    foot: true
  }
}, {
  path: "/member/orderList/:status/:orderType",
  component: OrderList,
  name: "cashierOrderlist",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/member/orderList/:status/:orderType",
  component: OrderList,
  name: "storeOrderlist",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/member/orderList/:status/:orderType",
  component: OrderList,
  name: "supplierOrderlist",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/member/orderList/:status/:orderType",
  component: OrderList,
  name: "hotelOrderlist",
  meta: {
    title: "酒店订单",
    foot: true
  }
}, {
  path: "/member/orderList/:status/:orderType",
  component: OrderList,
  name: "auctionOrderlist",
  meta: {
    title: "拍卖订单",
    foot: true
  }
}, {
  path: "/group/orderList/:status/:orderType",
  component: OrderList,
  name: "GroupOrderlist",
  meta: {
    title: $i18n.t("拼团订单"),
    foot: true
  }
}, {
  path: "/lease_toy/orderList/:status/:orderType",
  component: OrderList,
  name: "lease_toyOrderlist",
  meta: {
    title: "租赁订单",
    foot: true
  }
}, {
  path: "/yz_lease_toy/orderList/:status/:orderType",
  component: OrderList,
  name: "yz_lease_toyOrderlist",
  meta: {
    title: "租赁订单",
    foot: true
  }
}, {
  path: "/stockorderList/:stock",
  component: OrderList,
  name: "stockorderList",
  meta: {
    title: "订单存货",
    foot: true
  }
}, {
  path: "/deliveryStation/DeliveryReplenish/:status/:orderType",
  component: OrderList,
  name: "DeliveryReplenish",
  meta: {
    title: "补货单",
    foot: true
  }
}, {
  path: "/hireCar/serviceReplenish/:status/:orderType",
  component: OrderList,
  name: "serviceReplenish",
  meta: {
    title: "补货单",
    foot: true
  }
}, {
  path: "/subsidiary/orderList",
  component: OrderList,
  name: "subsidiary",
  meta: {
    title: "分公司订单",
    foot: true
  }
}, {
  path: "/member/express/:refund_id",
  component: OrderExpress,
  name: "express",
  meta: {
    title: "退款快递信息",
    foot: true
  }
}, {
  path: "/member/orderdetail/:order_id/:orderType?/:show_detail_pay?",
  component: Goodsinfo,
  name: "orderdetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/member/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "cashierOrderdetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/member/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "storeOrderdetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/debtOrderDetail/:order_id/:orderType/:member_type",
  component: Goodsinfo,
  name: "debtOrderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/member/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "supplierOrderdetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/member/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "commissionOrderdetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/member/logistics/:id?",
  component: Logistics,
  name: "logistics",
  meta: {
    title: "物流详情",
    foot: true
  }
}, {
  path: "/member/logisticsDetails",
  component: OrderLogisticsDetails,
  name: "orderLogisticsDetails",
  meta: {
    title: "物流详情",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/member/track",
  component: track,
  name: "track",
  meta: {
    title: "物流轨迹",
    foot: true
  }
}, {
  path: "/member/evaluate/:order_id/:id",
  component: Evaluate,
  name: "evaluate",
  meta: {
    title: "评价",
    foot: true
  }
}, {
  path: "/member/replyEvaluate",
  component: ReplyEvaluate,
  name: "replyEvaluate",
  meta: {
    title: "回复评论",
    foot: true
  }
}, {
  path: "/member/addevaluate/:goods_id/:order_id/:id",
  component: Addevaluate,
  name: "addevaluate",
  meta: {
    title: "追加评价",
    foot: true
  }
}, {
  path: "/member/refund/:order_id/:order_type",
  component: Refund,
  name: "refund",
  meta: {
    title: "申请售后",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/member/aftersaleslist",
  component: Aftersaleslist,
  name: "aftersaleslist",
  meta: {
    title: "售后列表",
    foot: true
  }
}, {
  path: "/member/aftersales/:refund_id",
  component: Aftersales,
  name: "aftersales",
  meta: {
    title: "售后详情",
    foot: true
  }
}, {
  path: "/member/aftersales/:refund_id/:debt",
  component: Aftersales,
  name: "aftersalesDebt",
  meta: {
    title: "售后详情",
    foot: true
  }
}, {
  path: "/member/orderpay/:order_ids/:orderType?",
  component: OrderPay,
  name: "orderpay",
  meta: {
    title: $i18n.t("订单支付"),
    foot: true
  }
},
// 实名费用支付订单
{
  path: "/member/orderpay/:tag/:price",
  component: OrderPay,
  name: "authPay",
  meta: {
    title: $i18n.t("订单支付"),
    foot: true
  }
}, {
  path: "/member/order/verification/:order_id/:api",
  component: OrderVerification,
  name: "orderVerification",
  meta: {
    title: "订单核销",
    foot: true
  }
}, {
  path: "/member/order/cash/:order_pay_id/:pid",
  component: OrderCash,
  name: "orderCash",
  meta: {
    title: "门店支付",
    foot: true
  }
}, {
  path: "/member/orderrecord/:order_id",
  component: OrderRecord,
  name: "OrderRecord",
  meta: {
    title: "汇款记录",
    foot: true
  }
}, {
  path: "/member/negotiationRecord/:refund_id",
  component: NegotiationRecord,
  name: "negotiationRecord",
  meta: {
    title: "协商记录",
    foot: true
  }
},
// {path: '/member/orderpay/:status/:order_ids', component: OrderPay, name: 'orderpay',meta: { title: '订单支付', foot:true }},
// orderpay
{
  path: "/member/payErr",
  component: PayErr,
  name: "payErr",
  meta: {
    title: "支付取消或失败",
    foot: "true"
  }
},
// 支付错误
{
  path: "/member/payYes",
  component: PayYes,
  name: "PayYes",
  meta: {
    title: "支付成功",
    foot: "true"
  }
},
// 支付成功
{
  path: "/member/blacklist",
  component: Blacklist,
  name: "blacklist",
  meta: {
    title: "黑名单",
    foot: "true"
  }
}, {
  path: "/member/mandate",
  component: Mandate,
  name: "mandate",
  meta: {
    title: "微信授权失败",
    foot: "true"
  }
},
// coupon
{
  path: "/coupon/coupon_index",
  component: Coupon,
  name: "coupon",
  meta: {
    title: "优惠券",
    foot: false
  }
}, {
  path: "/coupon/coupon_store",
  component: CouponStore,
  name: "couponStore",
  meta: {
    title: "领券中心",
    foot: false
  }
}, {
  path: "/coupon/coupon_share_code",
  component: CouponShareCode,
  name: "CouponShareCode",
  meta: {
    title: "优惠券",
    foot: true
  }
}, {
  path: "/coupon/shareShow/:id",
  component: CouponShareShow,
  name: "CouponShareShow",
  meta: {
    title: "优惠券",
    foot: true
  }
}, {
  path: "/coupon/shareGet",
  component: CouponShareGet,
  name: "CouponShareGet",
  meta: {
    title: "优惠券",
    foot: true
  }
}, {
  path: "/coupon/checkList",
  component: CouponCheckList,
  name: "CouponCheckList",
  meta: {
    title: "优惠券核销",
    foot: false
  }
}, {
  path: "/coupon/independent_share/:id/:coupon_sn?",
  component: CouponIndependent,
  name: "CouponIndependent",
  meta: {
    title: "优惠券详情",
    foot: true
  }
}, {
  path: "/coupon/coupon_info",
  component: CouponInfo,
  name: "couponInfo",
  meta: {
    title: "详情",
    foot: true
  }
}, {
  path: "/coupon/share/:order_id",
  component: CouponShare,
  name: "couponShare",
  meta: {
    title: "分享优惠券",
    foot: true
  }
}, {
  path: "/coupon/get_coupon/:order_id",
  component: getCoupon,
  name: "getCoupon",
  meta: {
    title: "领取优惠券",
    foot: true
  }
}, {
  path: "/coupon/coupon_v2_detail/:id/:combine_show?",
  component: coupon_v2_detail,
  name: "coupon_v2_detail",
  meta: {
    title: "优惠券详情",
    foot: true
  }
},
// plug
// {
//   path: '/member/marketing',
//   component: Marketing,
//   name: 'marketing',
//   meta: {
//     title: '营销工具',
//     foot: true
//   }
// },
// {
//   path: '/member/messageSettings',
//   component: MessageSettings,
//   name: 'messageSettings',
//   meta: {
//     title: '消息提醒设置',
//     foot: true
//   }
// },
// Other
{
  path: "/distributionCouponSearch/:coupon_id",
  component: Search,
  name: "distributionCouponSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/search",
  component: Search,
  name: "search",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/LeaseSearch",
  component: Search,
  name: "LeaseSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/selfPickupPoint/search",
  component: Search,
  name: "selfPickSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/searchall",
  component: SearchAll,
  name: "searchAll",
  meta: {
    title: "全部商品",
    foot: true
  }
},
// {
//   path: "/register",
//   component: Register,
//   name: "register",
//   meta: {
//     title: "注册",
//     foot: true
//   }
// },
{
  path: "/registerAgreement",
  component: RegisterAgreement,
  name: "registerAgreement",
  meta: {
    title: "协议",
    foot: true
  }
},
// {
//   path: "/findpwd",
//   component: Findpwd,
//   name: "findpwd",
//   meta: {
//     title: "忘记密码",
//     foot: true
//   }
// },
{
  path: "/register_guide",
  component: RegisterGuide,
  name: "RegisterGuide",
  meta: {
    title: "下载APP",
    foot: true
  }
}, {
  path: "/category",
  component: Category,
  name: "category",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/selfPickupPoint/category",
  component: Category,
  name: "selfPickCategory",
  //自提点关联的供应商的商品和平台商品（包含聚合供应链商品）其他供应商商品不显示
  meta: {
    title: "分类",
    foot: false
  }
}, {
  path: "/category_child/:id",
  component: CategoryChild,
  name: "category_child",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/catelist/:id/:type?",
  component: Catelist,
  name: "catelist",
  meta: {
    title: "分类列表",
    foot: false
  }
}, {
  path: "/brand",
  component: Brand,
  name: "brand",
  meta: {
    title: "品牌",
    foot: false
  }
}, {
  path: "/brandgoods/:id",
  component: BrandGoods,
  name: "brandgoods",
  meta: {
    title: "品牌商品",
    foot: true
  }
}, {
  path: "/cart",
  component: Cart,
  name: "cart",
  meta: {
    title: "购物车",
    foot: false
  }
}, {
  path: "/cart_share",
  component: CartShare,
  name: "CartShare",
  meta: {
    title: "购物车分享",
    foot: true
  }
}, {
  path: "/cart/settlement",
  component: Settlement,
  name: "settlement",
  meta: {
    title: "结算",
    foot: true
  }
},
///grabGroup/:id(id为商品id)
{
  path: "/grabGroup/:id",
  component: Goods,
  name: "grab_group",
  meta: {
    title: "抢团活动详情",
    foot: true
  }
}, {
  path: "/goods/:id/:coid?",
  component: Goods,
  name: "goods",
  meta: {
    title: "商品详情",
    is_single_share: true,
    foot: true
  }
}, {
  path: "/debtGood/:id/:debtId?",
  component: Goods,
  name: "debtGood",
  meta: {
    title: "商品详情",
    is_single_share: true,
    foot: true
  }
}, {
  // 新零售商品
  path: "/newRetail/good/:id/:mode",
  component: Goods,
  name: "newRetailSalesGood",
  meta: {
    title: "商品详情",
    foot: true
  }
}, {
  path: "/comment/:id",
  component: GoodsComment,
  name: "GoodsComment",
  meta: {
    title: "商品评论",
    foot: true
  }
}, {
  path: "/groupWork/goods/:id",
  component: Goods,
  name: "groupWorkGood",
  //0.1元拼团
  meta: {
    title: $i18n.t("拼团活动详情"),
    foot: true
  }
}, {
  path: "/packageGoods/:id",
  component: PackageGoods,
  name: "packageGoods",
  meta: {
    title: "商品套餐页",
    foot: true
  }
}, {
  path: "/goods/:id/:tag/:store_id",
  component: GoodsO2O,
  name: "goodsO2O",
  meta: {
    title: "商品详情",
    is_single_share: true,
    foot: true
  }
},
// 扫码柜子商品
{
  path: "/goods/:id/:mark",
  component: CupGoods,
  name: "CupGoods",
  meta: {
    title: "商品详情",
    is_single_share: true,
    foot: true
  }
},
// {path: '/goodsorder/:tag/:goodsId/:optionsId/:total/:cart_ids', component: GoodsOrder,name: 'goodsorder',meta: {  title: '填写订单', foot:true }},
{
  path: "/orderDiyForm/:id",
  component: GoodsOrderDiyForm,
  name: "GoodsOrderDiyForm",
  meta: {
    title: "填写资料",
    foot: true
  }
}, {
  path: "/goodsorder",
  component: GoodsOrder,
  name: "goodsorder",
  meta: {
    title: $i18n.t("填写订单"),
    foot: true
  }
}, {
  path: "/debtgoodsorder/:debtId",
  component: GoodsOrder,
  name: "debtgoodsorder",
  meta: {
    title: $i18n.t("填写订单"),
    foot: true
  }
}, {
  path: "/goods/goodstabs/:id/:goods",
  component: Goodstabs,
  name: "goodstab",
  meta: {
    title: "商品详情内容",
    is_single_share: true,
    foot: true
  }
},
// {path:'/addons/yun_shop',redirect:'/'}

// sign
{
  path: "/member/sign",
  component: Sign,
  name: "sign",
  meta: {
    title: "签到",
    foot: true
  }
}, {
  path: "/member/signrules",
  component: Signrules,
  name: "signrules",
  meta: {
    title: "规则说明",
    foot: true
  }
}, {
  path: "/member/signrecord",
  component: Signrecord,
  name: "signrecord",
  meta: {
    title: "签到记录",
    foot: true
  }
},
// 排行榜 old
// {
//   path: "/ranking/index",
//   component: RankingIndex,
//   name: "rankingIndex",
//   meta: {
//     title: "排行榜",
//     foot: true
//   }
// },
// {
//   path: "/ranking/list/first/:action/:key",
//   component: RankingListFirst,
//   name: "rankingListFirst",
//   meta: {
//     title: "排行榜列表",
//     foot: true
//   }
// },
// {
//   path: "/ranking/list/second/:action",
//   component: RankingListSecond,
//   name: "rankingListSecond",
//   meta: {
//     title: "排行榜列表",
//     foot: true
//   }
// },

//排行榜 new
{
  path: "/Rankings",
  component: RankingIndex,
  name: "rankingIndex",
  meta: {
    title: "排行榜",
    foot: true
  }
},
//代理商分红
{
  path: "/AgentBonus",
  component: AgentBonus,
  name: "AgentBonus",
  meta: {
    title: "代理商分红",
    foot: true
  }
}, {
  path: "/GroupOrder",
  component: GroupOrder,
  name: "GroupOrder",
  meta: {
    title: "团队订单",
    foot: true
  }
}, {
  path: "/AgentCustomer",
  component: AgentCustomer,
  name: "AgentCustomer",
  meta: {
    title: "我的客户",
    foot: true
  }
},
// 招商中心
{
  path: "/member/enterprise/index",
  component: EnterpriseIndex,
  name: "enterprise_index",
  meta: {
    title: "招商",
    foot: true
  }
}, {
  path: "/member/enterprise/apply",
  component: EnterpriseApply,
  name: "enterprise_apply",
  meta: {
    title: "招商",
    foot: true
  }
}, {
  path: "/member/enterprise/supplier",
  component: EnterpriseSupplier,
  name: "enterprise_supplier",
  meta: {
    title: "我的供应商",
    foot: true
  }
}, {
  path: "/member/enterprise/supplierInfo/:id",
  component: EnterpriseSupplierInfo,
  name: "enterprise_supplier_info",
  meta: {
    title: "供应商详情",
    foot: true
  }
}, {
  path: "/member/enterprise/center/apply",
  component: EnterpriseCenterApply,
  name: "enterprose_center_apply",
  meta: {
    title: "招商中心",
    foot: true
  }
}, {
  path: "/member/enterprise/center/supplier",
  component: EnterpriseCenterSupplier,
  name: "enterprise_center_supplier",
  meta: {
    title: "我的供应商",
    foot: true
  }
},
// 招商-我的门店
{
  path: "/member/enterprise/store/:type",
  component: EnterpriseStore,
  name: "enterprise_store",
  meta: {
    title: "招商-我的门店",
    foot: true
  }
}, {
  path: "/others/integral_shop",
  component: integral_shop,
  name: "integral_shop",
  meta: {
    title: "",
    foot: true
  }
},
// 订单全返
{
  path: "/member/income/singleReturn",
  component: SingleReturnCenter,
  name: "single_return_center",
  meta: {
    title: "订单返现",
    foot: true
  }
}, {
  path: "/member/income/singleReturnListInfo/:id",
  component: SingleReturnListInfo,
  name: "single_return_list_info",
  meta: {
    title: "返现详情",
    foot: true
  }
}, {
  path: "/consumptionGift/detail/:id",
  component: SingleReturnListInfo,
  name: "consumptionGiftDetail",
  meta: {
    title: "返现详情",
    foot: true
  }
}, {
  path: "/member/income/video",
  component: function component() {
    return import("../views/member/income/singleReturn/single_return_video");
  },
  name: "single_return_video",
  meta: {
    title: "视频任务",
    foot: true
  }
},
// 团队等级全返
{
  path: "/member/income/teamLevelReturn",
  component: TeamLevelReturnCenter,
  name: "teamlevel_return_center",
  meta: {
    title: "团队返现",
    foot: true
  }
}, {
  path: "/member/income/teamLevelReturnListInfo/:id",
  component: TeamLevelReturnListInfo,
  name: "teamlevel_return_list_info",
  meta: {
    title: "返现详情",
    foot: true
  }
},
// 满额全返
{
  path: "/member/income/fullReturn",
  component: FullReturnCenter,
  name: "full_return_center",
  meta: {
    title: "满额返现",
    foot: true
  }
}, {
  path: "/member/income/fullReturn/new",
  component: FullReturnCenter,
  name: "full_return_center_new",
  meta: {
    title: "满额返现",
    foot: true
  }
}, {
  path: "/full_return_center_new_record",
  component: full_return_center_new_record,
  name: "full_return_center_new_record",
  meta: {
    title: "满额返现",
    foot: true
  }
}, {
  path: "/member/income/fullReturnListInfo/:id/:new?",
  component: FullReturnListInfo,
  name: "full_return_list_info",
  meta: {
    title: "返现详情",
    foot: true
  }
},
// 股东分红
{
  path: "/member/income/shareholderDividend",
  component: ShareholderDividend,
  name: "shareholder_dividend",
  meta: {
    title: "股东分红",
    foot: true
  }
},
// 股东奖励
{
  path: "/member/income/ShareholderReward",
  component: ShareholderReward,
  name: "ShareholderReward",
  meta: {
    title: "股东奖励",
    foot: true
  }
},
// 分销商全返
{
  path: "/member/income/level_return",
  component: LevelReturn,
  name: "level_return",
  meta: {
    title: "分销商全返",
    foot: true
  }
}, {
  path: "/love_trading",
  component: Love_trading,
  name: "love_trading",
  meta: {
    title: "交易中心",
    foot: true
  }
}, {
  path: "/love_trading_sell",
  component: Love_trading_sell,
  name: "love_trading_sell",
  meta: {
    title: "爱心值出售",
    foot: true
  }
  // },
  // //visit 邀请码
  // {
  //   path: '/visit',
  //   component: Visit,
  //   name: 'visit',
  //   meta: {
  //     title: '邀请码',
  //     foot: true
  //   }
}, {
  path: "/transformLoveV",
  component: Love_trading_sell,
  name: "transformLoveV",
  meta: {
    title: "转化爱心值",
    foot: true
  }
},
// 收银台
{
  path: "/cashier",
  component: cashier,
  name: "cashier",
  meta: {
    title: "收银台",
    foot: true
  }
}, {
  path: "/cashier_pay/:store_id",
  component: cashier_pay,
  name: "cashier_pay",
  meta: {
    title: "收银台",
    foot: true
  }
}, {
  path: "/cashier_qr",
  component: cashier_qr,
  name: "cashier_qr",
  meta: {
    title: "二维码",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/cashier_withdrawals",
  component: cashier_withdrawals,
  name: "cashier_withdrawals",
  meta: {
    title: "提现",
    foot: true
  }
}, {
  path: "/cashier_stat",
  component: cashier_stat,
  name: "cashier_stat",
  meta: {
    title: "统计结算",
    foot: true
  }
}, {
  path: "/show_balances/:pay_sn/:pay_type",
  component: show_balances,
  name: "showBalances",
  meta: {
    title: "支付奖励",
    foot: true
  }
}, {
  path: "/cashier_income/",
  component: cashier_income,
  name: "cashierIncome",
  meta: {
    title: "收入明细",
    foot: true
  }
}, {
  path: "/distribution_stat",
  component: cashier_stat,
  name: "distribution_stat",
  meta: {
    title: "统计结算",
    foot: true
  }
}, {
  path: "/o2o/home",
  component: o2oHome,
  name: "o2oHome",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/o2o/store_v2/:store_id",
  component: o2oStore_v2,
  name: "o2oStore_v2",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/o2o/location",
  component: o2oLocation,
  name: "o2oLocation",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/o2o/location/location",
  component: o2oLocation_loc,
  name: "o2oLocation_loc",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/o2o/location/city",
  component: o2oCity,
  name: "o2oCity",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/o2o/search/:city/:point",
  component: o2oSearch,
  name: "o2oSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/o2o/o2oSearchMultiple/:city/:point",
  component: o2oSearchMultiple,
  name: "o2oSearchMultiple",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/o2o/category/:id/:name?",
  component: o2oCategory,
  name: "o2oCategory",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/o2o/storeApply",
  component: StoreApply,
  name: "storeApply",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/o2o/storeWithdrawals",
  component: StoreWithdrawals,
  name: "storeWithdrawals",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/o2o/store_manage",
  component: StoreManage,
  name: "storeManage",
  meta: {
    title: "门店中心",
    foot: true
  }
}, {
  path: "/o2o/cloud_horn",
  component: cloudHorn,
  name: "cloudHorn",
  meta: {
    title: "云喇叭绑定",
    foot: true
  }
}, {
  path: "/o2oGroup_activitylist/:store_id",
  component: o2oGroupActivitylist,
  name: "o2oGroupActivitylist",
  meta: {
    title: "门店拼团",
    foot: false
  }
}, {
  path: "/openGroupList/:id/:store_id",
  component: openGroupList,
  name: "openGroupList",
  meta: {
    title: "开团列表",
    foot: false
  }
}, {
  path: "/openGroupDetail/:id",
  component: openGroupDetail,
  name: "openGroupDetail",
  meta: {
    title: "开团详情",
    foot: false
  }
}, {
  path: "/groupDataStatistics/:id",
  component: groupDataStatistics,
  name: "groupDataStatistics",
  meta: {
    title: "数据统计",
    foot: false
  }
}, {
  path: "/createGroup/:store_id/:id?",
  component: createGroup,
  name: "createGroup",
  meta: {
    title: "创建活动",
    foot: false
  }
}, {
  path: "/o2o/zkz_device",
  component: zkzDevice,
  name: "zkzDevice",
  meta: {
    title: "zkc设备绑定",
    foot: true
  }
}, {
  path: "/o2o/qr_code/:store_id",
  component: qrCode,
  name: "qrCode",
  meta: {
    title: "门店二维码",
    foot: true
  }
}, {
  path: "/o2o/collectionCode/:store_id",
  component: collectionCode,
  name: "collectionCode",
  meta: {
    title: "收款二维码",
    foot: true
  }
}, {
  path: "/o2o/grouplist",
  component: o2oGrouplist,
  name: "o2oGrouplist",
  meta: {
    title: "门店拼团",
    foot: false
  }
},
// {
//   path: "/o2o/privacy",
//   component: privacy,
//   name: "privacy",
//   meta: {
//     title: "隐私",
//     foot: true
//   }
// },
{
  path: "/store/search/:store_id/:fromHome?",
  component: StoreSearch,
  name: "StoreSearch",
  meta: {
    title: "商品搜索",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/conference/conferenceList",
  component: conferenceList,
  name: "conferenceList",
  meta: {
    title: "活动列表",
    foot: true
  }
}, {
  path: "/conference/activity/:id",
  component: activity,
  name: "activity",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/conference/my_activity_info/:id",
  component: MyActivityInfo,
  name: "myActivityInfo",
  meta: {
    title: "活动信息",
    foot: true
  }
}, {
  path: "/conference/sign/:id",
  component: conferenceSign,
  name: "conferenceSign",
  meta: {
    title: "活动签到",
    foot: true
  }
}, {
  path: "/activity/task/list/:id",
  component: ActivityTaskList,
  name: "activityTaskList",
  meta: {
    title: "活动任务",
    foot: true
  }
}, {
  path: "/clockin",
  component: ClockPunch,
  name: "ClockPunch",
  meta: {
    title: "早起打卡",
    foot: true
  }
}, {
  path: "/clockin/clockinrule",
  component: ClockPunchRule,
  name: "ClockPunchRule",
  meta: {
    title: "挑战规则",
    foot: true
  }
}, {
  path: "/clockin/clockinrecord",
  component: ClockPunchRecord,
  name: "ClockPunchRecord",
  meta: {
    title: "我的战绩",
    foot: true
  }
}, {
  path: "/member/myrelationsearch",
  component: MyRelationshipSearch,
  name: "MyRelationshipSearch",
  meta: {
    title: "我的关系",
    foot: true
  }
}, {
  path: "/rechargeCode",
  component: RechargeCode,
  name: "rechargeCode",
  meta: {
    title: "充值码",
    foot: true
  }
}, {
  path: "/rechargeCodeByQrCode/:qrCode",
  component: RechargeCode,
  name: "rechargeCodeByQrCode",
  meta: {
    title: "充值码",
    foot: true
  }
}, {
  path: "/o2o/home/homeseller/:store_id",
  component: HomeSeller,
  name: "HomeSeller",
  meta: {
    title: "商家",
    foot: true
  }
},
// {
//   path: '/o2o/home/homeseller/detail/:store_id',
//   component: HomeSellerDetail,
//   name: 'HomeSellerDetail',
//   meta: {
//     title: '商家详情',
//     foot: true
//   }
// },
// {
//   path: "/o2o/home/homeseller/address/:store_id",
//   component: HomeSellerAddress,
//   name: "HomeSellerAddress",
//   meta: {
//     title: "门店地址",
//     foot: true
//   }
// },

// 代付
{
  path: "/member/payanother/:order_id/:style?",
  component: PayAnother,
  name: "PayAnother",
  meta: {
    title: "代付",
    foot: true
  }
},
// 代付详情
{
  path: "/member/payanotherdetail",
  component: PayAnotherDetail,
  name: "PayAnotherDetail",
  meta: {
    title: "代付详情",
    foot: true
  }
},
// 保税真实信息添加
{
  path: "/member/address/myinfo",
  component: MyInfo,
  name: "myinfo",
  meta: {
    title: "我的信息",
    foot: true
  }
},
// 推广申请

{
  path: "/member/extension_apply",
  component: ExtensionApply,
  name: "ExtensionApply",
  meta: {
    title: "推广申请",
    foot: true
  }
},
// 会员等级列表

{
  path: "/member/membergradelist",
  component: MemberGradeList,
  name: "MemberGradeList",
  meta: {
    title: "会员等级",
    foot: true
  }
}, {
  path: "/member/rightGood/:level_id",
  component: RightGood,
  name: "RightGood",
  meta: {
    title: "权益商品",
    foot: true
  }
}, {
  path: "/member/policyManage",
  component: policyManage,
  name: "policyManage",
  meta: {
    title: "保单管理",
    foot: true
  }
},
// {
//   path: '/member/policyAdd',
//   component: policyAdd,
//   name: 'policyAdd',
//   meta: {
//     title: '保单添加',
//     foot: true
//   }
// },

// 会员等级详情

{
  path: "/member/membergradedetail/:id",
  component: MemberGradeListDetail,
  name: "MemberGradeListDetail",
  meta: {
    title: "会员等级",
    foot: true
  }
},
// 待退还

{
  path: "/member/return/:id",
  component: OrderReturn,
  name: "OrderReturn",
  meta: {
    title: "退还商品",
    foot: true
  }
}, {
  path: "/member/return/yz_lease_apply_adopt/:id",
  component: OrderReturn,
  name: "yz_lease_apply_adopt",
  meta: {
    title: "退还商品",
    foot: true
  }
},
// 待退还中

{
  path: "/member/returncheck/:id",
  component: OrderReturnCheck,
  name: "OrderReturnCheck",
  meta: {
    title: "退还商品",
    foot: true
  }
},
// 待确定

{
  path: "/member/returnsure/:id",
  component: OrderReturnSure,
  name: "OrderReturnSure",
  meta: {
    title: "退还商品",
    foot: true
  }
},
// 租赁归还售后详情

{
  path: "/member/returnafterdetail/:id",
  component: OrderReturnAftersalesDetail,
  name: "OrderReturnAftersalesDetail",
  meta: {
    title: "售后详情",
    foot: true
  }
},
// 会员退款迁移

{
  path: "/member/refundsmigration",
  component: MemberRefundsMigration,
  name: "MemberRefundsMigration",
  meta: {
    title: "退款迁移",
    foot: true
  }
},
// 商品详情品牌

{
  path: "/member/goodsbrand/:id",
  component: GoodsBrand,
  name: "GoodsBrand",
  meta: {
    title: "品牌详情",
    foot: true
  }
},
// 区域选择页面

{
  path: "/zone_search",
  component: ZoneChoose,
  name: "ZoneChoose",
  meta: {
    title: "区域选择",
    foot: true
  }
},
// 文章列表

{
  path: "/article_list",
  component: ArticleList,
  name: "ArticleList",
  meta: {
    title: "文章列表",
    foot: true
  }
},
// SupplierCenter 供应商管理中心

{
  path: "/supplier_center/:uid?",
  component: SupplierCenter,
  name: "SupplierCenter",
  meta: {
    title: "供应商管理",
    foot: true
  }
}, {
  path: "/commodity_management/:store_id/:store",
  component: CommodityManagement,
  name: "CommodityManagement",
  meta: {
    title: "商品管理",
    foot: true
  }
}, {
  path: "/commodity_editor/:store_id/:goods_id/:store",
  component: CommodityEditor,
  name: "CommodityEditor",
  meta: {
    title: "商品编辑",
    foot: true
  }
}, {
  path: "/commodity_release/:store_id/:goods_id?/:store",
  component: CommodityRelease,
  name: "CommodityRelease",
  meta: {
    title: "商品发布",
    foot: true
  }
}, {
  path: "/commodity_coupon",
  component: CommodityCoupon,
  name: "CommodityCoupon",
  meta: {
    title: "优惠券管理",
    foot: true
  }
}, {
  path: "/commodityCoupon_edit",
  component: CommodityCouponEdit,
  name: "CommodityCouponEdit",
  meta: {
    title: "优惠券编辑",
    foot: true
  }
}, {
  path: "/CommodityCoupon_give",
  component: CommodityCouponGive,
  name: "CommodityCouponGive",
  meta: {
    title: "优惠券发放",
    foot: true
  }
},
// 商品分类
{
  path: "/goods_classify/:store_id",
  component: GoodsClassify,
  name: "GoodsClassify",
  meta: {
    title: "商品分类",
    foot: true
  }
}, {
  path: "/add_classify/:store_id/:classify_id/:level",
  component: AddClassify,
  name: "AddClassify",
  meta: {
    title: "添加分类",
    foot: true
  }
}, {
  path: "/first_edit/:store_id/:classify_id/:level",
  component: FirstEdit,
  name: "FirstEdit",
  meta: {
    title: "一级分类",
    foot: true
  }
},
// 供应商--信息管理

{
  path: "/supplier_info_manage/:member_id",
  component: SupInfoManage,
  name: "SupInfoManage",
  meta: {
    title: "信息管理",
    foot: true
  }
},
// 转账
{
  path: "/transfer_account/:id",
  component: TransferAccount,
  name: "TransferAccount",
  meta: {
    title: "汇款支付",
    foot: true
  }
},
// 排队奖励记录
{
  path: "/reward_record",
  component: QueueRecord,
  name: "QueueRecord",
  meta: {
    title: "奖励记录",
    foot: true
  }
},
// 冻结币
{
  path: "/frozen_coin",
  component: FrozenCoin,
  name: "FrozenCoin",
  meta: {
    title: "冻结币",
    foot: true
  }
}, {
  path: "/recharge_coin",
  component: RechargeCoin,
  name: "RechargeCoin",
  meta: {
    title: "充值",
    foot: true
  }
}, {
  path: "/look_details_coin",
  component: LookDetailsCoin,
  name: "LookDetailsCoin",
  meta: {
    title: "查看明细",
    foot: true
  }
}, {
  path: "/put_forward_coin",
  component: PutForwardCoin,
  name: "PutForwardCoin",
  meta: {
    title: "提现",
    foot: true
  }
},
// 登录中转页面

{
  path: "/login_validate",
  component: LoginValidate,
  name: "LoginValidate",
  meta: {
    title: $i18n.t("登录"),
    foot: true
  }
},
// 音频页
{
  path: "/voiceList",
  component: VoiceList,
  name: "VoiceList",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/voiceDetail/:article_id",
  component: VoiceDetail,
  name: "VoiceDetail",
  meta: {
    title: " ",
    foot: true
  }
},
// 供应商店铺页面
{
  path: "/supplier_shop/:id",
  component: SupplierShop,
  name: "SupplierShop",
  meta: {
    title: "供应商店铺",
    foot: false
  }
}, {
  path: "/supplier_shop2/:id",
  component: SupplierShop2,
  name: "SupplierShop2",
  meta: {
    title: "供应商店铺",
    foot: true
  }
},
// 供应商店铺信息页面

{
  path: "/supplier_info/:id",
  component: SupplierInfo,
  name: "SupplierInfo",
  meta: {
    title: "供应商店铺",
    foot: true
  }
},
// 供应商搜索

{
  path: "/supplier_goods/:sid/:cid?/:is_hot?",
  component: SupplierSearch,
  name: "SupplierSearch",
  meta: {
    title: "搜索",
    foot: true
  }
},
// 共享链
{
  path: "/extension/reward_queue",
  component: RewardQueue,
  name: "RewardQueue",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/extension/reward_detail",
  component: RewardDetail,
  name: "RewardDetail",
  meta: {
    title: "奖励统计",
    foot: true
  }
}, {
  path: "/extension/reward_process/:source_code?/:queue_id?",
  component: RewardProcess,
  name: "RewardProcess",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/share_chain_index",
  component: share_chain_index,
  name: "share_chain_index",
  meta: {
    title: "共享链",
    foot: true
  }
}, {
  path: "/share_chain_dealDetail/:plugn",
  component: share_chain_dealDetail,
  name: "share_chain_dealDetail",
  meta: {
    title: "交易明细",
    foot: true
  }
},
// mryt
{
  path: "/extension/mryt",
  component: MRYT,
  name: "MRYT",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/extension/sale_commission",
  component: SaleCommission,
  name: "SaleCommission",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/extension/award_record",
  component: AwardRecord,
  name: "AwardRecord",
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  path: "/extension/award_detail",
  component: AwardDetail,
  name: "AwardDetail",
  meta: {
    title: "分红详情",
    foot: true
  }
},
// 手动分红
{
  path: "/profit",
  component: Profit,
  name: "profit",
  meta: {
    title: "",
    foot: true
  }
},
// 连锁店
{
  path: "/chain_shop_index",
  component: ChainShopIndex,
  name: "ChainShopIndex",
  meta: {
    title: "连锁店首页",
    foot: true
  }
}, {
  path: "/chain_shop_info/:id",
  component: ChainShopInfo,
  name: "ChainShopInfo",
  meta: {
    title: "门店信息",
    foot: true
  }
},
// 武昌医院--会务活动
{
  path: "/meeting_index",
  component: MeetingIndex,
  name: "MeetingIndex",
  meta: {
    title: "报名记录",
    foot: true
  }
}, {
  path: "/meeting_index_detail/:id/:member_id",
  component: MeetingIndexDetail,
  name: "MeetingIndexDetail",
  meta: {
    title: "活动报名信息",
    foot: true
  }
}, {
  path: "/meeting_extend_list/:id/:member_id",
  component: MeetingExtendList,
  name: "MeetingExtendList",
  meta: {
    title: "推广报名列表",
    foot: true
  }
}, {
  path: "/meeting_extend_detail/:id",
  component: MeetingExtendDetail,
  name: "MeetingExtendDetail",
  meta: {
    title: "推广报名详情",
    foot: true
  }
},
// 入驻商品
{
  path: "/enter_shop",
  component: EnterShop,
  name: "EnterShop",
  meta: {
    title: "入驻商品",
    foot: true
  }
}, {
  path: "/enter_protocol",
  component: EnterProtocol,
  name: "EnterProtocol",
  meta: {
    title: "入驻协议",
    foot: true
  }
}, {
  path: "/ApplyMerchant",
  component: ApplyMerchant,
  name: "ApplyMerchant",
  meta: {
    title: "申请商家",
    foot: true
  }
}, {
  path: "/submit_succeed",
  component: SubmitSucceed,
  name: "SubmitSucceed",
  meta: {
    title: "提交成功",
    foot: true
  }
}, {
  path: "/truck_list/:order_id",
  component: TruckList,
  name: "TruckList",
  meta: {
    title: "虚拟卡密",
    foot: true
  }
},
// 区间消费返点
{
  path: "/extension/consumer_return_point",
  component: consumerReturnPoint,
  name: "consumerReturnPoint",
  meta: {
    title: "区间消费返点",
    foot: true
  }
}, {
  path: "/wft_balance_recharge/:status/:url",
  component: Alipay,
  name: "wft_balance_recharge",
  meta: {
    title: "支付宝",
    foot: true
  }
},
// 爱心值充值
{
  path: "/love_recharge",
  component: LoveRecharge,
  name: "LoveRecharge",
  meta: {
    title: "爱心值充值",
    foot: true
  }
}, {
  path: "/love_alipay/:status/:pay_id",
  component: Alipay,
  name: "love_alipay2",
  meta: {
    title: "支付宝",
    foot: true
  }
},
// 支付宝爱心值充值
// 专区商品
{
  path: "/extension_goods",
  component: ExtensionGoods,
  name: "ExtensionGoods",
  meta: {
    title: "专区商品",
    foot: true
  }
}, {
  path: "/salesManagement",
  component: salesManagement,
  name: "salesManagement",
  meta: {
    title: "团队销量管理",
    foot: true
  }
},
// 398团队数据统计
{
  path: "/team_data_count",
  component: TeamDataCount,
  name: "TeamDataCount",
  meta: {
    title: "团队数据统计",
    foot: true
  }
},
// 易宝支付 AggregateEntRegEdit
{
  path: "/aggregate_ind_reg",
  component: AggregateIndReg,
  name: "AggregateIndReg",
  meta: {
    title: "个人注册",
    foot: true
  }
}, {
  path: "/aggregate_ind_reg_edit",
  component: AggregateIndRegEdit,
  name: "AggregateIndRegEdit",
  meta: {
    title: "重新申请个人注册",
    foot: true
  }
}, {
  path: "/aggregate_me_reg",
  component: AggregateMeReg,
  name: "AggregateMeReg",
  meta: {
    title: "个体工商户注册",
    foot: true
  }
}, {
  path: "/aggregate_me_reg_edit",
  component: AggregateMeRegEdit,
  name: "AggregateMeRegEdit",
  meta: {
    title: "重新申请个体工商户注册",
    foot: true
  }
}, {
  path: "/aggregate_ent_reg",
  component: AggregateEntReg,
  name: "AggregateEntReg",
  meta: {
    title: "企业注册 ",
    foot: true
  }
}, {
  path: "/aggregate_ent_reg_edit",
  component: AggregateEntRegEdit,
  name: "AggregateEntRegEdit",
  meta: {
    title: "重新申请企业注册 ",
    foot: true
  }
}, {
  path: "/aggregate_bank_card",
  component: AggregateBankCardSet,
  name: "AggregateBankCardSet",
  meta: {
    title: "结算银行卡修改 ",
    foot: true
  }
}, {
  path: "/aggregate_audit_part",
  component: AggregateAuditPart,
  name: "AggregateAuditPart",
  meta: {
    title: "入网信息",
    foot: true
  }
}, {
  path: "/company_edit",
  component: CompanyEdit,
  name: "CompanyEdit",
  meta: {
    title: "企业注册 ",
    foot: true
  }
}, {
  path: "/individual_edit",
  component: IndividualEdit,
  name: "IndividualEdit",
  meta: {
    title: "个体工商户注册",
    foot: true
  }
}, {
  path: "/show_apply",
  component: ShowApply,
  name: "ShowApply",
  meta: {
    title: "入网信息",
    foot: true
  }
},
// 邀请页面
{
  path: "/input_visit",
  component: InputVisit,
  name: "InputVisit",
  meta: {
    title: "邀请码",
    foot: true
  }
}, {
  path: "/inviter",
  component: Inviter,
  name: "Inviter",
  meta: {
    title: "邀请码",
    foot: true
  }
},
// 消费积分-爱心值
{
  path: "/member/Integral_love",
  component: Integral_love,
  name: "Integral_love",
  meta: {
    title: "消费积分-爱心值",
    foot: true
  }
}, {
  path: "/member/Integral_invest",
  component: Integral_invest,
  name: "Integral_invest",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/member/Integral_change/:type?",
  component: Integral_change,
  name: "Integral_change",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/member/Integral_love_detail",
  component: Integral_love_detail,
  name: "Integral_love_detail",
  meta: {
    title: "查看明细",
    foot: true
  }
}, {
  path: "/member/Integral_love_transfer",
  component: Integral_love_transfer,
  name: "Integral_love_transfer",
  meta: {
    title: "转让",
    foot: true
  }
}, {
  path: "/member/Integral_love_cash",
  component: Integral_love_cash,
  name: "Integral_love_cash",
  meta: {
    title: "提现",
    foot: true
  }
},
// 398一卡通
{
  path: "/o2o/card_index",
  component: CardIndex,
  name: "CardIndex",
  meta: {
    title: "一卡通",
    foot: true
  }
}, {
  path: "/o2o/card_open_member",
  component: CardOpenMembership,
  name: "CardOpenMembership",
  meta: {
    title: "一卡通",
    foot: true
  }
}, {
  path: "/o2o/choose_member_time/:goods_id/:level_id",
  component: CardChooseMembership,
  name: "CardChooseMembership",
  meta: {
    title: "一卡通",
    foot: true
  }
}, {
  path: "/o2o/card_right",
  component: CardRight,
  name: "CardRight",
  meta: {
    title: "一卡通",
    foot: true
  }
}, {
  path: "/o2o/new_card",
  component: NewCard,
  name: "NewCard",
  meta: {
    title: "开通会员",
    foot: true
  }
}, {
  path: "/o2o/newcard_list/:level_id",
  component: NewCardList,
  name: "NewCardList",
  meta: {
    title: "开通会员",
    foot: true
  }
},
// 门店优惠券
{
  path: "/o2o/cupcon/:id",
  component: StoreCoupon,
  name: "StoreCoupon",
  meta: {
    title: "门店优惠券",
    foot: true
  }
},
// 门店优惠券
{
  path: "/hotel/cupcon/:id",
  component: hotelCoupon,
  name: "hotelCoupon",
  meta: {
    title: "酒店优惠券",
    foot: true
  }
},
//发票中心
{
  path: "/invoiceCenter",
  component: invoiceCenter,
  name: "invoiceCenter",
  meta: {
    title: "发票中心",
    foot: true
  }
},
//发票编辑或者新建
{
  path: "/invoiceDetails/:id",
  component: invoiceDetails,
  name: "invoiceDetails",
  meta: {
    title: "发票详情",
    foot: true
  }
},
//发票详情
{
  path: "/invoiceOperation/:type",
  component: invoiceOperation,
  name: "invoiceOperation",
  meta: {
    title: "编辑发票",
    foot: true
  }
},
// 发票详情
{
  path: "/invoice/:order_id",
  component: Invoice,
  name: "Invoice",
  meta: {
    title: "发票详情",
    foot: true
  }
},
// 供应商提现
{
  path: "/member/supplier_withdrawals/:supplier/:member_id",
  component: Balance_withdrawals_v2,
  name: "supplier_withdrawals",
  meta: {
    title: "提现",
    foot: true
  }
},
//酒店供应链路由
{
  path: "/hotel_supply/search",
  component: HotelSupplySearch,
  name: "HotelSupplySearch",
  meta: {
    title: "酒店搜索",
    foot: true
  }
},
//酒店供应首页
{
  path: "/hotel_supply/HotelSupplyIndex",
  component: HotelSupplyIndex,
  name: "HotelSupplyIndex",
  meta: {
    title: "酒店",
    foot: true
  }
},
//酒店供应酒店主页
{
  path: "/hotel_supply/home_page/:id",
  component: HotelSupplyHome,
  name: "HotelSupplyHome",
  meta: {
    title: "酒店主页",
    foot: true
  }
},
//酒店供应酒店主页
{
  path: "/hotel_supply/hotelSupplyGoods/:hotel_id/:id",
  component: hotelSupplyGoods,
  name: "hotelSupplyGoods",
  meta: {
    title: "酒店房型详情",
    foot: true
  }
},
//酒店供应酒店下单页
{
  path: "/hotel_supply/HotelSupplyGoodsOrder/:hotel_id/:sid/:date_price/:start/:end",
  component: HotelSupplyGoodsOrder,
  name: "HotelSupplyGoodsOrder",
  meta: {
    title: "酒店",
    foot: true
  }
},
//酒店供应酒店下单页
{
  path: "/hotel_supply/hotel_order/:id",
  component: hotelOrder,
  name: "hotelOrder",
  meta: {
    title: "酒店详情",
    foot: true
  }
},
// 酒店路由
{
  path: "/hotel/index",
  component: HotelIndex,
  name: "HotelIndex",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/hotel/search",
  component: HotelSearch,
  name: "HotelSearch",
  meta: {
    title: "酒店搜索",
    foot: true
  }
}, {
  path: "/hotel/home_page/:id",
  component: HotelHome,
  name: "HotelHome",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/hotel/choose_room/:id",
  component: HotelChooseRoom,
  name: "HotelChooseRoom",
  meta: {
    title: "查看房型",
    foot: true
  }
},
// { path: '/hotel/goods',component: HotelGood,name: 'HotelGood',meta: {title: '商品详情',foot: true}},
{
  path: "/hotel/goods_order",
  component: HotelGoodsOrder,
  name: "HotelGoodsOrder",
  meta: {
    title: "酒店",
    foot: true
  }
}, {
  path: "/hotel/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "HotelOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/hotel/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "netCarDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/deliveryStation/deliveryDetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "deliveryStationOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/deliveryStation/DeliveryReplenishDetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "DeliveryReplenishDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/serviceStation/serviceReplenishDetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "serviceReplenishDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/group/groupDetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "groupDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/hotel/manage",
  component: HotelManage,
  name: "HotelManage",
  meta: {
    title: "酒店管理",
    foot: true
  }
}, {
  path: "/hotel/apply",
  component: StoreApply,
  name: "hotelApply",
  meta: {
    title: "申请",
    foot: true
  }
}, {
  path: "/hotel/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "HotelCenterOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
},
// 租凭订单详情
{
  path: "/lease_toy/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "LeaseToyOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/lease_toy/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "yzLeaseToyOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
},
//拍卖
{
  path: "/auctioneer/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "auctioneerOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
}, {
  path: "/auction/orderdetail/:order_id/:orderType",
  component: Goodsinfo,
  name: "auctionOrderDetail",
  meta: {
    title: "订单",
    foot: true
  }
},
// { path: '/hotel/location/:tag',component: o2oLocation_loc,name: 'hotel_location',meta: {title: '',foot: true}},
// { path: '/hotel/city/:tag',component: o2oCity,name: 'hotelCity',meta: {title: '',foot: true}},
// { path: '/hotel/location/:tag',component: o2oLocation,name: 'hotelLocation',meta: {title: '',foot: false}},

// 酒店收银台
{
  path: "/hotel/cashier_pay/:hotel_id",
  component: cashier_pay,
  name: "HotelCashierPay",
  meta: {
    title: "收银台",
    foot: true
  }
}, {
  path: "/hotel/cashier",
  component: HotelManage,
  name: "HotelCashier",
  meta: {
    title: "收银台",
    foot: true
  }
},
// 营业额
{
  path: "/extension/performance",
  component: Performance,
  name: "Performance",
  meta: {
    title: "营业额",
    foot: true
  }
},
// 合伙人团队
{
  path: "/extension/partnership_team",
  component: PartnershipTeam,
  name: "PartnershipTeam",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/extension/management_record",
  component: ManagementRecord,
  name: "ManagementRecord",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/extension/task_list",
  component: TaskList,
  name: "TaskList",
  meta: {
    title: "任务中心",
    foot: true
  }
},
// 消费红包
{
  path: "/extension/consume_packet",
  component: ConsumePacket,
  name: "ConsumePacket",
  meta: {
    title: "消费红包",
    foot: true
  }
}, {
  path: "/extension/consume_packet_success",
  component: ConsumePacketSuccess,
  name: "ConsumePacketSuccess",
  meta: {
    title: " ",
    foot: true
  }
}, {
  path: "/member/team_distribution",
  component: TeamDistribution,
  name: "TeamDistribution",
  meta: {
    title: "团队分销商统计",
    foot: true
  }
},
// 团队管理奖
{
  path: "/extension/team_manage_reward",
  component: TeamManageReward,
  name: "team_manage_reward",
  meta: {
    title: "团队管理奖",
    foot: true
  }
},
// 挂单
{
  path: "/entry_orders/coupon_exchange",
  component: EntryExchange,
  name: "EntryExchange",
  meta: {
    title: "积分兑换",
    foot: true
  }
}, {
  path: "/entry_orders/entry_record",
  component: CouponExrecord,
  name: "CouponExrecord",
  meta: {
    title: "获得记录",
    foot: true
  }
}, {
  path: "/entry_orders/entry_volume",
  component: EntryVolume,
  name: "EntryVolume",
  meta: {
    title: "商品零售",
    foot: true
  }
}, {
  path: "/entry_orders/money_detail",
  component: MoneyDetail,
  name: "MoneyDetail",
  meta: {
    title: "提成明细",
    foot: true
  }
}, {
  path: "/entry_orders/my_volume",
  component: MyVolume,
  name: "MyVolume",
  meta: {
    title: "我的零售",
    foot: true
  }
}, {
  path: "/entry_orders/volume_detail",
  component: VolumeDetail,
  name: "VolumeDetail",
  meta: {
    title: "批发券明细",
    foot: true
  }
}, {
  path: "/entry_orders/wholesale_volume",
  component: WholesaleVolume,
  name: "WholesaleVolume",
  meta: {
    title: "我的批发券",
    foot: true
  }
}, {
  path: "/entry_orders/home",
  component: paddingOrder_Home,
  name: "paddingOrder_Home",
  meta: {
    title: "批发区首页",
    foot: true
  }
}, {
  path: "/others/material_center",
  component: materialCenter,
  name: "materialCenter",
  meta: {
    title: "素材中心",
    foot: false
  }
}, {
  path: "/others/materialDetail/:id",
  component: materialDetail,
  name: "materialDetail",
  meta: {
    title: "素材中心",
    foot: true
  }
}, {
  path: "/others/material_search",
  component: materialSearch,
  name: "materialSearch",
  meta: {
    title: "素材搜索",
    foot: true
  }
}, {
  path: "/member/redEnvelope/:aid",
  component: redEnvelope,
  name: "redEnvelope",
  meta: {
    title: "口令红包",
    foot: false
  }
}, {
  path: "/member/redEnvelopeRecord/:tag/:aid",
  component: redEnvelopeRecord,
  name: "redEnvelopeRecord",
  meta: {
    title: "口令红包",
    foot: true
  }
},
//虚拟币
{
  path: "/others/address_manage",
  component: addressManage,
  name: "addressManage",
  meta: {
    title: "提出地址管理",
    foot: true
  }
}, {
  path: "/others/address_add",
  component: addressAdd,
  name: "addressAdd",
  meta: {
    title: "新增提出地址",
    foot: true
  }
}, {
  path: "/others/revise_address/:id",
  component: reviseAddress,
  name: "reviseAddress",
  meta: {
    title: "修改提出地址",
    foot: true
  }
}, {
  path: "/others/hand_withdraw",
  component: handWithdraw,
  name: "handWithdraw",
  meta: {
    title: "手动提现",
    foot: true
  }
}, {
  path: "/extension/dealer_award",
  component: DealerAward,
  name: "DealerAward",
  meta: {
    title: "经销商奖励",
    foot: true
  }
}, {
  path: "/extension/dealer_detail",
  component: DriverDetail,
  name: "DriverDetail",
  meta: {
    title: "奖励详情",
    foot: true
  }
}, {
  path: "/extension/PeerAward",
  component: DealerPeerAward,
  name: "DealerPeerAward",
  meta: {
    title: "经销商平级奖",
    foot: true
  }
}, {
  path: "/extension/peerAwardes_withdraw",
  component: peerAwardes_withdraw,
  name: "peerAwardes_withdraw",
  meta: {
    title: "经销商平级奖",
    foot: true
  }
}, {
  path: "/WeightedDividend",
  component: WeightedDividend,
  name: "WeightedDividend",
  meta: {
    title: "经销商分红",
    foot: true
  }
},
//酒店二维码 cashier_qr
{
  path: "/cashier_qr/:id/:tag",
  component: cashier_qr,
  name: "hotel_cashier_qr",
  meta: {
    title: "二维码",
    foot: true,
    notKeepAlive: true
  }
},
// 门店余额收款码
{
  path: "/cashier_qr/:id/:tag",
  component: cashier_qr,
  name: "homesellerCode",
  meta: {
    title: "我的二维码",
    foot: true,
    notKeepAlive: true
  }
},
// 统计结算 cashier_stat
{
  path: "/cashier_stat/:hotel_id/:tag",
  component: cashier_stat,
  name: "hotel_cashier_stat",
  meta: {
    title: "统计结算",
    foot: true
  }
}, {
  path: "/member/orderdetail/:order_id",
  component: Goodsinfo,
  name: "HotelCashierOrderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
},
//服务站
{
  path: "/member/serviceStation",
  component: ServiceStation,
  name: "serviceStation",
  meta: {
    title: "服务站",
    foot: true
  }
},
//提成明细
{
  path: "/commissionList/:orderType",
  component: CommissionList,
  name: "CommissionList",
  meta: {
    title: "提成明细",
    foot: true
  }
}, {
  path: "/member/distribution_orders",
  component: DistributionOrders,
  name: "DistributionOrders",
  meta: {
    title: "",
    foot: true
  }
},
//微社区
{
  path: "/microHome",
  component: MicroHome,
  name: "MicroHome",
  redirect: "/microHome/microIndex",
  meta: {
    title: "微社区首页入口",
    foot: false
  },
  children: [{
    path: "microIndex",
    component: MicroIndex,
    name: "MicroIndex",
    meta: {
      title: "首页",
      foot: true
    }
  }, {
    path: "microAttention",
    component: microAttention,
    name: "microAttention",
    meta: {
      title: "关注",
      foot: true
    }
  }, {
    path: "/microRelease",
    component: MicroRelease,
    name: "microRelease",
    meta: {
      title: "微社区发布",
      foot: true,
      notKeepAlive: true
    }
  }]
}, {
  path: "/microFanlist",
  component: microFanlist,
  name: "microFanlist",
  meta: {
    title: "关注和粉丝",
    foot: true
  }
},
// {
//   path: "/microIndex",
//   component: MicroIndex,
//   name: "microIndex",
//   meta: {
//     title: "微社区首页",
//     foot: true
//   }
// },
{
  path: "/microsearchindex",
  component: Microsearchindex,
  name: "microSearchIndex",
  meta: {
    title: "微社区搜索",
    foot: true
  }
}, {
  path: "/microclassification",
  component: Microclassification,
  name: "microClassification",
  meta: {
    title: "微社区分类搜索",
    foot: true
  }
}, {
  path: "/microcommentdetails/:stickId",
  component: Microcommentdetails,
  name: "microCommentDetails",
  meta: {
    title: "微帖详情",
    foot: true
  }
}, {
  path: "/microhomepage",
  component: microhomepage,
  name: "microhomepage",
  meta: {
    title: "个人主页",
    foot: true
  }
}, {
  path: "/microEdit",
  component: MicroEdit,
  name: "microEdit",
  meta: {
    title: "微社区发布编辑",
    foot: true
  }
},
//历史拼团
{
  path: "/history_group",
  component: historyGroup,
  name: "historyGroup",
  meta: {
    title: "历史成团记录",
    foot: true
  }
},
//拼团
{
  path: "/group_detail/:id/:store_id?",
  component: GroupDetail,
  name: "GroupDetail",
  meta: {
    title: $i18n.t("拼团详情"),
    foot: true
  }
}, {
  path: "/group_list",
  component: GroupList,
  name: "GroupList",
  meta: {
    title: "拼团列表",
    foot: true
  }
}, {
  path: "/zhpGroup/list",
  component: GroupList,
  name: "zhpGroupList",
  meta: {
    title: "拼团列表",
    foot: true
  }
}, {
  path: "/zhpFinance",
  component: zhpFinance,
  name: "zhpFinance",
  meta: {
    title: "财务统计",
    foot: true
  }
}, {
  path: "/groups/:id/:store_id?",
  component: Goods,
  name: "GroupGoods",
  meta: {
    title: $i18n.t("拼团活动详情"),
    //商品活动详情页
    is_single_share: true,
    foot: true
  }
}, {
  path: "/zhpGroups/:id",
  component: Goods,
  name: "zhpGroup",
  meta: {
    title: $i18n.t("拼团活动详情"),
    //拼团活动详情页（珍惠拼插件）
    foot: true
  }
}, {
  path: "/ywmGroups/:id",
  component: Goods,
  name: "ywmGroup",
  meta: {
    title: $i18n.t("拼团活动详情"),
    //拼团活动详情页（ywm-fight-groups插件）
    foot: true
  }
}, {
  path: "/mygroups",
  component: MyGroups,
  name: "MyGroups",
  meta: {
    title: "我的拼团",
    //我的拼团
    foot: true
  }
}, {
  path: "/zhp/mygroups/:status?",
  component: MyGroups,
  name: "zhpMyGroups",
  meta: {
    title: "我的珍惠拼",
    //珍惠拼拼团抽奖
    foot: true
  }
}, {
  path: "/group/contribution",
  component: groupContribution,
  name: "groupContribution",
  meta: {
    title: "",
    //拼团贡献值
    foot: true
  }
}, {
  path: "/group/contribution/details/:id",
  component: groupContributionDetails,
  name: "groupContributionDetails",
  meta: {
    title: "",
    //拼团贡献值
    foot: true
  }
}, {
  path: "/group/contribution/log/:id",
  component: groupContributionDetailslog,
  name: "groupContributionDetailslog",
  meta: {
    title: "",
    //拼团贡献值
    foot: true
  }
}, {
  path: "/group/groupcblog",
  component: groupcblog,
  name: "groupcblog",
  meta: {
    title: "",
    //贡献值
    foot: true
  }
}, {
  path: "/group/pastLog",
  component: pastLog,
  name: "pastLog",
  meta: {
    title: "",
    //拼团贡献值
    foot: true
  }
}, {
  path: "/group/redeem/:id",
  component: groupRedeem,
  name: "groupRedeem",
  meta: {
    title: "兑换",
    //兑换
    foot: true
  }
}, {
  path: "/zhp/groupDetail/:id",
  component: zhpgroupDetail,
  name: "zhpgroupDetail",
  meta: {
    title: "参团详情",
    //兑换
    foot: true
  }
}, {
  path: "/zhp/redeemLog/:id",
  component: redeemLog,
  name: "redeemLog",
  meta: {
    title: "兑换明细",
    //兑换
    foot: true
  }
},
//拼购列表
{
  path: "/groupBuyList",
  component: GroupBuyList,
  name: "GroupBuyList",
  meta: {
    title: "拼购列表",
    foot: false
  }
}, {
  path: "/myGroupBuy",
  component: myGroupBuy,
  name: "myGroupBuy",
  meta: {
    title: "我的拼购",
    foot: true
  }
}, {
  path: "/groupBuy/:aid/:id",
  component: groupBuy,
  name: "groupBuy",
  meta: {
    title: "拼购活动详情",
    //商品详情页
    foot: true
  }
}, {
  path: "/groupBuyDetail/:id",
  component: groupBuyDetail,
  name: "groupBuyDetail",
  meta: {
    title: "拼购详情",
    foot: false
  }
},
// {
//   path: '/groupBuyGoodslist',
//   component: groupBuyGoodslist,
//   name: 'groupBuyGoodslist',
//   meta: {
//     title: '拼购商品列表',
//     foot: true
//   }
// },

//语音商品，已改为goods/：id
{
  path: "/voice_good/:id",
  component: VoiceGood,
  name: "VoiceGood",
  meta: {
    title: "详情",
    foot: true
  }
}, {
  path: "/my_voices",
  component: MyVoices,
  name: "MyVoices",
  meta: {
    title: "我的语音",
    foot: true
  }
}, {
  path: "/lottery",
  component: Lottery,
  name: "Lottery",
  meta: {
    title: "幸运大抽奖",
    foot: true
  }
}, {
  path: "/Lottery_record",
  component: LotteryRecord,
  name: "LotteryRecord",
  meta: {
    title: "中奖列表",
    foot: true
  }
}, {
  path: "/extension/custom_queue",
  component: CustomQueue,
  name: "CustomQueue",
  meta: {
    title: "",
    foot: true
  }
},
//会务活动
{
  path: "/MyActivity",
  component: MyActivity,
  name: "MyActivity",
  meta: {
    title: "我的活动",
    foot: false
  }
}, {
  path: "/videoAgreement",
  component: videoAgreement,
  name: "videoAgreement",
  meta: {
    title: "用户协议",
    foot: true
  }
}, {
  path: "/Mylove",
  component: Mylove,
  name: "Mylove",
  meta: {
    title: "我的关注",
    foot: false
  }
}, {
  path: "/myVideo/:id?",
  component: myVideo,
  name: "myVideo",
  meta: {
    title: "个人主页",
    foot: true
  }
}, {
  path: "/videoList",
  component: VideoList,
  name: "VideoList",
  meta: {
    foot: false
  }
}, {
  path: "/Registration",
  component: Registration,
  name: "Registration",
  meta: {
    title: "报名情况",
    foot: true
  }
}, {
  path: "/videoDetail",
  component: VideoDetail,
  name: "VideoDetail",
  meta: {
    foot: true
  }
}, {
  path: "/RegistrationDetail",
  component: RegistrationDetail,
  name: "RegistrationDetail",
  meta: {
    title: "报名详情",
    foot: true
  }
}, {
  path: "/releaseVideo",
  component: releaseVideo,
  name: "releaseVideo",
  meta: {
    title: "发布视频",
    foot: true
  }
}, {
  path: "/Myshaky",
  component: Myshaky,
  name: "Myshaky",
  meta: {
    title: "我的报名",
    foot: false
  }
}, {
  path: "/addVideoGood",
  component: addVideoGood,
  name: "addVideoGood",
  meta: {
    title: "添加商品",
    foot: true
  }
}, {
  path: "/lecturerDetail/:member_id/:id",
  component: lecturerDetail,
  name: "lecturerDetail",
  meta: {
    title: "讲师详情",
    foot: true
  }
}, {
  path: "/enroll/:id/:exchange_code_id?",
  component: enroll,
  name: "enroll",
  meta: {
    title: "立即报名",
    foot: true
  }
}, {
  path: "/activityHome",
  component: activityHome,
  name: "activityHome",
  meta: {
    title: "首页",
    foot: false
  }
}, {
  path: "/enrollEnter/:id/:exchange_code_id?",
  component: enrollEnter,
  name: "enrollEnter",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/searchResult",
  component: searchResult,
  name: "searchResult",
  meta: {
    title: "搜索结果",
    foot: true
  }
}, {
  path: "/signIn/:id",
  component: signIn,
  name: "signIn",
  meta: {
    title: "签到",
    foot: false
  }
}, {
  path: "/Sort/:id?",
  component: Sort,
  name: "Sort",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/VideoReward",
  component: VideoReward,
  name: "VideoReward",
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  path: "/TeamPerformanceAward",
  component: TeamPerformanceAward,
  name: "TeamPerformanceAward",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/performanceNew",
  component: TeamPerformanceAward,
  name: "performanceNew",
  meta: {
    title: "",
    foot: true
  }
},
// 宠物医院调查表
{
  path: "/others/survey",
  component: survey,
  name: "survey",
  meta: {
    title: "调查表",
    foot: true
  }
},
// 分销管理奖励
{
  path: "/member/income/distributionHome",
  component: DistributionHome,
  name: "DistributionHome",
  meta: {
    title: "分销管理奖励",
    foot: true
  }
},
// 分销奖励
{
  path: "/member/income/distributionDetail",
  component: DistributionDetail,
  name: "DistributionDetail",
  meta: {
    title: "奖励详情",
    foot: true
  }
},
// 柯怡 管理奖
{
  path: "/member/manageAward",
  component: ManageAward,
  name: "ManageAward",
  meta: {
    title: "管理奖",
    foot: true
  }
},
// 柯怡 排序共享奖
{
  path: "/member/shareAward_sort",
  component: ShareAward,
  name: "shareAward_sort",
  meta: {
    title: "排序共享奖",
    foot: true
  }
},
// 柯怡 直推共享奖
{
  path: "/member/shareAward_directly",
  component: ShareAward,
  name: "shareAward_directly",
  meta: {
    title: "直推共享奖",
    foot: true
  }
},
// 自提点申请
{
  path: "/member/selfCarryApply",
  component: SelfCarryApply,
  name: "SelfCarryApply",
  meta: {
    title: "申请",
    foot: true
  }
},
// 自提点列表
{
  path: "/selfCarry_info",
  component: SelfCarry_info,
  name: "SelfCarry_info",
  meta: {
    title: "",
    foot: true
  }
},
// 自提点核销优惠券
{
  path: "/selfCarry/writeOffCodePage/:coupon_id",
  component: function component() {
    return import("../views/member/self_carry/writeOffCodePage");
  },
  name: "selfCarryWriteOffCodePage",
  meta: {
    title: "优惠券核销",
    foot: true,
    notKeepAlive: true
  }
},
// 自提点奖励
{
  path: "/member/selfCarry_reward",
  component: selfCarry_reward,
  name: "selfCarry_reward",
  meta: {
    title: "奖励",
    foot: true
  }
},
// 平级奖
{
  path: "/member/peersAward",
  component: PeersAward,
  name: "peersAward",
  meta: {
    title: "平级奖",
    foot: true
  }
}, {
  path: "/picture_album",
  component: pictureAlbum,
  name: "pictureAlbum",
  meta: {
    title: "画册",
    is_single_share: true,
    foot: true
  }
},
// 广告中心
{
  path: "/member/admarketHome",
  component: admarketHome,
  name: "admarketHome",
  meta: {
    title: "广告中心",
    foot: true
  }
},
// 广告中心-我的设备
{
  path: "/member/myDevice",
  component: MyDevice,
  name: "MyDevice",
  meta: {
    title: "我的设备",
    foot: true
  }
},
// 广告中心-广告管理
{
  path: "/member/DeviceManger",
  component: DeviceManger,
  name: "DeviceManger",
  meta: {
    title: "广告管理",
    foot: true
  }
},
// 广告中心-广告主申请
{
  path: "/member/adApply",
  component: ADApply,
  name: "ADApply",
  meta: {
    title: "广告主申请",
    foot: true
  }
},
// 广告中心-我的设备=>设备详情
{
  path: "/member/deviceDetail/:sn",
  component: DeviceDetail,
  name: "DeviceDetail",
  meta: {
    title: "设备详情",
    foot: true
  }
},
// 广告中心-我的设备=>设备详情=>店长提成
{
  path: "/member/managerCommission",
  component: managerCommission,
  name: "managerCommission",
  meta: {
    title: "设备详情",
    foot: true
  }
}, {
  path: "/advertiser",
  component: advertiser,
  name: "advertiser",
  meta: {
    title: "广告主",
    foot: true
  }
}, {
  path: "/addAdvertising",
  component: addAdvertising,
  name: "addAdvertising",
  meta: {
    title: "添加广告",
    foot: true
  }
}, {
  path: "/editAdvertising/:id",
  component: addAdvertising,
  name: "editAdvertising",
  meta: {
    title: "编辑广告",
    foot: true
  }
}, {
  path: "/advertisingFee",
  component: advertisingFee,
  name: "advertisingFee",
  meta: {
    title: "广告费记录",
    foot: true
  }
}, {
  path: "/placedAdvertising",
  component: placedAdvertising,
  name: "placedAdvertising",
  meta: {
    title: "投放的广告",
    foot: true
  }
},
// 奖金池
{
  path: "/others/revenus_bonus",
  component: revenus_bonus,
  name: "revenus_bonus",
  meta: {
    title: "收益奖",
    foot: true
  }
}, {
  path: "/recommend_reward",
  component: recommend_reward,
  name: "recommend_reward",
  meta: {
    title: "直荐奖",
    foot: true
  }
}, {
  path: "/share_reward",
  component: share_reward,
  name: "share_reward",
  meta: {
    title: "分享奖",
    foot: true
  }
},
// 电子合同
{
  path: "/signBusiness",
  component: signIndex,
  name: "signBusiness",
  meta: {
    title: "个人中心",
    foot: false
  }
}, {
  path: "/signIndex",
  component: signBusiness,
  name: "signIndex",
  // 多企业
  meta: {
    title: "合同中心",
    foot: false
  }
}, {
  path: "/signPerson",
  component: signPerson,
  name: "signPerson",
  meta: {
    title: "合同中心",
    foot: false
  }
},
// {
//   path: "/signGuide/:show",
//   component: signGuide,
//   name: "signGuide",
//   meta: { title: "", foot: true }
// },
{
  path: "/personBank",
  component: personBank,
  name: "personBank",
  meta: {
    title: "个人认证",
    foot: true
  }
}, {
  path: "/identifyResult/:show",
  component: identifyResult,
  name: "identifyResult",
  meta: {
    title: "认证结果",
    foot: true
  }
}, {
  path: "/faceResult",
  component: faceResult,
  name: "faceResult",
  meta: {
    title: "认证结果",
    foot: true
  }
}, {
  path: "/signCompany",
  component: signCompany,
  name: "signCompany",
  meta: {
    title: "企业认证",
    foot: true
  }
}, {
  path: "/contractBills",
  component: contractBills,
  name: "contractBills",
  meta: {
    title: "合同账单",
    foot: true
  }
}, {
  path: "/staffManagement",
  component: staffManagement,
  name: "staffManagement",
  meta: {
    title: "员工管理",
    foot: true
  }
}, {
  path: "/addStaff",
  component: addStaff,
  name: "addStaff",
  meta: {
    title: "添加员工",
    foot: true
  }
}, {
  path: "/contractList",
  component: contractList,
  name: "contractList",
  meta: {
    title: "合同列表",
    foot: true
  }
}, {
  path: "/companyContractList",
  //公司合同
  component: companyContractList,
  name: "companyContractList",
  meta: {
    title: "合同列表",
    foot: true
  }
}, {
  path: "/signMission/:id",
  component: signMission,
  name: "signMission",
  meta: {
    title: "任务信息",
    foot: true
  }
}, {
  path: "/signPage/:id",
  component: signPage,
  name: "signPage",
  meta: {
    title: "签署页",
    foot: true
  }
},
//电子合同新增的优化页面
{
  path: "/signSeeTemplate",
  component: signSeeTemplate,
  name: "signSeeTemplate",
  meta: {
    title: "预览模版",
    foot: true
  }
}, {
  path: "/launchSign/:template_id?/:id?",
  component: launchSign,
  name: "launchSign",
  meta: {
    title: "发起签署",
    foot: true
  }
}, {
  path: "/contractFilling",
  component: contractFilling,
  name: "contractFilling",
  meta: {
    title: "发起签署",
    foot: true
  }
},
// 电子合同插件
{
  path: "/signIndexPlu",
  component: signIndexPlu,
  name: "signIndexPlu",
  meta: {
    title: "个人中心",
    foot: false
  }
}, {
  path: "/personBankPlu",
  component: personBankPlu,
  name: "personBankPlu",
  meta: {
    title: "个人认证",
    foot: true
  }
}, {
  path: "/identifyResultPlu/:show",
  component: identifyResultPlu,
  name: "identifyResultPlu",
  meta: {
    title: "认证结果",
    foot: true
  }
}, {
  path: "/signCompanyPlu",
  component: signCompanyPlu,
  name: "signCompanyPlu",
  meta: {
    title: "企业认证",
    foot: true
  }
}, {
  path: "/contractListPlu",
  component: contractListPlu,
  name: "contractListPlu",
  meta: {
    title: "合同列表",
    foot: true
  }
}, {
  path: "/signMissionPlu/:id",
  component: signMissionPlu,
  name: "signMissionPlu",
  meta: {
    title: "任务信息",
    foot: true
  }
}, {
  path: "/signPagePlu/:id",
  component: signPagePlu,
  name: "signPagePlu",
  meta: {
    title: "签署页",
    foot: true
  }
},
//契约锁

//客服
// {
//   path: '/chatIndex/:groupId/:employeeId',
//   component: chatIndex,
//   name: 'chatIndex',
//   meta: { title: '会话', foot: true }
// },
// {
//   path: '/employeeChat/:user_id?',
//   component: employeeChat,
//   name: 'employeeChat',
//   meta: { title: '客户会话', foot: true }
// },
{
  path: "/personalProfile",
  component: PersonalProfile,
  name: "personalProfile",
  meta: {
    title: "简介",
    foot: true
  }
}, {
  path: "/chatWindow",
  component: chatWindow,
  name: "chatWindow",
  meta: {
    title: "客户会话",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/chatList",
  component: chatList,
  name: "chatList",
  meta: {
    title: "会话",
    foot: false
  }
},
// 知识库
{
  path: "/knowledge/home",
  component: knowledgeIndex,
  name: "knowledgeIndex",
  meta: {
    title: "知识库",
    foot: false
  }
}, {
  path: "/knowledge/knowledge_whole",
  component: knowledgeWhole,
  name: "knowledgeWhole",
  meta: {
    title: "全部知识库",
    foot: false
  }
}, {
  path: "/knowledge/knowledge_list",
  component: knowledgeList,
  name: "knowledgeList",
  meta: {
    title: "知识库目录",
    foot: false
  }
}, {
  path: "/knowledge/knowledge_search",
  component: knowledgeSearch,
  name: "knowledgeSearch",
  meta: {
    title: "知识库搜索",
    foot: true
  }
}, {
  path: "/knowledge/knowledge_detail/:articleId?",
  component: knowledgeDetail,
  name: "knowledgeDetail",
  meta: {
    title: "知识库详情",
    foot: false
  }
},
// 奖金池
{
  path: "/others/bonus",
  component: Bonus,
  name: "Bonus",
  meta: {
    title: "奖金池",
    foot: true
  }
}, {
  path: "/appointment/index",
  component: Appointment,
  name: "Appointment",
  meta: {
    title: "预约首页",
    foot: true
  }
}, {
  path: "/show_appointment/:worker_id",
  component: showAppointment,
  name: "showAppointment",
  meta: {
    title: "可预约时间",
    foot: true
  }
}, {
  path: "/appointment_search",
  component: AppointmentSearch,
  name: "AppointmentSearch",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/project_details/:project_id",
  component: ProjectDetails,
  name: "ProjectDetails",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/ProjectDetailsStoreRecord",
  component: ProjectDetailsStoreRecord,
  name: "ProjectDetailsStoreRecord",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/project_list",
  component: ProjectList,
  name: "ProjectList",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/technician_details/:worker_id",
  component: TechnicianDetails,
  name: "TechnicianDetails",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/technician_time/:worker_id",
  component: TechnicianTime,
  name: "TechnicianTime",
  meta: {
    title: "添加工作时间",
    foot: true
  }
}, {
  path: "/hot_technician",
  component: HotTechnician,
  name: "HotTechnician",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/appointment_mine",
  component: AppointmentMine,
  name: "AppointmentMine",
  meta: {
    title: "我的",
    foot: true
  }
}, {
  path: "/technician_work",
  component: TechnicianWork,
  name: "TechnicianWork",
  meta: {
    title: "工作时间",
    foot: true
  }
}, {
  path: "/appointment_project",
  component: AppointmentProject,
  name: "AppointmentProject",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/add_technician/:store_id",
  component: AddTechnician,
  name: "AddTechnician",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/stores_technician",
  component: StoresTechnician,
  name: "StoresTechnician",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/client_appointment",
  component: ClientAppointment,
  name: "ClientAppointment",
  meta: {
    title: "我的预约",
    foot: true
  }
}, {
  path: "/client_evaluate/:service_id",
  component: ClientEvaluate,
  name: "ClientEvaluate",
  meta: {
    title: "评价",
    foot: true
  }
}, {
  path: "/client_verification/:service_id",
  component: ClientVerification,
  name: "ClientVerification",
  meta: {
    title: "核销",
    foot: true
  }
}, {
  path: "/make_appointment/:id",
  component: MakeAppointment,
  name: "MakeAppointment",
  meta: {
    title: "预约",
    foot: true
  }
}, {
  path: "/client_project",
  component: ClientProject,
  name: "ClientProject",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/AppointmentExtends",
  component: AppointmentExtends,
  name: "AppointmentExtends",
  meta: {
    title: "门店预约",
    foot: true
  }
}, {
  // path: "/others/advertisingPage",
  path: "/share_page_detail",
  component: advertisingPage,
  name: "advertisingPage"
  // meta: {
  //   title: "宣传单",
  //   foot: true
  // }
},
// 分销队列
{
  path: "/distribution/index",
  component: DistributionIndex,
  name: "DistributionIndex",
  meta: {
    title: "分销队列活动",
    foot: true
  }
}, {
  path: "/distribution/search",
  component: DistributionSearch,
  name: "DistributionSearch",
  meta: {
    title: "活动列表",
    foot: true
  }
}, {
  path: "/distribution/statistics",
  component: DistributionStatistics,
  name: "DistributionStatistics",
  meta: {
    title: "分销队列",
    foot: true
  }
}, {
  path: "/distribution/detail/:id",
  component: DistributionActivityDetail,
  name: "DistributionActivityDetail",
  meta: {
    title: "活动详情",
    foot: true
  }
}, {
  path: "/distribution/commission_detail/:tag",
  component: DistributionCommissionDetail,
  name: "DistributionCommissionDetail",
  meta: {
    title: "佣金详情",
    foot: true
  }
}, {
  path: "/distribution/participant/:id",
  component: DistributionParticipant,
  name: "DistributionParticipant",
  meta: {
    title: "参与人",
    foot: true
  }
}, {
  path: "/distribution/goods/:id/:activity_id",
  component: Goods,
  name: "DistributionGoods",
  meta: {
    title: "商品详情",
    is_single_share: true,
    foot: true
  }
}, {
  path: "/auctioneer",
  component: auctioneer,
  name: "auctioneer",
  meta: {
    title: "拍卖官管理",
    foot: true
  }
}, {
  path: "/introduceNear/:store_id",
  component: introduceNear,
  name: "introduceNear",
  meta: {
    title: "门店附近推荐商品",
    foot: true
  }
}, {
  path: "/auctionCollect",
  component: auctionCollect,
  name: "auctionCollect",
  meta: {
    title: "我的收藏",
    foot: true
  }
}, {
  path: "/nearSearch/:store_id",
  component: nearSearch,
  name: "nearSearch",
  meta: {
    title: "推荐商品搜索结果",
    foot: true
  }
}, {
  path: "/myAuction/:id",
  component: myAuction,
  name: "myAuction",
  meta: {
    title: "我的拍卖",
    foot: true
  }
}, {
  path: "/myIndex",
  component: myIndex,
  name: "myIndex",
  meta: {
    title: "我的",
    foot: true
  }
}, {
  path: "/myMoney",
  component: myMoney,
  name: "myMoney",
  meta: {
    title: "我的收入",
    foot: true
  }
}, {
  path: "/passwordChange",
  component: passwordChange,
  name: "passwordChange",
  meta: {
    title: "店铺密码设置",
    foot: true
  }
}, {
  path: "/setPassword",
  component: setPassword,
  name: "setPassword",
  meta: {
    title: "密码设置",
    foot: true
  }
}, {
  path: "/shopShare",
  component: shopShare,
  name: "shopShare",
  meta: {
    title: "店铺分享海报",
    foot: true
  }
}, {
  path: "/goodManage",
  component: goodManage,
  name: "goodManage",
  meta: {
    title: "拍品管理",
    foot: true
  }
}, {
  path: "/addNear/:id",
  component: addNear,
  name: "addNear",
  meta: {
    title: "添加推荐商品",
    foot: true
  }
}, {
  path: "/publishFirst",
  component: publishFirst,
  name: "publishFirst",
  meta: {
    title: "发布商品",
    foot: true
  }
}, {
  path: "/publishSecond",
  component: publishSecond,
  name: "publishSecond",
  meta: {
    title: "发布商品",
    foot: true
  }
}, {
  path: "/auctionIndex",
  component: auctionIndex,
  name: "auctionIndex",
  meta: {
    title: "拍卖中心",
    foot: false
  }
}, {
  path: "/auctionMore",
  component: auctionMore,
  name: "auctionMore",
  meta: {
    title: "推荐拍品",
    foot: true
  }
}, {
  path: "/auctionVip",
  component: auctionVip,
  name: "auctionVip",
  meta: {
    title: "贵宾厅",
    foot: true
  }
}, {
  path: "/auctionSearch",
  component: auctionSearch,
  name: "auctionSearch",
  meta: {
    title: "搜索结果",
    foot: true
  }
}, {
  path: "/auctionApply",
  component: auctionApply,
  name: "auctionApply",
  meta: {
    title: "拍卖申请",
    foot: true
  }
}, {
  path: "/auctionRecord/:goods_id",
  component: auctionRecord,
  name: "auctionRecord",
  meta: {
    title: "竞拍记录",
    foot: true
  }
}, {
  path: "/auctionGood/:goods_id/:id?",
  component: auctionGood,
  name: "auctionGood",
  meta: {
    title: "拍卖详情",
    foot: true
  }
}, {
  path: "/auctioneerShop/:id",
  component: auctioneerShop,
  name: "auctioneerShop",
  meta: {
    title: "拍卖官店铺",
    foot: true
  }
}, {
  path: "/auctioneerShopSearch/:id",
  component: auctioneerShopSearch,
  name: "auctioneerShopSearch",
  meta: {
    title: "全部拍品",
    foot: true
  }
}, {
  path: "/auctionLove",
  component: auctionLove,
  name: "auctionLove",
  meta: {
    title: "保证金",
    foot: true
  }
}, {
  path: "/auctionLoveDetail",
  component: auctionLoveDetail,
  name: "auctionLoveDetail",
  meta: {
    title: "保证金明细",
    foot: true
  }
}, {
  path: "/auctionLoveCash",
  component: auctionLoveCash,
  name: "auctionLoveCash",
  meta: {
    title: "保证金提现",
    foot: true
  }
}, {
  path: "/auctionRecharge",
  component: auctionRecharge,
  name: "auctionRecharge",
  meta: {
    title: "保证金充值",
    foot: true
  }
}, {
  path: "/notWithdraw",
  component: notWithdraw,
  name: "notWithdraw",
  meta: {
    title: "不可提现金额",
    foot: true
  }
}, {
  path: "/auctionWithdraw",
  component: auctionWithdraw,
  name: "auctionWithdraw",
  meta: {
    title: "保证金提现",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/qrCoupon",
  component: qrCoupon,
  name: "qrCoupon",
  meta: {
    title: "优惠券二维码",
    foot: true
  }
}, {
  path: "/easyRefuel",
  component: easyRefuel,
  name: "easyRefuel",
  meta: {
    title: "加油",
    foot: false
  }
}, {
  path: "/easyRefuel/search/:city/:point",
  component: easySearch,
  name: "easySearch",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/chooseBank/:order_pay_id/:status?",
  component: chooseBank,
  name: "chooseBank",
  meta: {
    title: "银行卡列表",
    foot: true
  }
}, {
  path: "/addBankFirst/:order_pay_id/:status?",
  component: addBankFirst,
  name: "addBankFirst",
  meta: {
    title: "添加银行卡",
    foot: true
  }
}, {
  path: "/addBankSecond/:order_pay_id/:status?",
  component: addBankSecond,
  name: "addBankSecond",
  meta: {
    title: "填写银行卡信息",
    foot: true
  }
}, {
  path: "/bindBank/:card_id/:order_pay_id/:status?",
  component: bindBank,
  name: "bindBank",
  meta: {
    title: "绑定银行卡",
    foot: true
  }
}, {
  path: "/memberchooseBank",
  component: memberchooseBank,
  name: "memberchooseBank",
  meta: {
    title: "银行卡列表",
    foot: true
  }
}, {
  path: "/memberaddBankFirst",
  component: memberaddBankFirst,
  name: "memberaddBankFirst",
  meta: {
    title: "添加银行卡",
    foot: true
  }
}, {
  path: "/memberaddBankSecond",
  component: memberaddBankSecond,
  name: "memberaddBankSecond",
  meta: {
    title: "添加银行卡",
    foot: true
  }
}, {
  path: "/memberbindBank/:card_id",
  component: memberbindBank,
  name: "memberbindBank",
  meta: {
    title: "绑定银行卡",
    foot: true
  }
}, {
  path: "/memberRemoveBank/:card_id",
  component: memberRemoveBank,
  name: "memberRemoveBank",
  meta: {
    title: "解绑银行卡",
    foot: true
  }
}, {
  path: "/quickIndex",
  component: quickIndex,
  name: "quickIndex",
  meta: {
    title: "汇聚支付",
    foot: true
  }
}, {
  path: "/extendRecord",
  component: extendRecord,
  name: "extendRecord",
  meta: {
    title: "推广记录",
    foot: true
  }
}, {
  path: "/extendHelper",
  component: extendHelper,
  name: "extendHelper",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/extendExplain",
  component: extendExplain,
  name: "extendExplain",
  meta: {
    title: "使用说明",
    foot: true
  }
},
// 推广助手
{
  path: "/popular_assistant/:id",
  component: popularAssistant,
  name: "popularAssistant",
  meta: {
    title: "推广助手",
    foot: true
  }
},
//能量舱
{
  path: "/powerCabin",
  component: powerCabin,
  name: "powerCabin",
  meta: {
    title: "能量舱",
    foot: true
  }
},
// {
//   path: '/powerResult',
//   component: powerResult,
//   name: 'powerResult',
//   meta: {
//     title: '检测结果',
//     foot: true
//   }
// },
// {
//   path: '/powerCheck',
//   component: powerCheck,
//   name: 'powerCheck',
//   meta: {
//     title: '检测记录',
//     foot: true
//   }
// },
{
  path: "/powerStore",
  component: powerStore,
  name: "powerStore",
  meta: {
    title: "店铺信息",
    foot: true
  }
}, {
  path: "/powerSell",
  component: powerSell,
  name: "powerSell",
  meta: {
    title: "能量舱经销商",
    foot: true
  }
}, {
  path: "/powerMember",
  component: powerMember,
  name: "powerMember",
  meta: {
    title: "会员信息",
    foot: true
  }
}, {
  path: "/powerEquipment",
  component: powerEquipment,
  name: "powerEquipment",
  meta: {
    title: "我的设备",
    foot: true
  }
}, {
  path: "/powerDetail",
  component: powerDetail,
  name: "powerDetail",
  meta: {
    title: "会员详情",
    foot: true
  }
}, {
  path: "/bindEquipment",
  component: bindEquipment,
  name: "bindEquipment",
  meta: {
    title: "绑定设备",
    foot: true
  }
}, {
  path: "/powerNotes/:id",
  component: powerNotes,
  name: "powerNotes",
  meta: {
    title: "经销商核销记录",
    foot: true
  }
}, {
  path: "/powerAddMeb/:dealer_uid",
  component: powerAddMeb,
  name: "powerAddMeb",
  meta: {
    title: "会员资料填写",
    foot: true
  }
}, {
  path: "/cardDetail/:id",
  component: cardDetail,
  name: "cardDetail",
  meta: {
    title: "查看详情",
    foot: true
  }
}, {
  path: "/member/subsidyManage",
  component: subsidyManage,
  name: "subsidyManage",
  meta: {
    title: "津贴管理",
    foot: true
  }
}, {
  path: "/teamSell",
  component: teamSell,
  name: "teamSell",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/powerCancellation/:card_type",
  component: powerCancellation,
  name: "powerCancellation",
  meta: {
    title: "核销记录",
    foot: true
  }
}, {
  path: "/teamActive",
  component: teamActive,
  name: "teamActive",
  meta: {
    title: "活跃值",
    foot: true
  }
}, {
  path: "/Cancellation/:id",
  component: Cancellation,
  name: "Cancellation",
  meta: {
    title: "核销",
    foot: true
  }
}, {
  path: "/teamLimit",
  component: teamLimit,
  name: "teamLimit",
  meta: {
    title: "激活权限",
    foot: true
  }
}, {
  path: "/addFamily/:id",
  component: addFamily,
  name: "addFamily",
  meta: {
    title: "家庭成员",
    foot: true
  }
}, {
  path: "/activeRecord",
  component: activeRecord,
  name: "activeRecord",
  meta: {
    title: "活跃值记录",
    foot: true
  }
}, {
  path: "/memberCard",
  component: memberCard,
  name: "memberCard",
  meta: {
    title: "会员卡",
    foot: true
  }
}, {
  path: "/teamCommission/:id",
  component: teamCommission,
  name: "teamCommission",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/storeShareholder",
  component: storeShareholder,
  name: "storeShareholder",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/cloudIndex",
  component: cloudIndex,
  name: "cloudIndex",
  meta: {
    title: $i18n.t("会员中心"),
    foot: false
  }
}, {
  path: "/pickUpArea",
  component: pickUpArea,
  name: "pickUpArea",
  meta: {
    title: "云仓",
    foot: false
  }
}, {
  path: "/pickUp/:id",
  component: pickUp,
  name: "pickUp",
  meta: {
    title: "云仓",
    foot: false
  }
}, {
  path: "/cloudOrder",
  component: cloudOrder,
  name: "cloudOrder",
  meta: {
    title: "下单",
    foot: true
  }
}, {
  path: "/cloudOrderList",
  component: cloudOrderList,
  name: "cloudOrderList",
  meta: {
    title: "提货订单",
    foot: true
  }
}, {
  path: "/cloudOrderDetail",
  component: cloudOrderDetail,
  name: "cloudOrderDetail",
  meta: {
    title: "订单详情",
    foot: true
  }
}, {
  path: "/cloudRecord",
  component: cloudRecord,
  name: "cloudRecord",
  meta: {
    title: "解冻记录",
    foot: true
  }
}, {
  path: "/cloudRelease",
  component: cloudRelease,
  name: "cloudRelease",
  meta: {
    title: "云仓释放",
    foot: true
  }
}, {
  path: "/cloudReleaseLog/:id/:active",
  component: cloudReleaseLog,
  name: "cloudReleaseLog",
  meta: {
    title: "查看详情",
    foot: true
  }
}, {
  path: "/balanceDetail",
  component: balanceDetail,
  name: "balanceDetail",
  meta: {
    title: "余额明细",
    foot: true
  }
}, {
  path: "/wechatCard",
  component: wechatCard,
  name: "wechatCard",
  meta: {
    title: "领取会员卡",
    foot: false
  }
}, {
  path: "/oil_station",
  component: oilStation,
  name: "oilStation",
  meta: {
    title: "全国油站",
    foot: true
  }
}, {
  path: "/salesTable",
  component: salesTable,
  name: "salesTable",
  meta: {
    title: "销售报表",
    foot: false
  }
}, {
  path: "/predictIncome",
  component: predictIncome,
  name: "predictIncome",
  meta: {
    title: "预估收益",
    foot: true
  }
}, {
  path: "/managementIndex/:id",
  component: managementIndex,
  name: "managementIndex",
  meta: {
    title: "厂家店铺",
    foot: true
  }
},
// 门店消费卡
{
  path: "/o2o/store_consume_index",
  component: storeConsumeIndex,
  name: "storeConsumeIndex",
  meta: {
    title: "门店消费卡",
    foot: true
  }
}, {
  path: "/o2o/store_consume_store/:id",
  component: storeConsumeStore,
  name: "storeConsumeStore",
  meta: {
    title: "门店消费卡门店",
    foot: true
  }
}, {
  path: "/o2o/store_consume_show/:id",
  component: storeConsumeShow,
  name: "storeConsumeShow",
  meta: {
    title: "门店消费卡核销码",
    foot: true
  }
}, {
  path: "/o2o/store_consume_use/:id/:card_id",
  component: storeConsumeUse,
  name: "storeConsumeUse",
  meta: {
    title: "门店消费卡核销",
    foot: true
  }
}, {
  path: "/o2o/store_consume_income",
  component: storeConsumeIncome,
  name: "storeConsumeIncome",
  meta: {
    title: "门店消费卡收入明细",
    foot: true
  }
}, {
  path: "/o2oCard/couponLog",
  component: couponLog,
  name: "couponLog",
  meta: {
    title: "使用记录",
    foot: true
  }
}, {
  path: "/member/pick_up_card/:plugin?",
  component: pickUpCardIndex,
  name: "pickUpCardIndex",
  meta: {
    title: "旅游激活卡",
    foot: true
  }
}, {
  path: "/proclamation/:id",
  component: proclamation,
  name: "proclamation",
  meta: {
    title: "厂家公告",
    foot: true
  }
}, {
  path: "/member/pick_up_card_reimburse/:order_id/:type/:plugin?",
  component: pickUpCardReimburse,
  name: "pickUpCardReimburse",
  meta: {
    title: "保证金退款",
    foot: true
  }
}, {
  path: "/managementLis",
  component: managementLis,
  name: "managementLis",
  meta: {
    title: "厂家列表",
    foot: true
  }
}, {
  path: "/managementGoods/:id/:kid",
  component: Goods,
  name: "managementGoods",
  meta: {
    title: "商品详情",
    foot: true
  }
}, {
  path: "/member/pick_up_card_order_list",
  component: pickUpCardOrderList,
  name: "pickUpCardOrderList",
  meta: {
    title: "提货卡订单",
    foot: true
  }
}, {
  path: "/member/pick_up_card_order_list/one",
  component: pickUpCardOrderList,
  name: "pickUpCardOrderListOne",
  meta: {
    title: "提货卡订单",
    foot: true
  }
}, {
  path: "/member/pick_up_card_my",
  component: pickUpCardMy,
  name: "pickUpCardMy",
  meta: {
    title: "我的卡",
    foot: true
  }
}, {
  path: "/member/pick_up_card_my_det",
  component: pickUpCardMyDet,
  name: "pickUpCardMyDet",
  meta: {
    title: "我的卡",
    foot: true
  }
}, {
  path: "/tokerIndex/:city/:point",
  component: tokerIndex,
  name: "tokerIndex",
  meta: {
    title: "拓客卡列表",
    foot: true
  }
}, {
  path: "/video_question",
  component: videoQuestion,
  name: "videoQuestion",
  meta: {
    title: "首页",
    foot: false
  }
}, {
  path: "/answer",
  component: mvideoAnswer,
  name: "mvideoAnswer",
  meta: {
    title: "聚合页",
    foot: true
  }
}, {
  path: "/apply",
  component: videoApply,
  name: "videoApply",
  meta: {
    title: "申请推广员",
    foot: true
  }
}, {
  path: "/watch/:id",
  component: videoWhtch,
  name: "videoWhtch",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/videoRecord",
  component: videoRecord,
  name: "videoRecord",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/videoRecordList/:id",
  component: videoRecordList,
  name: "videoRecordList",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/cpsIndex",
  component: cpsIndex,
  name: "cpsIndex",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/rightsInterests",
  component: rightsInterests,
  name: "rightsInterests",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/circleIndex",
  component: circleIndex,
  name: "circleIndex",
  meta: {
    title: "圈子首页",
    foot: true
  }
}, {
  path: "/circleSearch",
  component: circleSearch,
  name: "circleSearch",
  meta: {
    title: "圈子搜索",
    foot: true
  }
},
// {
//   path: "/circlePay",
//   component: circlePay,
//   name: "circlePay",
//   meta: {
//     title: "支付订单",
//     foot: true
//   }
// },
{
  path: "/circlePayRecords",
  component: circlePayRecords,
  name: "circlePayRecords",
  meta: {
    title: "付费记录",
    foot: true
  }
}, {
  path: "/circleIssue",
  component: circleIssue,
  name: "circleIssue",
  meta: {
    title: "发布",
    foot: true
  }
}, {
  path: "/circleMySet",
  component: circleMySet,
  name: "circleMySet",
  meta: {
    title: "我的设置",
    foot: true
  }
}, {
  path: "/circleMyAttention",
  component: circleMyAttention,
  name: "circleMyAttention",
  meta: {
    title: "我的关注",
    foot: true
  }
}, {
  path: "/circleMyCircle",
  component: circleMyCircle,
  name: "circleMyCircle",
  meta: {
    title: "我的圈子",
    foot: true
  }
}, {
  path: "/circleDetails",
  component: circleDetails,
  name: "circleDetails",
  meta: {
    title: "圈子详情",
    foot: true
  }
}, {
  path: "/circleMyRelease",
  component: circleMyRelease,
  name: "circleMyRelease",
  meta: {
    title: "我的发布",
    foot: true
  }
}, {
  path: "/circleIntroduce",
  component: circleIntroduce,
  name: "circleIntroduce",
  meta: {
    title: "圈子简介",
    foot: true
  }
}, {
  path: "/circleExtend",
  component: circleExtend,
  name: "circleExtend",
  meta: {
    title: "圈子推广中心",
    foot: true
  }
}, {
  path: "/circleSetting",
  component: circleSetting,
  name: "circleSetting",
  meta: {
    title: "圈子设置",
    foot: true
  }
}, {
  path: "/circleChecklist",
  component: circleChecklist,
  name: "circleChecklist",
  meta: {
    title: "审核列表",
    foot: true
  }
}, {
  path: "/circleMember",
  component: circleMember,
  name: "circleMember",
  meta: {
    title: "成员管理",
    foot: true
  }
}, {
  path: "/circleSelect",
  component: circleSelect,
  name: "circleSelect",
  meta: {
    title: "发布",
    foot: true
  }
}, {
  path: "/circlereleaseVideo",
  component: circlereleaseVideo,
  name: "circlereleaseVideo",
  meta: {
    title: "发布视频",
    foot: true
  }
}, {
  path: "/circleTopic",
  component: circleTopic,
  name: "circleTopic",
  meta: {
    title: "发布帖子",
    foot: true
  }
}, {
  path: "/circleVideogood",
  component: circleVideogood,
  name: "circleVideogood",
  meta: {
    title: "选择商品",
    foot: true
  }
}, {
  path: "/topicDetails",
  component: topicDetails,
  name: "topicDetails",
  meta: {
    title: "帖子详情",
    foot: true
  }
}, {
  path: "/circleVideodetail",
  component: circleVideodetail,
  name: "circleVideodetail",
  meta: {
    title: "视频",
    foot: false
  }
}, {
  path: "/circleEdit",
  component: circleEdit,
  name: "circleEdit",
  meta: {
    title: "编辑资料",
    foot: true
  }
}, {
  path: "/shopRanking",
  component: shopRanking,
  name: "shopRanking",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/shopRankingList/:id",
  component: shopRankingList,
  name: "shopRankingList",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/stock",
  //* 云库存
  component: CloudStock,
  name: "StockIndex",
  //* MP：stock/index
  redirect: "/stock/index",
  meta: {
    title: "云库存",
    foot: false
  },
  children: [{
    path: "/stock/index",
    component: CloudStockRelatedLink,
    name: "StockRelatedLinks",
    //* MP：stock/index
    meta: {
      title: "云库存",
      foot: false
    }
  }, {
    path: "promotion_center",
    //* fullpath：/stock/promotion_center
    component: CloudStockPromotionCenter,
    name: "StockPromotionCenter",
    //* MP：stock/promotion_center
    meta: {
      title: "推广中心",
      foot: false
    }
  }]
}, {
  path: "/stock/guide/:target?",
  //* 云库存 聚合
  component: CloudStockGoodsGuide,
  name: "StockGuide",
  //* MP：stock/guide
  meta: {
    title: "聚合",
    foot: true
  }
}, {
  path: "/stock/my",
  //* 云库存 我的库存
  component: ClousStockMyInventory,
  name: "StockMy",
  //* MP：stock/my
  meta: {
    title: "我的库存",
    foot: true
  }
}, {
  path: "/stock/details",
  //* 云库存 库存明细
  component: ClousStockDetails,
  name: "StockDetails",
  //* MP：stock/details
  meta: {
    title: "库存明细",
    foot: false
  }
}, {
  path: "/agency/dividend",
  //* 云库存 销售员分红
  component: function component(r) {
    return require(["../views/others/stock/extension_dividend"], r);
  },
  name: "agencyDividend",
  //* MP：stock/details
  meta: {
    title: "销售员分红",
    foot: true
  }
}, {
  path: "/cpsDetail/:id",
  component: cpsDetail,
  name: "cpsDetail",
  meta: {
    title: "详情",
    foot: true
  }
}, {
  path: "/cpsList/:id/:title",
  component: cpsList,
  name: "cpsList",
  meta: {
    title: "列表"
  }
}, {
  path: "/member/upgrade_code",
  //* 会员 -> 升级码
  component: MemberUpgradeCode,
  name: "MemberUpgradeCode",
  //* MP：/member/upgrade_code/index
  meta: {
    title: "升级码"
  }
}, {
  path: "/member/upgrade_code/group_details",
  //* 会员 -> 升级码 -> 升级码组详情
  component: MemberUpgradeCodeGroupDetails,
  name: "MemberUpgradeCodeGroupDetails",
  //* MP：/member/upgrade_code/group_details
  meta: {
    title: "升级码",
    is_single_share: true
  }
}, {
  path: "/member/upgrade_code/used_log",
  //* 会员 -> 升级码 -> 使用记录
  component: MemberUpgradeCodeUsedLog,
  name: "MemberUpgradeCodeUsedLog",
  //* MP：/member/upgrade_code/used_log
  meta: {
    title: "使用记录",
    foot: false
  }
}, {
  path: "/member/upgrade_code/upgrade_result",
  //* 会员 -> 升级码 -> 升级结果
  component: MemberUpgradeResult,
  name: "MemberUpgradeResult",
  //* MP：/member/upgrade_code/upgrade_result
  meta: {
    title: "升级结果",
    foot: false
  }
}, {
  path: "/cpsCard",
  component: cpsCard,
  name: "cpsCard",
  meta: {
    title: "卡券",
    foot: false
  }
}, {
  path: "/cpsPwd/:order_id",
  component: cpsPwd,
  name: "cpsPwd",
  meta: {
    title: "卡密",
    foot: false
  }
}, {
  path: "/cpsExtension/:tab?/:child?",
  component: cpsExtension,
  name: "cpsExtension",
  meta: {
    title: "",
    foot: false
  }
},
// 下级足迹
{
  path: "/footprintMember",
  component: footprintMember,
  name: "footprintMember",
  meta: {
    title: "下级足迹",
    foot: true
  }
}, {
  path: "/footprintMemberDetail/:id",
  component: footprintMemberDetail,
  name: "footprintMemberDetail",
  meta: {
    title: "下级足迹",
    foot: true
  }
}, {
  path: "/memberTokerCard",
  component: memberTokerCard,
  name: "memberTokerCard",
  meta: {
    title: "拓客卡",
    foot: true
  }
}, {
  path: "/tokerSetMeal/:id",
  component: tokerSetMeal,
  name: "tokerSetMeal",
  meta: {
    title: "拓客卡",
    foot: true
  }
}, {
  path: "/tokerCode/:id/:name",
  component: tokerCode,
  name: "tokerCode",
  meta: {
    title: "拓客卡",
    foot: true
  }
}, {
  path: "/tokerMerchant/:id",
  component: tokerMerchant,
  name: "tokerMerchant",
  meta: {
    title: "拓客卡",
    foot: true
  }
}, {
  path: "/leagueExtends",
  component: leagueExtends,
  name: "leagueExtends",
  meta: {
    title: "电商联盟"
  }
}, {
  path: "/leagueStoreIndex",
  component: LeagueStoreIndex,
  name: "leagueStoreIndex",
  meta: {
    title: "店商联盟"
  }
}, {
  path: "/liveAuthorization",
  component: liveAuthorization,
  name: "liveAuthorization",
  meta: {
    title: "直播授权码",
    foot: true
  }
}, {
  path: "/liveAuthorization_detail",
  component: liveAuthorization_detail,
  name: "liveAuthorization_detail",
  meta: {
    title: "直播授权码",
    foot: true
  }
}, {
  path: "/leagueOperatingCenter",
  component: LeagueOperatingCenter,
  name: "leagueOperatingCenter",
  meta: {
    title: "店商联盟",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/leagueOperatingStore",
  component: LeagueOperatingStore,
  name: "leagueOperatingStore",
  meta: {
    title: "店商联盟",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/leagueServiceCenter",
  component: LeagueServiceCenter,
  name: "leagueServiceCenter",
  meta: {
    title: "店商联盟",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/leagueTaskCenter",
  component: LeagueTaskCenter,
  name: "leagueTaskCenter",
  meta: {
    title: "店商联盟",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/leagueTaskProgress",
  component: LeagueTaskProgress,
  name: "leagueTaskProgress",
  meta: {
    title: "店商联盟",
    foot: true,
    notKeepAlive: true
  }
}, {
  path: "/liveAuthorization_collection/:id",
  component: liveAuthorization_collection,
  name: "liveAuthorization_collection",
  meta: {
    title: "直播授权码",
    foot: true
  }
}, {
  path: "/liveAuthorization_scanCode",
  component: liveAuthorization_scanCode,
  name: "liveAuthorization_scanCode",
  meta: {
    title: "直播授权码",
    foot: false
  }
}, {
  path: "/liveAuthorization_extension",
  component: liveAuthorization_extension,
  name: "liveAuthorization_extension",
  meta: {
    title: "直播授权码",
    foot: true
  }
}, {
  path: "/liveAuthorization_pay/:id",
  component: liveAuthorization_pay,
  name: "liveAuthorization_pay",
  meta: {
    title: "支付",
    foot: true
  }
}, {
  path: "/periodicalIndex",
  component: periodicalIndex,
  name: "periodicalIndex",
  meta: {
    title: "期刊杂志",
    foot: true
  }
}, {
  path: "/periodicalDetail/:id",
  component: periodicalDetail,
  name: "periodicalDetail",
  meta: {
    title: "期刊杂志",
    foot: false
  }
}, {
  path: "/periodicalWatch/:id/:ind",
  component: periodicalWatch,
  name: "periodicalWatch",
  meta: {
    title: "期刊杂志",
    foot: true
  }
}, {
  path: "/flightInformation",
  component: flightInformation,
  name: "flightInformation",
  meta: {
    title: "航班信息收集",
    foot: true
  }
}, {
  path: "/flightInformationDetailList",
  component: flightInformationDetailList,
  name: "flightInformationDetailList",
  meta: {
    title: "航班信息收集记录",
    foot: true
  }
}, {
  path: "/flightInformationDetail/:id/:form_id",
  component: flightInformationDetail,
  name: "flightInformationDetail",
  meta: {
    title: "航班信息收集记录",
    foot: true
  }
}, {
  path: "/questionnaireIndex",
  component: questionnaireIndex,
  name: "questionnaireIndex",
  meta: {
    title: "首页",
    foot: false
  }
}, {
  path: "/questionsSearch",
  component: questionsSearch,
  name: "questionsSearch",
  meta: {
    title: "搜索",
    foot: false
  }
}, {
  path: "/questions/:id/:tag?",
  component: questions,
  name: "questions",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/surveyResult/:id/:score/:allNum/:rewardPoint",
  component: surveyResult,
  name: "surveyResult",
  meta: {
    title: "调查结果",
    foot: true
  }
}, {
  path: "/bountique_album",
  component: bountique_album,
  name: "bountique_album",
  meta: {
    title: "选品专辑"
  }
}, {
  path: "/questioHistory",
  component: questioHistory,
  name: "questioHistory",
  meta: {
    title: "历史记录",
    foot: true
  }
}, {
  path: "/history_details/:id/:questionnaire_id",
  component: history_details,
  name: "history_details",
  meta: {
    title: "问卷详情",
    foot: true
  }
},
//更新下载
{
  path: "/downloadUpdate",
  component: DownloadUpdate,
  name: "downloadUpdate",
  meta: {
    title: "更新下载",
    foot: true
  }
}].concat(DepositGroupRoutes, NewMediaAdRoutes, [{
  path: "/merchantsCommissioner",
  component: MerchantsCommissioner,
  name: "MerchantsCommissioner",
  meta: {
    title: "招商专员",
    foot: true
  }
},
// 门店奖励
{
  path: "/member/extendStoreRewards",
  component: ExtendStoreRewards,
  name: "extendStoreRewards",
  meta: {
    title: "门店奖励",
    foot: true
  }
}, {
  path: "/member/commissionSummary",
  component: CommissionSummary,
  name: "commissionSummary",
  meta: {
    title: "提成概括",
    foot: true
  }
}, {
  path: "/member/storeOrder",
  component: StoreOrder,
  name: "storeOrder",
  meta: {
    title: "订单概括",
    foot: true
  }
}, {
  path: "/member/cashierOrder",
  component: CashierOrder,
  name: "cashierOrder",
  meta: {
    title: "订单概括",
    foot: true
  }
}, {
  path: "/scancodeAccount/:id",
  component: scancodeAccount,
  name: "scancodeAccount",
  meta: {
    title: "扫码支付",
    foot: true
  }
},
// 多门店核销
{
  path: "/StoreVerification",
  component: StoreVerification,
  name: "StoreVerification",
  meta: {
    title: "多门店核销",
    foot: false
  }
}, {
  path: "/WriteOffRecord/:project_title?",
  component: WriteOffRecord,
  name: "WriteOffRecord",
  meta: {
    title: "使用记录",
    foot: false
  }
}, {
  path: "/StoreRecords",
  component: StoreRecords,
  name: "StoreRecords",
  meta: {
    title: "门店记录",
    foot: true
  }
}, {
  path: "/VerificationRecord",
  component: VerificationRecord,
  name: "VerificationRecord",
  meta: {
    title: "核销员记录",
    foot: true
  }
}, {
  path: "/BusinessVerification/:id",
  component: BusinessVerification,
  name: "BusinessVerification",
  meta: {
    title: "核销",
    foot: true
  }
}, {
  path: "/WriteOff",
  component: WriteOff,
  name: "WriteOff",
  meta: {
    title: "核销",
    foot: true
  }
}, {
  path: "/MyProject",
  component: MyProject,
  name: "MyProject",
  meta: {
    title: "我的项目",
    foot: false
  }
}, {
  path: "/VerificationProjectDetails/:id",
  component: VerificationProjectDetails,
  name: "VerificationProjectDetails",
  meta: {
    title: "项目详情",
    foot: false
  }
}, {
  path: "/VerificationStoreList",
  component: VerificationStoreList,
  name: "VerificationStoreList",
  meta: {
    title: "服务门店",
    foot: true
  }
}, {
  path: "/VerificationProjectList",
  component: VerificationProjectList,
  name: "VerificationProjectList",
  meta: {
    title: "项目列表",
    foot: false
  }
}, {
  path: "/MoreStores/:id?/:project_id?/:name?",
  component: MoreStores,
  name: "MoreStores",
  meta: {
    title: "适用门店",
    foot: true
  }
}, {
  path: "/SearchProject",
  component: SearchProject,
  name: "SearchProject",
  meta: {
    title: "项目列表",
    foot: true
  }
}, {
  path: "/CategoryProjectList/:id",
  component: CategoryProjectList,
  name: "CategoryProjectList",
  meta: {
    title: "项目列表",
    foot: false
  }
}, {
  path: "/YeePay",
  component: YeePay,
  name: "YeePay",
  meta: {
    title: "易宝代付签约",
    foot: true
  }
}, {
  path: "/HighLight",
  component: HighLight,
  name: "HighLight",
  meta: {
    title: "高灯代发薪"
  }
}, {
  path: "/FreeRecord",
  component: FreeRecord,
  name: "FreeRecord",
  meta: {
    title: "免单记录",
    foot: true
  }
},
// 聚合cps订单
{
  path: "/cpsOrderList",
  component: cpsOrderList,
  name: "cpsOrderList",
  meta: {
    title: "我的订单"
  }
}, {
  path: "/cpsCouponList",
  component: cpsOrderList,
  name: "cpsCouponList",
  meta: {
    title: "卡券订单",
    foot: true
  }
}, {
  path: "/FreeDetails",
  component: FreeDetails,
  name: "FreeDetails",
  meta: {
    title: "查看详情",
    foot: true
  }
}, {
  path: "/FreeLottery",
  component: FreeLottery,
  name: "FreeLottery",
  meta: {
    title: "抽奖"
  }
}, {
  path: "/interestsGive",
  component: InterestsGive,
  name: "interestsGive",
  meta: {
    title: ""
  }
}, {
  path: "/interestsGive_new",
  component: InterestsGive,
  name: "interestsGive_new",
  meta: {
    title: ""
  }
}, {
  path: "/authorizationQuery",
  component: authorizationQuery,
  name: "authorizationQuery",
  meta: {
    title: "授权查询",
    foot: false
  }
}, {
  path: "/sign_in_subscribe",
  component: SignInSubscribe,
  name: "sign_in_subscribe",
  redirect: "/sign_in_subscribe/home",
  meta: {
    title: "签到认购",
    foot: true
  },
  children: [{
    path: "/sign_in_subscribe/home",
    component: function component() {
      return import("../views/sigin_in_subscribe/children/home.vue");
    },
    name: "sign_in_subscribe_home",
    meta: {
      title: "签到认购",
      foot: true
    }
  }, {
    path: "/sign_in_subscribe/school",
    component: function component() {
      return import("../views/sigin_in_subscribe/children/school.vue");
    },
    name: "sign_in_subscribe_school",
    meta: {
      title: "商学院",
      foot: true
    }
  }, {
    path: "/sign_in_subscribe/sign_in",
    component: function component() {
      return import("../views/sigin_in_subscribe/children/sign_in.vue");
    },
    name: "sign_in_subscribe_sign_in",
    meta: {
      title: "签到",
      foot: true
    }
  }, {
    path: "/sign_in_subscribe/my",
    component: function component() {
      return import("../views/sigin_in_subscribe/children/my.vue");
    },
    name: "sign_in_subscribe_my",
    meta: {
      title: "我的",
      foot: true
    }
  }]
}, {
  name: "sign_in_subscribe_set_meal",
  path: "/sign_in_subscribe_set_meal",
  component: function component() {
    return import("../views/sigin_in_subscribe/set_meal.vue");
  },
  meta: {
    title: "套餐",
    foot: true
  }
}, {
  name: "sign_in_subscribe_team",
  path: "/sign_in_subscribe_team",
  component: function component() {
    return import("../views/sigin_in_subscribe/team.vue");
  },
  meta: {
    title: "我的团队",
    foot: true
  }
}, {
  name: "sign_in_subscribe_wallet",
  path: "/sign_in_subscribe_wallet",
  component: function component() {
    return import("../views/sigin_in_subscribe/wallet.vue");
  },
  meta: {
    title: "钱包地址",
    foot: true
  }
}, {
  name: "sign_in_subscribe_roll_out",
  path: "/sign_in_subscribe_roll_out",
  component: function component() {
    return import("../views/sigin_in_subscribe/roll_out.vue");
  },
  meta: {
    title: "转出",
    foot: true
  }
}, {
  name: "sign_in_subscribe_code",
  path: "/sign_in_subscribe_code",
  component: function component() {
    return import("../views/sigin_in_subscribe/code.vue");
  },
  meta: {
    foot: true
  }
}, {
  name: "sign_in_subscribe_active_value",
  path: "/sign_in_subscribe_active_value",
  component: function component() {
    return import("../views/sigin_in_subscribe/active_value.vue");
  },
  meta: {
    title: "活跃值",
    foot: true
  }
}, {
  name: "sign_in_subscribe_acer_truncatum",
  path: "/sign_in_subscribe_acer_truncatum",
  component: function component() {
    return import("../views/sigin_in_subscribe/acer_truncatum.vue");
  },
  meta: {
    title: "元宝枫",
    foot: true
  }
}, {
  name: "sign_in_subscribe_invite",
  path: "/sign_in_subscribe_invite",
  component: function component() {
    return import("../views/sigin_in_subscribe/invite.vue");
  },
  meta: {
    title: "邀请码",
    foot: true
  }
}, {
  name: "sign_in_subscribe_stock_rights",
  path: "/sign_in_subscribe_stock_rights",
  component: function component() {
    return import("../views/sigin_in_subscribe/stock_rights.vue");
  },
  meta: {
    title: "股权",
    foot: true
  }
}, {
  name: "sign_in_subscribe_user",
  path: "/sign_in_subscribe_user",
  component: function component() {
    return import("../views/sigin_in_subscribe/user.vue");
  },
  meta: {
    title: "用户",
    foot: true
  }
}, {
  name: "sign_in_subscribe_user_detail",
  path: "/sign_in_subscribe_user_detail/:id",
  component: function component() {
    return import("../views/sigin_in_subscribe/user_detail.vue");
  },
  meta: {
    title: "用户详情",
    foot: true
  }
}, {
  name: "sign_in_subscribe_earnings",
  path: "/sign_in_subscribe_earnings",
  component: function component() {
    return import("../views/sigin_in_subscribe/earnings.vue");
  },
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  name: "sign_in_subscribe_trading",
  path: "/sign_in_subscribe_trading",
  component: SignInSubscribeTrading,
  meta: {
    title: "余额转活跃值",
    foot: true
  }
}, {
  name: "sign_in_subscribe_trading_b",
  path: "/sign_in_subscribe_trading_b",
  component: SignInSubscribeTrading,
  meta: {
    title: "活跃值转余额",
    foot: true
  }
}, {
  name: "sign_in_subscribe_shift_card",
  path: "/sign_in_subscribe_shift_card",
  component: function component() {
    return import("../views/sigin_in_subscribe/shift_card.vue");
  },
  meta: {
    title: "转证",
    foot: true
  }
}, {
  path: "/starStore",
  component: starStore,
  name: "starStore",
  meta: {
    title: "星级门店",
    foot: false
  }
}, {
  path: "/RegionalAgency",
  component: RegionalAgency,
  name: "RegionalAgency",
  meta: {
    title: "区域代理招商"
  }
}, {
  path: "/RegionalAgency/MyAgency",
  component: MyAgency,
  name: "MyAgency",
  meta: {
    title: "我的区域代理"
  }
}, {
  path: "/ApplyForMerchants",
  component: ApplyForMerchants,
  name: "ApplyForMerchants",
  meta: {
    title: "申请招商员"
  }
}, {
  path: "/ZhpBonusPool",
  component: BonusDividend,
  name: "ZhpBonusPool",
  meta: {
    title: "股东奖励分红",
    foot: false
  }
}, {
  path: "/Exchange",
  component: Exchange,
  name: "Exchange",
  meta: {
    title: "兑换权益",
    foot: false
  }
}, {
  path: "/Throughpay",
  component: Throughpay,
  name: "Throughpay",
  meta: {
    title: "通证支付",
    foot: true
  }
},
//分销收入奖励
{
  path: "/drm-incomeReward",
  component: function component() {
    return import("../views/member/income/distribution/record.vue");
  },
  name: "drm-incomeReward",
  meta: {
    title: "分销收入奖励",
    foot: true
  }
},
//转换消费积分
{
  path: "/to-integral",
  component: function component() {
    return import("../views/love/love-integral");
  },
  name: "love-integral",
  meta: {
    title: "转换消费积分",
    foot: true
  }
},
// 爱心值转换积分 服用转换消费积分的页面
{
  path: "/to-point",
  component: function component() {
    return import("../views/love/love-integral");
  },
  name: "love-point",
  meta: {
    title: "转换积分",
    foot: true
  }
},
// 爱心值转冻结爱心值
{
  path: "/to-freeze",
  component: function component() {
    return import("../views/love/to-freeze");
  },
  name: "to-freeze",
  meta: {
    title: "转换冻结爱心值",
    foot: true
  }
},
// 消费分红 consumption_dividend 转 积分 point
{
  path: "/consumption_dividend_toPoint/:pool_id",
  component: function component() {
    return import("../views/love/love-integral");
  },
  name: "consumption_dividend_toPoint",
  meta: {
    title: "转换积分",
    foot: true
  }
},
//合伙人分润
{
  path: "/shareProfit",
  component: function component() {
    return import("../views/extension/share-profit.vue");
  },
  name: "shareProfit",
  meta: {
    title: "合伙人分润",
    foot: true
  }
},
//美粉值
{
  path: "/americanFans",
  component: function component() {
    return import("../views/extension/american_fans/index.vue");
  },
  name: "americanFans",
  meta: {
    title: "美粉值",
    foot: true
  }
},
//收益代理管理
{
  path: "/revenue_agency",
  component: function component() {
    return import("../views/revenue_agency/revenue_agency.vue");
  },
  name: "revenue_agency",
  meta: {
    title: "代理收益管理",
    foot: true
  }
},
//团队销售额
{
  path: "/group_sales",
  component: function component() {
    return import("../views/group_sales/group_sales.vue");
  },
  name: "group_sales",
  meta: {
    title: "团队销售额",
    foot: true
  }
},
// 我的团队销售额
{
  path: "/my_team_sales",
  component: function component() {
    return import("../views/group_sales/my_team_sales.vue");
  },
  name: "my_team_sales",
  meta: {
    title: "团队销售额",
    foot: true
  }
},
//爆客二维码分享
{
  path: "/critical-customer-share",
  component: function component() {
    return import("../views/dev-plug/critical-customer-share/critical-customer-share.vue");
  },
  name: "critical-customer-share",
  meta: {
    title: "爆客二维码分享",
    foot: true,
    notKeepAlive: true
  }
},
//新登录页面
{
  path: "/login",
  component: function component() {
    return import("../views/login/new_login.vue");
  },
  name: "login",
  meta: {
    title: $i18n.t("登录"),
    foot: true
  }
},
//新忘记密码页面
{
  path: "/findpwd",
  component: function component() {
    return import("../views/register/new_findpwd.vue");
  },
  name: "findpwd",
  meta: {
    title: "忘记密码",
    foot: true
  }
}, {
  path: "/AuthorizedLogin",
  component: function component() {
    return import("../views/login/AuthorizedLogin.vue");
  },
  name: "AuthorizedLogin",
  meta: {
    title: $i18n.t("登录"),
    foot: true,
    notKeepAlive: true
  }
},
//新注册页面
{
  path: "/register",
  component: function component() {
    return import("../views/register/new_register.vue");
  },
  name: "register",
  meta: {
    title: $i18n.t("用户注册"),
    foot: true
  }
},
//新用户信息页面
{
  path: "/userinfo",
  component: function component() {
    return import("../views/register/new_register.vue");
  },
  name: "userinfo",
  meta: {
    title: $i18n.t("用户注册"),
    foot: true
  }
},
//新设置页面
{
  path: "/member/info",
  component: function component() {
    return import("../views/member/info/new_info_v2.vue");
  },
  name: "info",
  meta: {
    title: $i18n.t("我的信息"),
    foot: true
  }
},
//注册协议和平台协议页面
{
  path: "/registration_platform_agreement",
  component: function component() {
    return import("../views/member/registration_platform_agreement.vue");
  },
  name: "registration_platform_agreement",
  meta: {
    title: $i18n.t("协议页面"),
    foot: true
  }
},
//新登录页面
{
  path: "/usr/missionCenter",
  component: function component() {
    return import("../views/login/missionCenter.vue");
  },
  name: "missionCenter",
  meta: {
    title: "任务中心",
    foot: true
  }
},
//新客裂变
{
  path: "/newcomerFission",
  component: function component() {
    return import("../views/extension/newcomer_fission/newcomer_fission");
  },
  name: "newcomerFission",
  meta: {
    title: "新客裂变",
    foot: true
  }
}, {
  path: "/newcomerReward",
  component: function component() {
    return import("../views/extension/newcomer_fission/newcomer_reward.vue");
  },
  name: "newcomerReward",
  meta: {
    title: "奖励记录",
    foot: true
  }
}, {
  path: '/member_transfer_integral',
  component: function component() {
    return import("../views/member/integral_v2/member_transfer_integral.vue");
  },
  name: "member_transfer_integral",
  meta: {
    foot: true
  }
}, {
  path: "/sigin_in_subscribe_app",
  name: "sigin_in_subscribe_app",
  component: SiginInApp,
  meta: {
    foot: true
  }
}, {
  path: "/sigin_in_subscribe_information",
  name: "sigin_in_subscribe_information",
  component: function component() {
    return import("../views/sigin_in_subscribe/app/information.vue");
  },
  meta: {
    title: "实名认证",
    foot: true
  }
}, {
  path: "/sigin_in_subscribe_income",
  name: "sigin_in_subscribe_income",
  component: function component() {
    return import("../views/sigin_in_subscribe/app/income.vue");
  },
  meta: {
    title: "管理奖记录",
    foot: true
  }
}, {
  path: "/sigin_in_subscribe_withdraw",
  name: "sigin_in_subscribe_withdraw",
  component: function component() {
    return import("../views/sigin_in_subscribe/app/withdraw.vue");
  },
  meta: {
    title: "提现",
    foot: true
  }
}, {
  path: "/sigin_in_subscribe_details",
  name: "sigin_in_subscribe_details",
  component: function component() {
    return import("../views/sigin_in_subscribe/app/details.vue");
  },
  meta: {
    title: "提现记录",
    foot: true
  }
}, {
  path: "/sigin_in_subscribe_collection",
  name: "sigin_in_subscribe_collection",
  component: function component() {
    return import("../views/sigin_in_subscribe/app/collection.vue");
  },
  meta: {
    title: "公司账户",
    foot: true
  }
}, {
  path: "/shareValue",
  component: function component() {
    return import("../views/extension/share_value/index");
  },
  name: "shareValue",
  meta: {
    title: "分享值",
    foot: true
  }
}, {
  path: "/intergral_transform",
  name: "intergral_transform",
  component: function component() {
    return import("../views/member/intergral_transform/index.vue");
  },
  meta: {
    foot: true
  }
},
//跳转参数提示页
{
  path: '/tips',
  component: function component() {
    return import('../views/tips/index.vue');
  },
  name: "tips",
  meta: {
    foot: true
  }
},
// 数据大屏
{
  path: '/dataLarge',
  component: function component() {
    return import('../views/extension/data_large/index');
  },
  name: "dataLarge",
  meta: {
    title: "数据大屏",
    foot: true,
    isPC: true
  }
},
// 新盲盒
{
  path: '/newBlindBox/:id?',
  component: function component() {
    return import('../views/new_blind_box/index.vue');
  },
  name: "newBlindBox",
  meta: {
    title: "盲盒首页",
    foot: true
  }
}, {
  path: '/blindPool/:id?',
  component: function component() {
    return import('../views/new_blind_box/blind_pool.vue');
  },
  name: "blindPool",
  meta: {
    title: "盲盒商品池",
    foot: true
  }
}, {
  path: '/blindBox/:order_id',
  component: function component() {
    return import('../views/new_blind_box/blind_box.vue');
  },
  name: "blindBox",
  meta: {
    title: "盲盒开箱",
    foot: true
  }
}, {
  path: "/TikTokDetail/:id",
  component: function component() {
    return import("../views/member/cps/detail");
  },
  name: "TikTokDetail",
  meta: {
    title: '商品详情',
    foot: true
  }
}, {
  path: "/TikTokCps",
  component: function component() {
    return import("../views/member/cps/index");
  },
  name: "TikTokCps",
  meta: {
    title: '抖音精选'
  }
}, {
  path: "/TikTokBroadcastRoom",
  component: function component() {
    return import("../views/member/cps/broadcast_room.vue");
  },
  name: "TikTokBroadcastRoom",
  meta: {
    title: '抖音精选',
    foot: true
  }
}, {
  path: "/TikTokRoom",
  component: function component() {
    return import("../views/member/cps/room_detail.vue");
  },
  name: "TikTokRoom",
  meta: {
    title: '直播间',
    foot: true
  }
}, {
  path: "/TikTokSearch/:cate_id?/:title?",
  component: function component() {
    return import("../views/member/cps/search.vue");
  },
  name: "TikTokSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/TikTokOrder/:status?",
  name: "TikTokOrder",
  component: function component() {
    return import("../views/member/cps/order.vue");
  },
  meta: {
    title: "抖音精选",
    foot: true
  }
}, {
  path: "/StaffApproval",
  name: "StaffApproval",
  component: function component() {
    return import("../views/workWx/staff_approval");
  },
  meta: {
    title: "员工审批",
    foot: true
  }
}, {
  path: "/StaffApplyFor",
  name: "StaffApplyFor",
  component: function component() {
    return import("../views/workWx/staff_approval/apply_for");
  },
  meta: {
    title: "提交申请",
    foot: true
  }
},
//子平台系统-#11419
{
  path: "/subplatformLogin",
  name: "subplatformLogin",
  component: function component() {
    return import("../views/subplatform/login");
  },
  meta: {
    title: "子平台登录",
    foot: true,
    isPC: true
  }
}, {
  path: "/subplatformt",
  component: function component() {
    return import("../views/subplatform/index");
  },
  redirect: '/subplatformt',
  name: "subplatformtIndex",
  meta: {
    title: "子平台",
    foot: true,
    isPC: true
  },
  children: [{
    path: "subplatformtIndex",
    name: "subplatformtIndex",
    component: function component() {
      return import("../views/subplatform/subplatform");
    },
    meta: {
      title: "子平台管理",
      foot: true,
      isPC: true
    }
  }, {
    path: "subplatformReset",
    name: "subplatformReset",
    component: function component() {
      return import("../views/subplatform/reset");
    },
    meta: {
      title: "重置密码",
      foot: true,
      isPC: true
    }
  }]
}, {
  path: "/subplatformForget",
  name: "subplatformForget",
  component: function component() {
    return import("../views/subplatform/forget");
  },
  meta: {
    title: "忘记密码",
    foot: true,
    isPC: true
  }
}, {
  path: "/urlGuide",
  name: "urlGuide",
  component: function component() {
    return import("../views/urlGuide");
  },
  meta: {
    title: "跳转链接",
    foot: true
  }
},
// 汇聚代发薪签约
{
  path: "/hJwithdrawType",
  name: "hJwithdrawType",
  component: function component() {
    return import("../views/dev-plug/register");
  },
  meta: {
    title: "汇聚代发薪签约",
    foot: true
  }
}, {
  path: "/tax_withdraw_bank",
  name: "tax_withdraw_bank",
  component: function component() {
    return import("../views/dev-plug/register");
  },
  meta: {
    title: "税筹添薪",
    foot: true
  }
}, {
  path: "/phone_bill_recharge_RedirectTo",
  name: "phone_bill_recharge_RedirectTo",
  component: function component() {
    return import("../views/others/phone_bill_recharge_RedirectTo");
  },
  meta: {
    title: "跳转",
    foot: true
  }
}, {
  path: "/orderPaySuccess",
  name: "orderPaySuccess",
  component: function component() {
    return import("../views/order_pay_success");
  },
  meta: {
    title: "支付成功",
    foot: true
  }
}, {
  path: "/microApp_a",
  name: "microApp_a",
  component: function component() {
    return import("../views/others/microApp_a");
  },
  meta: {
    title: "消息",
    foot: true,
    notKeepAliveKey: true
  }
}]).concat(baseRewardTemplate).concat(BlockChain).concat(customizedPlugin).concat(couponStoreRouter).concat(vendingMachineRouter).concat(sachertorteRouter).concat(redeemCodeRouter).concat(taskPackageRouter).concat(storePriceTagRouter).concat(newPosterRouter).concat(stockServiceRouter).concat(checkoutEquipment).concat(sign_pluginRouter).concat(storeRouter).concat(chaining).concat(differenceReward).concat(exchangeBondRouter).concat(sino_skill).concat(dealerTeamReward).concat(anytimeService).concat(unionCpsPlu).concat(ecologicalPoint).concat(water_machineRouter).concat(supportCenter).concat(energyValue).concat(levelCompel).concat(consensusActivity).concat(drinkMachind).concat(priceDifferencePool).concat(courseRouter).concat(yzSupplyLease).concat(period_pay).concat(phoneBillRecharge).concat(videoCourse).concat(ServiceStationDividend).concat(outArea).concat(RandomShot).concat(coffeeMachine).concat(energyMachine).concat(tagBalance).concat(shareRoom).concat(chainStore).concat(communityMarket).concat(rankingListIndex).concat(capital_pool).concat(distribution_fund).concat(freezeCoupon).concat(consumptionDividend).concat(videoSharer).concat(video_on_demand).concat(quickenIncome).concat(reward_pool_dividend).concat(bigScreen).concat(Agents_dividend_tx).concat(numberCollection).concat(new_integral).concat(accountFjyx).concat(customerStatistics).concat(shopsProfits).concat(meituan_refund).concat(expressSignature).concat(tiktok_writeoff).concat(customCluster).concat(agency_reward).concat(manualOrder).concat(jianzhimao_withdraw).concat(love_release_bonu).concat(line_up_free).concat(distributionCoupon).concat(gradevin).concat(syncmain).concat(giftPackage).concat(txTeamPerformance).concat(integralShift).concat(yccMoney).concat(cloud_pay_money_sign).concat(coupon_scan_share).concat(assessment).concat(platform_order_import).concat(id_shop).map(function (route) {
  route.path = rootPath + route.path;
  return route;
});
// 404 页
routes.push({
  path: "*",
  component: NotFound,
  name: "notfound",
  meta: {
    title: "404",
    foot: true
  }
});
export default routes;