import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 链动 #10027
export default [{
  path: "/chainStoreIndex",
  component: function component() {
    return import("../../../views/chainStore/chainStoreIndex.vue");
  },
  name: "chainStoreIndex",
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/chainStoreSearch/:store_id",
  component: function component() {
    return import("../../../views/chainStore/chainStoreSearch.vue");
  },
  name: "chainStoreSearch",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/chainStoreSearchStore",
  component: function component() {
    return import("../../../views/chainStore/chainStoreSearchStore.vue");
  },
  name: "chainStoreSearchStore",
  meta: {
    title: "搜索",
    foot: true
  }
}, {
  path: "/chainStoreLoaction",
  component: function component() {
    return import("../../../views/chainStore/chainStoreLoaction.vue");
  },
  name: "chainStoreLoaction",
  meta: {
    title: "选择城市",
    foot: true
  }
}, {
  path: "/chainStoreLoactionCity",
  component: function component() {
    return import("../../../views/chainStore/chainStoreLoactionCity.vue");
  },
  name: "chainStoreLoactionCity",
  meta: {
    title: "选择地址",
    foot: true
  }
}];