import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/donation_detail/:id?",
  name: "donation_detail",
  component: function component() {
    return import("../../../views/member/donationProject/donation_detail");
  },
  meta: {
    title: "捐赠详情",
    foot: true
  }
}, {
  path: "/donation_certificate/:id?",
  name: "donation_certificate",
  component: function component() {
    return import("../../../views/member/donationProject/donation_certificate");
  },
  meta: {
    title: "电子证书",
    foot: true
  }
}];