import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import cFoot from "components/foot";
import loading from "components/loading";
import U_foot from "components/new_diy/foot";
import crmBind from "components/other/crmBind";
import mHead from "components/other/mHead";
import walletBind from "components/other/walletBind";
import qrCode from "components/qrCode";
import yzWechatShareImg from "components/ui_components/yz_wechatShareImg";

// import { mapState, mapMutations } from 'vuex';
//import BMap from 'BMap';

var isCreated = 0;
export default {
  data: function data() {
    return {
      invite: "",
      //记录form请求微信form请求只发送一次
      selected: false,
      foot: true,
      zone: "",
      isLocation: false,
      isRouterAlive: true,
      is_Inviter: "",
      invite_mobile: "",
      isCloneWalletBind: false,
      //是否自己关闭了钱包弹出

      id: "M" + new Date().getTime()
    };
  },
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    isShowReturnLiveBtn: function isShowReturnLiveBtn() {
      return this.$store.state._isShowReturnLiveBtn;
    },
    isShowCrmBind: function isShowCrmBind() {
      return this.$store.state._isShowCrmBind;
    },
    isShowWalletBind: function isShowWalletBind() {
      return this.$store.state._isShowWalletBind;
    },
    showWechatshar: function showWechatshar() {
      return this.$store.state.showWechatshar.value;
    },
    shareText: function shareText() {
      return this.$store.state.showWechatshar.text;
    }
  },
  watch: {
    //监测路由变化
    $route: function $route(to, from) {
      if (from.path === "/") {
        if (this.fun.getKey("t")) {
          this.reload();
        }
      }
      if (to.meta.title) {
        this.fun.setWXTitle(to.meta.title);
      } else {
        var _this$$store$state;
        var shopName = ((_this$$store$state = this.$store.state) === null || _this$$store$state === void 0 || (_this$$store$state = _this$$store$state.temp) === null || _this$$store$state === void 0 || (_this$$store$state = _this$$store$state.mailInfo) === null || _this$$store$state === void 0 ? void 0 : _this$$store$state.name) || null;
        if (shopName) this.fun.setWXTitle(shopName);
      }
      this.foot = to.meta.foot;

      // 浏览足迹  页面切换时更新new_page_comeIn
      window.new_page_comeIn = 1;
    }
  },
  mounted: function mounted() {
    var titleName = "加载中";
    try {
      if (this.fun.isCPS()) {
        titleName = cps.getAppName();
      }
    } catch (error) {
      console.log("获取app标题======", error);
    }
    this.fun.setWXTitle(titleName);
    this.isCloneWalletBind = localStorage.getItem("isCloneWalletBind") || false;
    window.homeFirst = 0;
    var that = this;

    /*区分关闭和刷新，关闭清理vid  start*/
    // 搬到index.html执行  因为要优先http.js执行
    // window.onload = function() {
    //   if (!window.sessionStorage["tempFlag"]) {
    //     window.localStorage.removeItem("uid");
    //     window.localStorage.removeItem("mid");
    //
    //     window.localStorage.removeItem("scope");
    //     window.localStorage.removeItem("appid");
    //     window.localStorage.removeItem("mob_parent");
    //     window.localStorage.removeItem("mob_user");
    //     window.localStorage.removeItem("timetamp");
    //     window.localStorage.removeItem("sign");
    //     window.localStorage.removeItem("videoReload");
    //     window.sessionStorage.removeItem("myLocation");
    //   } else {
    //     window.sessionStorage.removeItem("tempFlag");
    //   }
    // };
    window.onunload = function () {
      window.sessionStorage["tempFlag"] = true;
      window.localStorage.removeItem("vid");
      window.localStorage.removeItem("circle_invitation_id");
    };
    window.onbeforeunload = function () {
      window.sessionStorage["tempFlag"] = true;
      that.delCookie("memberlogin_status");
    };
    /*区分关闭和刷新，关闭清理vid end*/

    if (this.fun.getKey("reportId")) {
      var feast_system_param = {
        reportId: this.fun.getKey("reportId"),
        // reportId 宴席id
        qyDomain: this.fun.getKey("qyDomain"),
        // qyDomain 宴席域名
        shopId: this.fun.getKey("shopId"),
        // shopId 商城id
        tenantId: this.fun.getKey("tenantId"),
        // tenantId 供应链id
        qyCode: this.fun.getKey("qyCode") // qyCode 宴席编码
      };
      window.localStorage.setItem("feast_system_param", JSON.stringify(feast_system_param));
    }
    if (this.fun.getKey("big_screen_owner_id")) {
      var orderOtherParams = {
        big_screen_owner_id: this.fun.getKey("big_screen_owner_id") // 大屏机主id
      };
      window.sessionStorage.setItem("isQRPay", true);
      window.sessionStorage.setItem("orderOtherParams", JSON.stringify(orderOtherParams));
    }
  },
  created: function created() {
    if (isCreated === 0) {
      window.localStorage.removeItem("vid");
      // window.localStorage.removeItem("uid");
      window.localStorage.removeItem("mid");
      window.localStorage.removeItem("customizeHotelHead");
      this.initShare();
      this.getNowPackageDeliver(); //更新自提点关联
    }
    if (this.fun.getKey("shrID")) window.sessionStorage.setItem("shrID", this.fun.getKey("shrID") || 0);
    if (this.fun.getKey("min_token")) {
      window.sessionStorage.setItem("min_token", this.fun.getKey("min_token"));
    }
  },
  beforeUpdate: function beforeUpdate() {
    if (isCreated === 1) {
      this.initShare();
    }
  },
  updated: function updated() {
    isCreated = 1;
  },
  methods: {
    gotoLiveRoom: function gotoLiveRoom() {
      this.$router.push(this.fun.getUrl("livePage"));
    },
    clearLiveBtn: function clearLiveBtn() {
      this.$store.commit("setReturnLiveBtn", false);
    },
    initFoot: function initFoot(item) {
      this.foot = item;
    },
    getCookie: function getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return unescape(arr[2]);
      } else {
        return null;
      }
    },
    delCookie: function delCookie(name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    },
    reload: function reload() {
      var _this = this;
      this.isRouterAlive = false;
      this.$nextTick(function () {
        _this.isRouterAlive = true;
      });
    },
    //初始化分享设置
    initShare: function initShare() {
      if (this.$route.meta.is_single_share) {
        // 加入设置了页面单独设置分享则不进行全局分享设置
        return;
      }
      var _url = document.location.href;
      this.fun.wxShare("", {
        url: _url
      }, {
        share_from: "app"
      });
    },
    wxLocationGet: function wxLocationGet() {
      var json = {
        longitude: this.fun.getLat(),
        latitude: this.fun.getLng()
      };

      //获取城市信息
      $http.get("geocoding.get-address-component", json).then(function (response) {
        console.log("TAG:", json);
        if (response.result == 1) {
          //通过获取的district获取zoneId
          //this.getZoneId(response.data.district);
          //window.localStorage.setItem("nowZone", response.data.district);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getNowPackageDeliver: function getNowPackageDeliver() {
      var _this2 = this;
      var deliverIDStorage = sessionStorage.getItem("deliverId") || null; //获取当前会话是否存储了自提点id
      if (window.location.href.indexOf("deliverId") > -1 && !deliverIDStorage) {
        //添加判断sessionStorage是否已存在的目的是防止在其他页面刷新，关联的自提点又变成了链接上的，导致之前手动切换的失效
        var list = ["/home", "/newDiy", "/selfPickupPoint/category", "/selfPickupPoint/search"];
        for (var i = 0; i < list.length; i++) {
          if (window.location.href.indexOf(list[i]) > -1) {
            return false;
          }
        }
        var _dyID = this.fun.getKey("deliverId"); //获取链接上的参数，自提点id

        $http.get("plugin.package-deliver.frontend.decorate-deliver.getNowPackageDeliver", {
          deliver_id: _dyID
        }).then(function (response) {
          if (response.result === 1) {
            window.sessionStorage.setItem("deliverId", response.data.id); //储存到sessionStorage
          } else {
            _this2.$toast(response.msg);
          }
        }, function (response) {
          console.log(response);
        });
      }
    }
  },
  components: {
    U_foot: U_foot,
    loading: loading,
    qrCode: qrCode,
    crmBind: crmBind,
    walletBind: walletBind,
    yzWechatShareImg: yzWechatShareImg,
    mHead: mHead
  }
};