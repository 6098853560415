import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//排行榜开发
{
  path: "/rankingList/index",
  component: function component() {
    return import("../../../views/rankingList/index/index.vue");
  },
  name: "rankingTop",
  meta: {
    title: "排行榜",
    foot: false
  }
}, {
  path: "/rankingList/shareBonus",
  component: function component() {
    return import("../../../views/rankingList/shareBonus/shareBonus.vue");
  },
  name: "shareBonus",
  meta: {
    title: "排行榜分红",
    foot: true
  }
}, {
  path: "/rankingList/bonusDetail",
  component: function component() {
    return import("../../../views/rankingList/bonusDetail/bonusDetail.vue");
  },
  name: "bonusDetail",
  meta: {
    title: "小红花明细",
    foot: true
  }
}];