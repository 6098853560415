import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/supportCenter/index",
  component: function component() {
    return import("../../../views/others/supportCenter/supportCenterIndex.vue");
  },
  name: "supportCenterIndex",
  meta: {
    title: "帮扶中心",
    foot: true
  }
}, {
  path: "/supportCenter/Verifier",
  component: function component() {
    return import("../../../views/others/supportCenter/supportCenterVerifier.vue");
  },
  name: "supportCenterVerifier",
  meta: {
    title: "核销",
    foot: true
  }
}];