import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//能量仓对接 -- 复用咖啡机页面
{
  path: "/energy_machine",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine.vue");
  },
  name: "energy_machine",
  meta: {
    title: "设备管理",
    foot: true
  }
}, {
  path: "/energy_machine/:machine_id",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machineIndex.vue");
  },
  name: "energy_machineIndex",
  meta: {
    title: "门店中心",
    foot: true
  }
}, {
  path: "/energy_machine_record/:machine_id",
  component: function component() {
    return import("../../../views/coffee_machine/coffee_machine_record.vue");
  },
  name: "energy_machine_record",
  meta: {
    title: "收入明细",
    foot: true
  }
}, {
  path: "/energy_machine_Good/:machine_id",
  component: function component() {
    return import("../../../views/energy_machine/energy_machine_Good");
  },
  name: "energy_machine_cate",
  meta: {
    title: "商品列表",
    foot: true
  }
}];