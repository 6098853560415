var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "c-walletBind" } },
    [
      _c(
        "van-popup",
        {
          style: { height: "40%" },
          attrs: {
            position: "bottom",
            "close-on-click-overlay": false,
            round: ""
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("van-nav-bar", { attrs: { title: "绑定钱包地址" } }),
          _c(
            "div",
            { staticStyle: { padding: "40px 20px 0" } },
            [
              !_vm.walletSite
                ? _c(
                    "van-button",
                    {
                      attrs: { type: "danger", block: "", round: "" },
                      on: { click: _vm.getCurrentBTYAddress }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("获取钱包地址")))]
                  )
                : _vm._e(),
              _vm.walletSite
                ? _c(
                    "van-cell-group",
                    { attrs: { inset: "", border: false } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "钱包地址",
                          value: _vm.walletSite,
                          border: false
                        }
                      }),
                      _c(
                        "van-button",
                        {
                          attrs: { type: "primary", block: "", round: "" },
                          on: { click: _vm.bindBTYAddress }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("确认绑定")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "van-button",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { block: "", round: "" },
                  on: { click: _vm.setCloneWalletBind }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("暂不绑定")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }