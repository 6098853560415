var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.css) } }),
      _vm.isChoose !== "/category" &&
      _vm.isChoose !== "/cart" &&
      _vm.isChoose !== "/member/extension"
        ? _c("div", {
            style: { height: _vm.fun.isIphoneX() ? 84 + "px" : 50 + "px" }
          })
        : _vm._e(),
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "advertising-preview",
              class: [_vm.fun.isIphoneX() ? "iphoneXStyle" : ""],
              style: { width: _vm.clientWidth + "px" }
            },
            [
              _c(
                "ul",
                {
                  staticClass: "weixin-menu flex-a-c",
                  style: { width: _vm.clientWidth + "px" },
                  attrs: { id: "weixin-menu" }
                },
                _vm._l(_vm.menu_button, function(btn, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      staticClass: "menu-item flex-a-c flex-j-c",
                      class: {
                        current:
                          _vm.isChoose == _vm.urlParse(btn.link) ||
                          _vm.page_id == _vm.urlParse(btn.link)
                      },
                      style: { maxWidth: 100 / _vm.menu_button.length + "%" },
                      on: {
                        click: function($event) {
                          return _vm.selectedMenu(i, btn)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "menu-item-title flex-a-c flex-j-c",
                          class: _vm.showBadge(btn) ? "posNew" : "",
                          style: {
                            flexDirection:
                              _vm.tab_style.icon_set.icon_position == "top"
                                ? "column"
                                : "row"
                          }
                        },
                        [
                          btn.isIcon == "1" && _vm.tab_style.icon_set.icon_show
                            ? _c("i", {
                                staticClass: "menu-icon iconfont",
                                class: [
                                  btn.isIcon == "1"
                                    ? btn.icon || "icon-fenlei4"
                                    : ""
                                ]
                              })
                            : _vm._e(),
                          btn.isIcon == "2" && _vm.tab_style.icon_set.icon_show
                            ? _c(
                                "div",
                                {
                                  class:
                                    _vm.tab_style.text_set.text_show && btn.text
                                      ? "img-text"
                                      : "only-img"
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon-img",
                                    class:
                                      _vm.seletedId === btn.id &&
                                      !btn.text &&
                                      _vm.selectedMenuIndex
                                        ? "rotateImg"
                                        : "normalImg",
                                    attrs: {
                                      src: btn.image || _vm.emptyImage,
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm.tab_style.icon_set.icon_position == "top" &&
                          btn.isIcon != "3"
                            ? _c("div", {
                                staticClass: "hide-box",
                                staticStyle: { height: "10px" }
                              })
                            : _vm._e(),
                          _vm.tab_style.text_set.text_show && btn.text
                            ? _c(
                                "span",
                                {
                                  staticClass: "menu-text",
                                  style: {
                                    display:
                                      _vm.tab_style.icon_set.icon_position ==
                                      "top"
                                        ? "block"
                                        : "",
                                    marginLeft:
                                      _vm.tab_style.icon_set.icon_position ==
                                      "top"
                                        ? ""
                                        : "2px"
                                  }
                                },
                                [_vm._v(_vm._s(btn.text))]
                              )
                            : _vm._e(),
                          _vm.showBadge(btn) && _vm.getBadgeNum(btn) > 0
                            ? _c(
                                "div",
                                {
                                  class:
                                    _vm.getBadgeNum(btn) < 10
                                      ? "newTbox1"
                                      : _vm.getBadgeNum(btn) < 99
                                      ? "newTbox2"
                                      : "newTbox3"
                                },
                                [
                                  _c("div", { staticClass: "colorTxt" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getBadgeNum(btn) > 999
                                          ? "999+"
                                          : _vm.getBadgeNum(btn)
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      btn.isMinApp == 2 &&
                      btn.sub_button &&
                      btn.sub_button.length <= 0 &&
                      !_vm.fun.isApp() &&
                      !_vm.fun.isCPS()
                        ? _c("div", {
                            staticClass: "minLink",
                            domProps: { innerHTML: _vm._s(btn.html) },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          })
                        : _vm._e(),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.selectedMenuIndex === i &&
                                btn.sub_button &&
                                btn.sub_button.length > 0,
                              expression:
                                "selectedMenuIndex === i && btn.sub_button && btn.sub_button.length > 0"
                            }
                          ],
                          staticClass: "weixin-sub-menu",
                          class: {
                            "Circle-main":
                              _vm.tab_style.children_set.circle_show
                          }
                        },
                        _vm._l(btn.sub_button, function(sub, i2) {
                          return _c(
                            "li",
                            {
                              key: i2,
                              staticClass: "menu-sub-item",
                              class: {
                                current:
                                  _vm.isChoose == _vm.urlParse(sub.link) ||
                                  _vm.page_id == _vm.urlParse(sub.link)
                              },
                              style: _vm.tab_style.children_set.circle_show
                                ? _vm.selectedMenuIndex === i
                                  ? _vm.getSubPosition(btn.sub_button, i2)
                                  : { top: "160px", left: "100px" }
                                : {},
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.selectedSubMenu(i2, sub)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "menu-item-title",
                                  style: {
                                    lineHeight:
                                      _vm.tab_style.icon_set.icon_position ==
                                        "top" &&
                                      _vm.tab_style.icon_set.icon_show &&
                                      _vm.tab_style.text_set.text_show &&
                                      sub.isIcon != "3"
                                        ? "25px"
                                        : "50px",
                                    paddingTop:
                                      _vm.tab_style.icon_set.icon_position ==
                                      "top"
                                        ? "2px"
                                        : ""
                                  }
                                },
                                [
                                  sub.isIcon == "1" &&
                                  _vm.tab_style.icon_set.icon_show
                                    ? _c("i", {
                                        staticClass: "menu-icon iconfont",
                                        class: [
                                          sub.isIcon == "1"
                                            ? sub.icon || "icon-fenlei4"
                                            : ""
                                        ]
                                      })
                                    : _vm._e(),
                                  sub.isIcon == "2" &&
                                  _vm.tab_style.icon_set.icon_show
                                    ? _c("img", {
                                        staticClass: "icon-img",
                                        class:
                                          _vm.tab_style.text_set.text_show &&
                                          sub.text
                                            ? "img-text"
                                            : "only-img",
                                        attrs: {
                                          src: sub.image || _vm.emptyImage,
                                          alt: ""
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.tab_style.text_set.text_show
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "menu-text",
                                          style: {
                                            display:
                                              _vm.tab_style.icon_set
                                                .icon_position == "top"
                                                ? "block"
                                                : "",
                                            marginLeft:
                                              _vm.tab_style.icon_set
                                                .icon_position == "top"
                                                ? ""
                                                : "2px"
                                          }
                                        },
                                        [_vm._v(_vm._s(sub.text))]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              sub.isMinApp == 2 &&
                              !_vm.fun.isApp() &&
                              !_vm.fun.isCPS()
                                ? _c("div", {
                                    staticClass: "minLink",
                                    domProps: { innerHTML: _vm._s(sub.html) },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _c("van-overlay", { attrs: { show: _vm.showOverlay } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }