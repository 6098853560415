import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/reward_pool_dividend",
  name: "reward_pool_dividend",
  component: function component() {
    return import("../../../views/others/speed_up_index.vue");
  },
  meta: {
    foot: true,
    title: "奖金池分红"
  }
}];