import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/redeem_code/:share?",
  name: "redeem_code",
  component: function component() {
    return import("../../../views/redeem_code/index.vue");
  },
  meta: {
    title: "",
    foot: false
  }
}, {
  path: "/redeem_code_list",
  name: "redeem_code_list",
  component: function component() {
    return import("../../../views/redeem_code/center.vue");
  },
  meta: {
    title: "兑换码",
    foot: false
  }
}, {
  path: "/redeem_code_history",
  name: "redeem_code_history",
  component: function component() {
    return import("../../../views/redeem_code/history.vue");
  },
  meta: {
    title: "兑换码历史记录",
    foot: false
  }
}];