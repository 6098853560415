import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/meituan_refund",
  name: "meituan_refund",
  component: function component() {
    return import("../../../views/meituan_refund/index.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/meituan_refund_search",
  name: "meituan_refund_search",
  component: function component() {
    return import("../../../views/meituan_refund/search.vue");
  },
  meta: {
    foot: true
  }
}, {
  path: "/meituan_refund_detail",
  name: "meituan_refund_detail",
  component: function component() {
    return import("../../../views/meituan_refund/detail.vue");
  },
  meta: {
    title: "商品详情",
    foot: true
  }
}, {
  path: "/meituan_refund_order/:status?",
  name: "MeiTuanOrder",
  component: function component() {
    return import("../../../views/meituan_refund/order.vue");
  },
  meta: {
    title: "美团团购",
    foot: true
  }
}];