import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [
//love-release-bonus
{
  path: "/love_release_bonu",
  component: function component() {
    return import("../../../views/others/love_release_bonu.vue");
  },
  name: "love_release_bonu",
  meta: {
    title: "我的推广",
    foot: true
  }
}];