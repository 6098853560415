import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/tiktok_writeoff/:store_id?/:tiktok_shop_id?",
  component: function component() {
    return import("../../../views/tiktok_writeoff/index.vue");
  },
  name: "tiktok_writeoff",
  meta: {
    title: "抖音团购券核销"
  }
}, {
  path: "/tiktok_writeoff_record",
  component: function component() {
    return import("../../../views/tiktok_writeoff/record.vue");
  },
  name: "tiktok_writeoff_record",
  meta: {
    title: "核销记录"
  }
}, {
  path: "/tiktok_writeoff_record_detail/:id?",
  component: function component() {
    return import("../../../views/tiktok_writeoff/record_detail.vue");
  },
  name: "tiktok_writeoff_record_detail",
  meta: {
    title: "核销记录"
  }
}];