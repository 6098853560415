import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/splitview/index",
  name: "splitviewIndex",
  component: function component() {
    return import("../../../views/dev-plug/splitview/index.vue");
  },
  meta: {
    foot: true,
    isPC: true,
    notKeepAlive: true,
    title: '农优云',
    noLogPath: true
  }
}, {
  path: "/splitview/apply",
  name: "splitviewApply",
  component: function component() {
    return import("../../../views/dev-plug/splitview/apply.vue");
  },
  meta: {
    foot: true,
    notKeepAlive: true,
    title: '农优云'
  }
}, {
  path: "/splitview/reward",
  name: "splitviewReward",
  component: function component() {
    return import("../../../views/dev-plug/splitview/reward.vue");
  },
  meta: {
    foot: true,
    title: '农优云'
  }
}];