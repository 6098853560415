import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/video_sharer",
  name: "video_sharer",
  component: function component() {
    return import("../../../views/video_sharer/index.vue");
  },
  meta: {
    title: "视频号分享员邀请页"
  }
}];