var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.onlyValue !== "true"
      ? _c("small", [_vm._v(_vm._s(this.currentCurrency))])
      : _vm._e(),
    _vm.onlyUnit !== "true"
      ? _c("span", [_vm._v(_vm._s(this.showValue))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }