/**
 * Created by zhong on 16/10/15.
 */

export default {
  state: {
    //true

    adv_img: "",
    adv_url: ""
  },
  mutations: {
    setAdvimg: function setAdvimg(state, n) {
      state.adv_img = n.adv_img;
      state.adv_url = n.adv_url;
    }
  },
  actions: {},
  getters: {}
};