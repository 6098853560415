import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/customCluster/index",
  component: function component() {
    return import("../../../views/customCluster/polyIndex/polyIndex.vue");
  },
  name: "customIndex",
  meta: {
    title: "首页",
    foot: false
  }
}, {
  path: "/customCluster/takeList",
  component: function component() {
    return import("../../../views/customCluster/takeList/takeList.vue");
  },
  name: "customTakeList",
  meta: {
    title: "参与记录",
    foot: true
  }
}, {
  path: "/customCluster/customList/:groupId",
  component: function component() {
    return import("../../../views/customCluster/customList/customList.vue");
  },
  name: "customNameList",
  meta: {
    title: "定制团名称",
    foot: true
  }
}, {
  path: "/customCluster/statistics",
  component: function component() {
    return import("../../../views/customCluster/statistics/statistics.vue");
  },
  name: "cTatistics",
  meta: {
    title: "业绩统计",
    foot: true
  }
}, {
  path: "/customCluster/customUser",
  component: function component() {
    return import("../../../views/customCluster/customUser/customUser.vue");
  },
  name: "customUser",
  meta: {
    title: "我的",
    foot: true
  }
}];