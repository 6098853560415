import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 通用列表模板，如果是放在会员中心，需要重新定义一个新的name，不然会导致装修出现问题#28131
var baseRewardTemplate = function baseRewardTemplate(r) {
  return require(["../../../views/dev-plug/base-reward-template/index"], r);
};
export default [{
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplate",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/transfer/template",
  component: function component() {
    return import("../../../views/dev-plug/base-reward-template/transfer.vue");
  },
  name: "baseTransferTemplate",
  meta: {
    title: "",
    foot: true,
    notKeepAlive: true
  }
},
// -------------下面是处理会员中心的
{
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateRecommendReward",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateDealerAgent",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateUserRights",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateAgencyBalance",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateTxTeamPerIndex",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateGoodsContribution",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/reward/template",
  component: baseRewardTemplate,
  name: "baseRewardTemplateHeroCard",
  meta: {
    title: "",
    foot: true
  }
}, {
  path: "/base/transfer/template",
  component: function component() {
    return import("../../../views/dev-plug/base-reward-template/transfer.vue");
  },
  name: "baseTransferTemplateAccPoints",
  meta: {
    title: "",
    foot: true,
    notKeepAlive: true
  }
}];