import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//云汇算
export default [{
  path: "/cloud_pay_money_sign",
  name: "cloud_pay_money_sign",
  component: function component() {
    return import("../../../views/others/cloud_pay_money_sign.vue");
  },
  meta: {
    title: "云汇算电签",
    foot: true
  }
}];