import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
var DEFAULT_LANG = 'en';
var LOCALE_KEY = 'localeLanguage';
var locales = {
  zh: require('./zh.json'),
  en: require('./en.json')
};
var i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
  silentTranslationWarn: true
});
export var setup = function setup(lang) {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang);
  if (document.body.classList) {
    Object.keys(locales).forEach(function (lang) {
      document.body.classList.remove("lang-".concat(lang));
    });
    document.body.classList.add("lang-".concat(lang));
  }
  document.body.setAttribute('lang', lang);
  Vue.config.lang = lang;
  i18n.locale = lang;
};
setup("en");
window.$i18n = i18n;
window.$t = i18n.t;
export default i18n;