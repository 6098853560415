import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapState, mapMutations } from 'vuex';

export default {
  props: ["text", "hide", "goback", "url", "totext", "tolink", "setParams", "setQuery", "popClose", "showToast", 'toastTxt', 'isorder', 'backQuery'],
  data: function data() {
    return {
      show: false
    };
  },
  activated: function activated() {},
  mounted: function mounted() {
    //this.tempInfo();
  },
  methods: {
    gourl: function gourl(url) {
      if (url == 'presentationRecord') {
        this.$router.push(this.fun.getUrl(url, {}, {
          extension: 'extension'
        }));
      } else {
        this.$emit('postUrlTap', url);
        this.show = false;
      }
    },
    tapShow: function tapShow() {
      this.show = !this.show;
    },
    goto: function goto() {
      if (this.fun.isCPS()) {
        if (this.fun.isIosOrAndroid() === "ios") {
          routeBackUrlIOS({
            body: "routeBackUrlIOS"
          });
        } else {
          routeBackUrlAndroid();
        }
        return;
      }
      try {
        if (this.popClose) {
          this.$emit("onGoBack");
        } else if (this.url) {
          console.log('dddddddddddddd');
          if (this.backQuery) {
            this.$router.push(this.fun.getUrl(this.url, {}, {
              ind: this.backQuery
            }));
            return;
          }
          this.$router.push(this.fun.getUrl(this.url));
        } else if (window.history.length <= 1) {
          if (window.isMiniprogram) {
            // 小程序端跳转对应页面
            return wx.miniProgram.navigateBack({
              delta: 1
            });
          }
          if (this.fun.isWindVane()) {
            var params = {
              animation: true
            };
            window.WindVane.call('SBase', 'navigationBack', params, function (e) {
              console.log('navigationBack success:' + JSON.stringify(e));
            }, function (e) {
              console.log('navigationBack failure:' + JSON.stringify(e));
            });
            return;
          }
          this.$router.push(this.fun.getUrl("home", {}));
        } else {
          this.$router.go(-1);
        }
      } catch (error) {
        if (window.isMiniprogram) {
          // 小程序端跳转对应页面
          return wx.miniProgram.navigateBack({
            delta: 1
          });
        }
        if (this.fun.isWindVane()) {
          var _params = {
            animation: true
          };
          window.WindVane.call('SBase', 'navigationBack', _params, function (e) {
            console.log('navigationBack success:' + JSON.stringify(e));
          }, function (e) {
            console.log('navigationBack failure:' + JSON.stringify(e));
          });
          return;
        }
        this.$router.push(this.fun.getUrl("home", {}));
      }
    }
  }
};