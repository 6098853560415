import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/assessmentIndex",
  name: "assessmentIndex",
  component: function component() {
    return import("../../../views/assessment/assessmentIndex");
  },
  meta: {
    title: "测评",
    foot: true
  }
}, {
  path: "/assessment_questionnaire/:id/:view?",
  name: "assessment_questionnaire",
  component: function component() {
    return import("../../../views/assessment/assessment_questionnaire");
  },
  meta: {
    title: "测评",
    foot: true
  }
}, {
  path: "/assessment_questionnaire_record",
  name: "assessment_questionnaire_record",
  component: function component() {
    return import("../../../views/assessment/assessment_questionnaire_record");
  },
  meta: {
    title: "测评",
    foot: true
  }
}, {
  path: "/assessmentReport/:id/:view?",
  name: "assessmentReport",
  component: function component() {
    return import("../../../views/assessment/assessmentReport");
  },
  meta: {
    title: "测评报告",
    foot: true
  }
}, {
  path: "/member/orderpay/:order_ids/:ass_log_id",
  component: function component() {
    return import("../../../views/member/order/order_payment");
  },
  name: "orderpay_assessment",
  meta: {
    title: "订单支付",
    foot: true
  }
}];