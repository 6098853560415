import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 商城电子合同 路由表
export default [
//商城电子合同
{
  path: "/shop_sign/indexV2/:scene/:order_id?",
  //scene 场景类型1订单2提现3任务包
  component: function component() {
    return import("../../../views/others/sign_shopV2/index.vue");
  },
  name: "shopSignIndexV2",
  meta: {
    title: "合同签署",
    foot: true
  }
}];