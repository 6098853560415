import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//商品套餐二开，礼包套餐
export default [{
  path: "/gift/giftIndex/:id",
  component: function component() {
    return import("../../../views/giftPackage/giftIndex/giftIndex.vue");
  },
  name: "giftIndex",
  meta: {
    title: "套餐名称",
    foot: true
  }
}, {
  path: "/gift/giftGoodsList/:id",
  component: function component() {
    return import("../../../views/giftPackage/giftGoodsList/giftGoodsList.vue");
  },
  name: "giftGoodsList",
  meta: {
    title: "组合套餐列表",
    foot: true
  }
}];